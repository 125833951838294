/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { TransactionsExceptionDTO } from './TransactionsExceptionDTO'

export class TransactionsExceptionEdit implements TransactionsExceptionDTO {
  commissionBatchOid=''
  paymentDateMillis=''
  statementDateMillis=''
  isValidated=''
  principal=''
  remunerationType=''
  oid=''
  policyNumber=''
  advCode=''
  planCode=''
  premiumAmt=null
  transactionType=''
  issuedDateMillis=null
  payMode=null
  productType=''
  commissionType=''
  descriptions=''
  productName=''
  advName=''
  clientName=''
  commissionAmt=''
  paidToDate=''
  policyYear=''
  paid=''
  remark=''
  orCommissionAmt=''
  premiumType=''
  premiumYear=''
  statusCode=''

  assignAttributes (data: TransactionsExceptionDTO) {
    const {commissionBatchOid, paymentDateMillis, statementDateMillis, isValidated, principal, remunerationType, oid, policyNumber,advCode, planCode, premiumAmt, issuedDateMillis, payMode, productType, commissionType, descriptions, productName, advName, commissionAmt, paidToDate, policyYear, paid,remark, transactionType, orCommissionAmt, premiumType, premiumYear, clientName} = data
    this.commissionBatchOid= commissionBatchOid
    this.paymentDateMillis = paymentDateMillis
    this.statementDateMillis = statementDateMillis
    this.isValidated = isValidated
    this.principal = principal
    this.remunerationType = remunerationType
    this.oid = oid
    this.policyNumber = policyNumber
    this.advCode = advCode
    this.planCode = planCode
    this.premiumAmt = premiumAmt
    this.premiumType= premiumType
    this.premiumYear=premiumYear
    this.issuedDateMillis = issuedDateMillis
    this.payMode = payMode
    this.productType = productType
    this.commissionType = commissionType
    this.descriptions = descriptions
    this.productName = productName
    this.advName = advName
    this.commissionAmt = commissionAmt
    this.paidToDate = paidToDate
    this.policyYear = policyYear
    this.paid = paid
    this.remark = remark
    this.transactionType=transactionType
    this.orCommissionAmt=orCommissionAmt
    this.statementDateMillis=statementDateMillis
    this.clientName=clientName
  }
}
