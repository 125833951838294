import { Base } from '@master/model/Base'

export class ClientInfoBase extends Base {
  /**
   * this will assign the data to the object directly
   * @param data
   */
  extractAndAssignData (data: {}) {
    const b4data = super.beforeExtractData(data)
    const extdata = super.extractData(b4data)
    const finaldata = this.afterExtractData(extdata)
    Object.assign(this, finaldata)
  }
}
