/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BscBaseRecordRequestDTO = {
    reviewYear: number;
    reviewQuarter: BscBaseRecordRequestDTO.reviewQuarter;
    status: BscBaseRecordRequestDTO.status;
}

export namespace BscBaseRecordRequestDTO {

    export enum reviewQuarter {
        Q1 = 'Q1',
        Q2 = 'Q2',
        Q3 = 'Q3',
        Q4 = 'Q4',
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }

    export enum status {
        OPEN = 'Open',
        CLOSE = 'Close',
    }


}
