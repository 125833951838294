import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import '@/DIContainer'
import ActivityCard from './model/ActivityCard'

@Module({
  namespaced: true
})
export default class ActivityStore extends VuexModule {
  activities: ActivityCard[] = []

  @Mutation
  addActivity (activity: ActivityCard) {
    this.activities.push(activity)
  }

  get formsAndExams () {
    let forms = []
    let exams = []
    this.activities.forEach((act) => {
      // get exam types
      if (act.exams && act.exams.length) {
        exams = exams.concat(act.exams.map(exam => exam.name))
      }
      // get form types
      if (act.forms && act.forms.length) {
        forms = forms.concat(act.forms.map(form => form.type))
      }
    })
    forms = [...new Set(forms)]
    exams = [...new Set(exams)]
    return { forms, exams }
  }

  @Mutation
  upsertActivity (activity: ActivityCard) {
    const target = this.activities.find(act => act.oid === activity.oid)
    if (target) {
      Object.assign(target, activity)
    } else this.activities.push(activity)
  }

  @Mutation
  resetActivities () {
    this.activities = []
  }

  get allActivityPending () {
    return this.activities.every(act => act.status === 'PENDING')
  }

  get allActivityInProgress () {
    return this.activities.every(act => act.status === 'INPROGRESS')
  }

  get allActivityInReadyForSubmission () {
    return this.activities.every(act => act.status === 'READY_FOR_SUBMISSION')
  }

  get allActivityProcessing () {
    return this.activities.every(act => act.status === 'PROCESSING')
  }

  get allActivityCompleted () {
    return this.activities.every(act => act.status === 'COMPLETED')
  }
}
