import { ClientBase } from '../../ClientBase'
import { sum, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import { Default } from '@master/annotation/Default'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

/**
 * By default netWorthPlanning and noPlanningReason
 * is used by both client 1 and 2. All other_*_text
 * are shared as well.
 */
export default class NetWorth extends ClientBase {
  netWorthPlanning = ''

  @MaxChar(InputMaxChar.MEDIUM)
  noPlanningReason = ''
  
  @Default(0)
  others1 = ''

  @Default(0)
  cash = ''

  @Default(0)
  residentialAssets = ''

  @Default(0)
  motor = ''

  @Default(0)
  otherPersonalAssets = ''

  @Default(0)
  investBusiness = ''

  @Default(0)
  investProperty = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others1Text = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others2Text = ''

  @Default(0)
  stocksShares = ''

  @Default(0)
  unitTrust = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others3Text = ''

  @Default(0)
  others3 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others4Text = ''

  @Default(0)
  others4 = ''

  @Default(0)
  cpfOrdinary = ''

  @Default(0)
  cpfSpecial = ''

  @Default(0)
  cpfRa = ''

  @Default(0)
  srs = ''

  @Default(0)
  lifeInsuranceCash = ''

  @Default(0)
  cpfMedisave = ''

  @Default(0)
  bankDeposits = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others5Text = ''

  @Default(0)
  others5 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others6Text = ''

  @Default(0)
  others6 = ''

  @Default(0)
  homeMortgage = ''

  @Default(0)
  carLoans = ''

  @Default(0)
  personalLoans = ''

  @Default(0)
  creditCard = ''

  @Default(0)
  educationLoans = ''

  @Default(0)
  tax = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others7Text = ''

  @Default(0)
  others7 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others8Text = ''

  @Default(0)
  others8 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others9Text = ''

  @Default(0)
  others9 = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)
  }

  get totalUsedAssets () {
    return sum(this.cash, this.motor, this.residentialAssets, this.otherPersonalAssets)
  }

  get totalInvestedAssets () {
    return sum(this.investBusiness, this.stocksShares, this.investProperty, this.unitTrust, this.cpfOrdinary, this.cpfSpecial, this.cpfRa, this.cpfMedisave, this.srs, this.lifeInsuranceCash, this.others1)
  }

  get totalCashAssets () {
    return sum(this.bankDeposits, this.others5, this.others6)
  }

  get totalAssets () {
    return sum(this.totalUsedAssets, this.totalInvestedAssets, this.totalCashAssets)
  }

  get totalLiabilities () {
    return sum(this.homeMortgage, this.carLoans, this.creditCard, this.educationLoans, this.tax, this.others7)
  }

  get netWorth () {
    if (valueIsUndefined(this.totalAssets) && valueIsUndefined(this.totalLiabilities)) return null
    return sum(this.totalAssets, -this.totalLiabilities)
  }
}
