
import CCBS from '@master/model/kyc-pdf/CCBS'

export default class CCBSCustom extends CCBS {
  isAllValid () {
    let valid = true
    Object.keys(this).forEach((key) => {
      // not selected client/rep, no need call back survey (CBS)
      if (key === 'isNotSelectedClientOrRep' || this.isNotSelectedClientOrRep) return
      if ((this.outcomeRole === 'trusted-individual' || this.selectedClient !== 'yes') && key === 'qnAns4') return
      if (key === 'qnAnsRole') return
      if (key.includes('2nd') || key.includes('3rd')) return
      // not contactable/dont wanna take survey
      if (key.includes('qnAns') && this.outcome !== 'contactable_surveyed') return
      // client is not selected client and outcomeRole not trusted-individual, then exclude qnAns7
      // if (key === 'qnAns7' && (this.selectedClient === 'no' || this.outcomeRole !== 'client')) return
      // this version doesn't contain qn5-7
      if (key === 'qnAns7' || key === 'qnAns5' || key === 'qnAns6') return
      // no need answer callback question `aware of trusted individual option`
      if (this[key] === '' || !this[key]) {
        console.log(key, 'ahwaer')
        valid = false
      }
    })
    return valid
  }
}
