/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommissionScheduleCsvDTO } from '../models/CommissionScheduleCsvDTO';
import type { CommissionScheduleDTO } from '../models/CommissionScheduleDTO';
import type { NewCommissionScheduleDTO } from '../models/NewCommissionScheduleDTO';
import type { NewProductDTO } from '../models/NewProductDTO';
import type { ProductDTO } from '../models/ProductDTO';
import type { ProductFilterDTO } from '../models/ProductFilterDTO';
import { request as __request } from '@openapi/request';

export class ProductService {

    /**
     * @param requestBody
     * @returns ProductDTO Return list of products
     * @throws ApiError
     */
    public static async retrieveAllProducts(
        requestBody: ProductFilterDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/all`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param productOid
     * @returns ProductDTO
     * @throws ApiError
     */
    public static async getProductDetails(
        productOid: string,
    ): Promise<ProductDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product/${productOid}/details`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async productCsvExport(
        requestBody: Array<string>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/csv-export`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async productCsvImport(
        requestBody: Array<NewProductDTO>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/csv-import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async productCsvValidate(
        requestBody: Array<NewProductDTO>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/csv-validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ProductDTO
     * @throws ApiError
     */
    public static async createNewProduct(
        requestBody: NewProductDTO,
    ): Promise<ProductDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ProductDTO
     * @throws ApiError
     */
    public static async updateProduct(
        requestBody: ProductDTO,
    ): Promise<ProductDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/product`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ProductDTO
     * @throws ApiError
     */
    public static async deleteProducts(
        requestBody: Array<string>,
    ): Promise<ProductDTO> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/product`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param productOid
     * @returns CommissionScheduleDTO
     * @throws ApiError
     */
    public static async getProductCommissionSchedules(
        productOid: string,
    ): Promise<Array<CommissionScheduleDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product/${productOid}/commission-schedule`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CommissionScheduleDTO
     * @throws ApiError
     */
    public static async createCommissionSchedule(
        requestBody: NewCommissionScheduleDTO,
    ): Promise<CommissionScheduleDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/commission-schedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CommissionScheduleDTO
     * @throws ApiError
     */
    public static async editCommissionSchedule(
        requestBody: CommissionScheduleDTO,
    ): Promise<CommissionScheduleDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/product/commission-schedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CommissionScheduleDTO
     * @throws ApiError
     */
    public static async deleteCommissionSchedule(
        requestBody: CommissionScheduleDTO,
    ): Promise<CommissionScheduleDTO> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/product/commission-schedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async validateCommissionSchedulesCsv(
        requestBody: Array<CommissionScheduleCsvDTO>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/commission-schedule/csv-validation`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async importCommissionSchedulesCsv(
        requestBody: Array<CommissionScheduleCsvDTO>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/commission-schedule/import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string Product Commission Schedule CSV
     * @throws ApiError
     */
    public static async exportCommissionScheduleCsv(
        requestBody: Array<string>,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/commission-schedule/export`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async updateProductsIssuerOid(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product/update-all-issuer-oid`,
        });
        return result.body;
    }

}