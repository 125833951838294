export default class InternalSub {
  _oid = ''
  companyName = ''
  products = []

  constructor (rawData) {
    this.extractData(rawData)
  }

  extractData (rawData: any) {
    if (rawData) {
      Object.keys(this).forEach((key) => {
        this[key] = rawData[key]
      })
    }
  }

  updateProperties (latest: object) {
    Object.keys(latest).forEach((key) => {
      this[key] = latest[key]
    })
  }
}
