/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserEducationDTO } from '../models/AdviserEducationDTO';
import { request as __request } from '@openapi/request';

export class AdviserEducationService {

    /**
     * @returns AdviserEducationDTO Retrieve the list of Adviser Education records
     * @throws ApiError
     */
    public static async retrieveAdviserEducationList(): Promise<Array<AdviserEducationDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_education/get_list`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserEducationDTO Retrieve a specific  Adviser Education record
     * @throws ApiError
     */
    public static async retrieveAdviserEducation(
oid: string,
): Promise<AdviserEducationDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_education/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns AdviserEducationDTO Updates a  Adviser Education record
     * @throws ApiError
     */
    public static async updateAdviserEducation(
oid: string,
requestBody: AdviserEducationDTO,
): Promise<AdviserEducationDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/adviser_education/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns any Deletes a Adviser education record (hard delete)
     * @throws ApiError
     */
    public static async deleteAdviserEducation(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/adviser_education/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserEducationDTO Retrieve Adviser Education records by user oid 
     * @throws ApiError
     */
    public static async retrieveAdviserEducationByUserOid(
oid: string,
): Promise<Array<AdviserEducationDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_education/user/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Creates Adviser Education record(s)
     * @throws ApiError
     */
    public static async createAdviserEducations(
requestBody: Array<AdviserEducationDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser_education`,
            body: requestBody,
        });
        return result.body;
    }

}