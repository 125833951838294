import { ClientBase } from '../../ClientBase'
import { Ignore } from '@master/annotation/Ignore'
import { Default } from '@master/annotation/Default'
import { sum, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

/**
 * When declaring CashFlow, do note that if frequency
 * is not provided, it will default all values based on
 * annually
 */
export default class CashFlow extends ClientBase {
  cashFlowPlanning = ''

  @MaxChar(InputMaxChar.MEDIUM)
  cashFlowPlanningReasons = ''

  @Default(0)
  employment = ''

  @Default(0)
  business = ''

  @Default(0)
  mortgageLoan = ''

  @Default(0)
  carLoan = ''

  @Default(0)
  lifestyleExpenses = ''

  @Default(0)
  investmentIncome = ''

  @Default(0)
  dividends = ''

  @Default(0)
  rentalIncome = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others10Text = ''

  @Default(0)
  others10 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others11Text = ''

  @Default(0)
  others11 = ''

  @Default(0)
  loanRepayment = ''

  @Default(0)
  householdExpenses = ''

  @Default(0)
  parentalSupport = ''

  // @Default(0)
  // education = ''

  @Default(0)
  personalExpenses = ''

  @Default(0)
  insurancePremiums = ''

  @Default(0)
  regularSavings = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others12Text = ''

  @Default(0)
  others12 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others13Text = ''

  @Default(0)
  others13 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others14Text = ''

  @Default(0)
  others14 = ''

  cashFlowChanges = ''

  @MaxChar(InputMaxChar.MEDIUM)
  cashFlowChangesReasons = ''

  @MaxChar(InputMaxChar.XXXLARGE)
  cashFlowAdditionalNotes = '' // previously was your_finances_additional_notes

  // Not to be stored variables
  @Ignore()
  frequency = '' // to determine the calculation of the financial needs analysis calculation, either 'monthly' or 'annually'

  get totalIncome () { // was totalAnnualIncome
    return sum(this.employment, this.business, this.investmentIncome, this.dividends, this.rentalIncome, this.others10, this.others11)
  }

  /**
   * Although in the case of annually both
   * totalIncome and totalAnnualIncome will return the same
   * value, this is for scenarios when the frequency is monthly
   */
  get totalAnnualIncome () {
    if (this.frequency === 'annually') {
      return this.totalIncome
    }

    return this.totalIncome * 12
  }

  get totalExpenses () { // was totalAnnualExpenses
    return sum(this.loanRepayment,
      this.mortgageLoan,
      this.carLoan,
      this.lifestyleExpenses,
      this.householdExpenses,
      this.parentalSupport,
      this.personalExpenses,
      this.insurancePremiums,
      this.regularSavings,
      this.others12,
      this.others13,
      this.others14
    )
  }

  /**
   * Although in the case of annually both
   * totalExpenses and totalAnnualExpenses will return the same
   * value, this is for scenarios when the frequency is monthly
   */
  get totalAnnualExpenses () {
    if (this.frequency === 'annually') {
      return this.totalExpenses
    }
    return this.totalExpenses * 12
  }

  /**
   * When frequency is annually, calling surplusShortfall
   * and surplusAnnualShortfall is the same. In the case
   * of monthly, surplusShortfall will return the monthly
   * shortfall
   */
  get surplusShortfall () {
    if (valueIsUndefined(this.totalIncome) && valueIsUndefined(this.totalExpenses)) return null
    return sum(this.totalIncome, -this.totalExpenses)
  }

  get surplusAnnualShortfall () {
    if (valueIsUndefined(this.totalAnnualIncome) && valueIsUndefined(this.totalAnnualExpenses)) return null
    return sum(this.totalAnnualIncome, -this.totalAnnualExpenses)
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', frequency: 'annually' | 'monthly' = 'annually') {
    super(type)
    this.frequency = frequency
  }

  /**
   * Depending on the customer's needs we will change the default the frequency
   * accordingly to what is required
   * @param data
   */
  convertToRawData () {
    return {
      ...super.convertToRawData()
    }
  }
}
