import { BscGradeImportSummaryDto } from '@core/pages/bsc/api/models/BscGradeImportSummaryDto'

export class BscGradeImportSummary implements BscGradeImportSummaryDto {
  oid = ''
  adviserCode = ''
  writingAdviserGrade = ''
  writingAdviserGrossVariableIncome = null
  misconductReportRequired = ''
  reportingAdviser1Code = ''
  reportingAdviser1GrossVariableIncome = null
  reportingAdviser2Code = ''
  reportingAdviser2GrossVariableIncome = null
  reportingAdviser3Code = ''
  reportingAdviser3GrossVariableIncome = null
  reportingAdviser4Code = ''
  reportingAdviser4GrossVariableIncome = null
  isActive = undefined
  bscBaseRecordOid = ''
  year = null
  quarter = null
}