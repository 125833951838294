import { RouteRecordRaw } from 'vue-router'
 
export const approvalsRouter = ((): RouteRecordRaw[] => {
  return [
    {
      path: '/approvals',
      name: 'Approvals List',
      component: () => import('@core/pages/approvals/components/Approvals.vue'),
      meta: {
        subject: ['approval', 'incomeDeductible']
      }
    }]
})()