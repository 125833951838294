import { TypeBase } from '../TypeBase'

export default class RPA extends TypeBase {
  rpaCompleted = false
  question1?: number = undefined
  question2?: number = undefined
  question3?: number = undefined
  question4?: number = undefined
  question5?: number = undefined
  question6?: number = undefined
  agreement = ''
  ownChoice = false
  reviewConfirmation = ''
  riskProfile = '' // chosen risk profile, previously was risk_profile
  riskProfileCalculated = '' // calculated risk profile based on riskScore, previously was risk_score
  riskProfilePreferred = '' // preferred risk profile, previously was risk_profile_preferred
  riskScore = 0 // was rp_result
  // completed = false // was complete_cka_rpa, not required

  // RISK PROFILES
  // as_per_questionnaire
  // conservative
  // moderately_conservative
  // balanced
  // moderately_aggressive
  // aggressive

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(`${type}_rpa`)
  }

  get riskProfileResult () {
    let result = ''
    if (this.riskProfile === 'as_per_questionnaire') {
      if (this.agreement === 'no') {
        result = this.riskProfilePreferred
      } else {
        result = this.riskProfileCalculated
      }
    } else {
      result = this.riskProfile
    }
    return result
  }
}
