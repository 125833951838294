export const RelationshipOptions = [
  { value: '', label: '' },
  { value: 'Aunt', label: 'Aunt' },
  { value: 'Authorised Signatory', label: 'Authorised Signatory' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Cousin', label: 'Cousin' },
  { value: 'Daughter', label: 'Daughter' },
  { value: 'Director', label: 'Director' },
  { value: 'Father', label: 'Father' },
  { value: 'Grandchild', label: 'Grandchild' },
  { value: 'Grandfather', label: 'Grandfather' },
  { value: 'Grandmother', label: 'Grandmother' },
  { value: 'Legal Guardian', label: 'Legal Guardian' },
  { value: 'Husband', label: 'Husband' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Nephew', label: 'Nephew' },
  { value: 'Niece', label: 'Niece' },
  { value: 'Others', label: 'Others' },
  { value: 'Shareholder', label: 'Shareholder' },
  { value: 'Spouse', label: 'Spouse' },
  { value: 'Son', label: 'Son' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Uncle', label: 'Uncle' },
  { value: 'Ward', label: 'Ward' },
  { value: 'Wife', label: 'Wife' }
]


export const RelationshipLabel = RelationshipOptions.map(item => item.label)
export const RelationshipValues = RelationshipOptions.map(item => item.value)
export const getRelationshipByLabelValues = (arg: string) => RelationshipOptions.find(item => item?.label?.toLowerCase() === arg.toLowerCase() || item?.value?.toLowerCase() === arg?.toLowerCase() )