import { MaxChar } from '@master/annotation/MaxChar'
import { Base } from '../Base'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class SwitchingPolicy extends Base {
  @MaxChar(InputMaxChar.XSMALL)
  name = ''

  @MaxChar(InputMaxChar.XSMALL)
  planType = ''

  @MaxChar(InputMaxChar.XSMALL)
  policyNum = ''

  inceptionDate = undefined
  surrenderDate = undefined

  @MaxChar(InputMaxChar.XSMALL)
  benefitsProvided = ''

  @MaxChar(InputMaxChar.LARGE)
  reasons = ''

  @MaxChar(InputMaxChar.XSMALL)
  detail = ''

  newProduct = ''
  premiumAmt = ''
  coverage = ''

  clone (template: SwitchingPolicy) {
    Object.keys(template).forEach((attr) => {
      this[attr] = template[attr]
    })
  }

  reset () {
    this.detail = ''
    this.name = ''
    this.planType = ''
    this.policyNum = ''
    this.inceptionDate = null
    this.surrenderDate = null
    this.benefitsProvided = ''
    this.newProduct = ''
    this.premiumAmt = ''
    this.coverage = ''
  }
}
