import { rangeStep } from 'lodash/fp'
import { ProductMetaData } from '../ProductMetaData'

export const CoveringTypeOptions = [
  {
    label: 'Comprehensive - Classic',
    value: 'comprehensive-classic'
  },
  {
    label: 'Comprehensive - Premier',
    value: 'comprehensive-premier'
  },
  {
    label: 'Third Party Fire & Theft',
    value: 'thirdpartyfiretheft'
  },
  {
    label: 'Third Party Fire Only',
    value: 'thirdpartyfireonly'
  }
]

export const NcdOptions = () => {
  return rangeStep(0, 60, 5).map((pc) => {
    return {
      label: pc + '%',
      value: `${pc}`
    }
  })
}
export class GiMotor extends ProductMetaData {
  insuranceClass = undefined
  hpCompany = undefined
  coverNoteNo = undefined
  coverNoteIssueDate = undefined
  coveringType = undefined
  grossPremium = undefined
  gst = undefined
  vehicleMake = undefined
  vehicleModel = undefined
  vehicleNo = undefined
  engineCapacity = undefined
  sittingCapacity = undefined
  registrationYear = undefined
  drivingExperience = undefined
  ncd = undefined
  commissionRate = undefined
  commissionPayment = undefined
  commissionPayable = undefined
  grossCommissionEarned = undefined

  afterExtractData (data) {
    // data.investmentAmount = Number(data.investmentAmount)
    // data.upfrontFees = Number(data.upfrontFees)
    // data.transactionFees = Number(data.transactionFees)
    // data.upfrontComm = Number(data.upfrontComm)
    return data
  }

  convertToRawData () {
    const raw = super.convertToRawData()
    return raw
  }
}
