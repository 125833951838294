import { ClientBase } from '@master/model/ClientBase'
// import BeneficialOwnerParticulars from '@master/model/kyc-form/external/BeneficialOwnerParticulars'
import CustomBOPParticulars from './CustomBOPParticulars'
export default class BeneficialOwner extends ClientBase {
  declarationBeneficialOwner = ''
  beneficialOwnerParticulars?: CustomBOPParticulars = undefined

  afterExtractData (data: { beneficialOwnerParticulars: {} }) {
    const bo = new CustomBOPParticulars()
    Object.assign(bo, bo.extractData(data.beneficialOwnerParticulars || {}))
    data.beneficialOwnerParticulars = bo
    return data
  }

  convertToRawData () {
    const result: any = super.convertToRawData()
    // As beneficial owner particular is a Base, we need to add them in manually
    if (this.beneficialOwnerParticulars) {
      result[this.type + '_beneficial_owner_particulars'] = this.beneficialOwnerParticulars.convertToRawData() // eslint-disable-line @typescript-eslint/camelcase
    }
    return result
  }
}
