import { snakeCase, isEmpty, camelCase } from 'lodash/fp'
import { ClientBase } from '../ClientBase'

export default class MyInfo extends ClientBase {
  infoLinked = ''
  lockedFields: string[] = []
  selectedProperty = -1
  timeSecondsFetched?: number = undefined
  timeSecondsLinked?: number = undefined
  transactionToken?: string = ''

  public snakeCaseKey (key: string) {
    return `myinfo_${snakeCase(key)}_${this.type}`
  }

  afterExtractData (data: { lockedFields: string }) {
    if (data.lockedFields && !isEmpty(data.lockedFields)) {
      const parsedLockedFields = JSON.parse(data.lockedFields)
      // convert rawkey to camelkey (key that same as kycform modal key),
      // so that it can direct use in kycform UI for myinfo checking
      data.lockedFields = parsedLockedFields.map((ele) => this.convertToFriendlyKey(ele))
    }
    return data
  }

  // do not convert to raw data, myInfo data should not override from frontend
  convertToRawData () {
    return {}
  }

  convertToFriendlyKey (rawKey: string) {
    const prefix = ['ma_', 'ja_']
    const prefixToReplace = prefix.find((ele) => {
      return rawKey.indexOf(ele) === 0
    }).length
    return camelCase(rawKey.substring(prefixToReplace))
  }

  keyIsLockedField (targetKey: string) {
    if (!this.lockedFields) {
      return
    }
    return this.lockedFields.includes(targetKey)
  }
}
