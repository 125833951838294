import { options } from '@master/model/options/Options'

export const ContractTypeList = [
  { name: 'Financial Consultant', rank: 'Tier 1' },
  { name: 'Senior Financial Consultant', rank: 'Tier 1' },
  { name: 'Executive Financial Consultant', rank: 'Tier 1' },
  { name: 'Executive Wealth Manger', rank: 'Tier 1' },
  { name: 'Associate Financial Services Manager', rank: 'Tier 2' },
  { name: 'Financial Services Manager', rank: 'Tier 2' },
  { name: 'Senior Financial Services Manager', rank: 'Tier 2' },
  { name: 'SeniorExecutive Financial Services Manager', rank: 'Tier 2' },
  { name: 'Executive Director', rank: 'Tier 3' }
] as const
export const ContractTypeOptions: options[] = ContractTypeList.map(contractType => {
  return {
    label: contractType.name,
    value: contractType.name
  }
})
