export const ActivityStatuses = ['PENDING', 'INPROGRESS', 'PROCESSING', 'COMPLETED', 'READY_FOR_SUBMISSION'] as const

export class ActivityCols {
  PENDING: ActivityCard[] = []
  INPROGRESS: ActivityCard[] = []
  READY_FOR_SUBMISSION: ActivityCard[] = []
  PROCESSING: ActivityCard[] = []
  COMPLETED: ActivityCard[] = []
}

export default class ActivityCard {
  pky: number = undefined
  oid: string = undefined
  name = ''
  status: typeof ActivityStatuses[number] = 'PENDING'
  exams?: any[] = []
  forms?: any[] = []
  uploads?: any[] = []
  ctm: number = undefined
  utm: number = undefined

  get cardType () {
    if (this.exams.length > 0) return 'EXAM'
    if (this.forms.length > 0) return 'FORM'
    if (this.uploads.length > 0) return 'UPLOAD'
  }

  get displayName () {
    if (this.cardType === 'EXAM') return `${this.name} Certificate`
    if (this.cardType === 'UPLOAD') return `${this.name} Upload`
    if (this.cardType === 'FORM') return 'Recruitment Forms'
  }

  get latestExam () {
    if (this.exams.length === 0) return undefined
    const passed = this.exams.find(exam => exam.status === 'PASSED')
    if (passed) return passed
    const pending = this.exams.find(exam => exam.status === 'PENDING')
    if (pending) return pending
    return this.exams[0]
  }

  get canSubmit () {
    if (this.cardType === 'EXAM') {
      return this.latestExam && this.latestExam.status === 'PASSED' && this.latestExam.certs.every(cert => {
        return cert.files.length > 0 && cert.status === 'VERIFIED'
      })
    }
    if (this.cardType === 'UPLOAD') return false
    if (this.cardType === 'FORM') return this.forms.every(form => form.signed)
  }
}
