import { TypeBase } from '../TypeBase'

// ECDD is an object, therefore the conversion is different than other object
/**
 * raw kyc structure
 * ma_ecdd: {
 *  additional_note: "dddddd"
 *  high_risk_country: "yes"
 *  residing_address: ""
 *  source_of_funds: "no"
 *  source_of_funds_2: null
 *  source_of_funds_2_info: ""
 *  stay_in_sg: "yes"
 * }
 *
 * Note: key is ma_ecdd or ja_ecdd
*/
export default class ECDD extends TypeBase {
  name = ''
  identityType = ''
  nric = ''
  highRiskCountry = ''
  stayInSg = ''
  residingAddress = ''
  // Giving it a string[] as Gen needs it to be a string[]
  sourceOfFunds: string | string[] = ''
  sourceOfFunds2 = ''
  sourceOfFunds2Info = ''
  additionalNote = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(`${type}_ecdd`)
  }

  extractData (rawData: any) {
    return super.extractData(rawData[this.type])
  }

  convertToRawData () {
    const results = super.convertToRawData()
    return {
      [this.type]: results
    }
  }

  isNRICOrFIN () {
    return this.identityType === 'nric' || this.identityType === 'fin'
  }
}
