import { FormBase } from '../FormBase'

export class LetterOfUndertaking extends FormBase {
  // shared.name
  // shared.nric.value
  // shared.branch
  type = 'letter-of-undertaking'

  data = {
    agree: false
  }
}
