import { Base } from '@master/model/Base'
import { Ignore } from '@master/annotation/Ignore'

export default class AMLDoc extends Base {
  @Ignore()
  customType = ''

  selected: 'indi' | 'partner' | 'corporate' = 'indi'

  indi = {
    ic: '',
    name: '',
    dob: '',
    address: '',
    nationality: '',
    employment: '',
    contact: '',
    boId: ''
  }

  partner = {
    ic: '',
    name: '',
    dob: '',
    address: '',
    nationality: '',
    register: '',
    contact: '',
    finance: '',
    deed: '',
    boardResolution: '',
    boId: '',
    boExemption: ''
  }

  corporate = {
    ic: '',
    name: '',
    dob: '',
    address: '',
    nationality: '',
    register: '',
    contact: '',
    finance: '',
    memorandum: '',
    boardResolution: '',
    boId: '',
    boExemption: '',
    acra: '',
    signatory: ''
  }

  @Ignore()
  docTypes = ['indi', 'partner', 'corporate']

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super()
    this.customType = type + '_aml_doc'
  }

  // custom method to extract since aml doc is a single object packed with the properties
  extractData (rawKycForm) {
    const nested = super.extractData(rawKycForm[this.customType])
    this.docTypes.forEach((typ) => {
      Object.keys(this[typ]).forEach((doc) => {
        this[typ][doc] = nested[typ] ? nested[typ][doc] || false : false
      })
    })
    return {}
  }

  convertToRawData () {
    const results = super.convertToRawData()
    return {
      [this.customType]: results
    }
  }

  @Ignore()
  get fieldsNaming () {
    return {
      indi: {
        // _name: '1. Individuals / Sole Proprietors',
        ic: 'IC/Passport:-',
        name: 'Full Name',
        dob: 'Date of Birth',
        address: 'Residential Address',
        nationality: 'Nationality',
        employment: 'Proof of Employment / Business',
        contact: 'Contact Details',
        boId: 'Ultimate Beneficial Owner identification (as per documents in Section B)'
      },
      partner: {
        // _name: '2. Partnerships',
        ic: 'IC/Passport of Partners:-',
        name: 'Full Name',
        dob: 'Date of Birth',
        address: 'Residential Address',
        nationality: 'Nationality',
        register: 'Certificate of Registration',
        contact: 'Contact Details',
        finance: 'Latest financial statements (audited)',
        deed: 'Partnership Deed',
        boardResolution: 'Certified Copy of Board Resolution',
        boId: 'Ultimate Beneficial Owner identification (as per documents in Section B)',
        boExemption: 'Please check Section A2 for exemptions'
      },
      corporate: {
        // _name: '3a. Corporations (Keyman)',
        ic: 'IC/Passport of Directors:-',
        name: 'Full Name',
        dob: 'Date of Birth',
        address: 'Residential Address',
        nationality: 'Nationality',
        register: 'Certificate of Registration',
        contact: 'Contact Details',
        finance: 'Latest financial statements (audited)',
        memorandum: 'Memorandum & Articles',
        boardResolution: 'Certified Copy of Board Resolution',
        boId: 'Ultimate Beneficial Owner identification (as per documents in Section B)',
        boExemption: 'Please check Section A2 for exemptions',
        // _name2: '3b. Corporations (EB Cases)',
        acra: 'ACRA Report',
        signatory: 'ID of Authorised Signatory (if individual’s name is not in ACRA)'
      }
    }
  }
}
