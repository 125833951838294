/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAuditLogsDto } from '../models/CreateAuditLogsDto';
import type { CsvExportDto } from '../models/CsvExportDto';
import type { ListAuditLogsRequestDto } from '../models/ListAuditLogsRequestDto';
import type { ListAuditLogsResponseDto } from '../models/ListAuditLogsResponseDto';
import { request as __request } from '@openapi/request';

export class AuditLogsService {

    /**
     * @param requestBody 
     * @returns ListAuditLogsResponseDto 
     * @throws ApiError
     */
    public static async getAuditLogsList(
requestBody: ListAuditLogsRequestDto,
): Promise<ListAuditLogsResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/auditLogs/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async productCsvExport(
requestBody: CsvExportDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/auditLogs/csv-export`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async filterOptions(
requestBody: CsvExportDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/auditLogs/filter-options`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async addLogs(
requestBody: CreateAuditLogsDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/auditLogs/add`,
            body: requestBody,
        });
        return result.body;
    }

}