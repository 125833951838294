/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSignatureDataDto } from '../models/ActiveSignatureDataDto';
import type { CreateKycPdfDto } from '../models/CreateKycPdfDto';
import type { KycPdfListRequestDto } from '../models/KycPdfListRequestDto';
import type { KycPdfSubmissionDto } from '../models/KycPdfSubmissionDto';
import type { UpdateKycPdfDto } from '../models/UpdateKycPdfDto';
import type { UserRemarksDto } from '../models/UserRemarksDto';
import { request as __request } from '@openapi/request';

export class KycPdfV2Service {

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createKycPdf(
        requestBody: CreateKycPdfDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateKycPdf(
        requestBody: UpdateKycPdfDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/v2/kycPdf`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getKycPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/new`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async listKycPdf(
        requestBody: KycPdfListRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveKycPdfSignatures(
        requestBody: ActiveSignatureDataDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/saveSignature`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async submitKycPdfSignatures(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/submitSignatures`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addUserRemarks(
        requestBody: UserRemarksDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/addUserRemarks`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async adviserCancel(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/adviserCancel`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async finaliseKycPdfSubmission(
        requestBody: KycPdfSubmissionDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/finalise`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     * 
     * stampedpdf/stamp_pep_pdfs_signatures
     */
    public static async getPepSignatureV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/stamped/pepSignature`,
            body: { "kycPdfOid": oid},
        });
        return result.body;

    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     *
     */
    public static async getStampedPep(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/stamped/pep`,
            body: { "kycPdfOid": oid},
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     *
     */
    public static async stampedALMPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/stamped/aml`,
            body: { "kycPdfOid": oid},
        });
        return result.body;
    }
}