import { RouteRecordRaw } from 'vue-router';

export const designationRoutes: Array<RouteRecordRaw> = [
  {
    path: '/designation-list',
    name: 'Designation List',
    component: () => import('@core/pages/designation/views/DesignationListPage.vue'),
    meta: {
      subject: ['designationList']
    }
  }
];
