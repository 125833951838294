import { isObject } from "class-validator";

export function extractSuperChildKeys(obj, parentKey = '') {
    let flattenedObject = {};
    for (let key in obj) {
        if(key === 'productMetadata'){
            // console.log("data type", typeof obj[key], obj[key])
        }
        if ( obj[key] !== null && isObject(obj[key])) {
            // If the value is an object, recursively flatten it
            
            const nestedObject = extractSuperChildKeys(obj[key], `${parentKey}${key}.`);
            Object.assign(flattenedObject, nestedObject);
        } else {
            // If the value is not an object, add it as key-value pair to the flattened object
            flattenedObject[`${parentKey}${key}`] = obj[key];
        }
    }
    return flattenedObject;
}
export function checkMissingFields(requiredFields: object, payload: object) {
    const errors = [];
    const keys = Object.keys(requiredFields)
    keys.forEach(value => {
        if (!(value in payload) || payload[value] === null || payload[value] === undefined || payload[value] === '') {
            errors.push(`${requiredFields[value] ?? value}`);
        }
    });
    return errors.length === 0 ? [] : errors;
}

export function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
export function mapNumber(value: any) {
    !isNaN(Number(value)) ? Number(value).toFixed(2) : 0
}