import 'reflect-metadata'

const selectionMetadataKey = Symbol('Selection')

export type Options = {
  label: string;
  value: string | boolean;
}
/**
 * Add selection for radio / checkbox / dropdown field
 * @param options list of possible value of the field
 * @param lookUpWhenStamp convert value into readable ('label' in options), default to no
 */
export function Selection (options: Options[], lookUpWhenStamp = false) {
  return Reflect.metadata(selectionMetadataKey, { options, lookUpWhenStamp })
}

/**
 * Get all options value
 * @param target Target object
 * @param propertyKey property key of target object
 */
export function getOptions (target: any, propertyKey: string): Options[] {
  return Reflect.getMetadata(selectionMetadataKey, target, propertyKey)?.options
}

/**
 * Check if auto lookup (convert to readable label) is required
 * @param target Target object
 * @param propertyKey property key of target object
 */
export function getLookUpWhenStamp (target: any, propertyKey: string): boolean {
  return Reflect.getMetadata(selectionMetadataKey, target, propertyKey)?.lookUpWhenStamp
}
