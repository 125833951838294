import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import '@/DIContainer'
import { ClientProfile } from '@core/pages/client-management/model/ClientProfile'
import { ClientProductMeta } from '@core/pages/client-management/model/ClientProductMeta'
import { ProductFullMetaList } from '@master/model/common/ProductFullMetaList'
import { container } from 'inversify-props'
import API from '@core/services/API'
import { ClientResponseDto, ClientService, ClientUpdateRequestDTO } from '../api'
import { AuditLogsService } from '@core/openapi/services/audit-logs/api/services/AuditLogsService'
import triggerAuditLog from '@core/utils/triggerAuditLog'
import { cloneDeep } from 'lodash/fp'

const $api = container.get<API>('$api')


@Module({
  namespaced: true,
  name: 'ClientInfoStore'
})
export default class ClientInfoStore extends VuexModule {
  clientProfile: ClientProfile = new ClientProfile()
  highRiskClient = false
  showSanctionModal = true
  loading = false
  clientProduct: ClientProductMeta = new ClientProductMeta()
  productList: ProductFullMetaList = new ProductFullMetaList([])
  readOnly = true
  relationShipsClone = null

  // Version 2 of CLient DTO
  clientDto: ClientResponseDto = null
  clientDtoClone: ClientResponseDto = null
  clientInterestClone = null

  triggerAuditLog = new triggerAuditLog()


  @Mutation
  setClientDtoV2 (clientDto: ClientResponseDto) {
    this.clientDto = clientDto;
  }

  @Mutation
  setClientInterestData (ClientInterest) {
    this.clientInterestClone = ClientInterest;
  }

  @Mutation
  setClientDtoV2Clone (clientDtoClone: ClientResponseDto) {
    this.clientDtoClone = clientDtoClone;
  }

  @Mutation
  setRelationshipsResponse (relationShipsClone) {
    this.relationShipsClone = relationShipsClone;
    console.log(this.relationShipsClone)
  }

  @Action({ rawError: true })
  async retrieveClientDTOV2 (clientOid: string) {
    try {
      const dto = await ClientService.retrieveClientWithOid(clientOid)
      this.context.commit('setClientDtoV2', dto)
      this.context.commit('setClientDtoV2Clone', cloneDeep(dto))
    } catch (error) {
      console.log(error)
    }
  }

  @Action({ rawError: true })
  async updateClientDTOV2 ({clientUpdateRequestDTO, subModule}) {
    this.context.commit('setLoading', true)
    try {
      clientUpdateRequestDTO = { ...clientUpdateRequestDTO, clientSince: clientUpdateRequestDTO?.clientSince, ftfDateOfVerification: clientUpdateRequestDTO?.ftfDateOfVerification?.toString() }
      if(clientUpdateRequestDTO.hrcRoles) {
        if(!Array.isArray(clientUpdateRequestDTO.hrcRoles)){
          clientUpdateRequestDTO.hrcRoles =  [clientUpdateRequestDTO.hrcRoles]
        }
      }else {
        clientUpdateRequestDTO.hrcRoles = []
      }
      if(clientUpdateRequestDTO.accountType === 'individual'){
        if(clientUpdateRequestDTO.personalInfoJson.mobileCountryCodeNoOne &&  clientUpdateRequestDTO.personalInfoJson.mobileNoOne){
          clientUpdateRequestDTO.mobileNumber = `${clientUpdateRequestDTO.personalInfoJson.mobileCountryCodeNoOne}${clientUpdateRequestDTO.personalInfoJson.mobileNoOne}`
        }
      }else {
        if(clientUpdateRequestDTO.personalInfoJson.officeCountryCodeNoOne &&  clientUpdateRequestDTO.personalInfoJson.officeNoOne){
          clientUpdateRequestDTO.mobileNumber = `${clientUpdateRequestDTO.personalInfoJson.officeCountryCodeNoOne}${clientUpdateRequestDTO.personalInfoJson.officeNoOne}`
        }
        
      }
      const dto = await ClientService.updateClientData(clientUpdateRequestDTO)
      this.context.commit('setClientDtoV2', dto)
      console.log(this.clientDto, this.clientDtoClone)
      // this.clientDto.
    this.context.dispatch('dispatchAuditLog', {oldData:this.clientDtoClone, newData:this.clientDto, entityIdentifier:this.clientDto.personalInfoJson.identityType, mainModule:'Clients', subModule})
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action({ rawError: true })
  async dispatchAuditLog ({oldData, newData, entityIdentifier, mainModule, subModule}) {
    const logs = triggerAuditLog.logCheck(oldData, newData, entityIdentifier, mainModule, subModule)
    console.log(logs)
    const response = await AuditLogsService.addLogs(logs)
    console.log(response)
  }

  @Mutation 
  setLoading (loading: boolean) {
    this.loading = loading
  }

  @Mutation
  setClientProfile (profile: ClientProfile) {
    this.clientProfile.extractAndAssignData(profile)
    if (this.clientProfile?.hrcRoles) {
      this.clientProfile.hrcRoles = JSON.parse(this.clientProfile.hrcRoles.toString())

      if (this.clientProfile.hrcRoles.length > 0) {
        this.highRiskClient = true
      }
          }
  }

  @Mutation
  setClientProduct (product: { meta: {}; _oid: string }) {
    this.clientProduct.extractMetaObject(product)
  }

  @Mutation
  setProductList (data: (string | number)[][]) {
    const productMetaList = new ProductFullMetaList(data)
    this.productList = productMetaList
  }

  @Mutation
  setReadOnly (readOnly: boolean) {
    this.readOnly = readOnly
  }

  @Mutation
  setShowSactionModal (show: boolean) {
    this.showSanctionModal = show
  }

  @Mutation
  resetClientProduct () {
    this.clientProduct = new ClientProductMeta()
  }

  @Action({ rawError: true })
  async retrieveClientProfile (clientOid: string) {
        try {
      /* eslint-disable @typescript-eslint/camelcase */
      const res = await $api.clientDetails.profile({
        client_oid: clientOid
      })

      if (typeof res === 'string' && JSON.parse(res).error) {
        throw new Error(JSON.parse(res).error)
      }

      this.context.commit('setClientProfile', res)
    } catch (error) {
      alert(error.message || error)
    } finally {}
  }
}

