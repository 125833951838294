import { Ignore } from '@master/annotation/Ignore'
import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export default class EducationMeta extends Meta {
  @RawKey('education_oid')
  @Ignore()
  oid = ''

  nameOfInstitution = ''
  location = ''
  dateFrom: number = undefined
  dateTo: number = undefined
  awarded = ''
  idx: number = undefined
}
