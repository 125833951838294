import { Selection } from '@core/annotation/Selection'
import { FormBase } from '../FormBase'
import { DeclarationOptions } from './GeneralDeclaration'

export const RepGradeOptions = [
  { label: '', value: '' },
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'Ungraded', value: 'U' }
]
export const SvGradeOptions = [
  { label: '', value: '' },
  { label: 'Good', value: 'good' },
  { label: 'Satisfactory', value: 'satisfactory' },
  { label: 'Fair', value: 'fair' },
  { label: 'Unsatisfactory', value: 'unsatisfactory' }
]
export class BscGrade {
  period = ''

  @Selection(RepGradeOptions, true)
  grade = ''
}
export class SvBscGrade extends BscGrade {
  @Selection(SvGradeOptions, true)
  grade = ''
}
export class BscGrading {
  unsatisfactory: typeof DeclarationOptions[number]['value'] = undefined
  rep: BscGrade[] = Array(8).fill(null).map(() => new BscGrade())
  sv: BscGrade[] = Array(8).fill(null).map(() => new SvBscGrade())
}
export class FitProperFields {
  1: typeof DeclarationOptions[number]['value'] = undefined
  2: typeof DeclarationOptions[number]['value'] = undefined
  3: typeof DeclarationOptions[number]['value'] = undefined
  4: typeof DeclarationOptions[number]['value'] = undefined
  5: typeof DeclarationOptions[number]['value'] = undefined
  6: typeof DeclarationOptions[number]['value'] = undefined
  7: typeof DeclarationOptions[number]['value'] = undefined
  8: typeof DeclarationOptions[number]['value'] = undefined
  9: typeof DeclarationOptions[number]['value'] = undefined
  10: typeof DeclarationOptions[number]['value'] = undefined
  11: typeof DeclarationOptions[number]['value'] = undefined
  12: typeof DeclarationOptions[number]['value'] = undefined
  13: typeof DeclarationOptions[number]['value'] = undefined
  14: typeof DeclarationOptions[number]['value'] = undefined
  15: typeof DeclarationOptions[number]['value'] = undefined
  16: typeof DeclarationOptions[number]['value'] = undefined
  17: typeof DeclarationOptions[number]['value'] = undefined
  18: typeof DeclarationOptions[number]['value'] = undefined
  19: typeof DeclarationOptions[number]['value'] = undefined
  20: typeof DeclarationOptions[number]['value'] = undefined
  21: typeof DeclarationOptions[number]['value'] = undefined
  22: typeof DeclarationOptions[number]['value'] = undefined
  23: typeof DeclarationOptions[number]['value'] = undefined
  24: typeof DeclarationOptions[number]['value'] = undefined
  25: typeof DeclarationOptions[number]['value'] = undefined
  26: typeof DeclarationOptions[number]['value'] = undefined
  27: typeof DeclarationOptions[number]['value'] = undefined
  28: typeof DeclarationOptions[number]['value'] = undefined
  29: typeof DeclarationOptions[number]['value'] = undefined
  30: typeof DeclarationOptions[number]['value'] = undefined
  31: typeof DeclarationOptions[number]['value'] = undefined
  32: typeof DeclarationOptions[number]['value'] = undefined
  taxOffence: typeof DeclarationOptions[number]['value'] = undefined
  taxOffenceSpecified = ''
  bscGrading: BscGrading = new BscGrading()
  additional = ''
}
export class FitAndProper extends FormBase {
  type = 'fit-proper'

  data: FitProperFields = new FitProperFields()
}
