import { IValidation } from '@master/interface/IValidation'
import { ProductMetaList } from '@master/model/common/ProductMetaList'
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from 'axios'
import { Assumption } from '@master/model/common/Assumption'
import { infAdjReturn, round } from '@core/model/kyc-form/need-analysis/math'
import { cid, container } from 'inversify-props'
import API from '@core/services/API'
import { FormOptions } from '@master/model/kyc-form/FormOptions'
import '@/DIContainer'
import { ProductHeaders } from '@master/model/common/ProductMeta'
import { IDateService } from '@master/services/IDateService'
import { ProductList } from '@core/pages/adminProductList/model/ProductList'
import { ProductService } from '@core/pages/adminProductList/api'
import { ProductModel } from '@core/pages/adminProductList/model/ProductModel'
import KYCForm from '@company-common/model/kyc-form/KYCForm'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { KycFormService } from '@core/pages/kyc-form/api/services/KycFormService'
import { CompanyDto, CompanyService } from '@core/pages/companyDetails/api'
import { AccountService } from '@core/openapi/services/user-account/api'
import { AdviserLicenceService } from '@core/openapi/services/adviser-licence/api'
import { ProductLicenceService } from '@core/openapi/services/product-licence/api'
import { MochaConfigService } from '@core/pages/account/api'

const $api = container.get<API>('$api')
const dateService = container.get<IDateService>(cid.IDateService)
const projectSettings = container.get<IProjectSettings>(cid.IProjectSettings)
const riskRatingService = container.get<IRiskRatingService>(cid.IRiskRatingService)

@Module({
  namespaced: true,
  name: 'KYCFormStore'
})
export default class KYCFormStore extends VuexModule {
  kycForm: KYCForm = new KYCForm(dateService, projectSettings, riskRatingService)
  formOptions: FormOptions
  assumption: Assumption = new Assumption()
  loadingState: 'loading' | 'loaded' | 'saving' = 'loading'
  computedValidationResult: IValidation = {}
  kycFormErrors = undefined
  myInfoStatus = {
    haveNewInfoClient1: false,
    haveNewInfoClient2: false
  }

  productMetaList: ProductMetaList = new ProductMetaList([])
  productList: ProductList = new ProductList([])
  cdMetaList: CompanyDto[] = []

  // State changes
  showCompletionModalState = false

  @Mutation
  setKYCForm (kycForm) {
    this.kycForm.setData(kycForm)
    this.formOptions = new FormOptions(this.kycForm)
  }

  @Mutation
  setDefaultAssumptions (assumptions) {
    this.kycForm.setupDefaultRate(assumptions)
  }

  @Mutation
  setKYCFormErrors (errors: {}) {
    this.kycFormErrors = errors
  }

  @Mutation
  setAssumption (assumption: {
    assumptions_annualinflation;
    assumptions_annualinvestmentreturn;
    assumptions_increaseineducationcosts;
    assumptions_annualsalaryincrement;
    assumptions_retirementincome;
  }) {
    const {
      assumptions_annualinflation, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_annualinvestmentreturn, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_increaseineducationcosts, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_annualsalaryincrement, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_retirementincome // eslint-disable-line @typescript-eslint/camelcase
    } = assumption
    const inflation = parseFloat(assumptions_annualinflation)
    const investmentReturn = parseFloat(assumptions_annualinvestmentreturn)
    this.assumption = {
      inflation,
      investmentReturn,
      educationInflationRate: parseFloat(assumptions_increaseineducationcosts),
      salaryIncrementRate: parseFloat(assumptions_annualsalaryincrement),
      retirementIncome: parseFloat(assumptions_retirementincome),
      inflationAdjustedReturn: round(infAdjReturn(inflation / 100, investmentReturn / 100) * 100, 2)
    }
    this.kycForm.setupDefaultRate(this.assumption)
  }

  @Mutation
  setLoadingState (state: 'loading' | 'loaded' | 'saving') {
    this.loadingState = state
  }

  @Mutation
  setShowCompletionModalState (state: boolean) {
    this.showCompletionModalState = state
  }

  @Mutation
  setValidationResult (result: IValidation) {
    this.computedValidationResult = result
  }

  @Mutation
  setProductMetaList (data: (string | number)[][]) {
    const productMetaList = new ProductMetaList(data)
    // Override filter when needed
    // e.g. productMetaList.filter.ilp.type = ['abc']
    this.productMetaList = productMetaList
  }

  @Mutation
  setProductList (data: ProductModel[]) {
    const productList = new ProductList(data)
    // Override filter when needed
    // e.g. productMetaList.filter.ilp.type = ['abc']
    this.productList = productList
  }

  get products() {
    return this.productList
  }

  @Mutation
  setCompanyDetailList (data: CompanyDto[]) {
    console.log('cdMetaList called')
    this.cdMetaList = data
  }

  get metaCDList () {
    return this.cdMetaList
  }

  @Action
  updateShowCompletionModalState (state: boolean) {
    this.context.commit('setShowCompletionModalState', state)
  }

  @Action
  updatedValidationResult (result: {}) {
    this.context.commit('setValidationResult', result)
  }

  // add rawError to get the raw error during this action to get better knowing where the issue come from
  @Action({ rawError: true })
  async loadKYCForm (formOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const { meta: kycForm, error } = await $api.kycForm.metaGet({ _oid: formOid })
    // const res = await KycFormService.getKycFormList(formOid)

    // To ensure Important Notice product list always has latest data
    const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: kycForm.adviser_oid })
    // const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: res.adviser_oid })
      .then((response) => {
        return response.meta
      })
      .catch((error) => {
        console.error('KYCForm Store: Fail to get adviser detail', error)
        alert('KYCForm Store: Fail to get adviser detail' + error)
      })

    kycForm.important_notice_list = adviserData?.adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.important_notice_others = adviserData?.other_adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.adviser_mas_rep_no = adviserData?.mas_repno // eslint-disable-line @typescript-eslint/camelcase

    if (error) {
      this.context.commit('setKYCFormErrors', error)
      console.log('in loadKYCForm action - loaded');
      this.context.commit('setLoadingState', 'loaded')
      return kycForm
    }
    //
    // pre processor that will change all the ma to the standardize format
    //

    this.context.commit('setKYCForm', kycForm)
    // this.context.commit('setKYCForm', res)
    this.context.commit('setLoadingState', 'loaded')
  }

  @Action({ rawError: true })
  async loadKYCFormV2 (formOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const { meta: kycForm, error } = await KycFormService.getKycForm(formOid)
    // To ensure Important Notice product list always has latest data
    const adviserProducts = await AdviserLicenceService.retrieveSpecificUserAdviserLicences(kycForm.adviser_oid)
    const systemLicences = await ProductLicenceService.listSystemAdviserLicences()
    const adviserInfo = await AccountService.retrieveUserAccountInformation(kycForm.adviser_oid ||kycForm._oid)
    // const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: kycForm.adviser_oid })
    //   .then((response) => {
    //     return response.meta
    //   })
    //   .catch((error) => {
    //     console.error('KYCForm Store: Fail to get adviser detail', error)
    //     alert('KYCForm Store: Fail to get adviser detail' + error)
    //   })
    const products = adviserProducts.filter(product => product.status === 'active').map(product => product.productLicence)
    const names = systemLicences.filter(sysLicence => products.includes(sysLicence.oid)).map(licence => licence.licenceName)

    kycForm.important_notice_list = names // eslint-disable-line @typescript-eslint/camelcase
    // kycForm.important_notice_others = adviserData?.other_adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.adviser_mas_rep_no = adviserInfo?.userCompanyInfo?.masRepNo // eslint-disable-line @typescript-eslint/camelcase

    if (error) {
      this.context.commit('setKYCFormErrors', error)
      this.context.commit('setLoadingState', 'loaded')
      return kycForm
    }
    //
    // pre processor that will change all the ma to the standardize format
    //

    this.context.commit('setKYCForm', kycForm)
    this.context.commit('setLoadingState', 'loaded')
  }

  @Action
  async loadAssumption () {
    const assumptionNames = [
      'assumptions_annualinflation',
      'assumptions_annualinvestmentreturn',
      'assumptions_increaseineducationcosts',
      'assumptions_annualsalaryincrement',
      'assumptions_retirementincome'
    ]

    try {
      const assumptionsKeys = {}
      assumptionNames.forEach(async key => {
        const response = await MochaConfigService.retrieveConfigValue(key)
        assumptionsKeys[response.kid] = response.kvl
        this.context.commit('setAssumption', assumptionsKeys)
      })
    } finally {
      this.context.commit('setDefaultAssumptions', this.assumption)
    }
    // const { data: { values: assumption } } = await axios.get(`/web/api/kvmApi/getValues?keys=${encodeURIComponent(JSON.stringify(assumptionNames))}`)
    // this.context.commit('setAssumption', assumption)
  }

  @MutationAction({ mutate: ['myInfoStatus'] })
  async checkMyInfoUpdate (kycFormOid) {
    /* eslint-disable @typescript-eslint/camelcase */
    const { data } = await $api.myInfo.newPersonInfoAvailable({ kycFormOid })

    const client0Available = data?.client_0?.new_person_info_available || false
    const client1Available = data?.client_1?.new_person_info_available || false

    const myInfoStatus = {
      haveNewInfoClient1: client0Available,
      haveNewInfoClient2: client1Available
    }
    /* eslint-enable @typescript-eslint/camelcase */
    return { myInfoStatus }
  }

  @Action({ rawError: true })
  async fetchRecommendedProducts () {
    if (this.productMetaList.getFullList().length > 0) return
    this.context.commit('setLoadingState', 'saving')

    const res = await ProductService.retrieveAllProducts({
      status: [1]
    })

    const { data } = res

    // const { data } = await $api.relProductList.list({
    //   headers: JSON.stringify(ProductHeaders),
    //   // only return active products
    //   query: 'status = ?',
    //   queryArgs: JSON.stringify([1])
    // })

    this.context.commit('setLoadingState', 'loaded')
    this.context.commit('setProductMetaList', data)
  }

  // @Action({ rawError: true })
  // async fetchRecommendedAllProducts () {
  //   if (this.productList.originalList.length > 0) return
  //   const products = await ProductService.retrieveAllProducts({
  //     status: [1]
  //   })

  //   const { data } = products // hanges service file and  added any data type

  //   const updatedProducts = data.map(product => {
  //     return Object.assign(new ProductModel(), product) //{data: [{}], totalRecord:sd}
  //   })

  //   this.context.commit('setProductList', updatedProducts)
  // }

  @Action
    async fetchRecommendedAllProducts({status = [1], limit = 10, retrieveAll = false} = {}) {
    if (this.productList.originalList.length > 0) return
    const res: any = (await ProductService.retrieveAllProducts({
      status,
      limit,
      retrieveAll
    }))
    const products = res.data.map(product => {
      return Object.assign(new ProductModel(), product)
    })
    this.context.commit('setProductList', products)
  }

  @Action({ rawError: true })
  async fetchCompanyDetails () {
    if(!this.metaCDList.length){
      const data = await CompanyService.retrieveCompanyList()
      this.context.commit('setCompanyDetailList', data)
    }
  }

  get updateLoadingState () {
    return this.loadingState
  }

  get issuerOptions () {
    const uniqueObjArray = [...new Map(this.cdMetaList.map((item) => [item.oid, { label: item.shortName, value: item.oid }])).values()]
    uniqueObjArray.unshift({
      label: '',
      value: ''
    })
    return uniqueObjArray
  }
}
