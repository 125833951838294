/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
// import type { GenericPaginationDto } from '../models/GenericPaginationDto';
import { request as __request } from '@openapi/request';

export class TenantConfigurationNativeService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async retrieveTenantConfigurationNative(
requestBody: any,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/tenant-config/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async createTenantConfiguration(
requestBody: any,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/tenant-config/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updateTenantConfiguration(
requestBody: any,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/tenant-config/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async getCurrentTenantLogo(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/tenant-config/logo`,
        });
        return result.body;
    }

}
