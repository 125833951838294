import { isUndefined } from 'lodash/fp'
import 'reflect-metadata'

const rawKeyMetadataKey = Symbol('RawKey')

export function RawKey (formatString: string) {
  return Reflect.metadata(rawKeyMetadataKey, formatString)
}

export function getRawKey (target: any, propertyKey: string) {
  let key = Reflect.getMetadata(rawKeyMetadataKey, target, propertyKey)

  if (isUndefined(key)) {
    return
  }

  if (/@/.test(key)) {
    key.match(/(?:@)([a-zA-Z0-9]+)/g)
      .forEach(match => {
        const value = target[match.substring(1, match.length)]
        key = key.replace(match, value)
      })
  }
  return key
}
