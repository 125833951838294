import TrustedIndividual from '@master/model/kyc-form/TrustedIndividual'
import KYCClient from './KYCClient'
export default class CustomTrustedIndividual extends TrustedIndividual {
  updateAccompanied (client1: KYCClient, client2: KYCClient) {
    const client1IsSelectedClient = client1.personalDetails.isSelectedClient()
    const client2Included = client2.personalDetails.included
    const client2IsSelectedClient = client2Included && client2.personalDetails.isSelectedClient()

    if (!client1IsSelectedClient && !client2IsSelectedClient) {
      this.accompanied = 'no'
    } else {
      this.accompanied = 'yes'
    }
  }
}
