import KYCForm from '@company-common/model/kyc-form/KYCForm'
import PreTransactionalCheck from './model/ptc/PreTransactionalCheck'

export default class KYCPdfSubmission extends KYCForm {
  preTransactionalChecks: PreTransactionalCheck = new PreTransactionalCheck(this)
  completion = {}
  selectedSupervisorOid = ''
  selectedProxySupervisorOid = ''
  kycFormOid = ''

  prepareForSubmission () {
    return {
      completion: this.completion,
      kycFormOid: this.kycFormOid,
      supervisorOid: this.selectedSupervisorOid,
      proxySupervisorOid: this.selectedProxySupervisorOid,
      ptc_fields: JSON.stringify(this.preTransactionalChecks.convertToRawData()) // eslint-disable-line @typescript-eslint/camelcase
    }
  }
}
