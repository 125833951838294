/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDto } from '../models/ClientDto';
import { ClientListRequestDTO } from '../models/ClientListRequestDTO';
import type { ClientResponseDto } from '../models/ClientResponseDto';
import type { ClientUpdateRequestDTO } from '../models/ClientUpdateRequestDTO';
import { request as __request } from '@openapi/request';

export class ClientService {

    /**
     * @deprecated
     * @returns ClientResponseDto Retrieve a list of clients
     * @throws ApiError
     */
    public static async retrieveClientList(): Promise<Array<ClientResponseDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/client/list`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createNewClient(
        requestBody: ClientDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/client/create_new_client`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param clientOid
     * @returns ClientResponseDto
     * @throws ApiError
     */
    public static async retrieveClientWithOid(
        clientOid: string,
    ): Promise<ClientResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/client/info/${clientOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ClientResponseDto
     * @throws ApiError
     */
    public static async updateClientData(
        requestBody: ClientUpdateRequestDTO,
    ): Promise<ClientResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/client/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ClientResponseDto Retrieve a list of clients
     * @throws ApiError
     */
    public static async getClientList(params: ClientListRequestDTO): Promise<{
        data: Array<any>,
        recordsTotal: number;
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/client/list`,
            body: params,
        });
        return result.body;
    }

    public static async exportClientList(params: any): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/client/csv-export`,
            body: params,
        });
        return result.body;
    }

    /**
    * @returns ClientResponseDto Retrieve a list of clients
    * @throws ApiError
    */
    public static async retrieveDropdownClientList(params: any) {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/client/retrieve-dropdown-option-client-list`,
            body: params,
        });
        return result.body;
    }

    /**
     * @param fromDate The date of the report as a Unix timestamp
     * @param toDate The date of the report as a Unix timestamp
     * @returns any
     * @throws ApiError
     */
    public static async getNbrSummaryReportPreview(
        fromDate: number,
        toDate: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/client/nbr-summary-report-preview`,
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
        });
        return result.body;
    }

    /**
     * @param fromDate The date of the report as a Unix timestamp
     * @param toDate The date of the report as a Unix timestamp
     * @param type The type of export, must be either "csv" or "excel"
     * @returns any
     * @throws ApiError
     */
    public static async exportNbrSummaryReport(
        fromDate: number,
        toDate: number,
        type: 'csv' | 'excel',
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/client/export-nbr-summary-report`,
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
                'type': type,
            },
        });
        return result.body;
    }
}
