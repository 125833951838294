import { sum } from '../math'
import NeedsBase from '../NeedsBase'

export default class OutpatientExpenses extends NeedsBase {
  name = '' // TODO: add in name
  needs = 'Hospitalisation Expenses'
  amount?: number = undefined

  // required
  required1?: number = undefined
  required2?: number = undefined
  required3?: number = undefined
  required4?: number = undefined
  required5?: number = undefined

  // available
  available1?: number = undefined
  available2?: number = undefined
  available3?: number = undefined
  available4?: number = undefined
  available5?: number = undefined

  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 'h4') {
    super(`${type}_${id}`)
  }

  get sumRequired () {
    return sum(this.required1, this.required2, this.required3, this.required4, this.required5)
  }

  get sumAvailable () {
    return sum(this.available1, this.available2, this.available3, this.available4, this.available5)
  }

  get surplusShortfall () {
    return sum(-this.amount, this.sumAvailable, -this.sumRequired)
  }
}
