import { ClientBase } from '../../ClientBase'
import { isUndefined, set, get } from 'lodash/fp'
import { MaxChar } from '@master/annotation/MaxChar'
import { Default } from '@master/annotation/Default'

/**
 *
 */
export default class Budget extends ClientBase {
  @Default(null)
  budgetCashPerAnnum?: number | string = undefined

  @Default(null)
  budgetCashPerMonth?: number | string = undefined

  @Default(null)
  budgetCash?: number | string = undefined

  @Default(null)
  budgetCpfOrdinary?: number | string = undefined

  @Default(null)
  budgetCpfSpecial?: number | string = undefined

  @Default(null)
  budgetSrsAccount?: number | string = undefined

  @Default(null)
  budgetUtilizeCpfMedisave?: number | string = undefined

  budgetSourceOfFunds: string[] = []
  budgetSourceOfWealth: string[] = []
  smallBudgetSize = ''

  @MaxChar(324)
  budgetSubstantialReasons = '' // was ma_budget_substantial_reasons

  @MaxChar(48)
  budgetOthers1 = '' // was ma_budget_others_1

  @MaxChar(48)
  budgetOthers2 = '' // was ma_budget_others_2

  @MaxChar(48)
  budgetOthers3 = '' // was ma_budget_others_3

  afterExtractData (data: {}) {
    let defaultMap = {}
    if (isUndefined(get('budgetSourceOfFunds', data))) {
      defaultMap = set('budgetSourceOfFunds', [], defaultMap)
    }
    if (isUndefined(get('budgetSourceOfWealth', data))) {
      defaultMap = set('budgetSourceOfWealth', [], defaultMap)
    }
    Object.assign(data, defaultMap)
    return data
  }

  annualBudget () {
    return (Number(this.budgetCashPerAnnum) || 0) + (Number(this.budgetCashPerMonth) * 12 || 0)
  }

  singleBudget () {
    return (Number(this.budgetCash) || 0) +
    (Number(this.budgetCpfOrdinary) || 0) +
    (Number(this.budgetCpfSpecial) || 0) +
    (Number(this.budgetSrsAccount) || 0)
  }
}
