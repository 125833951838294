import { ClientBase } from '@master/model/ClientBase'
import PoliticalExposedPerson from './PoliticalExposedPerson'

export default class PoliticalExposedPersonDeclaration extends ClientBase {
  pepDeclared = ''
  pepParticulars?: PoliticalExposedPerson = undefined

  constructor (type: 'client_0' | 'client_1', pep: PoliticalExposedPerson = new PoliticalExposedPerson()) {
    super(type)
    this.pepParticulars = pep
  }

  beforeExtractData (data: any) {
    // get from raw data
    const bopRaw = data[this.type + '_pep_particulars']
    Object.assign(this.pepParticulars, this.pepParticulars.extractData(bopRaw || {}))
    return data
  }

  convertToRawData () {
    const result: any = super.convertToRawData()
    // As beneficial owner particular is a Base, we need to add them in manually
    if (this.pepParticulars) {
      result[this.type + '_pep_particulars'] = this.pepParticulars.convertToRawData()
    }
    return result
  }
}
