import { Selection } from '@core/annotation/Selection'
import { CitizenshipOptions } from '@master/model/options/CitizenshipOptions'
import { GenderOptions } from '@master/model/options/GenderOptions'
import { MaritalStatusOptions } from '@master/model/options/MaritalStatusOptions'
import { RaceOptions } from '@master/model/options/RaceOptions'
import { FormBase } from '../FormBase'

export class SpouseDetails {
  name = ''
  nric = ''
  dob: number = undefined
  citizenship = '' // options
  nationalitySpecified = ''
  passportNo = ''
  occupation = ''
  employer = ''
}
export class ContactDetails {
  unit = ''
  block = ''
  street = ''
  building = ''
  postalCode = ''
  homeNo = ''
  mobileNo = ''
  email = ''
}
export class BankDetails {
  name = ''
  branch = ''
  accountNo = ''
}
export class PersonalFields {
  preferredName = ''

  @Selection(GenderOptions)
  gender = '' // options

  dob: number = undefined

  @Selection(CitizenshipOptions)
  citizenship = ''

  nationalitySpecified = ''
  passportNo = ''

  @Selection(RaceOptions)
  race = ''

  raceSpecified = ''

  @Selection(MaritalStatusOptions)
  maritalStatus = ''

  spouse: SpouseDetails = new SpouseDetails()

  contact: ContactDetails = new ContactDetails()

  bank: BankDetails = new BankDetails()
}
export class Personal extends FormBase {
  // shared.name
  // shared.nric
  // shared.branch
  // shared.rm
  // shared.fsm
  type = 'personal'
  data: PersonalFields = new PersonalFields()
}
