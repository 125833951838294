import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { snakeCase } from 'lodash/fp'
import { Base } from '../../Base'
import { IExistingInvestment } from './IExistingInvestment'

// this is a highly specific nested object, with intention to reduce number of first level fields in metaobject
// data preprocessor @ backend will handle the flattening of the nesting
export default class ExistingInvestSummary extends Base {
  existingInvestSummary = { c1: {}, c2: {}, others: {} }

  @Ignore()
  defaultValue = 0

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  @Ignore()
  @Pointer('ROOT.existingInvestment', 'portfolios')
  portfolios: IExistingInvestment[] = null

  @Ignore()
  summaryFields: { attrName: string; abbre: string }[]

  /**
   * @TODO: This might not be the best method, but it can eliminate the need for summaryFields
   * function in ExistingInvestment.ts (Check for ExistingInvestment.ts before 19 October 2022
   * for example of what summaryFields() is.)
   * @param existingInvestment The model that is going to be used just for getting summary fields
   */
  constructor (existingInvestment: IExistingInvestment) {
    super()
    this.summaryFields = existingInvestment.getSummaryFields()
    Object.keys(this.existingInvestSummary).forEach((role) => {
      this.summaryFields.forEach((field) => {
        this.existingInvestSummary[role][field.attrName] = this.defaultValue
      })
    })
  }

  c1PortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => { return pf.belongsTo('C1') })
      // Remove all previously stored values. If there is a need to store previous data,
      // then this logic will need to be reimplemented. The reason why I implement this
      // is because if the company does not need the field to be summary, there is
      // no point of keeping it anymore. Applies for c2 and others portfolio summary.
      this.existingInvestSummary.c1 = {}
      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'c1')
      })
    }
    return this.existingInvestSummary.c1
  }

  c2PortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => { return pf.belongsTo('C2') })
      this.existingInvestSummary.c2 = {}

      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'c2')
      })
    }
    return this.existingInvestSummary.c2
  }

  othersPortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => { return pf.isValid() && !pf.belongsTo('C1') && !pf.belongsTo('C2') })
      this.existingInvestSummary.others = {}

      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'others')
      })
    }
    return this.existingInvestSummary.others
  }

  computeSum (pfs, key: { attrName: string; abbre: string }, summaryKey) {
    if (pfs.length === 0) {
      this.existingInvestSummary[summaryKey][key.attrName] = 0
      return 0
    }
    const subtotal = pfs.map((cover) => {
      return Number(cover[key.attrName]) || 0
    }).reduce((amt, accum) =>
      amt + accum
    , 0)
    this.existingInvestSummary[summaryKey][key.attrName] = (Math.round(subtotal * 100) / 100).toFixed(2)
    return subtotal
  }

  /**
   * This is not a good way of recalculating and populating the result
   * but for now, it works as when the save is on other sections, we
   * ensure that the summary is being calculated properly as well.
   * For example, when the client 2 is checked as not included in the
   * Client Summary section, at least we know that this will be recalculated
   * @param index
   */
  convertToRawData(index?: number);
  convertToRawData () {
    this.c1PortfolioSummary()
    this.c2PortfolioSummary()
    this.othersPortfolioSummary()
    return super.convertToRawData()
  }

  extractData (rawKycForm: any) {
    const data = {}
    Object.keys(this).forEach((key) => {
      const summary = rawKycForm[snakeCase(key)]
      if (summary) {
        Object.keys(summary).forEach((role) => {
          Object.keys(summary[role]).forEach((field) => {
            if (!this.existingInvestSummary[role]) {
              this.existingInvestSummary[role] = {}
            }
            this.existingInvestSummary[role][field] = summary[role][field] ? summary[role][field] : this.defaultValue
          })
        })
      }
    })
    return data
  }
}
