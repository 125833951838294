import { FNAStructure } from '@master/model/structure/FNAStructure'
import HospitalizationNeeds from '@master/model/kyc-form/need-analysis/health/HospitalizationNeeds'

export function HospitalisationStructure (defaultValue: {amount: number}) {
  const structure: FNAStructure<HospitalizationNeeds> = {
    title: 'H2. Hospitalisation Expenses',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Existing hospital coverage (Type, ward and coverage)',
        key: 'coverage',
        validationMsg: 'Hospitalisation Expenses requires to indicate existing hospital coverage.',
        type: 'text'
      }, {
        label: 'Daily room and board (R&B) charges ($)',
        key: 'roomCharges',
        type: 'currency',
        validationMsg: 'Hospitalisation Expenses requires to indicate daily room and board (R&B) charges.'
      }, {
        label: 'Estimated surgical expenses ($)',
        key: 'surgicalExpenses',
        type: 'currency',
        validationMsg: 'Hospitalisation Expenses requires to indicate estimated surgical expenses.'
      }, {
        label: 'Desired amount for hospital income ($)',
        key: 'desiredAmount',
        type: 'currency',
        default: defaultValue?.amount,
        validationMsg: 'Hospitalisation Expenses requires to indicate desired amount for hospital income.'
      }, {
        label: 'Preferred hospital type',
        key: 'hospitalType',
        type: 'radio',
        options: [
          { label: 'Public', value: 'public' },
          { label: 'Private', value: 'private' }
        ],
        validationMsg: 'Hospitalisation Expenses requires to indicate preferred hospital type.'
      }, {
        label: 'Preferred ward type',
        key: 'wardType',
        type: 'radio',
        options: [
          { label: 'A', value: 'A' },
          { label: 'B1', value: 'B1' },
          { label: 'B2', value: 'B2' },
          { label: 'C', value: 'C' }
        ],
        disabled: (hospitalizationNeeds) => hospitalizationNeeds.hospitalType === 'private',
        validationMsg: 'Hospitalisation Expenses requires to indicate preferred ward type.'
      }, {
        label: 'Other benefit',
        key: 'other',
        type: 'radio',
        options: [
          { label: 'As charged', value: 'charged' },
          { label: 'Non as charged', value: 'notcharged' }
        ],
        validationMsg: 'Hospitalisation Expenses requires to indicate other benefit.'
      }, {
        label: 'Rider for Deductible & Co-Insurance',
        key: 'rider',
        type: 'radio',
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ],
        validationMsg: 'Hospitalisation Expenses requires to indicate rider for Deductible & Co-Insurance.'
      }
    ]
  }
  return structure
}
