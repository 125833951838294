import BasicProduct from './BasicProduct'
import InvestmentProduct from './InvestmentProduct'
import { Default } from '@master/annotation/Default'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { ClientChoiceOptions } from '@master/model/options/ClientChoiceOptions'

export class BasicProductV2 extends BasicProduct {
  @Default([])
  investmentFunds: InvestmentProduct[] = []

  kycSubmissionTimestamp = null 
  modeOfAcquisition = null 
  afterExtractData (data: { riders: any[]; investmentFunds: InvestmentProduct[]}) {
    const defaultMap = super.afterExtractData(data)

    const investmentFunds: InvestmentProduct[] = data.investmentFunds.map(funds => {
      const newInvest = new InvestmentProduct(this.riskRatingService, defaultMap)
      Object.assign(newInvest, newInvest.extractData(funds))
      return newInvest
    })
    data.investmentFunds = investmentFunds
    Object.assign(data, defaultMap)
    return data
  }

  convertToRawData () {
    const data: any = super.convertToRawData()

    // Convert riders
    const rawRiders = this.riders.map(riderProduct => riderProduct.convertToRawData())
    data.riders = rawRiders

    // convert investment sub-funds
    const rawInvestmentFunds = this.investmentFunds.map(investFund => investFund.convertToRawData())
    // eslint-disable-next-line @typescript-eslint/camelcase
    data.investment_funds = rawInvestmentFunds
    return data
  }

  get totalAnnualisedPremium () {
    let total = 0
    if (this.type === 'insurance' && this.isAcceptedProduct) {
      total += this.annualisedPremium
      total += this.riders.reduce((total, rider) => total + rider.annualisedPremium, 0)
    } else if (this.type === 'investment') {
      total += this.investmentFunds.reduce((total, invest) => {
        if (invest.isAcceptedProduct) {
          total += invest.annualisedPremium
        }
        return total
      }, 0)
    }

    return total
  }

  get totalSinglePremium () {
    let total = 0
    if (this.type === 'insurance' && this.isAcceptedProduct) {
      total += this.singlePremium
      total += this.riders.reduce((total, rider) => total + rider.singlePremium, 0)
    } else if (this.type === 'investment') {
      total += this.investmentFunds.reduce((total, invest) => {
        if (invest.isAcceptedProduct) {
          total += invest.singlePremium
        }
        return total
      }, 0)
    }

    return total
  }

  constructor (riskRatingService: IRiskRatingService) {
    super(riskRatingService)

    this.riskRatingService = riskRatingService

    const rejectValues = ['reject', 'kiv']
    // add proxy to get onChange on clientChoice and set all the riders to the save value if client reject or kiv
    // this is used in Our Recommendation client's decision tab
    // to make sure user can't reject or kiv on basic product and make riders accept or own choice at the same time
    // assign basic product client choice to rider's mainProductClientChoice
    // assign investment main account client choice to its own investment subfunds's mainProductClientChoice
    return new Proxy(this, {
      set: function (obj, prop, value) {
        if (prop === 'clientChoice') {
          if (rejectValues.includes(value)) {
            obj.riders.forEach(rider => { rider.clientChoice = value as keyof typeof ClientChoiceOptions })
            obj.investmentFunds.forEach(investFunds => { investFunds.clientChoice = value as keyof typeof ClientChoiceOptions })
          }
          obj.riders.forEach(rider => { rider.mainProductClientChoice = value as keyof typeof ClientChoiceOptions })
          obj.investmentFunds.forEach(investFunds => { investFunds.mainProductClientChoice = value as keyof typeof ClientChoiceOptions })
        }

        if (prop === 'clientIndex') {
          if (obj.type === 'investment') {
            // to made investment subfunds has main account info
            obj.investmentFunds.forEach(funds => { funds.mainAccountIndex = value })
          } else if (obj.type === 'insurance') {
            obj.riders.forEach(rider => { rider.clientIndex = value })
          }
        }

        if (prop === 'accountNumber' && obj.type === 'investment') {
          obj.investmentFunds.forEach(funds => { funds.accountNumber = value })
        }

        if (prop === 'oid' && obj.type === 'investment') {
          obj.investmentFunds.forEach(funds => { funds.mainAccountNameOid = value })
        }

        obj[prop] = value
        return true
      }
    })
  }
}
