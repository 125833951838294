import { ClientResponseDto, ClientService } from '@core/pages/client-management/api'
import { ClientPolicyService } from '@core/pages/new-business-register/api'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true, name: 'ClientStore'
})
export default class ClientStore extends VuexModule {
  loading = false
  clientList: ClientResponseDto[] = []
  corporateList: ClientResponseDto[] = []
  individualList: ClientResponseDto[] = []
  clientRecords: Partial<ClientResponseDto>[] = []
  @Mutation
  setClientList(data: ClientResponseDto[]) {
    if (data && data.length) {
      this.clientList = data
      this.corporateList = data.filter(client => client.accountType === 'corporate')
      this.individualList = data.filter(client => client.accountType === 'individual')
    }
  }

  /**
   * In the future, to have parameters to be pass in
   * for filtering
   * @TODO: To have the backend use Swagger for defining the type
   * for the response
   */
  @Action
  async retrieveClientList() {
    this.context.commit('setLoading', true)
    try {

      const res = await ClientService.retrieveClientList() as any

      if (typeof res === 'string' && JSON.parse(res).error) {
        throw new Error(JSON.parse(res).error)
      }

      this.context.commit('setClientList', res)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }

  @Action
  async exportCsv(params: {
    exportFor?: string,
  }) {
    return await ClientPolicyService.exportCsv(params.exportFor);
  }

  @Action
  async exportNBRCsv() {
    return await ClientPolicyService.exportNBRCsv();
  }

  @Mutation
  setClientRecords(data: ClientResponseDto[]) {
    if (data && data.length) {
      this.clientRecords = data

    }
  }



  @Action
  async retriveClientsForSelectDropdown(params: any) {
    this.context.commit('setLoading', true)
    try {
      const res = await ClientService.retrieveDropdownClientList(params) as any
      if (typeof res === 'string' && JSON.parse(res).error) {
        throw new Error(JSON.parse(res).error)
      }

      this.context.commit('setClientRecords', res)
      return res
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action
  async retrieveClientDropdown(params: any) {
    /**
     * params ={
     * search : "",
     * limit: 100,
     * page: 1
     * }
     */
    const res = await ClientService.retrieveDropdownClientList(params) as any
    if (typeof res === 'string' && JSON.parse(res).error) {
      throw new Error(JSON.parse(res).error)
    }
    return res.map((client:any) => {
      return {
        label: `${client.name} (${client.nric || "unknown"})`,
        value: client.oid,
      };
    })
  }
}
