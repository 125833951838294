import { FNAStructure } from '@master/model/structure/FNAStructure'
import OtherNeeds from '@master/model/kyc-form/need-analysis/others/OtherNeeds'

export function OthersStructure (dependantOptions) {
  const structure: FNAStructure<OtherNeeds> = {
    title: 'Other Needs',
    data: [
      {
        label: 'What are you planning for',
        key: 'planningFor',
        type: 'text',
        ejsClass: 'ejs-plan-div',
        validationMsg: 'Other Needs requires to indicate planning for.'
      }, {
        label: 'Who is this provided for',
        key: 'providedForWho',
        type: 'dropdown',
        options: dependantOptions,
        ejsClass: 'ejs-plan-div',
        validationMsg: 'Other Needs requires to indicate planning for.'
      }, {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Amount/Benefits needed',
        key: 'amountNeeded',
        type: 'currency',
        validationMsg: 'Other Needs requires to indicate amount needed.'
      }, {
        label: '- Existing relevant benefits ($)',
        key: 'existingBenefits',
        type: 'currency',
        validationMsg: 'Other Needs requires to specify existing benefits.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
