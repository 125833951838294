/**
 * @deprecated The method should import from `@master`
 */
function infAdjReturn (inflationRate: number, investmentReturn: number) {
  return ((1 + investmentReturn) / (1 + inflationRate)) - 1
}

/**
 * @deprecated The method should import from `@master`
 */
function pv (amount: number | undefined, period: number | undefined, rate: number | undefined, isEndMode = false) {
  const _amount = amount || 0
  const _period = period || 0
  const _rate = (rate || 0) / 100
  if (_rate === 0) {
    return _amount * _period
  } else {
    const dueFactor = (1 - (1 / (1 + _rate) ** _period)) * (isEndMode ? 1 : (1 + _rate))
    return _amount / _rate * dueFactor
  }
}

/**
 * @deprecated The method should import from `@master`
 */
function fv (amount: number, period: number, rate: number) {
  const result = amount * ((1 + rate) ** period)
  return isNaN(result) ? 0 : result
}

/**
 * @deprecated The method should import from `@master`
 */
function savingLumpSum (amount: number, period: number, rate: number) {
  return amount / (1 + rate) ** period
}

/**
 * @deprecated The method should import from `@master`
 */
function savingMonthly (amount: number, period: number, rate: number) {
  if (rate === 0) {
    return amount / period / 12
  }

  const monthlyRate = rate / 12
  const overallRate = (1 + monthlyRate) ** (12 * period + 1)
  const snRate = monthlyRate / (overallRate - (1 + monthlyRate))
  return amount * snRate
}

/**
 * @deprecated The method should import from `@master`
 */
function round (value: number, digitNumber: number) {
  const decimal = 10 ** digitNumber
  const result = Math.round(value * decimal) / decimal
  return isNaN(result) ? 0 : result
}

/**
 * @deprecated The method should import from `@master`
 */
function sum (...array: Array <string | number>): number {
  let total = 0
  array.forEach(value => {
    if (typeof value === 'string') {
      total += parseFloat(value) || 0
    } else if (typeof value === 'number' && value) {
      total += value
    }
  })
  return total
}

/**
 * @deprecated Should import from `@master`
 */
export { infAdjReturn, pv, fv, savingLumpSum, savingMonthly, round, sum }
