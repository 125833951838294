import { MaxChar } from '@master/annotation/MaxChar'
import { TypeBase } from '@master/model/TypeBase'

export default class ExistingInvestDeclaration extends TypeBase {
  constructor (type: 'existing_invest' = 'existing_invest') {
    super(type)
  }

  review = ''

  @MaxChar(140)
  reviewReasons = ''
}
