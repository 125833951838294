/* eslint-disable @typescript-eslint/camelcase */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../../../../src/store/index'
import '@/DIContainer'
import { AddPolicyIndividualDTO, ClientPolicyDto, ClientPolicyService, FilterPoliciesDTO, ManualNbrDTOV2, PolicyIndividualDTO, PolicySearchDto, PolicySearchResultDto, SyncPolicyIndividualRequestDTO, SyncPolicyIndividualResponseDTO, PolicyRoleDTO } from '../api'
import { ClientPolicyService as ClientPolicyServiceV2 } from '@core/pages/CommissionModule/api/services/ClientPolicyService'
import { camelCase, snakeCase } from 'lodash/fp'
import { ProductHeaders } from '@master/model/common/ProductMeta'
import { cid, container, inject } from 'inversify-props'
import API from '@core/services/API'
import AccountMeta from '@core/pages/account/model/AccountMeta'
import { ProductRider } from '../model/ProductRider'
import { ProductILPPremium } from '../model/ProductILPPremium'
import { ProductILPSingle } from '../model/ProductILPSingle'
import { PolicyClientMeta } from '../model/PolicyClientMeta'
import { ProductFullMetaList } from '@master/model/common/ProductFullMetaList'
import { CISLumpSum, CISRepeatInvestment, PorfolioTransactions } from '../model/CISPortfolio'
import { SharedVariable } from '@core/model/SharedVariable'
import { NBRStatusOptions } from '@master/model/options/NBRStatusOptions'
import { NBRProductStatusOptions, NBRProductCISStatusOptions } from '@master/model/options/NBRProductStatusOptions'
import { TypeOfServiceOptions } from '@master/model/options/TypeOfServiceOptions'
import { PaymentMethodOptions } from '@master/model/options/PaymentMethodOptions'
import { CurrencyOptions } from '@master/model/options/CurrencyOptions'
import { FrequencyOptions } from '@master/model/options/FrequencyOptions'
import { PaymentModeOptions } from '@master/model/options/PaymentModeOptions'
import { RelationshipOptions } from '@master/model/options/RelationshipOptions'
import { IdentityTypeOptions } from '@master/model/options/IdentityTypeOptions'
import { CitizenshipOptions } from '@master/model/options/CitizenshipOptions'
import { MaritalStatusOptions } from '@master/model/options/MaritalStatusOptions'
import { EduLevelOptions, WrittenLangOptions, IncomeTierOptions } from '@core/pages/client-management/model/ClientPersonal'
import { TransactionTypeOptions } from '@master/model/options/TransactionTypeOptions'
import { WrapOptions } from '@master/model/options/WrapOptions'
import { ProductList } from '@core/pages/adminProductList/model/ProductList'
import { UIService } from '@core/services/UIService'
import { AccountService } from '@core/openapi/services/user-account/api'
import { MochaConfigService } from '@core/pages/account/api'
import { PolicyDocumentService, PolicyDocumentUploadDto } from '@core/openapi/services/default/api'
import { PayeeService } from '@core/openapi/services/payee/api/services/PayeeService'
import { ProductMasterService } from '@core/openapi/services/product-master/api'
import { LOB_VALUES } from '@core/utils/global.json'
import * as constants from '@core/utils/global.json'
import { checkMissingFields, extractSuperChildKeys } from '@core/model/Validator'
import { ClientPolicyOtherPremiumInformationDTO } from '@core/pages/CommissionModule/api/models/UpdateClientPolicyInformationRequestDTO'
const $api = container.get<API>('$api')
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

@Module({
    namespaced: true, name: 'ClientPolicyStore' //vuex fix
})
export default class ClientPolicyStore extends VuexModule {
    // @inject()
    // private sharedVariable: SharedVariable

    loading = false
    saveClicked: boolean = false
    backNavigationPage = '/policy-list'
    policyDataKeys = {
        pky: undefined,
        oid: undefined,
        ctm: undefined,
        clientOid: undefined,
        adviserOid: undefined,
        introducerOid: undefined,
        introducerPct: undefined,
        introducerIfam: undefined,
        servicingOid: undefined,
        servicingPct: undefined,
        servicingIfam: undefined,
        vestingOid: undefined,
        vestingPct: undefined,
        vestingIfam: undefined,
        ownersOid: undefined,
        ownersName: undefined,
        ownersNric: undefined,
        insuredTitle: undefined,
        insuredName: undefined,
        insuredNric: undefined,
        insuredTitleTwo: undefined,
        insuredNameTwo: undefined,
        insuredNricTwo: undefined,
        productOid: undefined,
        name: undefined,
        productTypeDetailed: undefined,
        productCategory: undefined,
        taggedKyc: undefined,
        type: undefined,
        policyNumber: undefined,
        businessId: undefined,
        principal: undefined,
        status: undefined,
        applicationDate: undefined,
        opsApprovalDate: undefined,
        approvedDate: undefined,
        startDate: undefined,
        formCompleted: undefined,
        endDate: undefined,
        bscClawbackStatus: undefined,
        bscClawbackAmount: undefined,
        bscClawbackPct: undefined,
        bscGrade: undefined,
        clientRebateStatus: undefined,
        clientRebateAmount: undefined,
        clientAiStatus: undefined,
        productMetadata: undefined,
        clientMetadata: undefined,
        creationType: undefined,
        faceToFace: undefined,
        faceToFaceSpecified: undefined,
        policyProfileRemarks: undefined,
        level1ComOid: undefined,
        level2ComOid: undefined,
        level3ComOid: undefined,
        level4ComOid: undefined,
        level1ComPct: undefined,
        level2ComPct: undefined,
        level3ComPct: undefined,
        level4ComPct: undefined,
        groupId: undefined,
        unitName: undefined,
        branchName: undefined,
        clusterName: undefined,
        mainInvestmentNbr: undefined,
        mainNbrOid: undefined,
        otherInformation: undefined,
    }
    policy: ClientPolicyDto = this.policyDataKeys

    // NBR Version 2 Requirements (START)

    policyIndividuals: PolicyIndividualDTO[] = []

    subPolicies: ClientPolicyDto[] = []

    policyRoles: PolicyRoleDTO[] = []

    policyInView: ClientPolicyDto = null

    mainPolicies: PolicySearchDto[] = []

    syncClientResponse: SyncPolicyIndividualResponseDTO = null

    // NBR Version 2 Requirements (END)

    clientMetadata = new PolicyClientMeta()
    productMetadata: any = null

    otherInformation: any = new ClientPolicyOtherPremiumInformationDTO

    productMetaList: ProductFullMetaList = new ProductFullMetaList([])

    adviserDetails = new AccountMeta()
    servicingAdvisorDetails = new AccountMeta()
    transactingAdvisorDetails = new AccountMeta()
    commissionAdvisorDetails = new AccountMeta()


    commissionUserDetails: any = {}

    documentTypes: any[] = []
    productNamesList: any[] = []
    productMasterProductList: any[] = []
    productNamesOptions: any[] = []

    // get all reusable options
    lineOfBusinessOptions: any[] = []
    nbrStatusOptions = NBRStatusOptions.filter(option => option.value !== 'new')
    nbrProductStatusOptions = NBRProductStatusOptions
    nbrProductCisStatusOptions = NBRProductCISStatusOptions
    typeOfServiceOptions = TypeOfServiceOptions
    paymentMethodOptions = PaymentMethodOptions
    currencyOptions = CurrencyOptions
    frequencyOptions = FrequencyOptions
    paymentModeOptions = PaymentModeOptions
    relationshipOptions = RelationshipOptions
    identityTypeOptions = IdentityTypeOptions
    citizenshipOptions = CitizenshipOptions
    maritalStatusOptions = MaritalStatusOptions
    eduLevelOptions = EduLevelOptions
    // spokenLangOption = SpokenLangOptions
    writtenLangOptions = WrittenLangOptions
    incomeTierOptions = IncomeTierOptions
    transactionTypeOptions = TransactionTypeOptions
    wrapOptions = WrapOptions

    payeeIntermediaryList = null
    userList = null

    productStatusList = null
    totalSubNbrRecords: number = 0
    // get venueOptions () {
    //   return this.sharedVariable?.options?.venueOptions || []
    // }

    // get acquisitionOptions () {
    //   return this.sharedVariable?.options?.acquisitionOptions || []
    // }

    // get convertedKeyClientPolicy () {
    //   const convertedKeys: Record<string, any> = {}
    //   for (const key in this.policy) {
    //     convertedKeys[snakeCase(key)] = this.policy[key]
    //   }
    //   return convertedKeys
    // }

    // get spokenLanguageOptions () {
    //   return this.spokenLangOption
    // }
    @Action
    async listPolicies(filter: FilterPoliciesDTO) {
        this.context.commit('setLoading', true)

        try {
            const policies = await ClientPolicyService.listPoliciesV2(filter)
            this.context.commit('setMainPolicies', policies)
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Mutation
    setMainPolicies(policies: PolicySearchResultDto) {
        if (policies.data.length > 0) {
            this.mainPolicies = policies.data.map(policy => {
                if (typeof policy.productMetadata === 'string') {
                    policy.productMetadata = JSON.parse(policy.productMetadata)
                }
                if (typeof policy.role === 'string') {
                    policy.role = JSON.parse(policy.role)
                }
                return policy
            })
        }
    }

    @Action
    async updateFromIframe(obj: Record<string, any>) {
        const convertedKeys: Record<string, any> = {}
        for (const key in obj) {
            convertedKeys[camelCase(key)] = obj[key]
        }
        this.context.commit('updatePolicy', convertedKeys)
    }

    @Action({ rawError: true })
    async savePolicy() {
        this.context.commit('setSaveClicked', true)
        this.context.commit('setLoading', true)
        try {
            const selectedProduct = this.productMetaList?.getFullList().find(prod => prod.oid === this.policy.productOid)
            if (selectedProduct) {
                this.policy.name = selectedProduct.name
                this.policy.productCategory = selectedProduct.category
                this.policy.productTypeDetailed = selectedProduct.type
            }
            let valid = true
            let isPolicyMetaValid = true
            const invalidKeys = []
            let policyMapping = new Map()
            if (this.policy.type === LOB_VALUES.INVESTMENT) {
                policyMapping = new Map<string, string>(
                    Object.entries({
                        // policyNumber: 'Policy Number',
                        adviserOid: 'Adviser',
                        principal: 'Principal',
                        type: 'Line of Business',
                        ctm: 'NBR Creation Date',
                        // opsApprovalDate: 'NBR Status Date',
                        faceToFace: 'Place of Sales',
                        // businessId: 'New Business ID',
                        productOid: 'Investment Account',
                        submissionMode: 'Submission Mode',
                        commissionAdvOid: 'Commission Adviser',
                        transactingAdvOid: 'Transacting Adviser'
                    })
                )
            } else if (this.policy.type === LOB_VALUES.LIFE) {
                policyMapping = new Map<string, string>(
                    Object.entries({
                        // policyNumber: 'Policy Number',
                        adviserOid: 'Adviser',
                        principal: 'Principal',
                        type: 'Line of Business',
                        ctm: 'NBR Creation Date',
                        // opsApprovalDate: 'NBR Status Date',
                        faceToFace: 'Place of Sales',
                        // businessId: 'New Business ID',
                        productOid: 'Product Name',
                        submissionMode: 'Submission Mode',
                        kycSubmissionTimestamp: 'Financial Needs Analysis Submission Date',
                        commissionAdvOid: 'Commission Adviser',
                        transactingAdvOid: 'Transacting Adviser'
                        // introducerOid: 'Introducer Name',
                        // servicingOid: 'Servicing Agent Name'
                    })
                )
            }
            else {
                policyMapping = new Map<string, string>(
                    Object.entries({
                        // policyNumber: 'Policy Number',
                        adviserOid: 'Adviser',
                        principal: 'Principal',
                        type: 'Line of Business',
                        ctm: 'NBR Creation Date',
                        // opsApprovalDate: 'NBR Status Date',
                        // faceToFace: 'Place of Sales',
                        // businessId: 'New Business ID',
                        productOid: 'Product Name',
                        submissionMode: 'Submission Mode',
                        kycSubmissionTimestamp: 'Financial Needs Analysis Submission Date',
                        commissionAdvOid: 'Commission Adviser',
                        transactingAdvOid: 'Transacting Adviser'

                        // introducerOid: 'Introducer Name',
                        // servicingOid: 'Servicing Agent Name'
                    })
                )
            }
            let keys = []
            if (this.policy.type === LOB_VALUES.INVESTMENT) {
                keys = ['principal', 'policyNumber', 'commissionAdvOid', 'transactingAdvOid', 'adviserOid', 'status', 'approvedDate', 'faceToFace', 'applicationDate', 'type', 'policyProfileRemarks']
            } else {
                keys = ['principal', 'policyNumber', 'commissionAdvOid', 'transactingAdvOid', 'adviserOid', 'status', 'startDate', 'faceToFace', 'approvedDate', 'applicationDate', 'endDate', 'type', 'policyProfileRemarks']
            }
            Object.keys(this.policy).forEach(key => {
                const value = this.policy[key]
                if (!policyMapping.has(key)) return
                if (!value || value === '' || value.length === 0) {
                    invalidKeys.push(policyMapping.get(key))
                    console.log(invalidKeys)
                    valid = false
                }
            })
            let policyMetaMapping
            if (this.policy.type === LOB_VALUES.INVESTMENT && this.policy.type === LOB_VALUES.LIFE) {
                policyMetaMapping = new Map(
                    Object.entries({
                        premiumAmount: 'Basic Premium ($)',
                        frequency: 'Frequency',
                        modeOfAcquisition: 'Mode of Acquisition',
                        sumAssured: 'Benefit Amount ($)'
                    })
                )
            }
            else {
                policyMetaMapping = new Map(
                    Object.entries({
                        // premiumAmount: 'Basic Premium ($)',
                        // frequency: 'Frequency',
                        // modeOfAcquisition: 'Mode of Acquisition',
                        // sumAssured: 'Benefit Amount ($)'
                    })
                )
            }
            Object.keys(this.policy.productMetadata).forEach(key => {
                const value = this.policy.productMetadata[key]
                if (!policyMetaMapping.has(key)) return
                if (!value || value === '' || value.length === 0) {
                    invalidKeys.push(policyMetaMapping.get(key))
                    isPolicyMetaValid = false
                }
            })
            if (valid && isPolicyMetaValid) {
                this.policy.clientMetadata = JSON.stringify(this.clientMetadata.convertToRawData())
                this.policy.productMetadata = JSON.stringify(this.productMetadata)
                this.policy.bscClawbackPct = Number(this.policy.bscClawbackPct)
                await ClientPolicyServiceV2.updatePolicy(this.policy)
                this.context.commit('setFormCompleted', true)
                this.context.commit('setPolicy', this.policy)
                uiService.alert.show({
                    type: 'success',
                    message: 'Data Saved Successfully'
                })
            } else {
                console.log("invalidKeys", invalidKeys)
                if (invalidKeys.length > 0) {
                    uiService.alert.show({
                        type: 'error',
                        message: 'Error! Some mandatory fields are still missing.'
                    })
                    return;
                }
            }
        } catch (error) {
            this.policy.clientMetadata = JSON.parse(this.policy.clientMetadata)
            this.policy.productMetadata = JSON.parse(this.policy.productMetadata)
            this.context.commit('setFormCompleted', false)
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
        // this.context.commit('setSaveClicked', false)
        this.context.commit('setLoading', false)
    }

    @Mutation
    setFormCompleted(value) {
        this.policy.formCompleted = value;
    }

    @Action
    async fetchPolicyV2(oid: string) {
        this.context.commit('setLoading', true)
        try {
            const res = await ClientPolicyService.getPolicyV2(oid) as any
            if (typeof res === 'string' && JSON.parse(res).error) {
                throw new Error(JSON.parse(res).error)
            }
            if ('productTypeList' in res) {
                const lob = res['productTypeList']?.map((product) => {
                    return {
                        label: product.productLine,
                        value: product.productLine
                    }
                })
                this.context.commit('setProductTypes', lob)
                delete res['productTypeList']
            }
            this.context.commit('setPolicy', res)
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
        this.context.commit('setLoading', false)
    }

    // NBR Version 2 Requirements (START)

    @Action
    async retrieveSubPolicies(mainPolicyOid: string) {
        this.context.commit('setLoading', true)
        try {
            const res = await ClientPolicyService.getSubPolicy(mainPolicyOid, {})
            if (typeof res === 'string' && JSON.parse(res).error) {
                throw new Error(JSON.parse(res).error)
            }
            this.context.commit('setSubPolicies', res.data)
            this.context.commit("setTotalSubNbrRecords", res.recordsTotal) //totalSubNbrRecords
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
        this.context.commit('setLoading', false)
    }

    @Mutation
    setSubPolicies(data: ClientPolicyDto[] = []) {
        if (data && data.length) {
            this.subPolicies = data.map(policy => {
                return {
                    ...policy,
                    productMetadata: JSON.parse(policy.productMetadata) || {},
                    otherInformation: typeof policy.otherInformation === 'string' ? JSON.parse(policy.otherInformation) : policy.otherInformation
                }
            })
        } else {
            this.subPolicies = []
        }
    }

    @Mutation
    setSaveClicked(data) {
        this.saveClicked = data;
    }

    @Mutation
    setBackNatigation(data) {
        this.backNavigationPage = data;
    }

    @Action
    async saveSubPolicy(subPolicy: ClientPolicyDto) {
        this.context.commit('setLoading', true)
        try {
            subPolicy.productMetadata = JSON.stringify(subPolicy.productMetadata)
            const productList: ProductList = this.context.rootState.KYCFormStore.productList
            const selectedProduct = productList.originalList.find(prod => prod.oid === this.policy.productOid)

            if (selectedProduct) {
                subPolicy.name = selectedProduct.name
                subPolicy.productCategory = selectedProduct.category
                subPolicy.productTypeDetailed = selectedProduct.type
            }
            const createdPolicyOid = await ClientPolicyService.updatePolicy(subPolicy)
            // Doing so because version 1 API returns it as a string...
            subPolicy.productMetadata = JSON.parse(subPolicy.productMetadata)
            // this.context.commit('updatePolicyInView', subPolicy)
            return createdPolicyOid
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Action
    async addNewSubPolicy(subPolicy: ManualNbrDTOV2) {
        this.context.commit('setLoading', true)

        try {
            const createdPolicy = await ClientPolicyService.saveNewBusinessV2(subPolicy)
            // this.context.commit('updatePolicyInView', createdPolicy)
            this.context.commit('addSubPolicyToArray', createdPolicy)
            return createdPolicy
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Action
    selectedPolicyInView(policy: ClientPolicyDto) {
        this.context.commit('updatePolicyInView', policy)
    }

    @Mutation
    updatePolicyInView(policy: ClientPolicyDto) {
        if (typeof policy?.productMetadata === 'string') {
            policy.productMetadata = JSON.parse(policy.productMetadata)
        }
        this.policyInView = policy
    }

    @Mutation
    addSubPolicyToArray(policy: ClientPolicyDto) {
        this.subPolicies.push(policy)
    }

    @Action
    async removeSubPolicy({ mainNbrOid, ids }) {
        // Delete from database
        this.context.commit('setLoading', true)

        try {
            const deletedPolicy = await ClientPolicyService.deleteSubPolicy(mainNbrOid, ids)
            this.context.commit('removeSubPolicyFromArray', deletedPolicy)
            return deletedPolicy
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Mutation
    removeSubPolicyFromArray(policy: ClientPolicyDto) {
        const index = this.subPolicies.findIndex(subPolicy => subPolicy.oid === policy.oid)

        if (index !== -1) {
            this.subPolicies.splice(index, 1)
            this.policyInView = null
        }
    }

    @Action
    async addPolicyIndividual(individual: AddPolicyIndividualDTO) {
        this.context.commit('setLoading', true)
        try {
            const res = await ClientPolicyService.addNewPolicyIndividuals(individual) as any

            if (typeof res === 'string' && JSON.parse(res).error) {
                throw new Error(JSON.parse(res).error)
            }

            this.context.commit('setSubPolicies', res)
            return res
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Action
    async savePolicyIndividual(individual: PolicyIndividualDTO) {
        this.context.commit('setLoading', true)
        this.context.commit('setSaveClicked', true)
        let policyIndividualRequiredFields = {}
        try {
            policyIndividualRequiredFields = {
                "clientStatusDate": "Client Status Date",
                "role": "Role(s)"
            }
            let validatingData = extractSuperChildKeys(individual)
            let errors = checkMissingFields(policyIndividualRequiredFields, validatingData)
            console.log("errors", errors, individual, validatingData)
            if (errors.length) {
                throw Error("Error! Some mandatory fields are still missing.")
            }
            const updatedIndividual = await ClientPolicyService.savePolicyIndividual(individual)
            return updatedIndividual
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Action
    async deletePolicyIndividual(individual: PolicyIndividualDTO) {
        this.context.commit('setLoading', true)
        try {
            const deletedIndividual = await ClientPolicyService.deletePolicyIndividual(individual.oid)
            return deletedIndividual
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Action
    async fetchPolicyIndividuals(oid: string) {
        this.context.commit('setLoading', true)
        try {
            const res = await ClientPolicyService.retrievePolicyIndividuals(oid) as any

            if (typeof res === 'string' && JSON.parse(res).error) {
                throw new Error(JSON.parse(res).error)
            }

            this.context.commit('setPolicyIndividual', res)
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
        this.context.commit('setLoading', false)
    }

    @Mutation
    setPolicyIndividual(data: PolicyIndividualDTO[]) {
        if (data && data.length) {
            // removed duplicated roles
            this.policyIndividuals = data.map(policyIndividual => {
                let roles = []
                policyIndividual.role.forEach(role => {
                    if (role === 'tpp') {
                        roles.push("thirdPartyPayor")
                    } else if (role === 'lifeAssured') {
                        roles.push("lifeAssuredOne")
                    } else if (role === 'lifeAssuredTwo') {
                        roles.push("jointOwner")
                    } else {
                        roles.push(role)
                    }
                })
                roles = [...new Set(roles)]
                policyIndividual.role = roles
                return policyIndividual
            }).sort((a, b) => Number(b.isMainOwner) - Number(a.isMainOwner))
        }
    }

    // NBR Version 2 Requirements (END)

    @Mutation
    setProductMetaList(data: (string | number)[][]) {
        if (data && data.length) {
            const productMetaList = new ProductFullMetaList(data)
            this.productMetaList = productMetaList
        }
    }

    @Mutation
    setAdviserDetails(data: (string | number)[]) {
        if (data) {
            this.adviserDetails.assignData(Object.keys(data), Object.values(data))
        }
    }

    @Mutation
    setServicingAdviserDetails(data: (string | number)[]) {
        if (data) {
            this.servicingAdvisorDetails.assignData(Object.keys(data), Object.values(data))
        }
    }

    @Mutation
    setTransactionAdviserDetails(data: (string | number)[]) {
        if (data) {
            this.transactingAdvisorDetails.assignData(Object.keys(data), Object.values(data))
        }
    }

    @Mutation
    setcommissionAdviserDetails(data: (string | number)[]) {
        if (data) {
            this.commissionAdvisorDetails.assignData(Object.keys(data), Object.values(data))
        }
    }

    @Action({ rawError: true })
    async fetchRecommendedProducts() {
        const { data } = await $api.relProductList.list({
            headers: JSON.stringify(ProductHeaders),
            // only return active products
            query: 'status = ?',
            queryArgs: JSON.stringify([1])
        })
        this.context.commit('setProductMetaList', data)
    }

    @Action({ rawError: true })
    async fetchAdviserDetails() {
        const response = await AccountService.retrieveUserAccountInformation(this.policy.adviserOid)
        this.context.commit('setAdviserDetails', response)
    }

    @Action({ rawError: true })
    async fetchServicingAdvisorDetails() {
        if (this.policy.adviserOid !== this.policy.servicingOid && this.policy.servicingOid) {
            const response = await AccountService.retrieveUserAccountInformation(this.policy.servicingOid)
            this.context.commit('setServicingAdviserDetails', response)
        }
    }

    @Action({ rawError: true })
    async fetchTransactionAdvisorDetails() {
        if (this.policy.adviserOid !== this.policy.transactingAdvOid && this.policy.transactingAdvOid) {
            const response = await AccountService.retrieveUserAccountInformation(this.policy.transactingAdvOid)
            this.context.commit('setTransactionAdviserDetails', response)
        }
    }

    @Action({ rawError: true })
    async fetchCommissionAdvisorDetails() {
        if (this.policy.adviserOid !== this.policy.commissionAdvOid && this.policy.commissionAdvOid) {
            const response = await AccountService.retrieveUserAccountInformation(this.policy.commissionAdvOid)
            this.context.commit('setcommissionAdviserDetails', response)
        }
    }

    @Mutation
    setCommissionUserDetails(data: {}) {
        if (data) {
            this.commissionUserDetails = data
        }
    }

    @Action({ rawError: true })
    async fetchCommissionUserDetails() {
        const lvl1ComUser = await $api.account.loginMetaGet({ _oid: this.policy.level1ComOid })
        const lvl2ComUser = await $api.account.loginMetaGet({ _oid: this.policy.level2ComOid })
        const lvl3ComUser = await $api.account.loginMetaGet({ _oid: this.policy.level3ComOid })
        const data = {
            lvl1ComUser: lvl1ComUser.meta,
            lvl2ComUser: lvl2ComUser.meta,
            lvl3ComUser: lvl3ComUser.meta
        }
        this.context.commit('setCommissionUserDetails', data)
    }

    @Action({ rawError: true })
    async syncClientInformation(syncDto: SyncPolicyIndividualRequestDTO) {
        const response = await ClientPolicyService.syncClientInformation(syncDto)
        this.context.commit('setSyncClientResponse', response)
    }

    @Mutation
    setSyncClientResponse(syncResponse: SyncPolicyIndividualResponseDTO) {
        this.syncClientResponse = syncResponse
    }

    // @Mutation
    // resetPolicy() {
    //   this.policy = undefined
    // }

    @Mutation
    setPolicy(policy: ClientPolicyDto) {
        this.policy = policy
        let productMetadata = this.policy.productMetadata && typeof this.policy?.productMetadata === 'string' ? JSON.parse(policy.productMetadata) : this.policy?.productMetadata

        if (this.policy.clientMetadata && typeof this.policy.clientMetadata === 'string') {
            const parsedClientMetadata = JSON.parse(this.policy.clientMetadata)
            this.clientMetadata.assignData(Object.keys(parsedClientMetadata), Object.values(parsedClientMetadata))
        }

        if (typeof this.clientMetadata.pdpaMarketing === 'boolean') {
            this.clientMetadata.pdpaMarketing = []
        }

        if (!productMetadata) {
            productMetadata = {}
        }
        const isJson = (string) => {
            try {
                JSON.parse(string)
            } catch (e) {
                return false
            }
            return true
        }
        if (this.policy.submissionMode && isJson(this.policy.submissionMode)) {
            this.policy.submissionMode = JSON.parse(this.policy.submissionMode)
        }

        productMetadata.insured_dob = productMetadata.insured_dob || ''
        productMetadata.insured_citizenship = productMetadata.insured_citizenship || ''
        productMetadata.nationality = productMetadata.nationality || ''
        productMetadata.gender = productMetadata.gender || ''
        productMetadata.relationship_to_owner = productMetadata.relationship_to_owner || ''
        productMetadata.modeOfAcquisition = productMetadata.modeOfAcquisition || ''
        productMetadata.locationOfEventSpecified = productMetadata.locationOfEventSpecified || ''
        productMetadata.modeOfAcquisitionSpecified = productMetadata.modeOfAcquisitionSpecified || ''
        productMetadata.productStatus = productMetadata.productStatus || 'pending'
        productMetadata.statusReason = productMetadata.statusReason || 'App Received'

        if (this.policy.type === LOB_VALUES.INVESTMENT) {
            if (!productMetadata.cis_lump_sum) {
                productMetadata.cis_lump_sum = [new CISLumpSum()]
            }

            if (!productMetadata.cis_repeat_investment) {
                productMetadata.cis_repeat_investment = [new CISRepeatInvestment()]
            }
            if (!productMetadata.portfolio_transactions) {
                productMetadata.portfolio_transactions = [new PorfolioTransactions()]
            }
        }

        if (this.policy.type === LOB_VALUES.LIFE) {
            if (!productMetadata.riders) {
                productMetadata.riders = [new ProductRider()]
            }

            if (!productMetadata.ilp_premium_list) {
                productMetadata.ilp_premium_list = [new ProductILPPremium()]
            }

            if (!productMetadata.ilp_single_list) {
                productMetadata.ilp_single_list = [new ProductILPSingle()]
            }
        }


        this.productMetadata = {
            frequency: '',
            basicModalPremium: 0,
            basic_modal_premium: 0,
            ncBasicModalPremium: 0,
            nc_basic_modal_premium: 0,
            modalPremium: 0,
            modal_premium: 0,
            asp: 0,
            discount: 0,
            gst: 0,
            premiumPayable: 0,
            premium_payable: 0,
            netPremium: 0,
            net_premium: 0,
            ape: 0,
            commissionPayable: 0,
            grossCommissionEarned: 0,
            commissionPayment: 0,
            statusReason: '',
            sumAssured: 0,
            ...productMetadata
        }
        if (this.policy.otherInformation) {

            if (this.policy.otherInformation.length && typeof this.policy?.otherInformation === 'string') {
                this.otherInformation = JSON.parse(policy.otherInformation)
            } else {
                this.otherInformation = this.policy?.otherInformation
            }
        }
        this.policy.productMetadata = this.productMetadata
        // Assign product issuer and name, if product name is null, but have product oid
        if (this.policy.productOid && !this.policy?.name) {
            const product = this.productMetaList.allProduct.find(product => product.oid === this.policy.productOid)
            const name = product?.name
            const issuer = product?.issuer_oid
            this.policy.name = name

            if (!this.policy.principal) {
                this.policy.principal = issuer
            }
        }
    }

    @Mutation
    updatePolicy(update: Record<keyof ClientPolicyDto, any>) {
        Object.assign(this.policy, update)
    }

    @Mutation
    setLoading(value: boolean) {
        Object.assign(this.loading, value)
    }

    @Action
    async retrievePolicyRoles() {
        this.context.commit('setLoading', true)
        try {
            const response = await MochaConfigService.retrieveConfigValue('PRODUCT_ROLES')
            if (typeof response === 'string' && JSON.parse(response).error) {
                throw new Error(JSON.parse(response).error)
            }
            const policyRoles = JSON.parse(response?.kvl)
            this.context.commit('setPolicyRoles', policyRoles)
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        } finally {
            this.context.commit('setLoading', false)
        }
    }

    @Mutation
    setPolicyRoles(data: PolicyRoleDTO[]) {
        this.policyRoles = data;
    }

    @Action
    async fetchDocumentTypes() {
        try {
            const response = await MochaConfigService.retrieveConfigValue('NBR_DOCUMENT_TYPE')
            const typeList = JSON.parse(response?.kvl)
            this.context.commit('setDocumentTypes', typeList);
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
    }

    @Mutation
    setDocumentTypes(data: any[]) {
        this.documentTypes = data
    }

    @Action
    async uploadPolicyDocument(payload): Promise<any> {
        try {
            this.context.commit('setLoading', true)
            const response = await PolicyDocumentService.uploadPolicyDocument(payload)
            this.context.commit('setLoading', false)
            return response
        } catch (error) {
            this.context.commit('setLoading', false)
            throw new Error(error);
        }
    }

    @Action
    async fetchPayeeList(): Promise<any> {
        try {
            let headers = [
                'oid',
                'payeeFullname',
                'payeeNric',
                'payeeEmail',
                'intermediaryCode'
            ]
            const res = await PayeeService.getPayeeList('', headers.join(','), 1, 10000, '', true)
            this.context.commit('setPayeeList', res)
        } catch (error) {
            throw new Error(error)
        }
    }

    @Mutation
    setPayeeList(data: any) {
        this.payeeIntermediaryList = data?.data
    }


    @Action
    async fetchAccountList(): Promise<any> {
        try {
            let headers = [
                'oid',
                'payeeFullname',
                'payeeNric',
                'payeeEmail',
                'payeeCompanyCode'
            ]
            const res = await AccountService.listUserAccounts(headers.join(','), '', '', '', '', 1, 10000, true)
            this.context.commit('setUserList', res)
        } catch (error) {
            throw new Error(error)
        }
    }

    @Mutation
    setUserList(data: any) {
        this.userList = data?.data
    }

    @Mutation
    setProductTypes(data: any[]) {
        this.lineOfBusinessOptions = data
    }

    @Action
    async fetchProductTypes() {
        const productTypesList = await ProductMasterService.getProductTypes()
        const pl: any = [...new Map(productTypesList.map(item => [item["productLine"], item])).values()]
        const lob = pl?.map((product) => {
            return {
                label: product.productLine,
                value: product.productLine
            }
        })
        this.context.commit('setProductTypes', lob)
        return this.lineOfBusinessOptions
    }

    @Mutation
    setProductNames(providerNameList: any[]) {
        this.productMasterProductList = providerNameList
        const pnl = providerNameList.map((product) => {
            return {
                label: `${product.productName} (${product.productCode || 'No Product Code'})`,
                value: product.oid
            }
        }).sort((a, b) => a.label.localeCompare(b.label));
        this.productNamesList = providerNameList
        this.productNamesOptions = pnl
    }

    @Mutation
    savePolicyDetails(policy) {
        this.policy = policy
    }

    @Action
    async fetchProductNames(providerNameList: any[]) {
        this.context.commit('setProductNames', providerNameList)
    }

    @Action
    resetPolicy() {
        this.context.commit('setPolicy', this.policyDataKeys)
    }
    /**Product Status List store */
    @Mutation
    setProductStatusList(productStatuses) {
        this.productStatusList = productStatuses
    }
    @Action
    async fetchAllProductStatusList() {
        if (this.productStatusList) {
            return this.productStatusList
        }
        try {
            const res = await MochaConfigService.retrieveConfigValue('NEW_BUSINESS_REGISTER_STATUSES')
            this.context.commit('setProductStatusList', JSON.parse(res?.kvl))
            return JSON.parse(res?.kvl)
        } catch (error) {
            console.log("error ", error)
        }
    }


    @Action
    async updatePolicyInformation(payload) {
        this.context.commit('setSaveClicked', true)
        try {
            /** Validating Required fields */
            let policyRequiredFields = {}
            if (payload.section === constants.POLICY_INFORMATION) {
                /** First section in Policy Details Policy Information Tab */
                policyRequiredFields = {
                    "policyNumber": 'Policy Number',
                    "productOid": 'Product Name',
                    "principal": 'Product Provider',
                    "productMetadata.statusReason": "Policy Status Reason",
                    "productMetadata.productStatus": "Policy Status",
                    // "productMetadata.providerApplicationNumber": "Issuer Application Number"
                }
            } else if (payload.section === constants.BASIC_INFORMATION) {
                policyRequiredFields = {
                    "productMetadata.sumAssured": "Benefit Amount",
                }
                //INVESTMENT
                if ([LOB_VALUES.LIFE?.toLowerCase(), LOB_VALUES.INVESTMENT?.toLowerCase()].includes(this.policy?.type?.toLowerCase())) {
                    Object.assign(policyRequiredFields, { "productMetadata.frequency": "Frequency" })
                    if (LOB_VALUES.LIFE?.toLowerCase() === this.policy?.type?.toLowerCase()) {
                        Object.assign(policyRequiredFields, {
                            "endDate": "Expiry Date",
                            "productMetadata.policyTerm": "Policy Term",
                            "productMetadata.premiumTerm": "Premium Term",
                        })
                    }
                }
                /** updating data type of amounts */
                Object.assign(payload.data.productMetadata, {
                    sumAssured: Number(payload.data.productMetadata.sumAssured),
                    premiumAmount: Number(payload.data.productMetadata.premiumAmount),
                    // policyTerm: Number(payload.data.productMetadata.policyTerm),
                    // premiumTerm: Number(payload.data.productMetadata.premiumTerm),
                    sumInsured: Number(payload.data.productMetadata.sumInsured),
                    commission: Number(payload.data.productMetadata.commission),
                    investmentAmount: Number(payload.data.productMetadata.investmentAmount),
                    feesReceivable: Number(payload.data.productMetadata.feesReceivable),
                })
            } else if (payload.section === constants.OTHER_PREMIUM_INFORMATION) {
                payload.data.adminFee = Number(payload.data.adminFee)
                payload.data.targetPremium = Number(payload.data.targetPremium)
                payload.data.writtenPremium = Number(payload.data.writtenPremium)
                payload.data.initialAnnualPremium = Number(payload.data.initialAnnualPremium)
                payload.data.actualPremium = Number(payload.data.actualPremium)
                payload.data.commissionPremium = Number(payload.data.commissionPremium)
                payload.data.installmentPremium = Number(payload.data.installmentPremium)
                payload.data.loadingPremium = Number(payload.data.loadingPremium)
                payload.data.totalPremiumReceived = Number(payload.data.totalPremiumReceived)
                payload.data.extraPremium = Number(payload.data.extraPremium)
                payload.data.faceValue = Number(payload.data.faceValue)
                payload.data.coverageSumAssured = Number(payload.data.coverageSumAssured)
                payload.data.coverageInstallmentPremium = Number(payload.data.coverageInstallmentPremium)
                payload.data.sumInsured = Number(payload.data.sumInsured)

            }
            let validatingData = extractSuperChildKeys(payload.data)
            let errors = checkMissingFields(policyRequiredFields, validatingData)
            console.log("errors", errors, payload.data, validatingData)
            if (errors.length) {
                throw Error("Error! Some mandatory fields are still missing.")
            }

            const response = await ClientPolicyServiceV2.updateClientPolicyInformation(payload)
            if (response.hasOwnProperty("errors") && response.errors.length) {
                return response
            } else {
                uiService.alert.show({
                    type: 'success',
                    message: response.message
                })
            }
            this.context.commit("setPolicy", response.data)
            return true
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
            return false
        }
    }


    @Action
    async onProviderSelect(cdMetaList: any) {
        let lob = this.policy.type
        let providerOid = this.policy.principal
        let productCategory
        let indicator
        let productFamily
        const clientpid: any = this.policyIndividuals[0]

        if (clientpid?.clientDataSnapshot?.accountType === 'individual') {
            productFamily = 'Individual'
        }

        switch (lob) {
            case LOB_VALUES.INVESTMENT:
            case "collectiveinvestmentscheme": //  Investments
                productCategory = "ACC";
                indicator = "CIS";
                lob = LOB_VALUES.INVESTMENT;
                break;
            case LOB_VALUES.TRAILER_FEES:
                productCategory = "TRA";
                break;
            case LOB_VALUES.LIFE:
            case "lifeinsurance": // Life
                lob = LOB_VALUES.LIFE
                break;
            case LOB_VALUES.FEES_AND_REFERRALS:
            case "feebasedservice":
                lob = LOB_VALUES.FEES_AND_REFERRALS
                break;
            case LOB_VALUES.GI_GROUP:
            case "group-eb-tailored":
            case "group-eb":
            case "gi-health-group":
                lob = LOB_VALUES.GI_GROUP
                break;
            case LOB_VALUES.GI_ACCIDENT_HEALTH:
            case "gi-ae":
            case "gi-health-individual":
                lob = LOB_VALUES.GI_ACCIDENT_HEALTH
                productCategory = "GIA";
                break;
            case LOB_VALUES.GI_MOTOR:
            case "gi-motors-commercial":
            case "gi-motors":
                lob = LOB_VALUES.GI_MOTOR
                productCategory = "GIM";
                break;
            case LOB_VALUES.GI_TRAVEL:
            case "gi-travel-corporate":
            case "gi-travel":
                lob = LOB_VALUES.GI_TRAVEL
                productCategory = "GIT";
                break;
            case LOB_VALUES.GI_HOME:
            case "gi-homecontents":
                lob = LOB_VALUES.GI_HOME
                productCategory = "GIH";
                break;
            case LOB_VALUES.GI_COMMERCIAL:
            case "gi-clines":
                lob = LOB_VALUES.GI_COMMERCIAL
                productCategory = "GIC";
                break;
            case LOB_VALUES.GI_PERSONAL_ACCIDENT:
            case "gi-plines-corporate":
            case "gi-plines":
                lob = LOB_VALUES.GI_PERSONAL_ACCIDENT
                productCategory = "GIP";
                break;
            case LOB_VALUES.GI_OTHERS:
            case "gi-others":
                lob = LOB_VALUES.GI_OTHERS
                productCategory = "GIO";
                break;
        }

        const providerName = cdMetaList?.find((item) => item.oid === providerOid)?.shortName;
        if (providerName) {
            const res = await ProductMasterService.searchProdut(
                undefined, indicator, undefined, undefined, lob, providerName, undefined, undefined, productCategory, productFamily
            )
            // this.productOptionsByProvider = res
            this.context.commit('setProductNames', res)
        }

    }

    @Action
    async updateOrCreateComponent(subBbrDTO: any) {
        try {
            const response = await ClientPolicyServiceV2.updateNbrComponent(subBbrDTO.mainNbrOid, subBbrDTO.data)
            this.context.commit('setSubPolicies', response.data)
            this.context.commit("setTotalSubNbrRecords", response.data.length)
            return response
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
            throw error
        } finally {
            this.context.commit('setLoading', false)
        }
    }
    @Mutation
    setTotalSubNbrRecords(total: number) {
        this.totalSubNbrRecords = total
    }
    @Action
    async filterSubNbrPolicies(filter: any) {
        this.context.commit('setLoading', true)
        const { mainPolicyOid } = filter
        try {
            const res = await ClientPolicyService.getSubPolicy(mainPolicyOid, filter)
            if (typeof res === 'string' && JSON.parse(res).error) {
                throw new Error(JSON.parse(res).error)
            }
            console.log("res", res)
            this.context.commit('setSubPolicies', res.data)
            this.context.commit("setTotalSubNbrRecords", res.recordsTotal)
        } catch (error) {
            uiService.alert.show({
                type: 'error',
                message: error.message
            })
        }
        this.context.commit('setLoading', false)
    }
}
