import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { BscInscopeProductDTO, BscInscopeRequestBody, BscService } from '../api'

@Module({
  namespaced: true
})
export default class BscStore extends VuexModule {
  loading = false
  bscInscopeResult: BscInscopeProductDTO[] = []
  mainProductBscInscope: BscInscopeProductDTO

  @Action
  async validateProductIsBscInscope (productOids: string[]) {
    this.context.commit('setLoading', true)
    try {
      const request: BscInscopeRequestBody = {
        productOids: productOids
      }
      const bscResult = await BscService.checkProductsInscope(request)
      this.context.commit('setBscInscopeResult', bscResult)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setBscInscopeResult (bscResult: BscInscopeProductDTO[]) {
    this.bscInscopeResult = bscResult
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
