<template>
  <header>
    <div class="logo-wrapper">
      <div class="logo" @click="goToHome"></div>
    </div>
    <div class="menu-wrapper">
      <a @click="isShowMenu = true">
        <img v-svg-inline :src="menuIcon" alt="svg image" />
      </a>
    </div>
    <nav>
      <ul id="nav-list">
        <li v-if="enableVirtualMeeting">
          <a @click="startVirtualMeeting">
            <img v-svg-inline :src="videocamIcon" alt="svg image" />
            <p>Start Virtual Meeting</p>
          </a>
        </li>
        <template v-for="({ disable, event, href, target, icon, label, dropdown }, index) in header">
          <li v-if="!disable" :key="index">
            <template v-if="event">
              <a @click="$emit(event)" :target="target">
                <img v-svg-inline :src="icon" alt="svg image" />
                <p>{{ label }}</p>
              </a>
            </template>
            <template v-else>
              <a :href="href" :target="target">
                <img v-svg-inline :src="icon" alt="svg image" />
                <p>{{ label }}</p>
              </a>
            </template>
            <div v-if="dropdown && dropdown.length > 0" class="dropdown">
              <template v-for="(item, i) in dropdown">
                <a
                  v-if="!item.disable" :key="i" :href="item.href" class="dropdown-item"
                >{{ item.label }}</a>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </nav>
    <AppDialog v-if="enableVirtualMeeting" class="virtual-meeting-url" @closed="isCopied = false" :hideFooter="true">
      <template #header>Virtual Meeting URL</template>
      <div slot="body">
        <p>Click on the URL to copy: <strong>{{ isCopied ? 'Copied!' : '' }}</strong></p>
        <div @click="copyUrl" class="jitsi-url">
          <input id="url" :value="jitsiUrl" readonly>
        </div>
      </div>
    </AppDialog>
    <AppDialog v-if="isShowMenu" @closed="isShowMenu = false" class="menu-dialog" :size="'large'" :hideFooter="true">
      <template #header>
        <img src="@/assets/logo.png" @click="goToHome" width="200px" class="logo" />
      </template>
      <div slot="body">
        <el-menu
          class="custom-menu"
          :router="true"
          :unique-opened="true"
        >
          <template v-for="({ disable, event, href, target, icon, label, dropdown }, index) in header">
            <template v-if="!disable">
              <el-submenu v-if="dropdown && dropdown.length > 0" :key="index" :index="label">
                <template slot="title">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }}</span>
                </template>
                <template v-for="(item, i) in dropdown" :key="i">
                  <el-menu-item :index="item.label">
                    <a
                      v-if="!item.disable" :key="i" :href="item.href" class="dropdown-item"
                    >{{ item.label }}</a>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item v-else-if="event" :key="index+1" :index="label">
                <a @click="$emit(event)" :target="target">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }}</span>
                </a>
              </el-menu-item>
              <el-menu-item v-else :key="index+2" :index="label">
                <a :href="href" :target="target">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }}</span>
                </a>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
    </AppDialog>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { HeaderItem } from '@core/model/structure/HeaderStructure'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import VideocamIcon from '@core/components/header/assets/videocam.svg'
import MenuIcon from '@core/components/header/assets/menu.svg'
import AppDialog from '@core/components/AppDialog.vue'

@Component({
  components: {
    AppDialog
  }
})
export default class AppDynamicHeader extends Vue {
  @Prop({ required: true }) private readonly header!: HeaderItem[]

  private jitsiUrl = ''
  private isJitsiUrlShow = false
  private isCopied = false
  private isShowMenu = false

  startVirtualMeeting () {
    this.jitsiUrl = 'https://meet.jit.si/' + this.randomStr(32)
    this.isJitsiUrlShow = true
  }

  randomStr (len: number) {
    const arr = '1234567890abcdefghijklmnopqrstuvwxyz'
    let ans = ''
    for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)]
    }
    return ans
  }

  copyUrl () {
    const url: HTMLInputElement = document.getElementById('url') as HTMLInputElement
    url.select()
    url.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.isCopied = true
  }

  get enableVirtualMeeting () {
    const { virtualMeeting } = container.get<IProjectSettings>(cid.IProjectSettings)
    return virtualMeeting ? virtualMeeting.enable : false
  }

  get videocamIcon () {
    return VideocamIcon
  }

  get menuIcon () {
    return MenuIcon
  }

  // Methods
  goToHome () {
    window.location.href = '/web/home'
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';

a {
  svg {
    width: 20px;
    height: 20px;
  }

  &:hover svg {
  }
}

header {
  // border-bottom: 1px solid lightgrey;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-wrapper {
    padding-left: 2rem;
    height: $header-height;
    transition: all 0.5s ease;
    .logo {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center left;
      height: 100%;
      width: 170px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .menu-wrapper {
    display: none;
  }

  nav {
    height: 100%;
    padding-right: 2rem;

    ul#nav-list {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      height: 100%;

      & > li {
        margin: 0;
        padding: 0;
        height: 100%;

        a {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          color: #7b7b7b;
          text-align: center;
          padding: 10px;
          text-decoration: none;
          transition: 0.2s;
          cursor: pointer;
          height: 100%;

          p {
            margin: 0;
            line-height: 1;
            word-wrap: break-word;
          }

          &:hover {
            // background-color: $color-other-form;
            color: #fff;
            text-decoration: none;
          }
        }

        .dropdown {
          display: none;
          position: fixed;
          background-color: #fff;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 100;
          border-top: 1px solid lightgrey;
        }
      }

      li:hover {
        .dropdown {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  svg {
    width: 20px;
    height: 20px;

    :deep(*) {
      // fill: #7b7b7b;
    }
  }

  header {
    .menu-wrapper {
      display: block;
      padding-right: 2rem;
    }

    nav {
      display: none;
    }
  }

  .menu-dialog {

    .custom-menu {
      margin-left: 0;

      .el-submenu, .el-menu-item {
        margin-bottom: 0;

        a {
          display: flex;
          align-items: center;
          height: 56px;
          color: #7b7b7b;
          text-decoration: none;
        }

        span {
          margin-left: 0.75rem;
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
