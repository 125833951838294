import 'reflect-metadata'

const summaryFieldKey = Symbol('SummaryField')

export function isSummaryField (target: any, propertyKey: string): { abbre: string } {
  return Reflect.getMetadata(summaryFieldKey, target, propertyKey)
}

/**
 * To be used as an annotation in property for ExistingInvestment and ExistingPortfolio
 * so as to use an identifer to know which of the fields are supposed to be considered
 * for calculation in the summary.
 *
 */
export function SummaryField (abbre = '') {
  return Reflect.metadata(summaryFieldKey, {
    abbre
  })
}
