import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { RouteRecordRaw } from 'vue-router'
import { bscReviewerRestriction, RouteStructure } from './structure'
export function userProfileRoutes (): RouteRecordRaw {
  const route: RouteRecordRaw = RouteStructure as RouteRecordRaw
  return route
}

export function getChildSections (systemRole: string, router) {
  const useNewCommissionModule = container.get<IProjectSettings>(cid.IProjectSettings).useNewCommissionModule
  const childrenRoute = router.options.routes.find(route => route.name === 'User Profile Main')?.children

  if (useNewCommissionModule) {
    const indexOfCommissionStatements = childrenRoute.findIndex(e => e.name === 'User Commission Statements')
    childrenRoute[indexOfCommissionStatements] = {
      path: 'commission-statements',
      name: 'User Commission Statements',
      shouldDisabled: bscReviewerRestriction,
      component: () => import('@core/pages/CommissionModule/new-commission-module-components/UserCommissionStatements.vue')
    }

    const indexOfCommissionStructure = childrenRoute.findIndex(e => e.name === 'User Commission Structure')
    childrenRoute[indexOfCommissionStructure] = {
      path: 'commission-structure',
      name: 'User Commission Structure',
      shouldDisabled: bscReviewerRestriction,
      component: () => import('@core/pages/CommissionModule/UserCommissionStructure.vue')
    }
  }

  childrenRoute.map(child => {
    // if there is disabledUI function then implement it here, because we cannot pass system role when we init the route
    if (child.shouldDisabled && typeof child.shouldDisabled === 'function') {
      const functionName = child.shouldDisabled as Function
      child.shouldDisabled = functionName(systemRole)
    }
    return child
  })

  return childrenRoute
}
