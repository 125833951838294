import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CommissionReportsService, CalculateCommissionsDTO, CommissionRecordsService, CommissionRecordsImportService  } from  '../../CommissionModule/api'


@Module({
  namespaced: true,
  name: "CommissionsStore",
})
export default class CommissionsStore extends VuexModule {
  calculationCommission = [];
  calculationCommissionCount = 0;
  exceptionCommission = [];
  exceptionCommissionCount = 0;
  calculatedCommission = [];
  calculatedCommissionCount = 0;
  exportCsvData = null;
  successResponse = '';

  @Action ({rawError: true})
  async retrieveCalculatingCommission(params) {
    const {calculationStatus, page, limit, searchField, searchFieldValue, sortBy, orderBy} = params
    try {
      const result = await CommissionReportsService.calculatedCommissions(calculationStatus, searchField, searchFieldValue, limit, page, sortBy, orderBy
      );
      this.context.commit("setCalculationCommission",  result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setCalculationCommission(data) {
    this.calculationCommission = data.data;
    this.calculationCommissionCount = data.recordsTotal;
  }

  @Action
  async retrieveExceptionCommission(params) {
    const {calculationStatus, page, limit, searchField, searchFieldValue, sortBy, orderBy} = params

    try {
      const result = await CommissionReportsService.calculatedCommissions(
        calculationStatus, searchField, searchFieldValue, limit, page, sortBy, orderBy
      );
      this.context.commit("setExceptionCommission", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setExceptionCommission(data) {
    this.exceptionCommission = data.data;
    this.exceptionCommissionCount = data.recordsTotal;
  }

  @Action
  async retrieveCalculatedCommissions(params) {
    const {calculationStatus, page, limit, searchField, searchFieldValue, sortBy, orderBy} = params
    try {
    const result = await CommissionReportsService.calculatedCommissions(calculationStatus, searchField, searchFieldValue, limit, page, sortBy, orderBy);
      this.context.commit("setCalculatedCommissions", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setCalculatedCommissions(data) {
    this.calculatedCommission = data.data;
    this.calculatedCommissionCount = data.recordsTotal;
  }

  @Action
  async commissionsCsvExport( {module, type, ...otherParams }) {
      try {
         const result = await CommissionRecordsService.exportCsv(module, type, ...Object.values(otherParams));
         this.context.commit('setCommissionsCsv', result);
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  setCommissionsCsv(response: any) {
      this.exportCsvData = response;
  }

  @Action
  async updateCalculateCommissions(payload : CalculateCommissionsDTO) {
      try {
      const response = await CommissionRecordsImportService.calculateCommissions(payload)
      this.context.commit('updateCalculate', response)
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  updateCalculate (response: string) {
    this.successResponse = response;
  }

  @Action
  async updateCommissionRecord(items) {
      try {
      const response = await CommissionRecordsService.updateTransaction(items.commissionOId,items.payload)
      this.context.commit('updateCommissions', response)
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  updateCommissions (response: string) {
    this.successResponse =response;
  }

}
