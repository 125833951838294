/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompensationSetupImportDTO } from '../models/CompensationSetupImportDTO';
import { request as __request } from '@openapi/request';

export class CompensationSetupImportService {

    /**
     * @param requestBody 
     * @returns CompensationSetupImportDTO 
     * @throws ApiError
     */
    public static async importCompensationSetupImport(
requestBody: Array<CompensationSetupImportDTO>,
): Promise<Array<CompensationSetupImportDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/compensation-setup-import/import-csv`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async validateImportComensationRecords(
requestBody: Array<CompensationSetupImportDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/compensation-setup-import/validate-csv`,
            body: requestBody,
        });
        return result.body;
    }

}