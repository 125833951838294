import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CalculateCommissionsDTO,CommissionRecordsImportService, CommissionRecordUpdateDTO, CommissionRecordsService  } from  '../../CommissionModule/api'
import { debug } from "console";




@Module({
  namespaced: true,
  name: "TransactionsStore",
})
export default class TransactionsStore extends VuexModule {
  clearedTransactions = [];
  exceptionTransactions = [];
  clearedTransactionsCount = 0;
  exceptionTransactionsCount = 0;
  exportCsvData = null;
  successResponse = '';
  loadingStatus = false;

  @Action
  async apiTimeoutHandler(){
    if(this.loadingStatus !== false){
        setTimeout(()=> {
          if(this.loadingStatus === true){
            this.context.commit('setLoading', false)
          } else{
            console.log('api responded')
          }
          return;
        },
      10000)
    }
  }

  @Action
  async retrieveClearedTransactions(params) {
    const {page, limit, searchField, searchFieldValue, isValidated} = params
    try {
      const result = await CommissionRecordsService.getTansactionList(
        searchField,
        searchFieldValue,
        limit,
        page,
        isValidated
      );
      this.context.commit("setClearedTransactions",  result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setClearedTransactions(result) {
    this.clearedTransactions = result.data;
    this.clearedTransactionsCount = result.recordsTotal;
  }

  @Action({ rawError: true })
  async retrieveExceptionTransactions(params) {
    const {page, limit, searchField, searchFieldValue, isValidated} = params
    try {
      this.context.commit('setLoading', true)
      const result = await CommissionRecordsService.getTansactionList(
        searchField,
        searchFieldValue,
        limit,
        page,
        isValidated
      );
      this.context.commit("setExceptionTransactions", result);
      this.apiTimeoutHandler()
    } catch (error) {
      alert(error.message)
    }finally{
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setExceptionTransactions(result: any) {
    this.exceptionTransactions = result.data;
    this.exceptionTransactionsCount = result.recordsTotal;
  }

  @Action
  async transactionCsvExport({ module, type, ...otherParams }) {
    try {
      this.context.commit('setLoading', true)
      const result = await CommissionRecordsService.exportCsv(module, type, ...Object.values(otherParams));
      this.context.commit("setTransactionCsv", result);
    } catch (error) {
      alert(error.message);
    }
    finally{
      this.context.commit('setLoading', false)  
    }
  }
  @Mutation
  setTransactionCsv(response: any) {
    this.exportCsvData = response;
  }

  @Action
  async updateCalculateCommissions(payload : CalculateCommissionsDTO) {
      try {
        this.context.commit('setLoading', true)  
        const response = await CommissionRecordsImportService.calculateCommissions(payload)
        this.context.commit('updateCommissions', response)
      } catch (error) {
          alert(error.message)
      }finally{
        this.context.commit('setLoading', false)  
      }
  }

  @Mutation
  updateCommissions (response: string) {
    this.successResponse = response;
  }

  @Action
  async validateCommission(payload: Array<string>) {
      try {
      const response = await CommissionRecordsImportService.validateNewCommissionData(payload)
      this.context.commit('setValidateCommission', response)
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  setValidateCommission (response: string) {
    this.successResponse = response;
  }

  @Action
  async updateTransactionRecord(items) {
      try {
      const response = await CommissionRecordsService.updateTransaction(items.transitionOId,items.payload)
      this.context.commit('updateTransaction', response)
      } catch (error) {
        this.context.commit('updateTransaction', error.message)
          alert(error.message)
      }
  }

  @Mutation
  updateTransaction (response: string) {
    this.successResponse =response;
  }

  @Mutation
  setLoading(status: boolean){
    this.loadingStatus = status
  }
}
