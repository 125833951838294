import { Module, VuexModule } from 'vuex-module-decorators'
import clientSummary from '@core/assets/svg/heart-empty.svg'
import importantNotice from '@core/assets/svg/imp-notice.svg'
import finances from '@core/assets/svg/finances.svg'
import kyc from '@core/assets/svg/kyc-no-fill.svg'
import currentPlans from '@core/assets/svg/current-plans.svg'
import recommendatiom from '@core/assets/svg/recommendation.svg'
import investment from '@core/assets/svg/investment.svg'
import profile from '@core/assets/svg/client.svg'
import priorities from '@core/assets/svg/priorities.svg'
import clientDeclaration from '@core/assets/svg/client-declaration.svg'
import switching from '@core/assets/svg/switching.svg'
import disclaimer from '@core/assets/svg/disclaimers.svg'
import notification from '@core/assets/svg/financial-adviser.svg'
import incompleteIcon from '@core/assets/svg/incomplete.svg'
import completedIcon from '@core/assets/svg/completed.svg'
import beneficialOwnerIcon from '@core/assets/svg/appendix-upload.svg'
import politicalIcon from '@core/assets/svg/political-icon.svg'
import appendixIcon from '@core/assets/svg/beneficial-owner.svg'
import AMLCheckIcon from '@core/assets/svg/AML-check.svg'

@Module({
  namespaced: true,
  name: 'KYCSectionStore'
})
export default class KYCSectionStore extends VuexModule {
  sections: { name: string; url?: string; completed?: boolean; allowed?: boolean; icon?: string; completedIcon?: string; incompleteIcon?: string; subs?: any[] }[] = [
    {
      name: 'Client Summary',
      url: 'client-summary',
      completed: false,
      allowed: true,
      icon: clientSummary,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    }, // url empty as default
    {
      name: 'Important Notices',
      url: 'important-notices',
      completed: false,
      allowed: true,
      icon: importantNotice,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'About You',
      url: 'about-you',
      completed: false,
      icon: profile,
      subs: [
        { name: 'Personal Details', url: 'personal-details', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Dependants', url: 'dependants', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Trusted Individual', url: 'trusted-individual', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Your Current Plans',
      url: 'current-portfolio',
      completed: false,
      icon: currentPlans,
      subs: [
        { name: 'Insurance Portfolio', url: 'insurance', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Investment Portfolio', url: 'investment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Your Finances',
      url: 'your-finances',
      completed: false,
      icon: finances,
      subs: [
        { name: 'Net Worth', url: 'net-worth', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Cash Flow', url: 'cash-flow', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Budget', url: 'budget', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Your Investment Risk Profile',
      url: 'investment-risk-profile',
      completed: false,
      icon: investment,
      subs: [
        { name: 'Customer Knowledge Assessment', url: 'customer-knowledge-assessment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Risk Profile Assessment', url: 'risk-profile-assessment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Your Priorities & Needs Analysis',
      url: 'needs-analysis',
      completed: false,
      icon: priorities,
      subs: [
        { name: 'Adjustments & Assumptions', url: 'adjustments-and-assumptions', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Identifying Your Priorities', url: 'identifying-your-priorities', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Needs Analysis Calculation', url: 'needs-analysis-calculation', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Our Recommendation',
      url: 'our-recommendation',
      completed: false,
      icon: recommendatiom,
      subs: [
        { name: 'Product Recommendations', url: 'product-recommendations', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'For Investments - ILP/Sub funds', url: 'ilp-sub-funds', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Basis of Recommendation', url: 'basis-of-recommendation', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Client\'s Decision', url: 'client-decision', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: false,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    { name: 'Replacement / Switching', url: 'replacement-or-switching', completed: false, allowed: false, icon: switching, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Disclaimers & Client\'s Checklist', url: 'disclaimers-and-client-checklist', completed: false, allowed: false, icon: disclaimer, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Client\'s Declaration', url: 'client-declaration', completed: false, allowed: false, icon: clientDeclaration, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Financial Adviser\'s Declaration', url: 'financial-consultant-declaration', completed: false, allowed: false, icon: notification, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'KYC Confirmation', url: 'kyc-confirmation', completed: false, allowed: false, icon: kyc, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'space' },
    { name: 'Beneficial Owner Particulars Form', url: 'bop', completed: false, allowed: false, icon: beneficialOwnerIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Politically Exposed Person Form', url: 'pep', completed: false, allowed: false, icon: politicalIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Client\'s AML Check', url: 'aml', completed: false, allowed: false, icon: AMLCheckIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Appendix - Upload PDFs', url: 'appendix', completed: true, allowed: true, icon: appendixIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
  ]

  get sectionsList () {
    return JSON.parse(JSON.stringify(this.sections));
  }
}
