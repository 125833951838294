import { Ignore } from '@master/annotation/Ignore'
import { TypeBase } from '../TypeBase'
import SwitchingPolicy from './SwitchingPolicy'

export default class SwitchingWithPolicies extends TypeBase {
  surrenderedInvestment = ''
  topUp = ''
  advisedBefore = ''
  policies: SwitchingPolicy[] = []

  @Ignore()
  policiesLimit = 3

  constructor (type: 'client_0' | 'client_1' = 'client_0') { // previously was ma
    super(`${type}_switching`)
  }

  extractData (rawData: any) {
    const data = super.extractData(rawData)
    const rawPolicies = rawData[this.type + '_policies']
    if (!rawPolicies || rawPolicies.length === 0) {
      data.policies = [
        new SwitchingPolicy(),
        new SwitchingPolicy()
      ]
      return data
    }
    const switchingPolicies = rawPolicies.map((rawpolicy) => {
      const sp = new SwitchingPolicy()
      Object.assign(sp, rawpolicy)
      return sp
    })
    Object.assign(data, { policies: switchingPolicies || [] })
    return data
  }

  addPolicy (newPolicy: SwitchingPolicy) {
    const fresh = new SwitchingPolicy()
    fresh.clone(newPolicy)
    this.policies.push(fresh)
  }

  removePolicy (idx: number) {
    this.policies = this.policies.filter((policy, index) => { return index !== idx })
  }

  updatePolicy (idx: number, updated: SwitchingPolicy) {
    const fresh = new SwitchingPolicy()
    fresh.clone(updated)
    this.policies.splice(idx, 1, fresh)
  }

  reset () {
    this.advisedBefore = ''
  }
}
