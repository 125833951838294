import CoreKYCPdf from '@master/model/kyc-pdf/KYCPdf'

import { keys, get, snakeCase } from 'lodash/fp'
import { Base } from '@master/model/Base'
import { ArrayBase } from '@master/model/ArrayBase'
import ESignDoc from '@master/model/kyc-pdf/ESignDoc'
import ESignSigner from '@master/model/kyc-pdf/ESignSigner'
import SignPadSigners from '@master/model/kyc-pdf/data/SignPadSigners'
import OfficerRemarks from '@master/model/kyc-pdf/OfficerRemarks'
import OfficerApproval from '@master/model/kyc-pdf/OfficerApproval'
import { Ignore, isIgnore } from '@master/annotation/Ignore'
import { setupPointer } from '@master/annotation/Pointer'
import { IDateService } from '@master/services/IDateService'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { PTCBase } from '@master/model/ptc/PTCBase'

import KYCForm from '@company-common/model/kyc-form/KYCForm'
import KYCClient from '@master/model/kyc-form/KYCClient'
import CustomManagerEndorsement from './CustomManagerEndorsement'
import CCBSCustom from './CCBSCustom'

export default class KYCPdf extends KYCForm implements CoreKYCPdf {
  oid = ''
  ptcFields = {} as PTCBase<KYCForm, KYCClient>
  completion = {}
  pdfStatus = ''
  consolidatedStatus = ''
  adviserFinalised = false
  adviserOid = ''
  supervisorOid = ''
  supervisorProxyOid = ''
  supervisorDecidedOid = ''
  kycId = ''
  adviserSignatureDate = ''

  esign = {
    doc: new ESignDoc(),
    signers: Array(9).fill(null).map(() => new ESignSigner())
  }

  managerEndorsement = new CustomManagerEndorsement()
  ccbs: CCBSCustom
  signpad = new SignPadSigners()

  @Ignore()
  currentManager: CustomManagerEndorsement // this is used to check is value on manager UI changed

  // Timestamps
  dateAdviserFinalised = undefined
  dateSupervisorDecide = undefined

  constructor (dateService: IDateService, projectSettings: IProjectSettings, riskRatingService: IRiskRatingService, ptcFields: PTCBase<KYCForm, KYCClient>) {
    super(dateService, projectSettings, riskRatingService)
    this.ccbs = new CCBSCustom(dateService)
    this.ptcFields = ptcFields
  }

  @Ignore()
  curUser: { oid: string ; systemrole: string }

  get isCurrentAdviser () {
    return this.adviserOid === (this.curUser?.oid || false)
  }

  get isCurrentSupervisor () {
    return this.supervisorOid === (this.curUser?.oid || false) || this.supervisorProxyOid === (this.curUser?.oid || false)
  }

  get isOpsStaff () {
    return ['ops-staff', 'it-super-admin'].includes(this.curUser?.systemrole)
  }

  get isAdviserConfirmed () {
    return this.pdfStatus === 'adviser-confirmed'
  }

  get isAdviserSigned () {
    return this.pdfStatus === 'adviser-signed'
  }

  get isAdviserCancelled () {
    return this.pdfStatus === 'adviser-cancelled'
  }

  get isSupervisorApproved () {
    return this.pdfStatus === 'supervisor-approved'
  }

  get supervisorSigned () {
    return this.pdfStatus === 'supervisor-approved' || this.pdfStatus === 'supervisor-rejected'
  }

  get submissionAccepted () {
    return this.pdfStatus === 'submission-accepted'
  }

  get submissionCompleted () {
    return this.pdfStatus === 'submission-accepted' || this.pdfStatus === 'submission-rejected'
  }

  get disableAdviserRemark () {
    return !this.isCurrentAdviser || this.adviserFinalised || (this.pdfStatus !== 'adviser-confirmed' && this.pdfStatus !== 'adviser-signed')
  }

  get disableSupervisorRemark () {
    return !this.adviserFinalised || this.pdfStatus !== 'adviser-signed' || !this.isCurrentSupervisor
  }

  get disableOpsRemark () {
    return this.pdfStatus !== 'supervisor-approved' || !this.isOpsStaff
  }

  get canSeeManagerEndorse () {
    const notSignednotConfirmed = this.pdfStatus !== 'adviser-signed' && this.pdfStatus !== 'adviser-confirmed'
    return (this.isCurrentAdviser && notSignednotConfirmed) ||
      (this.isCurrentSupervisor && this.adviserFinalised && this.pdfStatus !== 'adviser-confirmed') ||
      (this.isOpsStaff && notSignednotConfirmed)
  }

  get canManagerEndorse () {
    return this.isCurrentSupervisor && this.pdfStatus === 'adviser-signed' && this.adviserFinalised
  }

  get canOpsApprove () {
    return !['submission-accepted', 'submission-rejected'].includes(this.pdfStatus) && this.isOpsStaff
  }

  get canReassignSupervisor () {
    return this.isOpsStaff && (this.pdfStatus === 'adviser-confirmed' || this.pdfStatus === 'adviser-signed')
  }

  get hasECDD1 () {
    return this.ptcFields.getById('high_risk')?.client1 === true
  }

  get hasECDD2 () {
    return this.ptcFields.getById('high_risk')?.client2 === true
  }

  remarks = new OfficerRemarks()
  approval = new OfficerApproval()

  setData (rawData: any, obj: Record<string, any> = this, path = '') {
    keys(obj).filter(key => !isIgnore(this, key)).forEach(key => {
      const newObject = get(key, obj)
      if (newObject instanceof Base || newObject instanceof PTCBase) {
        if (newObject instanceof Base) setupPointer(newObject.getPointers(), this, obj, newObject)
        Object.assign(newObject, newObject.extractData(rawData))
      } else if (newObject instanceof Array) {
        newObject.forEach((object: ArrayBase, index) => {
          if (object instanceof Base) setupPointer(object.getPointers(), this, obj, object)
          Object.assign(object, object.extractData(rawData, index))
        })
      } else if (['pdfStatus', 'supervisorDecidedOid', 'dateAdviserFinalised', 'dateSupervisorDecide', 'adviserSignatureDate', 'adviserFinalised', 'adviserOid', 'supervisorOid', 'supervisorProxyOid', 'kycId'].includes(key)) {
        let target = rawData[snakeCase(key)]
        // if mismatch of type, resolve (specifically for adviserFinalised old data)
        if (typeof obj[key] === 'boolean' && typeof target === 'string') {
          target = target === 'true'
        }
        obj[key] = target
      } else if (['consolidatedStatus', 'completion'].includes(key)) {
        obj[key] = rawData[key]
      } else if (newObject instanceof Object) {
        this.setData(rawData, newObject, `${path}.${key}`)
      } else {
        obj[key] = rawData[key]
      }
    })
  }
}
