import { SharedVariable } from '@core/model/SharedVariable'
import { UserMemberListResponseDTO, UserMemberService, UserMemberStructureProxyBranchAccount } from '@core/openapi/services/user-member/api'
import { UIService } from '@core/services/UIService'
import { container, cid } from 'inversify-props'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

@Module({ namespaced: true, name: 'AdviserTeamManagementStore' })
export default class AdviserTeamManagementStore extends VuexModule {
  members: UserMemberStructureProxyBranchAccount[] = []
  numOfMemberRecords = 0

  @Action({ rawError: true })
  async retrieveAccountMemberListing (accountOid: string) {
    const memberListing = await UserMemberService.listAllUserAccountBasedOnHierarchy({
      accountOid
    })
    this.context.commit('setMemberListing', memberListing)
  }

  @Mutation
  setMemberListing (memberListing: UserMemberListResponseDTO) {
    this.members = memberListing.members
    this.numOfMemberRecords = memberListing.total
  }

  @Action({ rawError: true })
  async findMemberInStructure (accountOid: string) {
    try {
      const response = await UserMemberService.findMemberInStructure(accountOid)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async findMember (accountOid: string) {
    try {
      const response = await UserMemberService.findMember(accountOid)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }
}
