export const VenueOptions = [
  { value: undefined, label: '' },
  { value: 2000, label: 'Branch / Office Premise' },
  { value: 3001, label: 'Client\'s own / relative home (including Void Deck, Lift Lobby, Condo Club House, etc)'},
  { value: 3000, label: 'Client\'s office / shop / workplace (including Office Lobby, etc)'},
  { value: 4001, label: 'Adviser\'s Home'},
  { value: 4000, label: 'Retailer / Shopping area (Shopping mall, Cafe, Restaurant, Fast Food, Foodcourt, Coffeeshop, etc)'},
  { value: 5000, label: 'Public / Outdoor Areas (Hospitals, Mrt station, Parks, etc)'},
  { value: 5001, label: 'Event Site of Roadshow / Retailer Tie-up / Seminar'},
  { value: 9999, label: 'Non Face-to-Face - Telephone'},
  { value: 9999, label: 'Non Face-to-Face - Virtual Meeting (Webex, Zoom, Teams, Video call, etc)'}
]
export const venueLabel = VenueOptions.map(item => item.label)
export const venueValues = VenueOptions.map(item => item.value)
export const getVennueByLabelOrValue = (arg: any) => VenueOptions.find(item => item?.label?.toLowerCase() === arg.toLowerCase() || item?.value === arg)