import { ElNotification, ElMessageBox } from 'element-plus'
import { startCase } from 'lodash/fp'

class Loading {
  // force to use array instate of set due to reactive set was only support in vue 3
  private loadingTasks: string[] = []
  private readonly randomID: () => string

  constructor (randomID: () => string) {
    this.randomID = randomID
  }

  addLoadingTask (id: string) {
    this.loadingTasks.push(id)
  }

  removeLoadingTask (id: string) {
    this.loadingTasks = this.loadingTasks.filter(value => value !== id)
  }

  async loadingWrapper<T = any> (functionToExecute: Promise<T>) {
    const id = this.randomID()
    this.addLoadingTask(id)

    return new Promise((resolve, reject) => {
      functionToExecute.then(result => resolve(result))
        .catch(error => reject(error))
        .finally(() => {
          this.removeLoadingTask(id)
        })
    })
  }

  hasLoadingTasks () {
    return this.loadingTasks.length > 0
  }
}

/**
 * Override from ElNotificationOptions interface since the title is optional
 */
interface Options {
  type: 'success' | 'warning' | 'info' | 'error';
  title?: string;
  message: string;
  duration?: number;
  customClass?: string;
  dangerouslyUseHTMLString?: boolean;
}

/**
 * Element UI ElNotification Component
 * https://element.eleme.io/#/en-US/component/notification
 */
class Alert {
  /**
   * should be using async await to show the alert, e.g
   * await this.uiService.alert.show({ type: 'success', message: 'this is success' })
   * @param options
   * @returns ElNotification
   */

  getClass (type: string) {
    if (type === 'success') {
      return 'notification-overlay success-override'
    } else if (type === 'warning') {
      return 'notification-overlay warning-override'
    } else if (type === 'info') {
      return 'notification-overlay info-override'
    } else if (type === 'error') {
      return 'notification-overlay error-override'
    } else {
      return 'notification-overlay'
    }
  }

  show (options: Options) {
    return ElNotification({
      type: options.type,
      title: options.title ?? startCase(options.type),
      message: options.message,
      duration: options.duration ?? 4500, // default duration is 4500ms,
      customClass: options.customClass ?? this.getClass(options.type),
      position: 'bottom-right',
      dangerouslyUseHTMLString: options.dangerouslyUseHTMLString,
    })
  }
}

/**
 * Override from ElNotificationOptions interface since the title is optional
 */
 interface ConfirmOptions {
  type: 'success' | 'warning' | 'error';
  title?: string;
  message: string;
  confirmButtonText?: string;
  showCancelButton?: boolean;
  customClass?: string;
}

/**
 * Element UI Message Box Component
 * Need to have 'then' and 'catch', so the 'close' or 'cancel' button did not go throught the Vue errorHandler
 * https://element.eleme.io/#/en-US/component/message-box
 */
class Confirm {
  show (options: ConfirmOptions) {
    return new Promise((resolve) => {
      ElMessageBox({
        type: options.type,
        title: options.title ?? startCase(options.type),
        message: options.message,
        confirmButtonText: options.confirmButtonText ?? 'OK',
        showCancelButton: options.showCancelButton ?? true, // default always show cancel button
        customClass: options.customClass ?? 'confirm'
      }).then(() => {
        resolve(true)
      }).catch(() => {
        resolve(false)
      })
    })
  }
}

export class UIService {
  loading = new Loading(this.randomID)
  alert = new Alert()
  confirm = new Confirm()
  demoMode = '$DEMO_MODE'

  private randomChar () {
    return String.fromCharCode(Math.ceil(Math.random() * 26) + 64)
  }

  private randomPrefix (length: number) {
    return new Array(length).fill(undefined).map(this.randomChar).join('')
  }

  randomID () {
    return this.randomPrefix(3) + Date.now()
  }
}
