import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/svg/cancel.svg'
import _imports_1 from '@core/assets/svg/delete_icon.svg'


const _hoisted_1 = {
  class: "modal-mask",
  ref: "dialogContent"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { src: _imports_0 }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_7 = {
  key: 1,
  class: "modal-footer"
}
const _hoisted_8 = {
  key: 2,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["modal-container", _ctx.sizeClass]),
            role: "dialog",
            style: _normalizeStyle({width: _ctx.customWidth })
          }, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true),
              _createElementVNode("button", {
                class: "btn-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
                  [_directive_svg_inline]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "body", {}, undefined, true),
              (!_ctx.hideFooter && !_ctx.isNewTheme)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.showCancel)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                          class: "small-cta-cancel"
                        }, " Cancel "))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isNewTheme)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_ctx.showCancel)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
                          class: "small-cta-cancel-new"
                        }, " Cancel "))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.needDelete)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", {
                      class: "delete",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('delete')))
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        width: "20",
                        class: "color-del"
                      }, null, -1),
                      _createTextVNode("Delete ")
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 6)
        ])
      ], 512)
    ]),
    _: 3
  }))
}