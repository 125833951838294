import { Ignore } from '@master/annotation/Ignore'
import { MetaList } from '@master/model/common/MetaList'
import { UserMeta } from './UserMeta'

export class UserMetaList extends MetaList<UserMeta> {
  declare list: UserMeta[]

  constructor (data: (string | number)[][], header: string[] = null) {
    const defaultHeader = [
      '_oid',
      'names',
      'fullname',
      'systemrole',
      'email',
      'mobile',
      'company_code',
      'mas_repno',
      'adviser_branch',
      'status',
      'adviser_products',
      'other_adviser_products',
      'id_doc_type',
      'id_number',
      'with_exp',
      'groups',
      'old_password',
      'new_password',
      'confirm_password'
    ]
    super(!header ? defaultHeader : header, data, UserMeta)
  }

  getListFilterByRole (roles: string[]) {
    return this.list.filter(usermeta => roles.includes(usermeta.systemrole))
  }

  @Ignore()
  get options () {
    return this.list.map((user) => {
      return {
        label: user.fullname || user.names[0],
        value: user.oid
      }
    })
  }
}
