<template>
  <header :class="{ mini: mini }">
    <div class="logo-wrapper">
      <div class="logo" @click="goToHome"></div>
    </div>
    <!-- default -->
    <!-- slot with specified options through props then default options shpuld come under hamburger -->
    <nav role="navigation" class="viewPort-main" >
     <ul id="nav-list">
        <template v-for="({icon, label}, index) in header()" :key="index">
          <li class="icon-space" :class="label">
            <a>
                <img v-svg-inline :src="icon" alt="svg image" />
                <span>{{ label }}</span>
              </a>
          </li>
          <!-- <a @click="$emit(event)" :target="target">
                <img v-svg-inline :src="icon" alt="svg image" />
                <span>{{ label }}</span>
              </a> -->
        </template>
        <div class="vertical-line"></div>
        <li class="hamburger">
          <AppHamburgerHeader class="changeHeaderHamburger" @logout="logout" :header="hambugerHeader" :withLogo = false></AppHamburgerHeader>
        </li>
      </ul>
    </nav>

    <nav role="navigation" class="viewPort-tab">
     <ul id="nav-list">
          <li class="icon-space">
            <template>
              <el-dropdown>
                <a>
                  <span>{{ 'Actions' }}</span>
                  <img v-svg-inline :src="arrowDown" alt="svg image" />
                </a>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  v-for="(item, i) in header()" :key="i">
                    <a>
                      <img v-svg-inline :src="item.icon" alt="svg image" />
                      <span>{{ item.label }}</span>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              </template>
          </li>
          <!-- <a @click="$emit(event)" :target="target">
                <img v-svg-inline :src="icon" alt="svg image" />
                <span>{{ label }}</span>
              </a> -->
        <li class="hamburger">
          <AppHamburgerHeader @logout="logout" :header="hambugerHeader" :withLogo = false></AppHamburgerHeader>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import AppHamburgerHeader from './AppHamburgerHeader.vue'
import { HeaderItem } from '@core/model/structure/HeaderStructure'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import VideocamIcon from '@core/components/header/assets/videocam.svg'
import MochaLogo from '@core/assets/mocha-logo.svg'
import ArrowDownIcon from '@core/assets/svg/arrow-down.svg'
import AppDialog from '@core/components/AppDialog.vue'

@Component({
  components: {
    AppDialog,
    AppHamburgerHeader
  }
})
export default class AppDynamicHeader extends Vue {
  private isMenuOpen = true

  @Prop({ required: true }) private readonly header!: HeaderItem[]

  @Prop({ required: true }) private readonly hambugerHeader!: HeaderItem[]

  @Prop()
  private mini

  private jitsiUrl = ''
  private isJitsiUrlShow = false
  private isCopied = false
  private hovering = -1
  private dropDownWidth = 200
  private arrowDown = ArrowDownIcon

  startVirtualMeeting () {
    this.jitsiUrl = 'https://meet.jit.si/' + this.randomStr(32)
    this.isJitsiUrlShow = true
  }

  randomStr (len: number) {
    const arr = '1234567890abcdefghijklmnopqrstuvwxyz'
    let ans = ''
    for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)]
    }
    return ans
  }

  copyUrl () {
    const url: HTMLInputElement = document.getElementById('url') as HTMLInputElement
    url.select()
    url.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.isCopied = true
  }

  // for flipping the dropdown list so that it stays within viewport
  isOutOfView (eleId) {
    const ele = document.getElementById(eleId)
    if (!ele) return false
    const pose = ele.getBoundingClientRect()
    if (document.documentElement.clientWidth - this.dropDownWidth < pose.left) {
      return true
    } else return false
  }

  get showSideLogo () {
    const { useSideLogo } = container.get<IProjectSettings>(cid.IProjectSettings)
    return useSideLogo
  }

  get enableVirtualMeeting () {
    const { virtualMeeting } = container.get<IProjectSettings>(cid.IProjectSettings)
    return virtualMeeting ? virtualMeeting.enable : false
  }

  get videocamIcon () {
    return VideocamIcon
  }

  get mochaIcon () {
    return MochaLogo
  }

  // Methods
  goToHome () {
    window.location.href = '/web/home'
  }

  toggleMenu () {
    this.isMenuOpen = !this.isMenuOpen
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';
$nav-icon-width: 4.4rem;
$nav-icon-height: 3rem;
$nav-dropdown-width: 200px;
$toggleSize: 40px;
$toggleMargin: 10px;
$toggleLine: 4px;
$toggleColor: red;

@media (min-width: 486px) and (max-width: 992px) {
  .viewPort-main {
    display: none;
    max-height: 0;
  }
  .viewPort-tab {
    display: block;
  }
}

@media (min-width: 992px) {
  .viewPort-main {
    display: block;
  }
  .viewPort-tab {
    display: none;
    max-height: 0;
  }
}
.nav-container {
  position: relative;
  display: inline-block;
  max-width: $toggleSize + $toggleMargin;
  max-height: $toggleSize + $toggleMargin;
  overflow: visible;
  outline: none;
  //&:focus-within, &:focus {
  &.is-active {
    // .nav-toggle {
    //   &:before, &:after {
    //     box-shadow: none;
    //   }

    //   &:before {
    //     transform: rotate(-45deg);
    //   }

    //   &:after {
    //     transform: rotate(45deg);
    //   }
    // }
    .nav-items {
      transform: translate(0, 0);
    }
  }
  .nav-toggle {
    $offset: $toggleSize * 0.5;
    position: relative;
    width: $toggleSize;
    height: $toggleSize;
    margin: $toggleMargin;
    z-index: 2;

    &:hover {
      cursor: pointer;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      top: calc($offset - $toggleLine / 2);
      left: 0;
      transform: translate(0, 0);
      width: 100%;
      height: $toggleLine;
      background: $toggleColor;
      transition: transform .3s ease, box-shadow .3s ease;
    }

    &:before {
      box-shadow: 0 calc($offset / 1.5) 0 0 $toggleColor;
    }

    &:after {
      box-shadow: 0 calc($offset * -1 / 1.5) 0 0 $toggleColor;
    }
  }

  .nav-items {
    position: absolute;
    top: -3rem;
    left: 0;
    min-width: 269px;
    max-width: 25vw;
    width: 87vw;
    height: 134vh;
    z-index: 1;
    padding: 38px 20px 20px 10px;
    transition: transform 0.3s ease;
    transform: translate(calc(-109% - -27px), 0);
    background: #EFEFEF;
    display: grid;
    grid-template-columns: -1fr;
    grid-gap: 5px 0;
    align-content: baseline;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);

    .nav-item {
      background: darken(#EFEFEF, 5%);
      padding: 10px;
      transition: background-color .3s ease;

      &:hover {
        cursor: pointer;
        background: darken(#EFEFEF, 10%);
      }
    }
  }
}

a {
  img,
  svg {
    width: var(--i-width, 20px);
    // :deep(*) {
    //   fill: #7b7b7b;
    // }
  }

  &:hover svg {
    // :deep(*) {
    //   fill: #FFF;
    // }
  }
}

header {
  // border-bottom: 1px solid lightgrey;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 60!important;
  transition: all 0.5s ease;

  &.mini {
    // height: calc( #{$header-height} - 20px );
    padding: 0rem 2rem;
    z-index: 60!important;
    .logo-wrapper {
      height: 3rem;
      padding: 0 0.25rem;
    }
    nav {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      // grid-template-rows: auto;
      ul#nav-list {
        & > li {
          a:not(.dropdown-item) {
            border-radius: 5px;
            width: $nav-icon-width;
            height: $nav-icon-height;
            padding: 0;
            // border: 1px solid $color-other-form;
            svg {
              margin: auto;
              outline: none;
            }
            &:active {
              transform: scale(0.8);
              opacity: 0.5;
            }
          }
          span {
            text-align: center;
            // line-height: 1.5;
            font-size: 1rem;
          }
          &.has-drop:hover {
            a:not(.dropdown-item) {
              // border-radius: 50% 50% 0 0;
            }
          }
        }
      }
    }
  }

  .vertical-line {
    margin: 10px;
    border-left: 3px solid #D8D8D8;
    width: 1vw;
    height: 5vh;
  }

  .icon-space {
    margin-right:1rem;
  }

  .Completion {
    margin-left: auto;
    margin-right: 10px;
  }

  :deep(.icon) {
    margin: 0;
  }

  .icon {
    margin-left: 0;
    margin-bottom: 0;
  }

  .li-space {
    padding: 0.25rem;
  }

  .viewPort-tab {
    .hamburger {
      margin-left:auto;
    }
  }

  .logo-wrapper {
    height: $header-height;
    padding: 0.25rem;
    transition: all 0.5s ease;
    .logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center left;
      height: 100%;
      width: 200px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  nav {
    width: 100%;

    ul#nav-list {
      margin-left: 5rem;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      & > li {
        position: relative;
        transition: all 0.3s ease;
        flex-direction: row;
        align-items: center;
        color: #7b7b7b;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        border: 1px solid #D8D8D8;
        border-radius: 50px;
        transition: all 0.5s ease;
        &.hamburger {
         border: none;
         padding: 0.5rem;
        }

        &:is(.focus, :hover) a:not(.dropdown-item) {
          text-decoration: none;
        }

        a {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          color: #7b7b7b;
          text-align: center;
          padding: 10px 10px;
          text-decoration: none;
          cursor: pointer;
          height: 100%;
          transition: all 0.5s ease;

          svg {
            margin-right:0.25rem;
          }

          p {
            margin: 0;
            text-align: center;
            line-height: 1;
            font-size: 0.8rem;
            word-wrap: break-word;
            transition: all 0.5s ease;
          }
          &:hover {
            color: $color-other-form;
            text-decoration: none;
          }
        }
        .dropdown {
          position: absolute;
          left: 0.5rem;
          top: 3.4rem;
          background-color: #fff;
          width: 200px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          border: 1px solid $color-other-form;
          border-radius: 0 1rem 1rem 1rem;
          z-index: 100;
          transform: scale(0);
          opacity: 0;
          transform-origin: top left;
          transition: all 0.3s ease;
          .dropdown-item {
            padding: 1rem 1.5rem;
            border-bottom: 1px inset $color-other-form;

            &:hover {
              background-color: $color-other-form;
              color: #fff;
            }
          }
          .dropdown-item:first-child {
            border-top-right-radius: 1rem;
          }
          .dropdown-item:last-child {
            border-bottom: none;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
          }
        }
        &.dd-flip .dropdown {
          left: calc( -#{$nav-dropdown-width} + 0.5rem + #{$nav-icon-width});
          border-radius: 1rem 0 1rem 1rem;
          transform-origin: right top;
        }
      }

      li:hover {
        .dropdown {
          transform: scale(1);
          opacity: 1;
        }
      }

    }
  }
}
</style>
