import 'reflect-metadata'

const maxChar = Symbol('MaxChar')

export function MaxChar (charLimit: number) {
  return Reflect.metadata(maxChar, charLimit)
}

export function getMaxChar (target: any, propertyKey: string) {
  return Reflect.getMetadata(maxChar, target, propertyKey)
}
