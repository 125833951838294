import { SimpleKYCFNA } from '@master/model/kyc-form/need-analysis/PrioritiesNeeds'
import highRiskCountryList from '@company-common/config/high-risk-country.json'
import { isEmpty, pickBy } from 'lodash/fp'
import { budgetLimitBreachedCheck, clientRiskProfileConservativeCheck, highRiskCheck, noFullDisclosure, noRankedHighNeedsCheck, productRiskRating } from '@master/logic/PretransactionsLogic'
import { PTC } from '@master/model/ptc/PTC'
import { PTCBase } from '@master/model/ptc/PTCBase'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import { getValidationSectionForClient } from '@core/logic/Validation'
import { ThePrioritiesNeeds } from '@company-common/model/kyc-form/needs-analysis/ThePrioritiesNeeds'
import { noRecommendationForHighPriorityNeedsCheck, recommendationForUnprioritisedNeeds } from '@master/logic/PTCLogicProductRecommendation'
import { cid, container } from 'inversify-props'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import KYCForm from '@company-common/model/kyc-form/KYCForm'
import KYCClient from '@company-common/model/kyc-form/KYCClient'

/**
 * Temporary solutions after updated the JPara @core, @master, @company-common to the latest branch
 * Will need to change the logic from frontend to @core or @master
 */

export default class PreTransactionalCheck extends PTCBase<KYCForm, KYCClient> {
  constructor (kycForm: KYCForm) {
    super(kycForm)

    const prioritiesNeeds = new ThePrioritiesNeeds()

    const riskRatingService = container.get<IRiskRatingService>(cid.IRiskRatingService)

    this.preTransactionalList = [
      {
        title: 'Know Your Client',
        sections: [
          new PTC({
            label: 'Client is a selected client',
            id: 'selected_client',
            validation: ({ client }) => client.personalDetails.isSelectedClient()
          }),
          new PTC({
            label: 'No financial needs ranked as \'high\'',
            id: 'no_high_priority',
            validation: ({ client }) => noRankedHighNeedsCheck(client)
          }),
          new PTC({
            label: 'Client chose not to disclose Net Worth, Cash Flow and Current Plans',
            id: 'client_not_disclose',
            validation: ({ client, kycForm }) => {
              const { netWorth, cashFlow } = client
              const declaration = kycForm.existingPortfolio.declaration
              return noFullDisclosure(netWorth.netWorthPlanning === 'yes', cashFlow.cashFlowPlanning === 'yes', declaration.review === 'yes')
            }
          }),
          new PTC({
            label: 'Budget affordability limit breached',
            id: 'budget_limit_breached',
            validation: ({ client, clientIndex, kycForm }) => {
              return budgetLimitBreachedCheck(client.budget.annualBudget(), client.budget.singleBudget(), client.budget.smallBudgetSize,
                kycForm.recommendations.totalAnnualizedPremiumForClient(clientIndex),
                kycForm.recommendations.totalSinglePremiumForClient(clientIndex))
            }
          }),
          new PTC({
            label: 'Client failed CKA',
            id: 'client_failed_cka',
            validation: ({ client }) => {
              return client.cka.ckaMet === 'no'
            }
          }),
          new PTC({
            label: 'Client investment risk profile is \'Conservative\'',
            id: 'client_risk_profile_conservative',
            validation: ({ client }) => {
              return clientRiskProfileConservativeCheck(client.rpa.riskProfileResult)
            }
          }),
          new PTC({
            label: 'Client did not provide an email address',
            id: 'client_not_email_address',
            validation: ({ client }) => {
              return isEmpty(client.personalDetails.contactEmail)
            }
          })
        ]
      }, {
        title: 'Needs Analysis',
        sections: [
          new PTC({
            label: 'Default Assumptions changed',
            id: 'default_assumption_changed',
            validation: ({ client, kycForm }) => {
              // education details is not instance of NeedsBase, so need to handle separately
              const eduNeedsDetails = kycForm.educationNeedsDetails.filter((edu) => {
                return edu.isValid() && !edu.isDefaultRate
              })
              if (eduNeedsDetails.length > 0) return true
              // then, check for all instances of NeedsBase
              const fnaNeeds = Object.keys(client).filter((key) => {
                return client[key] instanceof NeedsBase && client[key].isDefaultRate !== undefined
              })
              return fnaNeeds.some((key) => {
                // console.log(key, client.personalDetails.name, client[key].plan, client[key].isDefaultRate)
                return client[key].plan && !client[key].isDefaultRate
              })
            }
          })
        ]
      }, {
        title: 'Product Recommendation',
        sections: [
          new PTC({
            label: 'No recommendation for \'high priority\' needs',
            id: 'no_recommendation_for_high',
            validation: ({ clientIndex, client, kycForm }) => {
              const fna = pickBy(object => object instanceof NeedsBase, client) as SimpleKYCFNA
              return noRecommendationForHighPriorityNeedsCheck(clientIndex, fna, kycForm.recommendations.rcmd, prioritiesNeeds.getPriorities())
            }
          }),
          new PTC({
            label: 'Product recommendation for needs not prioritised by client',
            id: 'recommendation_for_unprioritised_needs',
            validation: ({ clientIndex, client, kycForm }) => {
              const fna = pickBy(object => object instanceof NeedsBase, client) as SimpleKYCFNA
              return recommendationForUnprioritisedNeeds(clientIndex, fna, kycForm.recommendations.rcmd, prioritiesNeeds.getPriorities())
            }
          }),
          new PTC({
            label: 'Client disagrees with Representative\'s recommendations and decides to buy product(s) not recommended',
            id: 'client_disagrees',
            validation: ({ clientIndex, kycForm }) => {
              return kycForm.recommendations.rcmd
                .filter(product => Number(product.clientIndex) === clientIndex)
                .some(product => product.clientChoice === 'ownchoice')
            }
          }),
          new PTC({
            label: 'Recommendation for product replacement / switching',
            id: 'recommendation_for_replacement_switching',
            validation: ({ client }) => {
              return client.switching.topUp === 'yes' || client.switching.advisedBefore === 'yes'
            }
          }),
          new PTC({
            label: 'Product Risk Rating is greater than the Customer\'s Investment Profile',
            id: 'product_risk_rating',
            validation: ({ clientIndex, client, kycForm }) => {
              return productRiskRating(clientIndex, client.rpa.riskProfileResult, kycForm.recommendations.rcmd, riskRatingService)
            }
          })
        ]
      }, {
        title: 'Client Declaration',
        sections: [
          new PTC({
            label: 'Client has incomplete fields in Client\'s Declaration',
            id: 'client_incomplete_fields',
            validation: ({ clientIndex, validation }) => {
              const validationSection = getValidationSectionForClient(validation, 'Client\'s Declaration', clientIndex)
              return !isEmpty(validationSection)
            }
          }),
          new PTC({
            label: 'Beneficial Owner Particulars Form and/or Politically Exposed Person Form section(s) are/is incomplete, if applicable',
            id: 'incomplete_bop_pep',
            validation: ({ clientIndex, validation }) => {
              const boValidation = getValidationSectionForClient(validation, 'Beneficial Owner Particulars Form', clientIndex)
              const pepValidation = getValidationSectionForClient(validation, 'Politically Exposed Person Form', clientIndex)
              return !isEmpty(boValidation) || !isEmpty(pepValidation)
            }
          }),
          new PTC({
            label: 'Client is assessed to be from a higher risk categories/countries or jurisdictions',
            id: 'high_risk',
            validation: ({ clientIndex, client, kycForm }) => {
              const { citizenship, citizenshipSpecified, citizenshipSpecified2 } = client.personalDetails
              return highRiskCheck(clientIndex, citizenship, citizenshipSpecified, kycForm.recommendations.rcmd, highRiskCountryList, citizenshipSpecified2)
            }
          })
        ]
      }
    ]
  }

  isClient2Include () {
    return this.kycForm.hasClient2
  }
}
