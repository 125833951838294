/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ClientPolicyReportRequestDTO = {
    type: ClientPolicyReportRequestDTO.type;
    startDate?: number;
    endDate?: number;
    issuers?: Array<any>;
    accountOid?: Array<any>;
    nbrApplicationStatus?: string;
}

export namespace ClientPolicyReportRequestDTO {

    export enum type {
        PRODUCTION_MTD_PERSONAL = 'production_mtd_personal',
        PRODUCTION_MTD_SUPERVISOR = 'production_mtd_supervisor',
        PRODUCTION_YTD_PERSONAL = 'production_ytd_personal',
        PRODUCTION_YTD_SUPERVISOR = 'production_ytd_supervisor',
        PRODUCTION_REPORT_CUSTOM = 'production_report_custom',
    }


}