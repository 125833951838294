/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountDeleteRequest } from '../models/AccountDeleteRequest';
import type { AccountDetailsDTO } from '../models/AccountDetailsDTO';
import type { AccountDto } from '../models/AccountDto';
import type { AccountFinancialSchemeDto } from '../models/AccountFinancialSchemeDto';
import type { AccountFinancialSchemeRequest } from '../models/AccountFinancialSchemeRequest';
import type { FullMochaUserInfo } from '../models/FullMochaUserInfo';
import type { MochaUserWithLegacyInfo } from '../models/MochaUserWithLegacyInfo';
import type { PicodedAccountDTO } from '../models/PicodedAccountDTO';
import type { UserAccountCreationDTO } from '../models/UserAccountCreationDTO';
import type { UserAccountExportDto } from '../models/UserAccountExportDto';
import type { UserAccountImportDto } from '../models/UserAccountImportDto';
import type { UserAccountImportResponse } from '../models/UserAccountImportResponse';
import type { UserAccountListResponseDTO } from '../models/UserAccountListResponseDTO';
import type { UserAccountUpdateDTO } from '../models/UserAccountUpdateDTO';
import type { UserNameRequestDto } from '../models/UserNameRequestDto';
import type { AdviserDropdownResponseDTO } from '../models/AdviserDropdownResponseDTO';
import { request as __request } from '@openapi/request';
import { AdviserProfileResponseDTO } from '../models/AdviserProfileResponseDTO';

export class AccountService {

    /**
     * @returns MochaUserWithLegacyInfo 
     * @throws ApiError
     */
    public static async getCurrentUserAccountInformation(): Promise<MochaUserWithLegacyInfo> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/account/current`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns FullMochaUserInfo 
     * @throws ApiError
     */
    public static async retrieveUserAccountInformation(
oid: string,
): Promise<FullMochaUserInfo> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/account/profile`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param headers 
     * @param representativeCodes 
     * @param companyCodes 
     * @param rnfNumbers 
     * @param oids 
     * @param page 
     * @param limit 
     * @param retrieveAll 
     * @param search 
     * @returns UserAccountListResponseDTO 
     * @throws ApiError
     */
    public static async listUserAccounts(
headers: string,
representativeCodes: string,
companyCodes: string,
rnfNumbers: string,
oids: string,
page: number,
limit: number,
retrieveAll?: boolean,
search?: string,
systemRoles?: string[],
): Promise<UserAccountListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/account/list`,
            query: {
                'headers': headers,
                'representativeCodes': representativeCodes,
                'companyCodes': companyCodes,
                'rnfNumbers': rnfNumbers,
                'oids': oids,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
                'search': search,
                'systemRoles': systemRoles,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns FullMochaUserInfo 
     * @throws ApiError
     */
    public static async createUserAccount(
requestBody: UserAccountCreationDTO,
): Promise<FullMochaUserInfo> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/account/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns FullMochaUserInfo 
     * @throws ApiError
     */
    public static async updateUserAccount(
requestBody: UserAccountUpdateDTO,
): Promise<FullMochaUserInfo> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/account/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns UserAccountImportResponse 
     * @throws ApiError
     */
    public static async massImportUsersIntoSystemV3(
requestBody: any, role: string
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/account/import/${role}`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @throws ApiError
     */
    public static async massImportAdviserPromotionDemotion(requestBody: any): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/account/import/promotion-demotion`,
            formData: requestBody,
        });
        return result.body;
    }


    /**
     * @returns UserAccountExportDto 
     * @throws ApiError
     */
    public static async massExportUsersFromSystemV3(type:string): Promise<Array<UserAccountExportDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/account/export?systemRole=${type}`,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async getSupervisorList(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account/supervisorlist`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async verifyToken(
requestBody: AccountDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/verify-token`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async genToken(
requestBody: AccountDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/request-reset-password`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateOtp(
requestBody: AccountDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/generate-otp`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async resetPasswordWithToken(
requestBody: AccountDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/reset-password`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param directorOid 
     * @returns PicodedAccountDTO 
     * @throws ApiError
     */
    public static async getDirectorSupervisorList(
directorOid: string,
): Promise<Array<PicodedAccountDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account/${directorOid}/director-supervisorlist`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async changeUserName(
requestBody: UserNameRequestDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/username/alter`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async getServerDate(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account/timestamp`,
        });
        return result.body;
    }

    /**
     * @param userOid 
     * @returns AccountFinancialSchemeDto 
     * @throws ApiError
     */
    public static async retrieveUserFinancialSchemeList(
userOid: string,
): Promise<Array<AccountFinancialSchemeDto>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account/scheme/financial/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns AccountFinancialSchemeDto 
     * @throws ApiError
     */
    public static async createOrUpdateFinancialScheme(
requestBody: AccountFinancialSchemeRequest,
): Promise<AccountFinancialSchemeDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/scheme/financial/add`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns AccountFinancialSchemeDto 
     * @throws ApiError
     */
    public static async removeFinancialSchemeFromUser(
requestBody: AccountDeleteRequest,
): Promise<AccountFinancialSchemeDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/account/scheme/financial`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async initializeAccountDetails(
requestBody: AccountDetailsDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account/detail/initialize`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getCountryList(requestBody = {}): Promise<Array<any>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param search
     * @param limit
     * @param selectedOid
     * @returns AdviserDropdownResponseDTO
     * @throws ApiError
     */
    public static async getAdviserDropdown(
        search?: string,
        limit?: number,
        selectedOid?: string,
        additionalKeys?: boolean
    ): Promise<AdviserDropdownResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/account/adviser-dropdown`,
            query: {
                'search': search,
                'limit': limit,
                'selectedOid': selectedOid,
                'additionalKeys': additionalKeys,
            },
        });
        return result.body;
    }

    public static async adviserProfile( adviserOid:string ) : Promise<AdviserProfileResponseDTO>{
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/account/adviser-profile/${adviserOid}`,
        });
        return result.body;
    }

}