import { Ignore } from '@master/annotation/Ignore'
import { MetaClass } from '@master/annotation/MetaClass'
import { Meta } from '@master/model/common/Meta'
import { ContractDetailsMeta } from './ContractDetailsMeta'
import EducationMeta from './EducationMeta'
import EmploymentMeta from './EmploymentMeta'
import { NextOfKinDetailsMeta } from './NextOfKinDetailsMeta'
import PersonalDetailsMeta from './PersonalDetailsMeta'
import PersonalItemsIssuedMeta from './PersonalItemsIssuedMeta'
import QualificationMeta from './QualificationMeta'
import { RecruitmentChecklistMeta } from './RecruitmentChecklistMeta'
import SpouseDetailsMeta from './SpouseDetailsMeta'
import { IAdviserService } from '@master/services/IAdviserService'

export class UserAdviserDetailsMeta extends Meta {
  // @Json()
  // myinfoLockedFieldsClient: Array<string> = []

  // linkedToMyinfo: boolean = undefined

  // Recruitment checklist
  atLeast21Checked: boolean = undefined
  atLeast4GceChecked: boolean = undefined
  aLvlChecked: boolean = undefined
  examRequirementsChecked: boolean = undefined
  examRequirements2Checked: boolean = undefined
  examRequirements3Checked: boolean = undefined
  examRequirements4Checked: boolean = undefined
  examRequirements5Checked: boolean = undefined
  solvencySearchChecked: boolean = undefined
  contractStatusChecked: boolean = undefined
  marketConductChecked: boolean = undefined
  employerChecked: boolean = undefined
  masProhibitionCheckChecked: boolean = undefined

  // Contract details
  contractStatus = ''
  dateContracted: number = undefined
  dateTerminated: number = undefined
  dateIssued: number = undefined
  contractType: number = undefined
  dateOfLastPromotion: number = undefined
  dateMasApplication: number = undefined
  dateMasYearlyRenewal: number = undefined
  dateMasTriYearlyRenewal: number = undefined

  // Personal details
  dateOfBirth: number = undefined
  dateEpIssued: number = undefined
  dateEpExpiry: number = undefined

  @MetaClass(ContractDetailsMeta)
  contractDetails: ContractDetailsMeta = new ContractDetailsMeta()

  @MetaClass(PersonalDetailsMeta)
  personalDetails: PersonalDetailsMeta = new PersonalDetailsMeta()

  @MetaClass(RecruitmentChecklistMeta)
  recruitmentChecklist: RecruitmentChecklistMeta = new RecruitmentChecklistMeta()

  @MetaClass(SpouseDetailsMeta)
  spouseDetails: SpouseDetailsMeta = new SpouseDetailsMeta()

  @MetaClass(NextOfKinDetailsMeta)
  nextOfKinDetails: NextOfKinDetailsMeta = new NextOfKinDetailsMeta()

  @MetaClass(PersonalItemsIssuedMeta)
  personalItemsIssued: PersonalItemsIssuedMeta = new PersonalItemsIssuedMeta()

  @MetaClass(EducationMeta, { isArray: true })
  @Ignore()
  educationList: EducationMeta[] = []

  @MetaClass(EmploymentMeta, { isArray: true })
  @Ignore()
  employmentList: EmploymentMeta[] = []

  @MetaClass(QualificationMeta, { isArray: true })
  @Ignore()
  qualificationList: QualificationMeta[] = []

  @Ignore()
  adviserService: IAdviserService

  constructor (adviserService: IAdviserService) {
    super()
    this.adviserService = adviserService
  }

  get contractTypeRank () {
    if (typeof this.adviserService.adviserContractTypeList === 'undefined') return null
    const selectedContractType = this.adviserService.adviserContractTypeList().find(type => type.name === this.contractType)
    return selectedContractType?.rank
  }
}
