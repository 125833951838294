import { MaxChar } from '@master/annotation/MaxChar'
import { isEmpty, isNull } from 'lodash/fp'
import { Base } from '../../Base'

/**
 * This is an interface for when app wanna customize BeneficialOwnerParticulars
 */
export interface IBOP {
  fullName: string;
  nric: string;
  identityType: string;
  nationality: string;
  nationalitySpecified: string;
  dateOfBirth?: number;
  contactNumber: string;
  residentialAddress: string;
  correspondenceAddress: string;
  politicallyExposedPerson: string;
  relationshipToCustomer: string;
  reasonsForAppointment: string;
  product: string;
  extractData (rawData: any);
  convertToRawData ();
  isNotComplete ();
  isNRICOrFIN ();
}

export default class BeneficialOwnerParticulars extends Base implements IBOP {
  @MaxChar(82)
  fullName = ''

  @MaxChar(25)
  nric = ''

  identityType = ''

  @MaxChar(25)
  nationality = ''

  nationalitySpecified = ''
  dateOfBirth?: number = undefined

  @MaxChar(25)
  contactNumber = ''

  @MaxChar(176)
  residentialAddress = ''

  @MaxChar(176)
  correspondenceAddress = ''

  politicallyExposedPerson = ''

  @MaxChar(82)
  relationshipToCustomer = ''

  @MaxChar(632)
  reasonsForAppointment = ''

  @MaxChar(256)
  product = ''

  isNotComplete () {
    return isEmpty(this.contactNumber) ||
    isEmpty(this.correspondenceAddress) ||
    isNaN(this.dateOfBirth) ||
    isNull(this.dateOfBirth) ||
    isEmpty(this.fullName) ||
    isEmpty(this.nationality) ||
    isEmpty(this.nric) ||
    isEmpty(this.politicallyExposedPerson) ||
    isEmpty(this.reasonsForAppointment) ||
    isEmpty(this.relationshipToCustomer) ||
    isEmpty(this.residentialAddress)
  }

  isNRICOrFIN () {
    return this.identityType === 'nric' || this.identityType === 'fin'
  }
}
