import { MaxChar } from '@master/annotation/MaxChar'
import { TypeBase } from '../TypeBase'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class Switching extends TypeBase {
  surrenderedInvestment = ''
  topUp = ''
  advisedBefore = ''

  @MaxChar(InputMaxChar.SMALL)
  name = ''

  @MaxChar(InputMaxChar.SMALL)
  planType = ''

  @MaxChar(InputMaxChar.SMALL)
  policyNum = ''

  inceptionDate = ''
  surrenderDate = ''

  @MaxChar(InputMaxChar.SMALL)
  benefitsProvided = ''

  @MaxChar(InputMaxChar.LARGE)
  reasons = ''

  @MaxChar(InputMaxChar.SMALL)
  detail = ''

  newProduct = ''

  premiumAmt = ''

  coverage = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') { // previously was ma
    super(`${type}_replacement`)
  }

  reset () {
    this.detail = ''
    this.name = ''
    this.planType = ''
    this.policyNum = ''
    this.inceptionDate = null
    this.surrenderDate = null
    this.benefitsProvided = ''
    this.advisedBefore = ''
    this.newProduct = ''
    this.premiumAmt = ''
    this.coverage = ''
  }
}
