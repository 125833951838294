import { IRiskRatingService } from '@master/services/IRiskRatingService'
import ProductBase from './ProductBase'
import { PaymentModeList } from '@master/model/options/PaymentModeOptions'
import { WrapList } from '@master/model/options/WrapOptions'
import { ClientChoiceOptions } from '@master/model/options/ClientChoiceOptions'

export default class InvestmentProduct extends ProductBase {
  clientIndex?: 0 | 1 = undefined
  jointClientIndex?: 0 | 1 = undefined
  wrap: '' | typeof WrapList[number] = ''
  paymentMode: '' | typeof PaymentModeList[number] = ''
  mainAccountIndex?: 0 | 1 = undefined
  mainAccountNameOid = ''
  mainProductClientChoice: '' | keyof typeof ClientChoiceOptions = '' // this is used to save its main account's client choice, used in client choice table

  constructor (riskRatingService: IRiskRatingService, mainAccount) {
    super(riskRatingService)
    this.riskRatingService = riskRatingService

    this.type = 'investment-subFunds'

    // to init main account value to sub-funds
    this.mainAccountIndex = mainAccount.clientIndex // Main Account Holder
    this.accountNumber = mainAccount.accountNumber // Account Number
    this.mainAccountNameOid = mainAccount.oid // Account Name'
    this.mainProductClientChoice = mainAccount.clientChoice
    this.clientChoice = mainAccount.clientChoice // default to main account's client choice
  }

  extractData(rawData: any, index?: number);
  extractData (rawData: any): {} {
    const data = super.extractData(rawData)
    data.type = 'investment-subFunds'

    // to init main account value to sub-funds
    data.mainAccountIndex = this.mainAccountIndex // Main Account Holder
    data.accountNumber = this.accountNumber // Account Number
    data.mainAccountNameOid = this.mainAccountNameOid // Account Name'
    data.mainProductClientChoice = this.mainProductClientChoice
    return data
  }
}
