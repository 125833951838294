import { Ignore } from '@master/annotation/Ignore'
import { TypeBase } from '@master/model/TypeBase'
import { IDateService } from '@master/services/IDateService'
import { inject } from 'inversify-props'

export class ClientProductRider extends TypeBase {
  @inject()
  protected dateService: IDateService

  constructor (idx: number) {
    super(`rider_${idx}`)
  }

  name = undefined
  benefitAmt = undefined
  premium = undefined
  ncp = undefined
  effectiveDate = undefined
  expiryDate = undefined
  duration = undefined

  @Ignore()
  dateFields = ['effectiveDate', 'expiryDate']

  afterExtractData (data) {
    data.benefitAmt = Number(data.benefitAmt)
    data.premium = Number(data.premium)
    data.ncp = Number(data.ncp)
    // existing data store date string
    this.dateFields.forEach((dtf) => {
      if (data[dtf]) {
        data[dtf] = new Date(data[dtf])?.getTime()
      }
    })
    return data
  }

  formattedDate (unixTime: number | string) {
    return this.dateService.format(Number(unixTime), 'YYYY-MM-DD')
  }

  convertToRawData () {
    const raw = super.convertToRawData()
    // special handling to store the date as string ( existing data )
    const dateFields = ['effectiveDate', 'expiryDate']
    dateFields.forEach((dtf) => {
      const sckey = this.snakeCaseKey(dtf)
      if (raw[sckey]) {
        raw[sckey] = this.formattedDate(raw[sckey])
      }
    })
    return raw
  }
}
