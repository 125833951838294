import { ISectionValidation, IValidation } from '@master/interface/IValidation'
import { isEmpty, isNull, omit } from 'lodash/fp'

export default class Validation {
  protected belongsTo = 'unsorted'

  protected isEmpty (value: any) {
    return typeof value === 'undefined' || value === null || value === ''
  }

  protected validator () {
    const validationRules: { logic: () => string | null; category: string; sections: string[]; mandatory?: boolean; key: string[]; index: number }[] = []
    const getMessages = () => {
      return validationRules.map(({ logic, category, sections, mandatory = false, key, index }) => {
        const message = logic()
        return {
          message,
          category,
          sections,
          key,
          index,
          mandatory,
          belongsTo: this.belongsTo
        }
      })
        .filter(({ message }) => message != null)
    }
    const ADD = (logic: () => string | null, sections: string[], key: string[] = [], index: number = null, category = 'normal') => {
      validationRules.push({
        logic,
        category,
        key,
        index,
        sections
      })
    }
    /**
     * add a mandatory validation , which is to be completed before submission
     * @param logic
     * @param sections
     * @param category
     */
    const ADDMANDATORY = (logic: () => string | null, sections: string[], key: string[] = [], index: number = null, category = 'normal') => {
      validationRules.push({
        logic,
        category,
        sections,
        key,
        index,
        mandatory: true
      })
    }
    const REQUIRE = (value: any, message: string) => {
      return () => this.isEmpty(value) ? message : null
    }
    const REQUIRE_TRUE = (value: any, message: string) => {
      return () => typeof value === 'undefined' || !value ? message : null
    }
    const REQUIRE_NUMBER = (value: any, message: string) => {
      return () => isNull(value) || value === '' || isNaN(Number(value)) ? message : null
    }
    const WHEN = (condition: boolean, logic: () => string | null) => {
      return () => condition ? logic() : null
    }
    const REQUIRE_ARRAY = (value: any, message: string) => {
      return () => this.isEmpty(value) || !Array.isArray(value) || value.length === 0 ? message : null
    }

    return {
      getMessages,
      ADD,
      ADDMANDATORY,
      REQUIRE,
      REQUIRE_TRUE,
      REQUIRE_NUMBER,
      REQUIRE_ARRAY,
      WHEN
    }
  }
}

export function getValidationSectionForClient (validation: IValidation, section: string, clientIndex: 0 | 1): ISectionValidation {
  const validationSection = validation[section]
  if (isEmpty(validationSection)) {
    return {}
  }

  // remove unrelated client validation and return
  // if is client 1 remove client 2 validation and vice versa
  const omitKey = clientIndex === 0 ? 'Client 2' : 'Client 1'
  return omit(omitKey, validationSection)
}
