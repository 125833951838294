import { IDateService } from '@master/services/IDateService'
import { Base } from '@master/model/Base'
import { snakeCase } from 'lodash/fp'
import { isPointer, Pointer } from '@master/annotation/Pointer'
import { Ignore, isIgnore } from '@master/annotation/Ignore'

export default class CCBS extends Base {
  selectedClient = ''
  repClient = ''
  attemptDate1st: number | string = ''
  attemptTime1st = ''
  attemptDate2nd: number | string = ''
  attemptTime2nd = ''
  attemptDate3rd: number | string = ''
  attemptTime3rd = ''
  outcomeRole = ''
  outcome = ''
  qnAnsRole = ''
  qnAns1 = ''
  qnAns2 = ''
  qnAns3 = ''
  qnAns4 = ''
  qnAns5 = ''
  qnAns6 = ''
  qnAns7 = ''

  @Ignore()
  @Pointer('ROOT.managerEndorsement', 'isNotSelectedClientOrRep')
  isNotSelectedClientOrRep = false

  @Ignore()
  dateService: IDateService

  @Ignore()
  listOfExcludedAttributesForValidation = []

  constructor (dateService: IDateService) {
    super()
    this.dateService = dateService
  }

  extractData (rawData: any) {
    const data = {}
    const prefix = 'ccbs_'
    Object.keys(this)
      .filter(key => !isIgnore(this, key) && !isPointer(this, key))
      .forEach((key) => {
        if (key === 'isNotSelectedClientOrRep') return
        const isAttempt = new RegExp(/^attempt(Date|Time)/g) // if its question, dont snakecasify
        let val = this[key]
        if (isAttempt.test(key)) {
          const seq = key.substring(key.length - 3)
          const type = key.substring(0, key.length - 3)
          val = rawData[prefix + seq + '_' + snakeCase(type)] || val
        } else {
          val = rawData[prefix + snakeCase(key)] || val
        }
        data[key] = val
      })
    return this.afterExtractData(data)
  }

  packData () {
    const data = {}
    const prefix = 'ccbs_'
    Object.keys(this).forEach((key) => {
      if (key === 'isNotSelectedClientOrRep') return
      const isAttempt = new RegExp(/^attempt(Date|Time)/g) // if its question, dont snakecasify
      if (isAttempt.test(key)) {
        const seq = key.substring(key.length - 3)
        const type = key.substring(0, key.length - 3)
        data[prefix + seq + '_' + snakeCase(type)] = this[key]
      } else {
        data[prefix + snakeCase(key)] = this[key]
      }
    })
    return this.afterPackData(data)
  }

  afterExtractData (data: { }) {
    return this.getReadableDates(data)
  }

  afterPackData (data: { }) {
    return this.getSaveableDates(data)
  }

  getReadableDates (ccbs: any) {
    const readFormat = 'YYYY-MM-DD'
    Object.keys(ccbs).forEach((key) => {
      if (key.includes('attemptDate')) {
        const getDate = this.dateService.format(ccbs[key], readFormat)
        ccbs[key] = getDate.includes('Invalid') ? '' : getDate
      }
    })
    return ccbs
  }

  getSaveableDates (ccbs: any) {
    Object.keys(ccbs).forEach((key) => {
      if (key.includes('attempt_date')) {
        ccbs[key] = new Date(ccbs[key]).valueOf()
      }
    })
    return ccbs
  }

  isAllValid () {
    let valid = true
    Object.keys(this).forEach((key) => {
      // not selected client/rep, no need call back survey (CBS)
      if (key === 'isNotSelectedClientOrRep' || this.isNotSelectedClientOrRep) return
      if ((this.outcomeRole === 'trusted-individual' || this.selectedClient === 'no') && key === 'qnAns4') return
      if (key === 'qnAnsRole') return
      if (key.includes('2nd') || key.includes('3rd')) return
      // not contactable/dont wanna take survey
      if (key.includes('qnAns') && this.outcome !== 'contactable_surveyed') return
      // no need answer callback question `aware of trusted individual option`
      if (this[key] === '' || !this[key]) valid = false
    })
    return valid
  }
}
