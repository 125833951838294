import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CommissionRecordsService } from "../../CommissionModule/api";
@Module({
  namespaced: true,
  name: "CommissionStatementStore",
})
export default class CommissionStatementStore extends VuexModule {
  commissionStatements = [];
  commissionStatementCount = 0;
  exportCsvData = null;
  successResponse = "";

  @Action
  async retrieveCommissionStatement(params) {
      const {page, limit, searchField, searchFieldValue} = params
      try {
    const result = await CommissionRecordsService.reportList(
      limit,
      page,
      searchField,
      searchFieldValue
    );
    this.context.commit("setCommissionStatement", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setCommissionStatement(result) {
    this.commissionStatements = result.data;
    this.commissionStatementCount = result.recordsTotal;
  }

  @Action
  async exportCommissionStatementsCSV() {
    const csv = await CommissionRecordsService.exportCsvReportList()
    return csv
  }

  @Action
  async downloadFile(params) {
    const file = await CommissionRecordsService.getReport(params)
    return file
  }

  
}