/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateFileUploadDto = {
    accountOid: string;
    name: string;
    type: CreateFileUploadDto.type;
}

export namespace CreateFileUploadDto {

    export enum type {
        MISC = 'MISC',
        ACRA = 'ACRA',
        SPF = 'SPF',
        CREDIT_BUREAU = 'CREDIT_BUREAU',
    }


}
