import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "switch" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: option }, [
        (index != 0)
          ? (_openBlock(), _createElementBlock("span", { key: index }))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          class: _normalizeClass(["option", { selected: _ctx.selected == option }]),
          onClick: ($event: any) => (_ctx.optionSelected(option))
        }, _toDisplayString(option), 11, _hoisted_2)
      ], 64))
    }), 128))
  ]))
}