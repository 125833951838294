import { Ignore, isIgnore } from '@master/annotation/Ignore'
import { MaxChar } from '@master/annotation/MaxChar'
import { isPointer } from '@master/annotation/Pointer'
import { Base } from '@master/model/Base'
import { snakeCase } from 'lodash/fp'

export interface ManagerEndorsementQuestion<T> {
  key: keyof T;
  desc: string;
}

export const ChecklistRatingTypes = [
  { label: 'Satisfactory', value: 'satisfactory' },
  { label: 'Unsatisfactory', value: 'unsatisfactory' },
  { label: 'NA', value: 'na' }
] as const
export default class ManagerEndorsement extends Base {
  q1 = undefined
  q2 = undefined
  q3 = undefined
  q4 = undefined
  q5 = undefined
  q6 = undefined
  q7 = undefined
  q8 = undefined

  @MaxChar(220)
  elaborateReasons = ''

  agree = ''
  present = ''
  farepCoached = ''

  @MaxChar(800)
  remarks = ''

  complete = false

  extractData (rawData: any) {
    const data = {}
    const prefix = 'manager_endorsement_'
    Object.keys(this).filter(key => !isIgnore(this, key) && !isPointer(this, key)).forEach((key) => {
      const isQuestion = new RegExp(/^q\d/g) // if its question, dont snakecasify
      let val = this[key]
      if (isQuestion.test(key)) {
        val = rawData[prefix + key] || val
      } else {
        val = rawData[prefix + snakeCase(key)] || val
      }
      data[key] = val
    })
    return data
  }

  packData () {
    const data = {}
    const prefix = 'manager_endorsement_'
    Object.keys(this).forEach((key) => {
      const isQuestion = new RegExp(/^q\d/g) // if its question, dont snakecasify
      if (isQuestion.test(key)) {
        data[prefix + key] = this[key]
      } else {
        data[prefix + snakeCase(key)] = this[key]
      }
    })
    return data
  }

  @Ignore()
  get isNotSelectedClientOrRep () {
    return this.q8 === 'na'
  }

  isAllValid () {
    let valid = true
    Object.keys(this).forEach(val => {
      if (val === 'complete') return
      if (this.agree === 'agree' && val === 'elaborateReasons') return
      if (this[val] === '' || !this[val]) valid = false
    })
    return valid
  }

  formFields () {
    return [
      {
        key: 'q1',
        desc: 'All information required in the Personal Financial Review (PFR) Form was obtained and documented.'
      },
      {
        key: 'q2',
        desc: 'The client\'s objective to be addressed, including the shortfall amount ($), is clearly established.'
      },
      {
        key: 'q3',
        desc: 'The client\'s need analysis is correctly calculated.'
      },
      {
        key: 'q4',
        desc: 'Reasons for recommendation / deviation / switch / replacement / is/are appropriateand was/were clearly explained.'
      },
      {
        key: 'q5',
        desc: 'The premium(s) proposed to meet the client\'s objective(s) is within the client\'s budget.'
      },
      {
        key: 'q6',
        desc: 'The product proposed to meet the client\'s objective(s) aligns with the client\'s risk profile.'
      },
      {
        key: 'q7',
        desc: 'The product features, benefits, limitations and risks of the plan recommendedwere explained and highlighted correctly.'
      },
      {
        key: 'q8',
        desc: 'The Client Call-Back (for selected client and selected representative) is completed.'
      }
    ]
  }
}
