/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ScheduleTimeDto = {
    enable: boolean;
    hideTask?: boolean;
    minute: number;
    hour: number;
    frequency: ScheduleTimeDto.frequency;
    name: ScheduleTimeDto.name;
    email?: string;
    ccEmail?: string;
}

export namespace ScheduleTimeDto {

    export enum frequency {
        WEEKLY = 'WEEKLY',
        BIWEEKLY = 'BIWEEKLY',
        MONTHLY = 'MONTHLY',
        DAILY = 'DAILY',
        WEEKDAYS = 'WEEKDAYS',
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }

    export enum name {
        COMMISSION_REPORT = 'COMMISSION_REPORT',
        ADVISER_NAME_SCREENING = 'ADVISER_NAME_SCREENING',
        CLIENT_NAME_SCREENING = 'CLIENT_NAME_SCREENING',
        CORPORATE_NAME_SCREENING = 'CORPORATE_NAME_SCREENING',
        PRESALES_CALLBACK = 'PRESALES_CALLBACK',
        MARKETING_CONSENT = 'MARKETING_CONSENT',
    }


}
