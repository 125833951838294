import NeedsBase from '../NeedsBase'

export default class EducationNeeds extends NeedsBase {
  name = 'Education Planning'
  needs = 'Children\'s Education'
  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 's4') {
    super(`${type}_${id}`)
  }
}
