import { RawKey } from '@master/annotation/RawKey'
import { Json } from '@master/annotation/Json'
import { Meta } from './Meta'

export const ProductHeaders = [
  '_oid',
  'issuer',
  'issuer_oid',
  'name',
  'type',
  'category',
  'recommended_risk_profile',
  'product_code',
  'needs_covered',
  'status',
  'reasons',
  'limitations',
  'launch_date_millis',
  'withdrawn_date_millis'
]

export const insuranceProductTypes = [
  /**
   *  match all types that have 'insurance' word but not include rider
   *  e.g.
   *  'insurance - rider' = false
   *  'insurance rider' = false
   *  'insurance riders' = false
   *  'insurance rider xxx' = false
   *  'insurance - ilp' = true
   *  'insurance ilp' = true
   *  'insurance xxx' = true
   *  'insurance ***' = true
   */
  /insurance(?!.+?rider)/
]
export const insuranceProductCategories = [
  'critical illness',
  'decreasing term insurance',
  'disability income insurance',
  'health insurance',
  'hospital cash insurance',
  'investment linked plan',
  'long term care',
  'personal accident',
  'term insurance',
  'universal life',
  /endowment/,
  /whole life/
]

export const investmentProductTypes = ['collective investment scheme', 'corporate bond', 'etf fund', 'reit', 'ut fund', 'Investment']
export const investmentProductCategories = [
  'alternatives',
  'annuity',
  'bond',
  'cash',
  'commodity',
  'equity',
  'exchanged traded fund',
  'not applicable',
  'others',
  'property',
  'structured note',
  'unit trust'
]

export const ilpProductTypes = [/fund/, /corporate/, /reit/]

export const riderProductTypes = [/insurance.+?rider/, /insurance-rider/]

export const generalProductTypes = [/general/, /General/, /Group/]

// @TODO: This has to be placed in a util function, havent decide where, but at least this is in @master
// Product filter is case insensitive
// filter list make it to support both string & Regex to support more broader cases
// Known issue in LINC is product category as 'hospital cash insurance' will show under investment
// due to LINC include any category contain 'cash'
export const isInclude = (checkList: (string | RegExp)[], checkValue: string) => {
  checkValue = checkValue.toLowerCase()
  return checkList.some(check => {
    
    if (check instanceof RegExp) {
      return check.test(checkValue)
    } else {
      return check.toLowerCase() === checkValue
    }
  })
}

type CheckList = (string | RegExp)[]

export class ProductMeta extends Meta {
  @RawKey('_oid')
  oid = ''

  issuer = ''
  issuer_oid = ''
  productCode = ''
  name = ''
  type = ''
  category = ''
  recommendedRiskProfile: number = undefined
  @Json()
  needsCovered: string[] = []

  productFamilyCode = ''
  productFamilyName = ''
  status: number = undefined
  reasons = ''
  limitations = ''
  launchDateMillis: number = undefined
  withdrawnDateMillis: number = undefined
  // Only put here just for Gen to work properly
  premiumType = ''

  isRider (productType: CheckList = riderProductTypes, categoryType: CheckList = ['rider']) {
    return this.isInclude(productType, this.type) || this.isInclude(categoryType, this.category)
  }

  isInsuranceProduct (productType: CheckList = insuranceProductTypes, categoryType: CheckList = insuranceProductCategories, fullProductType: CheckList = [...insuranceProductTypes, ...investmentProductTypes]) {
    return this.isInclude(fullProductType, this.type) ? this.isInclude(productType, this.type) : this.isInclude(categoryType, this.category)
  }

  isInvestmentProduct (productType: CheckList = investmentProductTypes, categoryType: CheckList = investmentProductCategories, fullProductType: CheckList = [...insuranceProductTypes, ...investmentProductTypes]) {
    return this.isInclude(fullProductType, this.type) ? this.isInclude(productType, this.type) : this.isInclude(categoryType, this.category)
  }

  isILPProduct (productType: CheckList = ilpProductTypes, categoryType: CheckList = []) {
    return this.isInclude(productType, this.type) || this.isInclude(categoryType, this.category)
  }

  afterAssign () {
    // freeze this object to prevent changes
    Object.freeze(this)
  }

  // Product filter is case insensitive
  // filter list make it to support both string & Regex to support more broader cases
  // Known issue in LINC is product category as 'hospital cash insurance' will show under investment
  // due to LINC include any category contain 'cash'
  isInclude (checkList: (string | RegExp)[], checkValue: string) {
    checkValue = checkValue?.toLowerCase()
    return checkList.some(check => {
      if (check instanceof RegExp) {
        return check.test(checkValue)
      } else {
        return check === checkValue
      }
    })
  }
}
