import { FNAStructure } from '@master/model/structure/FNAStructure'
import RetirementNeeds from '@master/model/kyc-form/need-analysis/savings/RetirementNeeds'

export function RetirementStructure (defaultValue: { incomePv: number; retirementYear: number; duration: number}) {
  const structure: FNAStructure<RetirementNeeds> = {
    title: 'S1. Retirement',
    data: [
      {
        label: 'Years to retirement',
        key: 'years',
        default: defaultValue?.retirementYear,
        validationMsg: 'Retirement requires to indicate years to retirement.'
      }, {
        label: 'Annual income required (PV) ($)',
        key: 'incomePv',
        type: 'currency',
        default: defaultValue?.incomePv,
        validationMsg: 'Retirement requires to indicate annual income required (PV).'
      }, {
        label: 'Average inflation rate (%)',
        key: 'inflation',
        defaultTo: 'defaultRate',
        validationMsg: 'Retirement requires to indicate average inflation rate.'
      }, {
        label: 'Annual income required (FV) ($)',
        key: 'incomeFv',
        type: 'currency',
        disabled: true
      },
      {
        label: '- Existing regular income (annualised) ($)',
        key: 'available1',
        type: 'currency',
        validationMsg: 'Retirement requires to indicate existing regular income (annualised).'
      },
      {
        title: 'Total retirement income required for retirement (at retirement age)'
      }, {
        label: 'First year income required at retirement age (PMT) ($)',
        key: 'firstYearIncome',
        type: 'currency',
        disabled: true
      }, {
        label: 'Duration of retirement',
        key: 'duration',
        default: defaultValue?.duration,
        validationMsg: 'Retirement requires to indicate duration of retirement.'
      }, {
        label: 'Inflation adjusted rate return (%)',
        key: 'infAdjReturn',
        defaultTo: 'defaultInfAdjReturn',
        validationMsg: 'Retirement requires to indicate inflation adjusted rate return.'
      }, {
        label: 'Total amount required at start of retirement ($)',
        key: 'totalAmount',
        type: 'currency',
        disabled: true
      }, {
        label: '- Maturity value of insurance plan(s) at retirement ($)',
        key: 'available2',
        type: 'currency',
        validationMsg: 'Retirement requires to indicate maturity value of insurance plan(s) at retirement.'
      }, {
        label: '- Total asset to be used or liquidated for this need ($)',
        key: 'available3',
        type: 'currency',
        validationMsg: 'Retirement requires to indicate total asset to be used or liquidated for this need.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
