export class ProductILPPremium {
  regular_name = ''
  regular_amt = ''
  regular_freq = ''
  regular_approved_date = undefined
  regular_end_date = undefined
  regular_unit_balance = ''
  regular_risk_class = ''
  regular_regular_cash = ''
  regular_regular_stock = ''
  regular_regular_bonds = ''
}
