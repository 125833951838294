import { Base } from '@master/model/Base'

export default class OfficerRemarks extends Base {
  adviser = ''
  supervisor = ''
  submission = ''

  extractData (rawData: any) {
    const data = {}
    if (rawData) {
      Object.keys(this).forEach((key) => {
        data[key] = rawData[key + '_remarks']
      })
    }
    return data
  }

  packData () {
    const data = {}
    if (data) {
      Object.keys(this).forEach((key) => {
        data[key + '_remarks'] = this[key]
      })
    }
    return data
  }
}
