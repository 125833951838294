import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = {
  role: "navigation",
  class: "viewPort-main"
}
const _hoisted_3 = { id: "nav-list" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "hamburger" }
const _hoisted_6 = {
  role: "navigation",
  class: "viewPort-tab"
}
const _hoisted_7 = { id: "nav-list" }
const _hoisted_8 = { class: "icon-space" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "hamburger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHamburgerHeader = _resolveComponent("AppHamburgerHeader")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass({ mini: _ctx.mini })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "logo",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
      })
    ]),
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header(), ({icon, label}, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["icon-space", label])
          }, [
            _createElementVNode("a", null, [
              _withDirectives(_createElementVNode("img", {
                src: icon,
                alt: "svg image"
              }, null, 8, _hoisted_4), [
                [_directive_svg_inline]
              ]),
              _createElementVNode("span", null, _toDisplayString(label), 1)
            ])
          ], 2))
        }), 128)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "vertical-line" }, null, -1)),
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_AppHamburgerHeader, {
            class: "changeHeaderHamburger",
            onLogout: _ctx.logout,
            header: _ctx.hambugerHeader,
            withLogo: false
          }, null, 8, ["onLogout", "header"])
        ])
      ])
    ]),
    _createElementVNode("nav", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        _createElementVNode("li", _hoisted_8, [
          _createElementVNode("template", null, [
            _createVNode(_component_el_dropdown, null, {
              default: _withCtx(() => [
                _createElementVNode("a", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, _toDisplayString('Actions'), -1)),
                  _withDirectives(_createElementVNode("img", {
                    src: _ctx.arrowDown,
                    alt: "svg image"
                  }, null, 8, _hoisted_9), [
                    [_directive_svg_inline]
                  ])
                ]),
                _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header(), (item, i) => {
                      return (_openBlock(), _createBlock(_component_el_dropdown_item, { key: i }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", null, [
                            _withDirectives(_createElementVNode("img", {
                              src: item.icon,
                              alt: "svg image"
                            }, null, 8, _hoisted_10), [
                              [_directive_svg_inline]
                            ]),
                            _createElementVNode("span", null, _toDisplayString(item.label), 1)
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("li", _hoisted_11, [
          _createVNode(_component_AppHamburgerHeader, {
            onLogout: _ctx.logout,
            header: _ctx.hambugerHeader,
            withLogo: false
          }, null, 8, ["onLogout", "header"])
        ])
      ])
    ])
  ], 2))
}