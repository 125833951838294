import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { CompensationSetupDTO, CompensationSetupImportService, CompensationSetupService } from '../api'
import { cid, container } from 'inversify-props'
import { IDateService } from '@master/services/IDateService'

const dateService = container.get<IDateService>(cid.IDateService)

@Module({
  namespaced: true,
  name: 'CompensationSetupStore'
})
export default class CompensationSetupStore extends VuexModule {
  compensationSetupRows: CompensationSetupDTO[] = null

  compensationInView: CompensationSetupDTO = null
  setCompensationSetupRecordsTotal = null

  @Action({ rawError: true })
  async importCompensationSetup (data: CompensationSetupDTO[]) {
    const res = await CompensationSetupImportService.importCompensationSetupImport(data)
    return res
  }

  @Action({ rawError: true })
  async fetchCompensationSetup ({searchField, searchFieldValue, page, limit}) {
    const res = await CompensationSetupService.getCompensationList(searchField, searchFieldValue, page, limit)
    this.context.commit('setCompensationSetup', res)
  }

  @Mutation
  setCompensationSetup (res) {
    this.compensationSetupRows = res.data.map(record => {
      const startDateMillis = dateService.format(record.startDate, 'DD-MM-YYYY')
      const endDateMillis = dateService.format(record.endDate, 'DD-MM-YYYY')
      return {
        ...record,
        startDateMillis,
        endDateMillis,
        emit: true // required to display dialog on row click
      }
    })
    this.setCompensationSetupRecordsTotal = res.recordsTotal
  }

  @Action({ rawError: true })
  selectCompensationToView (row: CompensationSetupDTO) {
    this.context.commit('setCompensationInView', row)
  }

  @Mutation
  setCompensationInView (row: CompensationSetupDTO) {
    this.compensationInView = row
  }
}
