import { ISimpleForm } from '@master/interface/ISimpleForm'
import { IValidationValue } from '@master/interface/IValidationValue'
import { PTCConstructor } from '@master/interface/IPTCConstructor'
import { getRawKey, RawKey } from '@master/annotation/RawKey'
import { IValidation } from '@master/interface/IValidation'

export class PTC<FORM extends ISimpleForm<CLIENT>, CLIENT> {
  @RawKey('client_0_ptc_@id')
  client1 = false

  @RawKey('client_1_ptc_@id')
  client2 = false

  @RawKey('ptc_@id_verified')
  verified = false

  label: string
  id: string
  private validation: (value: IValidationValue<FORM, CLIENT>) => boolean;

  constructor ({ label, id, validation }: PTCConstructor<FORM, CLIENT>) {
    this.label = label
    this.id = id
    this.validation = validation
  }

  validate (kycForm: FORM, validation: IValidation, includeClient2: boolean) {
    this.client1 = this.validation({ client: kycForm.client1, clientIndex: 0, kycForm, validation })
    this.client2 = includeClient2 ? this.validation({ client: kycForm.client2, clientIndex: 1, kycForm, validation }) : false
    // revalidate need to turn verified to false
    this.verified = false
  }

  requiredVerify () {
    return this.client1 || this.client2
  }

  isVerified () {
    // no need to verify, return is verified
    if (this.requiredVerify() === false) {
      return true
    }
    return this.verified
  }

  extractData (rawData) {
    Object.keys(this)
      .forEach(key => {
        const rawKey = getRawKey(this, key)

        // only read for rawKey
        if (rawKey) {
          this[key] = rawData[rawKey]
        }
      })
  }

  convertToRawData () {
    return Object.keys(this)
      .reduce((result, key) => {
        const rawKey = getRawKey(this, key)

        // only convert value with rawKey
        if (rawKey) {
          result = { ...result, [rawKey]: this[key] }
        }
        return result
      }, {})
  }
}
