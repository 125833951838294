import { FormBase } from '../FormBase'

export class TransferRulesAcknowledgement extends FormBase {
  type = 'transfer-rules-acknowledgement'
  // shared.name
  // shared.branch

  data = {
    agree: false
  }

  get error () {
    return !this.data.agree ? [{ key: 'agree', msg: 'Please read and indicate terms agreement' }] : []
  }
}
