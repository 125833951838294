import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CommissionRecordsService } from "../../CommissionModule/api";
@Module({
  namespaced: true,
  name: "BatchRecordsStore",
})
export default class BatchRecordsStore extends VuexModule {
  batchRecordsPending = [];
  batchRecordsApproved = [];
  batchRecordsRejected = [];
  batchRecordsPendingCount = 0;
  batchRecordsApprovedCount = 0;
  batchRecordsRejectedCount = 0;
  exportCsvData = null;
  successResponse = "";
  batchRecordsStoreTab: any = "Pending";
  batchSummary: Object = {}
  @Action
  async retrieveBatchRecords(params) {
      const {page, limit, status, retrieveAll, searchField, searchFieldValue} = params
      try {
    const result = await CommissionRecordsService.commissionRecords(
      limit,
      page,
      status,
      retrieveAll,
      searchField, 
      searchFieldValue
    );
    this.context.commit("setBatchRecords", { status, result });
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setBatchRecords(params: { status: string; result: any }) {
    this.batchSummary = params.result['summary']
    switch (params.status) {
      case 'Pending': 
        this.batchRecordsPendingCount = params.result.recordsTotal;
        this.batchRecordsPending = params.result.data;
        break;
      case 'Approved': 
        this.batchRecordsApprovedCount = params.result.recordsTotal;
        this.batchRecordsApproved = params.result.data;
        break;
      case 'Rejected': 
        this.batchRecordsRejectedCount = params.result.recordsTotal;
        this.batchRecordsRejected = params.result.data;
        break;
    }
  }

  @Action
  async exportBatchRecordsCSV(params: {
    searchField?: string,
    searchFieldValue?: string,
    limit?: number,
    page?: number,
    status?: string,
    retrieveAll?: boolean,
  }) {
    return await CommissionRecordsService.exportBatchDataCsv(params.searchField, params.searchFieldValue, params.limit, params.page, params.status, params.retrieveAll);
  }

  @Action
  async downloadFile(params) {
    return await CommissionRecordsService.getReport(params);
  }

  @Action
  async updateBatchRecordsStoreTab(params) {
      try {
    const result = params
    this.context.commit("setBatchRecordsStoreTab", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setBatchRecordsStoreTab(result) {
        this.batchRecordsStoreTab = result;
  }
}