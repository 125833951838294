export class CISLumpSum {
  transaction_no = ''
  name = ''
  amt = ''
  approved_date: number = undefined
  unit_balance = ''
  risk_class = ''
  cash = ''
  stock = ''
  bonds = ''
}

export class CISRepeatInvestment {
  transaction_no = ''
  name = ''
  amt = ''
  freq = ''
  approved_date: number = undefined
  end_date: number = undefined
  unit_balance = ''
  risk_class = ''
  cash = ''
  stock = ''
  bonds = ''
}

export class PorfolioTransactions {
  transaction_no = ''
  subaccount_no = ''
  name = ''
  amt = ''
  freq = ''
  transaction_type = ''
  wrap = ''
  paymentMode = ''
  approved_date: number = undefined
  end_date: number = undefined
}
