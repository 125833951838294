/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvFileResponseDTO } from '../models/CsvFileResponseDTO';
import { request as __request } from '@openapi/request';

export class ProductMasterService {

    /**
     * Product master csv file validator
     * @param template 
     * @returns CsvFileResponseDTO 
     * @throws ApiError
     */
    public static async validateCsv(
template: string,
): Promise<CsvFileResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/validate-csv`,
            query: {
                'template': template,
            },
        });
        return result.body;
    }

    /**
     * Product master csv file upload
     * @param template 
     * @returns CsvFileResponseDTO 
     * @throws ApiError
     */
    public static async uploadCsv(
template: string,
): Promise<CsvFileResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/upload-csv`,
            query: {
                'template': template,
            },
        });
        return result.body;
    }

    /**
     * Gets list of product types
     * @returns any 
     * @throws ApiError
     */
    public static async getProductTypes(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/product-types`,
        });
        return result.body;
    }

    /**
     * Gets list of product categories
     * @returns any 
     * @throws ApiError
     */
    public static async getProductCategories(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/product-categories`,
        });
        return result.body;
    }

    /**
     * Gets list of fund types
     * @returns any 
     * @throws ApiError
     */
    public static async getFundTypes(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/fund-types`,
        });
        return result.body;
    }

    /**
     * Gets non UT fund products list
     * @param searchField 
     * @param searchFieldValue 
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @param sortBy 
     * @param orderBy 
     * @returns any 
     * @throws ApiError
     */
    public static async getProductList(
searchField?: string,
searchFieldValue?: string,
limit?: number,
page?: number,
retrieveAll?: boolean,
sortBy?: string,
orderBy?: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
                'sortBy': sortBy,
                'orderBy': orderBy,
            },
        });
        return result.body;
    }

    /**
     * Gets UT fund products list
     * @param searchField 
     * @param searchFieldValue 
     * @param limit 
     * @param page 
     * @param retrieveAll 
     * @param sortBy 
     * @param orderBy 
     * @returns any 
     * @throws ApiError
     */
    public static async getUtFundList(
searchField?: string,
searchFieldValue?: string,
limit?: number,
page?: number,
retrieveAll?: boolean,
sortBy?: string,
orderBy?: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/ut-funds`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
                'sortBy': sortBy,
                'orderBy': orderBy,
            },
        });
        return result.body;
    }

    /**
     * Gets the products list based on the search criteria
     * @param oid 
     * @param indicator 
     * @param productName 
     * @param productCode 
     * @param productLine 
     * @param providerName 
     * @param productTypeName 
     * @param productTypeCode 
     * @param productCategory 
     * @param productFamily 
     * @param productFamilyTag 
     * @param planName 
     * @param planCode 
     * @param productGroup 
     * @param trainingMandatory 
     * @param productNeeds 
     * @returns any 
     * @throws ApiError
     */
    public static async searchProdut(
oid?: string,
indicator?: string,
productName?: string,
productCode?: string,
productLine?: string,
providerName?: string,
productTypeName?: string,
productTypeCode?: string,
productCategory?: string,
productFamily?: string,
productFamilyTag?: string,
planName?: string,
planCode?: string,
productGroup?: string,
trainingMandatory?: boolean,
productNeeds?: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/search`,
            query: {
                'oid': oid,
                'indicator': indicator,
                'productName': productName,
                'productCode': productCode,
                'productLine': productLine,
                'providerName': providerName,
                'productTypeName': productTypeName,
                'productTypeCode': productTypeCode,
                'productCategory': productCategory,
                'productFamily': productFamily,
                'productFamilyTag': productFamilyTag,
                'planName': planName,
                'planCode': planCode,
                'productGroup': productGroup,
                'trainingMandatory': trainingMandatory,
                'productNeeds': productNeeds,
            },
        });
        return result.body;
    }

}