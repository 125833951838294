// import { PluginFunction, PluginObject } from 'vue'
// import { VuejsDatatableFactory } from 'vuejs-datatable'
import ElementPlus from 'element-plus'
// import locale from 'element-plus/lib/locale/lang/en'
import {useCurrencyInput} from 'vue-currency-input'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import PortalVue from 'portal-vue'
import VuePapaParse from 'vue-papa-parse'
import { RecycleScroller } from 'vue-virtual-scroller'
import { cid, container } from 'inversify-props'
import { IProjectInfo } from '@master/config/IProjectInfo'

// to overwrite the default setting of SGD$, if project info didnt have preset currency prefix, fallback to default one
const projectInfo = container.get<IProjectInfo>(cid.ProjectInfo)
const currencyPrefix = projectInfo.currency ?? 'SGD$'

// const CorePlugins: Array<{ plugin: PluginObject<any> | PluginFunction<any>; options?: {} | any[] }> = [
const CorePlugins: Array<any> = [
  {
    plugin: ElementPlus
    // options: { locale }
  },
  {
    plugin: useCurrencyInput,
    options: {
      globalOptions: {
        currency: { prefix: currencyPrefix, suffix: null },
        precision: 0
      }
    }
  },
  {
    plugin: VueSvgInlinePlugin,
    options: {
      attributes: {
        data: ['src'],
        remove: ['alt']
      }
    }
  },
  {
    plugin: PortalVue
  },
  {
    plugin: VuePapaParse
  },
  {
    plugin: RecycleScroller
  }
]

export default CorePlugins
