import { RouteRecordRaw } from 'vue-router'
export const commissionRouter = ((): RouteRecordRaw[] => {
    return [
      {
        path: '/commission/',
        component: () => import('@core/pages/commission/components/NewCommissionModule.vue'),
        meta: {
            subject: ['commissionRecord', 'commissionReportRecord', 'incomeDeductible']
        },
        redirect: '/commission/batch-records',
        children: [
            {
                path: 'batch-records',
                name: 'Batch Records',
                meta: {
                    title : "Pending Approval", 
                    key : "Pending", 
                    summary: 0,
                    section: "batch-records"
                },
                component: () => import('@core/pages/commission/components/BatchRecords.vue')
            },
            {
                path: 'batch-records/approved',
                name: 'Approved Batch Records',
                meta: {
                    title : "Approved",
                    key : "Approved",
                    summary : 0,
                    section: "batch-records"
                },
                component: () => import('@core/pages/commission/components/BatchRecords.vue')
            },
            {
                path: 'batch-records/rejected',
                name: 'Rejected Batch Records',
                meta: {
                    title : "Rejected",
                    key : "Rejected",
                    summary : 0,
                    section: "batch-records"
                },
                component: () => import('@core/pages/commission/components/BatchRecords.vue')
            },

            {
                path: 'batch-record-detail/:oid',
                name: 'Batch Record Detail',
                component: () => import('@core/pages/commission/components/BatchRecordDetail.vue')
            },
            {
                path: 'transaction-records',
                name: 'Transaction',
                component: () => import('@core/pages/commission/components/Transactions.vue')
            },
            {
                path: 'commission-records',
                name: 'Commissions',
                component: () => import('@core/pages/commission/components/Commissions.vue')
            },
            {
                path: 'payment-voucher',
                name: 'Payment Voucher',
                component: () => import('@core/pages/commission/components/PaymentVoucher.vue')
            },
            {
                path: 'intermediaries/servicing',
                name: 'Intermediaries',
                meta: {
                    section :"intermediary",
                    title : "Servicing Advisers",
                    key: "servicing",
                    tab: "Intermediaries",
                    sum: "servicingPoliciesRecordsTotal"
                },
                component: () => import('@core/pages/commission/components/Intermediaries.vue')
            },
            {
                path: 'intermediaries/introducers',
                name: 'IntroducerIntermediaries',
                meta: {
                    section :"intermediary",
                    title : "Introducers",
                    key: "introducer",
                    tab: "IntroducerIntermediaries",
                    sum: "introducerPoliciesRecordsTotal"
                },
                component: () => import('@core/pages/commission/components/Intermediaries.vue')
            },
            {
                path: 'intermediaries/vesting',
                name: 'VestingIntermediaries',
                meta: {
                    section :"intermediary",
                    title : "Vestings",
                    key: "vesting",
                    tab: "VestingIntermediaries",
                    sum: "vestingPoliciesRecordsTotal"
                },
                component: () => import('@core/pages/commission/components/Intermediaries.vue')
            },
            {
                path: 'reports-statements',
                name: 'Reports and Statements',
                component: () => import('@core/pages/commission/components/ReportsStatements.vue')
            },
        ]
      }
    ]
})()
