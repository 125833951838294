/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserLicenceDto } from '../models/AdviserLicenceDto';
import type { Number } from '../models/Number';
import { request as __request } from '@openapi/request';

export class AdviserLicenceService {

    /**
     * @param oid
     * @returns AdviserLicenceDto
     * @throws ApiError
     */
    public static async retrieveSpecificUserAdviserLicences(
        oid: string,
    ): Promise<Array<AdviserLicenceDto>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-licence/user/${oid}`,
        });
        return result.body;
    }

    /**
     * @returns AdviserLicenceDto Retrieve the list of Licence Product Mappings
     * @throws ApiError
     */
    public static async retrieveAdviserLicenceList(): Promise<Array<AdviserLicenceDto>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-licence/list`,
        });
        return result.body;
    }

    /**
     * @param mappingOid
     * @returns AdviserLicenceDto Retrieve a specific Licence Product Mapping
     * @throws ApiError
     */
    public static async retrieveAdviserLicence(
        mappingOid: string,
    ): Promise<AdviserLicenceDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-licence/${mappingOid}`,
        });
        return result.body;
    }

    /**
     * @param mappingOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteCpdMasterRecord(
        mappingOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/adviser-licence/${mappingOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns AdviserLicenceDto Add License for an Adviser
     * @throws ApiError
     */
    public static async addAdviserLicence(
        requestBody: AdviserLicenceDto,
    ): Promise<Array<AdviserLicenceDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-licence/add`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Number Updates a Licence Product Mapping
     * @throws ApiError
     */
    public static async updateLicence(
        requestBody: AdviserLicenceDto,
    ): Promise<Number> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/adviser-licence/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async deleteAdviserLicence(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/adviser-licence/delete/${oid}`,
        });
        return result.body;
    }

}