import { Meta } from '@master/model/common/Meta'
import { RawKey } from '@master/annotation/RawKey'

export default class AccountMeta extends Meta {
  @RawKey('_oid')
  oid = ''

  systemrole = ''
  username = ''
  names: string[] = []
  fullname? = ''
  email? = ''
  nric = ''
  companyCode? = ''
  masRepno? = ''
  mobile? = ''
  adviserBranch? = ''
  idNumber? = ''
  userCompanyInfo?: any
  userPersonalInfo?: any
  fullnameLabel?:string
}
