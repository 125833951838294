import CoreNetWorth from '@master/model/kyc-form/finances/NetWorth'
import { Default } from '@master/annotation/Default'

export default class NetWorth extends CoreNetWorth {
  @Default(0)
  cash = ''

  @Default(0)
  motor = ''

  @Default(0)
  otherPersonalAssets = ''

  @Default(0)
  investBusiness = ''

  @Default(0)
  cpfRa = ''

  @Default(0)
  srs = ''

  @Default(0)
  investProperty = ''

  @Default(0)
  lifeInsuranceCash = ''

  @Default(0)
  educationLoans = ''

  get totalUsedAssets () {
    let res = 0
    res += parseInt(this.cash) || 0
    res += parseInt(this.residentialAssets) || 0
    res += parseInt(this.motor) || 0
    res += parseInt(this.otherPersonalAssets) || 0
    return res
  }

  get totalInvestedAssets () {
    let res = 0
    res += parseInt(this.investBusiness) || 0
    res += parseInt(this.stocksShares) || 0
    res += parseInt(this.investProperty) || 0
    res += parseInt(this.unitTrust) || 0
    res += parseInt(this.cpfOrdinary) || 0
    res += parseInt(this.cpfSpecial) || 0
    res += parseInt(this.cpfRa) || 0
    res += parseInt(this.cpfMedisave) || 0
    res += parseInt(this.srs) || 0
    res += parseInt(this.lifeInsuranceCash) || 0
    res += parseInt(this.others1) || 0
    return res
  }

  totalUsableAssets () {
    return this.totalInvestedAssets + this.totalCashAssets
  }

  totalAssetsForTPD () {
    let res = 0
    res += this.totalInvestedAssets || 0
    res -= parseInt(this.cpfOrdinary) || 0
    res -= parseInt(this.cpfSpecial) || 0
    res += this.totalCashAssets
    return res
  }
}
