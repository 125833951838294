import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { Base } from '../Base'
import BasicProduct from '@master/model/kyc-form/product/BasicProduct'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
export default class Recommendation extends Base {
  rcmd: BasicProduct[] = []
  @Ignore()
  riskRatingService: IRiskRatingService

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  constructor (riskRatingService: IRiskRatingService) {
    super()
    this.riskRatingService = riskRatingService
  }

  get allProducts () {
    return this.rcmd.filter(prod => {
      if (!this.hasClient2 && prod.clientIndex === 1) {
        return false
      }

      return true
    }).flatMap(basicProduct => {
      return [
        basicProduct,
        ...basicProduct.riders
      ]
    })
  }

  getProductsForClient (clientIndex) {
    return this.rcmd.filter(prod => prod.clientIndex === clientIndex).flatMap(basicProduct => {
      return [
        basicProduct,
        ...basicProduct.riders
      ]
    })
  }

  get hasCustomProduct () {
    return this.allProducts.some(prod => {
      return prod.isCustom
    })
  }

  hasCustomProductForClient (clientIndex) {
    return this.getProductsForClient(clientIndex).some(prod => {
      return prod.isCustom
    })
  }

  afterExtractData (data: { rcmd: any[] }) {
    const rcmd = data.rcmd || []
    data.rcmd = rcmd.map((element) => {
      const basic = new BasicProduct(this.riskRatingService)
      Object.assign(basic, basic.extractData(element))
      return basic
    })
    return data
  }

  // to get back the raw data for saving to backend
  convertToRawData () {
    const rawArray = this.rcmd.map(basicProduct => basicProduct.convertToRawData())

    return {
      rcmd: rawArray,
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_reasons: this.productReasons,
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_limitations: this.productLimitations
    }
  }

  totalAnnualizedPremiumForClient (clientIndex: 0|1) {
    return this.rcmd.reduce((total, prod) => {
      if (clientIndex === prod.clientIndex && ['accept', 'ownchoice'].includes(prod.clientChoice)) {
        return total + prod.totalAnnualisedPremium
      }
      return total
    }, 0)
  }

  totalSinglePremiumForClient (clientIndex: 0|1) {
    return this.rcmd.reduce((total, prod) => {
      if (clientIndex === prod.clientIndex && ['accept', 'ownchoice'].includes(prod.clientChoice)) {
        return total + prod.totalSinglePremium
      }
      return total
    }, 0)
  }

  get productReasons (): string {
    return this.allProducts.filter(prod => !prod.isCustom).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getReasons()
    }).join('\n\n')
  }

  get productLimitations (): string {
    return this.allProducts.filter(prod => !prod.isCustom).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getLimitations()
    }).join('\n\n')
  }

  get hasNtuc (): boolean {
    return this.rcmd.some(prod => {
      return prod.isNtuc()
    })
  }

  get c1TransactValue () {
    return this.totalAnnualizedPremiumForClient(0) + this.totalSinglePremiumForClient(0)
  }

  get c2TransactValue () {
    return this.totalAnnualizedPremiumForClient(1) + this.totalSinglePremiumForClient(1)
  }

  get c1hasMedical () {
    return this.allProducts.filter((rmd) => {
      return rmd.clientIndex === 0
    }).some((rmd) => {
      const accepted = rmd.clientChoice === 'accept' || rmd.clientChoice === 'ownchoice'
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }

  get c2hasMedical () {
    return this.allProducts.filter((rmd) => {
      return rmd.clientIndex === 1
    }).some((rmd) => {
      const accepted = rmd.clientChoice === 'accept' || rmd.clientChoice === 'ownchoice'
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }
}
