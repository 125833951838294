import { Ignore } from '@master/annotation/Ignore'
import { MetaClass } from '@master/annotation/MetaClass'
import { ClientInfoBase } from './ClientInfoBase'
import { ClientPersonal } from './ClientPersonal'

export const AccountTypeOptions = [
  { value: '', label: '' },
  { value: 'individual', label: 'Individual' },
  { value: 'corporate', label: 'Corporate' }
]
export const StatusOptions = [
  { value: '', label: '' },
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'Pending' },
  { value: 'terminated', label: 'Terminated' }
]
export const StatusReasonOptionsMapper = {
  active: [
    { value: '', label: '' },
    { value: 'Active', label: 'Active' },
    { value: 'App Converted to Policy', label: 'App Converted to Policy' },
    { value: 'Awaiting Renewal', label: 'Awaiting Renewal' },
    { value: 'Denied', label: 'Denied' },
    { value: 'First Reminder', label: 'First Reminder' },
    { value: 'In force', label: 'In force' },
    { value: 'Issued', label: 'Issued' },
    { value: 'Manually Reviewed', label: 'Manually Reviewed' },
    { value: 'On Claim', label: 'On Claim' },
    { value: 'On Waiver', label: 'On Waiver' },
    { value: 'Review Required', label: 'Review Required' },
    { value: 'Second Reminder', label: 'Second Reminder' },
  ],
  pending: [
    { value: '', label: '' },
    { value: 'App Received', label: 'App Received' },
    { value: 'Contract Underwritten', label: 'Contract Underwritten' },
    { value: 'In Complete', label: 'In Complete' },
    { value: 'In Process', label: 'In Process' },
    { value: 'Payment/Acceptance', label: 'Payment/Acceptance' },
    { value: 'Pending Endorsement', label: 'Pending Endorsement' },
    { value: 'Pending New Business', label: 'Pending New Business' },
    { value: 'Proposal', label: 'Proposal' },
    { value: 'Underwriting/Info', label: 'Underwriting/Info' },
  ],
  terminated: [
    { value: '', label: '' },
    { value: 'Cancel from Inception', label: 'Cancel from Inception' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Death Claim', label: 'Death Claim' },
    { value: 'Deceased', label: 'Deceased' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Dread Disease Claim', label: 'Dread Disease Claim' },
    { value: 'Free look Cancellation', label: 'Free look Cancellation' },
    { value: 'Lapsed', label: 'Lapsed' },
    { value: 'Matured', label: 'Matured' },
    { value: 'Non-Forfeiture Loan Surrender', label: 'Non-Forfeiture Loan Surrender' },
    { value: 'Other Claim', label: 'Other Claim' },
    { value: 'Postponed', label: 'Postponed' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Severe Disability Claim', label: 'Severe Disability Claim' },
    { value: 'Surrendered', label: 'Surrendered' },
    { value: 'Terminal Illness Claim', label: 'Terminal Illness Claim' },
    { value: 'Terminated', label: 'Terminated' },
    { value: 'TPD Claim', label: 'TPD Claim' },
    { value: 'Withdrawn', label: 'Withdrawn' },
  ],
};
export const TitleOptions = [
  { label: '', value: '' },
  { label: 'Dr', value: 'dr' },
  { label: 'Mr', value: 'mr' },
  { label: 'Miss', value: 'miss' },
  { label: 'Mrs', value: 'mrs' },
  { label: 'Mdm', value: 'mdm' }
]
export class ClientProfile extends ClientInfoBase {
  accountType = undefined
  clientOid = undefined
  clientSince = undefined
  mobileNumber = undefined
  countryCode = undefined
  title = undefined
  name = undefined
  nric = undefined
  status = undefined

  @MetaClass(ClientPersonal)
  personalInfoJson = new ClientPersonal()

  casualName = undefined
  printingName = undefined
  remarks = undefined

  isAccreditedInvestor = undefined
  isOverseasInvestor = undefined
  passedCka = undefined

  ckaDate = undefined
  aiDate = undefined
  oiDate = undefined

  ftfDateOfVerification = undefined
  ftfJobRole = undefined
  ftfPerformedBy = undefined
  ftfRemarks = undefined
  hrcRoles = []

  @Ignore()
  get accountTypeDisplay () {
    return AccountTypeOptions.find(ato => ato.value === this.accountType)?.label
  }

  @Ignore()
  get statusDisplay () {
    return StatusOptions.find(so => so.value === this.status)?.label
  }

  convertToRawData () {
    if (!this.personalInfoJson) {
      this.personalInfoJson = new ClientPersonal()
    }
    const raw = super.convertToRawData()
    raw[this.snakeCaseKey('personalInfoJson')] = JSON.stringify(this.personalInfoJson.convertToRawData())
    raw[this.snakeCaseKey('hrcRoles')] = JSON.stringify(this.hrcRoles)
    delete raw[this.snakeCaseKey('clientOid')] // clientOid is not a column
    return raw
  }
}
