// the main difference between Recommendation and Recommendation V2 is V2 involved investment subfunds handling
// it also saved flatAllProducts as client_choice_table so that it can directly use for client choice table stamping

import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { Base } from '../Base'
import { BasicProductV2 as BasicProduct } from '@master/model/kyc-form/product/BasicProductV2'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import InvestmentProduct from './product/InvestmentProduct'
import { isEmpty } from 'lodash/fp'

export default class Recommendation extends Base {
  rcmd: BasicProduct[] = []
  @Ignore()
  riskRatingService: IRiskRatingService

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  @Ignore()
  @Pointer('ROOT.client2.personalDetails', 'name')
  client2Name = ''

  @Ignore()
  @Pointer('ROOT', 'dependants')
  dependants = []

  constructor (riskRatingService: IRiskRatingService) {
    super()
    this.riskRatingService = riskRatingService
  }

  get validBasicProduct () {
    // to check on is client 2 included
    const dependantIncluded = this.dependants.find(dependant => dependant.name === this.client2Name)
    return this.rcmd.filter(prod => {
      if ((!this.hasClient2 && (prod.clientIndex === 1 || prod.jointClientIndex === 1)) ||
      (!this.hasClient2 && !isEmpty(this.client2Name) && (prod.lifeAssured === this.client2Name || prod.lifeAssuredTwo === this.client2Name) && !dependantIncluded)) {
        return false
      }
      return true
    })
  }

  //  flat the rcmd array which has basic insurance product (with nested rider product)
  // and investment subfunds, excluding the investment main account
  get allProducts (): any[] {
    return this.validBasicProduct.flatMap(basicProduct => {
      const investment = this.getInvestmentSubFunds(basicProduct.investmentFunds)
      return [
        basicProduct,
        ...investment
      ]
    }).filter(flatten => flatten.type !== 'investment') // investment here is the main invest account, only the invest sub-funds will take in account
  }

  // to flat all products out [insurance product, rider product, investment subFunds], excluding investment main account
  // use for PTC check, main investment account not counted in PTC
  get flatAllProducts () {
    return this.flatProductWithInvestMainAccount
      .filter(flatten => flatten.type !== 'investment')
  }

  // use in client choice table where it need to display investment main account
  get flatProductWithInvestMainAccount () {
    return this.validBasicProduct.flatMap(basicProduct => {
      const investment = this.getInvestmentSubFunds(basicProduct.investmentFunds)
      const riders = basicProduct.riders
      return [
        basicProduct,
        ...riders,
        ...investment
      ]
    })
  }

  getProductsForClient (clientIndex): any[] {
    return this.flatAllProducts.filter(product => product.clientIndex === clientIndex)
  }

  get hasCustomProduct () {
    return this.allProducts.some(prod => {
      return prod.isCustom
    })
  }

  hasCustomProductForClient (clientIndex) {
    return this.getProductsForClient(clientIndex).some(prod => {
      return prod.isCustom
    })
  }

  afterExtractData (data: { rcmd: any[] }) {
    const rcmd = data.rcmd || []
    data.rcmd = rcmd.map((element) => {
      const basic = new BasicProduct(this.riskRatingService)
      Object.assign(basic, basic.extractData(element))
      return basic
    })

    return data
  }

  // to get back the raw data for saving to backend
  convertToRawData () {
    const rawArray = this.rcmd.map(basicProduct => basicProduct.convertToRawData())
    return {
      rcmd: rawArray,
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_reasons: this.productReasons,
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_limitations: this.productLimitations,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_choice_list: this.flatProductWithInvestMainAccount.map(funds => funds.convertToRawData())
    }
  }

  totalAnnualizedPremiumForClient (clientIndex: 0|1) {
    return this.flatAllProducts.reduce((total, prod: BasicProduct) => {
      const accepted = ['accept', 'ownchoice'].includes(prod.clientChoice)
      if (prod.jointClientIndex && accepted) {
        return total + (prod.annualisedPremium / 2)
      }
      if (clientIndex === prod.clientIndex && accepted) {
        return total + prod.annualisedPremium
      }
      return total
    }, 0)
  }

  totalSinglePremiumForClient (clientIndex: 0|1) {
    return this.flatAllProducts.reduce((total, prod: BasicProduct) => {
      const accepted = ['accept', 'ownchoice'].includes(prod.clientChoice)
      if (prod.jointClientIndex && accepted) {
        return total + (prod.singlePremium / 2)
      }
      if (clientIndex === prod.clientIndex && accepted) {
        return total + prod.singlePremium
      }
      return total
    }, 0)
  }

  get productReasons (): string {
    return this.flatAllProducts.filter(prod => !prod.isCustom).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getReasons()
    }).join('\n\n')
  }

  get productLimitations (): string {
    return this.flatAllProducts.filter(prod => !prod.isCustom).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getLimitations()
    }).join('\n\n')
  }

  get hasNtuc (): boolean {
    return this.rcmd.some(prod => {
      return prod.isNtuc()
    })
  }

  get c1TransactValue () {
    return this.totalAnnualizedPremiumForClient(0) + this.totalSinglePremiumForClient(0)
  }

  get c2TransactValue () {
    return this.totalAnnualizedPremiumForClient(1) + this.totalSinglePremiumForClient(1)
  }

  get c1hasMedical () {
    return this.flatAllProducts.filter((rmd) => {
      return rmd.clientIndex === 0
    }).some((rmd) => {
      const accepted = rmd.isAcceptedProduct
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }

  get c2hasMedical () {
    return this.flatAllProducts.filter((rmd) => {
      return rmd.clientIndex === 1
    }).some((rmd) => {
      const accepted = rmd.isAcceptedProduct
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }

  getInvestmentSubFunds (investMainFund: InvestmentProduct[]) {
    return investMainFund.filter(funds => {
      if (!this.hasClient2 && funds.clientIndex === 1) {
        return false
      }
      return true
    })
  }
}
