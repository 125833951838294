import NeedsBase from '../NeedsBase'

export default class DependantsProtection extends NeedsBase {
  name = 'Dependant\'s Protection'
  needs = 'Dependant Protection'
  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 'p6') {
    super(`${type}_${id}`)
  }
}
