import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ComplianceResponseDto, ComplianceService } from '../api'

@Module({
  namespaced: true,
  name: 'ComplianceStore'
})
export default class ComplianceStore extends VuexModule {
  loading = false
  complianceResult: ComplianceResponseDto = null

  @Action({ rawError: true })
  async policyComplianceCheck (policyOid: string) {
    this.context.commit('setLoading', true)
    try {
      const complianceResult = await ComplianceService.policyComplianceCheck(policyOid)
      this.context.commit('setComplianceResult', complianceResult)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setComplianceResult (result: ComplianceResponseDto) {
    this.complianceResult = result
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
