import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { snakeCase } from 'lodash/fp'
import { Base } from '../../Base'
import { IExistingPortfolio } from './IExistingPortfolio'

// this is a highly specific nested object, with intention to reduce number of first level fields in metaobject
// data preprocessor @ backend will handle the flattening of the nesting
interface Summary {
  [key: string]: number;
}
interface PortfolioSummary {
  c1: Summary;
  c2: Summary;
  others: Summary;
}
export default class ExistingPortfolioSummary extends Base {
  existingPortfolioSummary: PortfolioSummary = { c1: {}, c2: {}, others: {} }

  @Ignore()
  defaultValue = 0

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  @Ignore()
  @Pointer('ROOT.existingPortfolio', 'portfolios')
  portfolios: IExistingPortfolio[] = null

  @Ignore()
  summaryFields: { attrName: string; abbre: string }[]

  constructor (existingPortfolio: IExistingPortfolio) {
    super()
    this.summaryFields = existingPortfolio.getSummaryFields()
    Object.keys(this.existingPortfolioSummary).forEach((role) => {
      this.summaryFields.forEach((field) => {
        this.existingPortfolioSummary[role][field.attrName] = this.defaultValue
      })
    })
  }

  c1PortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => {
        // If portfolio owner is client 2 and client 2 is not included
        // the portfolio should not be included
        if (pf.owner === 'C2' && !this.hasClient2) {
          return false
        }

        // For all other portfolios whose life assured belongs to client 1, include
        // it in portfolio
        return pf.belongsTo('C1')
      })
      // Remove all previously stored values. If there is a need to store previous data,
      // then this logic will need to be reimplemented. The reason why I implement this
      // is because if the company does not need the field to be summary, there is
      // no point of keeping it anymore. Applies for c2 and others portfolio summary.
      this.existingPortfolioSummary.c1 = {}
      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'c1')
      })
      this.calculateApMv('c1')
    }

    return this.existingPortfolioSummary.c1
  }

  c2PortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => {
        // Explicitly do the check that if and only if client 2 is included, then
        // we include in this portfolio, this ensure that the calculation saved
        // to the metaObject is accurate
        return this.hasClient2 && pf.belongsTo('C2')
      })
      this.existingPortfolioSummary.c2 = {}
      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'c2')
      })
      this.calculateApMv('c2')
    }

    return this.existingPortfolioSummary.c2
  }

  othersPortfolioSummary () {
    if (this.portfolios != null) {
      const pfs = this.portfolios.filter(pf => {
        // If client 2 is not included, owner is client 1, life assured is client 2, put it
        // in others
        if (!this.hasClient2 && pf.owner === 'C1' && pf.belongsTo('C2')) {
          return true
        } else if (this.hasClient2 && pf.owner === 'C1' && pf.belongsTo('C2')) {
          // If client 2 is included and owner is client 1, life assured is client 2, do not
          // put in others
          return false
        } else if (!this.hasClient2 && pf.owner === 'C2' && !pf.belongsTo('C1') && !pf.belongsTo('C2')) {
          // if client 2 is not included, and owner is client 2, life assured is other thant client 1 & client 2,
          // do not put in others
          return false
        } else if (!pf.isValid()) {
          return false
        } else if (!pf.belongsTo('C1') && !pf.belongsTo('C2') && pf.isValid()) {
          // If portfolio's life assured is not client 1, include in others
          return true
        }

        return false
      })
      this.existingPortfolioSummary.others = {}
      this.summaryFields.forEach((field) => {
        this.computeSum(pfs, field, 'others')
      })
      this.calculateApMv('others')
    }

    return this.existingPortfolioSummary.others
  }

  calculateApMv (summaryKey: 'c1' | 'c2' | 'others') {
    this.existingPortfolioSummary[summaryKey].premium = 0
    this.portfolios.forEach(pf => {
      const owner = pf.owner?.toLowerCase()
      if (owner === summaryKey) {
        this.existingPortfolioSummary[summaryKey].premium += pf.premium ? Math.round(pf.premium * 100) / 100 : 0
      }
    })
  }

  /**
   * Computes individual summary key's value
   * @param pfs
   * @param key
   * @param summaryKey
   * @returns
   */
  computeSum (pfs, key: { attrName: string; abbre: string }, summaryKey) {
    if (pfs.length === 0) {
      this.existingPortfolioSummary[summaryKey][key.attrName] = 0
      return 0
    }
    const subtotal = pfs.map((cover) => {
      return Number(cover[key.attrName]) || 0
    }).reduce((amt, accum) =>
      amt + accum
    , 0)

    this.existingPortfolioSummary[summaryKey][key.attrName] = (Math.round(subtotal * 100) / 100).toFixed(2)
    return subtotal
  }

  /**
   * This is not a good way of recalculating and populating the result
   * but for now, it works as when the save is on other sections, we
   * ensure that the summary is being calculated properly as well.
   * For example, when the client 2 is checked as not included in the
   * Client Summary section, at least we know that this will be recalculated
   * @param index
   */
  convertToRawData(index?: number);
  convertToRawData () {
    this.c1PortfolioSummary()
    this.c2PortfolioSummary()
    this.othersPortfolioSummary()
    return super.convertToRawData()
  }

  extractData (rawKycForm: any) {
    const data = {}
    Object.keys(this).forEach((key) => {
      const summary = rawKycForm[snakeCase(key)]
      if (summary) {
        Object.keys(summary).forEach((role) => {
          Object.keys(summary[role]).forEach((field) => {
            if (!this.existingPortfolioSummary[role]) {
              this.existingPortfolioSummary[role] = {}
            }
            this.existingPortfolioSummary[role][field] = summary[role][field] ? summary[role][field] : this.defaultValue
          })
        })
      }
    })
    return data
  }
}
