import { IndividualMyinfoDTO } from '../api'

export class IndividualMyinfo implements IndividualMyinfoDTO {
  ownerOid: any;
  nric: any;
  name: any;
  lockedFields: string[];
  timeFetched: number;
  timeLinked: number;
  transactionToken: string;
}
