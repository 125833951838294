import { TypeBase } from '@master/model/TypeBase'
import { get, isUndefined } from 'lodash/fp'

export default class PropertyFinancing extends TypeBase {
  constructor (clientIndex: string) {
    super(`${clientIndex}_prop_financing`)
  }

  // takeFromMyInfo: 'yes' | 'no' = 'yes'
  // addressAsSection1 = ''
  address = ''
  nameOfOwners?: string[] = []
  nameOfOtherOwners?: string = ''
  gazetted = ''
  typeOfProperty = ''
  noOfRoom?: number = 0
  landArea?: number = 0
  builtInArea?: number = 0
  ownershipType = ''
  remainingOfOwnershipYears?: number = 0
  completed = ''
  estimateAgeYears?: number = 0
  topDate?: number = 0
  renovated = ''
  yearOfRenovation?: number = 0
  amountOfRenovation?: number = 0
  purchasePrice?: number = 0
  dateOfPurchase?: number = 0
  transactionType?: string[] = []
  propertyFor?: string[] = []
  existingBank = ''
  monthlyInstalmentTotal?: number = 0 // this is strictly only for myinfo to populate
  monthlyInstalmentCash?: number = 0
  monthlyInstalmentCPF?: number = 0
  originalLoanPeriod?: number = 0
  housingLoanOutstanding?: number = 0
  remainingHousingLoanTermYears?: number = 0
  termLoanOutstanding?: number = 0
  remainingLoanTermYears?: number = 0
  overdraft?: number = 0
  additionalLoan = ''
  additionalLoanAmt?: number = 0

  extractData (propf: any) {
    let data = super.extractData(propf)
    if (isUndefined(get('nameOfOwners', data))) {
      data = Object.assign(data, { nameOfOwners: [] })
    }
    if (isUndefined(get('transactionType', data))) {
      data = Object.assign(data, { transactionType: [] })
    }
    if (isUndefined(get('propertyFor', data))) {
      data = Object.assign(data, { propertyFor: [] })
    }
    return data
  }
}
