import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import ManagerEndorsement from '@master/model/kyc-pdf/ManagerEndorsement'
import { isEmpty } from 'lodash/fp'
import CCBSCustom from './CCBSCustom'

export default class CustomManagerEndorsement extends ManagerEndorsement {
  get isAdviserCoachedFieldsFilled () {
    return this.farepCoached && !isEmpty(this.remarks)
  }

  @Ignore()
  @Pointer('ROOT', 'ccbs')
  private ccbs: CCBSCustom = null

  @Ignore()
  get isSelectedRepClient () {
    return this.ccbs.repClient === 'yes'
  }

  isAllValid () {
    let valid = true
    Object.keys(this).forEach(val => {
      if (val === 'complete') return
      if (this.agree === 'agree' && val === 'elaborateReasons') return
      if (val === 'farepCoached' || val === 'remarks') return
      if (!this.isSelectedRepClient && val === 'present') return
      if (this[val] === '' || !this[val]) valid = false
    })
    return valid
  }
}
