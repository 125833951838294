import ExistingInvestSummary from '@master/model/kyc-form/existing-plans/ExistingInvestSummary'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { IExistingInvestment } from '@master/model/kyc-form/existing-plans/IExistingInvestment'

/**
 * The ROOT here is referring to ExistingInvestmentDTO, the reason why this is setup is because
 * 1) KYCForm.ts `setData` function will only setup ExistingInvestmentDTO (as it extends Base)
 * 2) ExistingInvestmentDTO will be responsible to setup both the declaration and summary
 *
 * This means that whatever this class needs for Pointer, the parent (ExistingInvestmentDTO) needs
 * to help setup in the parent class.
 *
 * This is a convoluted way of doing, but it solves the problem right now.
 *
 * Ideal way: The KycForm setData function is able to help setup child properties recursively even though
 * the parent extends from Base.
 */
export default class ExistingInvestSummaryV2 extends ExistingInvestSummary {
  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  @Ignore()
  @Pointer('ROOT', 'portfolios')
  portfolios: IExistingInvestment[] = null
}
