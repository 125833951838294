import { RouteRecordRaw } from 'vue-router'

export const scheduleRoutes = ((): RouteRecordRaw[] => {
  return [
    {
      path: '/scheduler',
      name: 'Schedule Tasks',
      component: () => import('@core/pages/scheduler/page/SchedulerPage.vue')
    }
  ]
})()
