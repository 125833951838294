/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDTO } from '../models/DocumentDTO';
import type { KycFormPdfDTO } from '../models/KycFormPdfDTO';
import { request as __request } from '@openapi/request';

export class KycDocUploadService {

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getAllDocumentFromOid(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kyc-doc-upload`,
            query: {
                'kycFormOid': kycFormOid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addDocument(
        requestBody: DocumentDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kyc-doc-upload`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async mergeAppendix(
        requestBody: KycFormPdfDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kyc-doc-upload/mergeAppendix`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteDocument(
        kycFormOid: string,
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/kyc-doc-upload/${kycFormOid}/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async getPdf(
        kycFormOid: string,
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kyc-doc-upload/${kycFormOid}/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async getMergedAppendix(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kyc-doc-upload/appendix/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async isAppendixFileExist(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kyc-doc-upload/appendix`,
            query: {
                'kycPdfOid': kycPdfOid,
            },
        });
        return result.body;
    }

}