import { ClientBase } from '../ClientBase'
import BeneficialOwnerParticulars, { IBOP } from './external/BeneficialOwnerParticulars'

export default class BeneficialOwner extends ClientBase {
  declarationBeneficialOwner = ''
  beneficialOwnerParticulars?: IBOP = undefined

  /**
   * @param type
   * @param bop - if not defined, it will take core's BOP
   */
  constructor (type: 'client_0' | 'client_1', bop: IBOP = new BeneficialOwnerParticulars()) {
    super(type)
    this.beneficialOwnerParticulars = bop
  }

  beforeExtractData (data: any) {
    // get from raw data
    const bopRaw = data[this.type + '_beneficial_owner_particulars']
    Object.assign(this.beneficialOwnerParticulars, this.beneficialOwnerParticulars.extractData(bopRaw || {}))
    return data
  }

  convertToRawData () {
    const result: any = super.convertToRawData()
    // As beneficial owner particular is a Base, we need to add them in manually
    if (this.beneficialOwnerParticulars) {
      result[this.type + '_beneficial_owner_particulars'] = this.beneficialOwnerParticulars.convertToRawData()
    }
    return result
  }
}
