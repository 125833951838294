/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyInformation = {
    userOid?: string;
    corporateEmail: string;
    paymentStatus?: string;
    companyCode: string;
    masRepNo?: string;
    masApplicationDate?: number;
    masYearlyRenewalDate?: number;
    masTriYearlyRenewalDate?: number;
    closeMonitoringFromDate?: number;
    closemonitoringToDate?: number;
    remark?: string;
    suspendedFromDate?: number;
    suspendedToDate?: number;
    requirementCheckList?: any;
    contractType?: any;
    ifpasNo?: string;
    contractDetails?: any;
    contractedFromDate?: number;
    terminatedDate?: number;
    issuedDate?: number;
    lastPromotionDate?: number;
    withExperience?: string;
    personalItemsIssued?: any;
    channel?: CompanyInformation.channel;
    isChannelVisible?: boolean;
}

export namespace CompanyInformation {

    export enum channel {
        SG = 'SG',
        PS = 'PS',
        PFP = 'PFP',
    }


}