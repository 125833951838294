import CoreAdviserDeclaration from '@master/model/kyc-form/AdviserDeclaration'

export default class AdviserDeclaration extends CoreAdviserDeclaration {
  clientInformation?: boolean = false
  clientMeetingDate?: number = undefined
  clientMeetingTime = ''
  clientMeetingVenue: number = undefined
  clientMeetingVenueSpecify = ''

  statements?: boolean = false
  jfwConsultantName = ''
  jfwConsultantComment = ''
  recommendation = '' // previously was declaration_recommendation_by_fc
  acknowledgement = '' // previously was declaration_acknowledgement_by_fc
  acknowledgementNotes = '' // previously was declaration_acknowledgement_fc_notes
  cashRebate = '' // Specific to JPara Changes
  monthlyAllowance = '' // Specific to JPara Changes
  haveJointFieldWork?: boolean = undefined // Specific to JPara Changes
  financialConsultantName = '' // Specific to JPara Changes
  financialConsultantMasNo = '' // Specific to JPara Changes
  financialConsultantComment = '' // Specific to JPara Changes

  constructor (type: 'fc_decl' = 'fc_decl') {
    super(type)
  }
}
