import { FormBase } from '../FormBase'
import { DeclarationOptions } from './GeneralDeclaration'

export class MediShieldLifeTest {
  passed: typeof DeclarationOptions[number]['value'] = undefined
  date: number = undefined
  insurer = ''
  failedReason = ''
}
export class BDMAssessmentFields {
  referrer: typeof DeclarationOptions[number]['value'] = undefined
  referrerName = ''
  referrerRepCode = ''
  taScheme: typeof DeclarationOptions[number]['value'] = undefined
  taSchemeType = ''

  briefedNoSales: typeof DeclarationOptions[number]['value'] = undefined
  briefedTerminateExistingAgency: typeof DeclarationOptions[number]['value'] = undefined

  bandingAgree: typeof DeclarationOptions[number]['value'] = undefined
  bandingFinalProposal = ''

  employmentEngaged: typeof DeclarationOptions[number]['value'] = undefined
  employmentDetails = ''

  prohibitedActivity: typeof DeclarationOptions[number]['value'] = undefined
  rockwillsIntroducer: typeof DeclarationOptions[number]['value'] = undefined
  mediShieldLifeTest: MediShieldLifeTest = new MediShieldLifeTest()
  declaredBi: typeof DeclarationOptions[number]['value'] = undefined
  medisaveOs: typeof DeclarationOptions[number]['value'] = undefined
  criminalRecord: typeof DeclarationOptions[number]['value'] = undefined
  warningLetter: typeof DeclarationOptions[number]['value'] = undefined
  declaredOther: typeof DeclarationOptions[number]['value'] = undefined // but no

  exceedSoc: typeof DeclarationOptions[number]['value'] = undefined
  socDeviation = '' // included period of deviation
}
export class BDMAssessment extends FormBase {
  // shared.name
  // shared.rm

  type = 'bdm-assessment'
  data: BDMAssessmentFields = new BDMAssessmentFields()
}
