/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Buffer } from '../models/Buffer';
import { request as __request } from '@openapi/request';

export class KycPdfService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async createKycPdfEntry(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/createKycPdfEntry`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @param type
     * @returns any
     * @throws ApiError
     */
    public static async emailNotificationHandler(
        oid: string,
        type: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/emailNotice`,
            query: {
                '_oid': oid,
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async supervisorEmailWarning(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/supervisorEmailWarning`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async emailNoticeUsingFaPortal(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/emailNotice`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async fetchGeneratedPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async generatePdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/generate`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async previewPdf(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/preview/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async stampedPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/stamped`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async splitPdf(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/split`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @param mergedFile
     * @returns any
     * @throws ApiError
     */
    public static async getSignaturePosition(
        oid: string,
        mergedFile: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/kycPdf/signaturePosition`,
            query: {
                '_oid': oid,
                'mergedFile': mergedFile,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async setCustomProperties(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/kycPdf/setProperties`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async fetchGeneratedPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async generatePdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/generate`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async generatePdfV3(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/kycPdf/generate`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns Buffer
     * @throws ApiError
     */
    public static async previewPdfV2(
        kycFormOid: string,
    ): Promise<Array<Buffer>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/preview/${kycFormOid}`,
            errors: {
                400: `An Object that contain the error message`,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async stampedPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/stamped`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async splitPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/split`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @param mergedFile
     * @returns any
     * @throws ApiError
     */
    public static async getSignaturePositionV2(
        oid: string,
        mergedFile: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/signaturePosition`,
            query: {
                '_oid': oid,
                'mergedFile': mergedFile,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async fetchManagerPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/manager`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async generateAndSignManagerPageV2(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/manager/stampedSignature/${kycPdfOid}`,
            query: {
                'kycPdfOid': kycPdfOid,
            },
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async stitchManagerPdfToKycPdf(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/manager/stitch/${kycPdfOid}`,
            query: {
                'kycPdfOid': kycPdfOid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async fetchEcddPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/ecdd`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async generateEcddPdfV2(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycPdf/ecdd/generate`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async stampedEcddSignatureV2(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/ecdd/stampedSignature/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @returns any
     * @throws ApiError
     */
    public static async stampedManagerToEcddV2(
        kycPdfOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/ecdd/stampedManagerSignature/${kycPdfOid}`,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid
     * @param targetFile
     * @returns any
     * @throws ApiError
     */
    public static async replaceEcddToKycPdf(
        kycPdfOid: string,
        targetFile: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycPdf/ecdd/stitch/${kycPdfOid}`,
            query: {
                'kycPdfOid': kycPdfOid,
                'targetFile': targetFile,
            },
        });
        return result.body;
    }

}