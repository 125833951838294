import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = { class: "menu-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { id: "nav-list" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onClick", "target"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["href", "target"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 2,
  class: "dropdown"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = { slot: "body" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { slot: "body" }
const _hoisted_16 = { slot: "title" }
const _hoisted_17 = ["src"]
const _hoisted_18 = ["href"]
const _hoisted_19 = ["onClick", "target"]
const _hoisted_20 = ["src"]
const _hoisted_21 = ["href", "target"]
const _hoisted_22 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDialog = _resolveComponent("AppDialog")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_submenu = _resolveComponent("el-submenu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "logo",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowMenu = true))
      }, [
        _withDirectives(_createElementVNode("img", {
          src: _ctx.menuIcon,
          alt: "svg image"
        }, null, 8, _hoisted_3), [
          [_directive_svg_inline]
        ])
      ])
    ]),
    _createElementVNode("nav", null, [
      _createElementVNode("ul", _hoisted_4, [
        (_ctx.enableVirtualMeeting)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startVirtualMeeting && _ctx.startVirtualMeeting(...args)))
              }, [
                _withDirectives(_createElementVNode("img", {
                  src: _ctx.videocamIcon,
                  alt: "svg image"
                }, null, 8, _hoisted_6), [
                  [_directive_svg_inline]
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("p", null, "Start Virtual Meeting", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, ({ disable, event, href, target, icon, label, dropdown }, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (!disable)
              ? (_openBlock(), _createElementBlock("li", { key: index }, [
                  event
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.$emit(event)),
                        target: target
                      }, [
                        _withDirectives(_createElementVNode("img", {
                          src: icon,
                          alt: "svg image"
                        }, null, 8, _hoisted_8), [
                          [_directive_svg_inline]
                        ]),
                        _createElementVNode("p", null, _toDisplayString(label), 1)
                      ], 8, _hoisted_7))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: href,
                        target: target
                      }, [
                        _withDirectives(_createElementVNode("img", {
                          src: icon,
                          alt: "svg image"
                        }, null, 8, _hoisted_10), [
                          [_directive_svg_inline]
                        ]),
                        _createElementVNode("p", null, _toDisplayString(label), 1)
                      ], 8, _hoisted_9)),
                  (dropdown && dropdown.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdown, (item, i) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (!item.disable)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: i,
                                  href: item.href,
                                  class: "dropdown-item"
                                }, _toDisplayString(item.label), 9, _hoisted_12))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 256))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ])
    ]),
    (_ctx.enableVirtualMeeting)
      ? (_openBlock(), _createBlock(_component_AppDialog, {
          key: 0,
          class: "virtual-meeting-url",
          onClosed: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isCopied = false)),
          hideFooter: true
        }, {
          header: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Virtual Meeting URL")
          ])),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", null, [
                _cache[9] || (_cache[9] = _createTextVNode("Click on the URL to copy: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.isCopied ? 'Copied!' : ''), 1)
              ]),
              _createElementVNode("div", {
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.copyUrl && _ctx.copyUrl(...args))),
                class: "jitsi-url"
              }, [
                _createElementVNode("input", {
                  id: "url",
                  value: _ctx.jitsiUrl,
                  readonly: ""
                }, null, 8, _hoisted_14)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isShowMenu)
      ? (_openBlock(), _createBlock(_component_AppDialog, {
          key: 1,
          onClosed: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isShowMenu = false)),
          class: "menu-dialog",
          size: 'large',
          hideFooter: true
        }, {
          header: _withCtx(() => [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args))),
              width: "200px",
              class: "logo"
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_el_menu, {
                class: "custom-menu",
                router: true,
                "unique-opened": true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, ({ disable, event, href, target, icon, label, dropdown }, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (!disable)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (dropdown && dropdown.length > 0)
                              ? (_openBlock(), _createBlock(_component_el_submenu, {
                                  key: index,
                                  index: label
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("template", _hoisted_16, [
                                      _withDirectives(_createElementVNode("img", {
                                        src: icon,
                                        alt: "svg image"
                                      }, null, 8, _hoisted_17), [
                                        [_directive_svg_inline]
                                      ]),
                                      _createElementVNode("span", null, _toDisplayString(label), 1)
                                    ]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdown, (item, i) => {
                                      return (_openBlock(), _createBlock(_component_el_menu_item, {
                                        key: i,
                                        index: item.label
                                      }, {
                                        default: _withCtx(() => [
                                          (!item.disable)
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: i,
                                                href: item.href,
                                                class: "dropdown-item"
                                              }, _toDisplayString(item.label), 9, _hoisted_18))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["index"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["index"]))
                              : event
                                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                    key: index+1,
                                    index: label
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("a", {
                                        onClick: ($event: any) => (_ctx.$emit(event)),
                                        target: target
                                      }, [
                                        _withDirectives(_createElementVNode("img", {
                                          src: icon,
                                          alt: "svg image"
                                        }, null, 8, _hoisted_20), [
                                          [_directive_svg_inline]
                                        ]),
                                        _createElementVNode("span", null, _toDisplayString(label), 1)
                                      ], 8, _hoisted_19)
                                    ]),
                                    _: 2
                                  }, 1032, ["index"]))
                                : (_openBlock(), _createBlock(_component_el_menu_item, {
                                    key: index+2,
                                    index: label
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("a", {
                                        href: href,
                                        target: target
                                      }, [
                                        _withDirectives(_createElementVNode("img", {
                                          src: icon,
                                          alt: "svg image"
                                        }, null, 8, _hoisted_22), [
                                          [_directive_svg_inline]
                                        ]),
                                        _createElementVNode("span", null, _toDisplayString(label), 1)
                                      ], 8, _hoisted_21)
                                    ]),
                                    _: 2
                                  }, 1032, ["index"]))
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}