/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdhocReportRequest = {
    typeOfReport: AdhocReportRequest.typeOfReport;
    fromTimestamp?: number;
    toTimestamp?: number;
}

export namespace AdhocReportRequest {

    export enum typeOfReport {
        COMMISSION_REPORT = 'COMMISSION_REPORT',
        ADVISER_NAME_SCREENING = 'ADVISER_NAME_SCREENING',
        CLIENT_NAME_SCREENING = 'CLIENT_NAME_SCREENING',
        CORPORATE_NAME_SCREENING = 'CORPORATE_NAME_SCREENING',
        PRESALES_CALLBACK = 'PRESALES_CALLBACK',
        MARKETING_CONSENT = 'MARKETING_CONSENT',
    }


}
