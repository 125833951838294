/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Object } from '../models/Object';
import { request as __request } from '@openapi/request';

export class KycFormService {

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async getKycForm(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/kycForm/meta/${kycFormOid}`,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async postKycFormList(
        kycFormOid: string,
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/meta/${kycFormOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async getKycFormList(
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/kycForm/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycFormOid
     * @returns any
     * @throws ApiError
     */
    public static async deleteKycForm(
        kycFormOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/kycForm/${kycFormOid}`,
        });
        return result.body;
    }

}