import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CommissionRecordsService } from "../../CommissionModule/api";
import { CommissionStatementDTO } from "../../CommissionModule/api";
@Module({
  namespaced: true,
  name: "StatementGeneratorStore",
})
export default class StatementGeneratorStore extends VuexModule {
  statementRecordLogs = [];
  statementLogsCount = 0;
  publishStatementRecord = [];
  publishStatementRecordCount = 0;
  exportCsvData = null;
  successResponse = "";

  @Action
  async retrieveStatementLogs(params) {
    const {page, limit, searchField, searchFieldValue} = params
    try {
    const result = await CommissionRecordsService.getReportLog(
      limit,
      page,
      searchField,
      searchFieldValue
    );
    this.context.commit("setStatementLogs", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setStatementLogs(result) {
    this.statementRecordLogs = result.data;
    this.statementLogsCount = result.recordsTotal;
  }

  @Action
  async retrievePublishStatementReport(payload) {
    try {
    this.context.commit("setPublishStatementReports", null)
    // TODO: openapi error fixes need to pass dynanic value for pagination
    const result = await CommissionRecordsService.getReportListByBatchId(payload.batchId, payload.limit, payload.page);
    this.context.commit("setPublishStatementReports", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setPublishStatementReports(result) {
    this.publishStatementRecord = result?.data || [];
    this.publishStatementRecordCount = result?.recordsTotal || 0;
  }

  @Action
  async exportStatementLogCSV() {
    const csv = await CommissionRecordsService.exportCsvReportLog()
    return csv
  }

  @Action
  async exportPublishStatementCSV(batchId: number) {
    const csv = await CommissionRecordsService.csvExportReportListByBatchId(batchId)
    return csv
  }
  @Action
  async generateCommissionZipFile(batchId:number) {
    try {
      await CommissionRecordsService.exportZip(batchId)
    } catch (error) {
      
    }
  }
}
