import { FormattedKYCForm } from './formattedKYCForm'
import { Base } from '@master/model/Base'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import Recommendation from './CustomRecommendation'
import MasterAML from '@master/model/kyc-form/AML'

// JPara specific
export default class AML extends MasterAML {
  @Ignore()
  customType = ''

  adverseDBChecks = ''
  redFlag = ''
  sanctioned = ''
  financialFlowHighRisk = ''
  pepAssociated = ''
  // transactValueBelowThreshold = '' // set by custom pointer
  // applyMedicalProduct = '' // set by custom pointer

  dateOfAssessment: string | number = ''

  @Ignore()
  @Pointer('ROOT', 'recommendations')
  rcmd: Recommendation = null

  @Ignore()
  @Pointer('personalDetails', 'isSingaporean')
  isSingaporean = 'no'

  @Ignore()
  @Pointer('personalDetails', 'isHighRiskPR')
  isHighRiskPR = 'no'

  @Ignore()
  @Pointer('personalDetails', 'isHighRiskForeign')
  isHighRiskForeign = 'no'

  @Ignore()
  transactThreshold = 20000

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super()
    this.customType = type + '_aml'
  }

  // custom method to extract since aml is a single object packed with the properties
  extractData (rawKycForm: FormattedKYCForm) {
    return super.extractData(rawKycForm[this.customType] ?? rawKycForm)
  }

  afterExtractData (data: any) {
    // Object.defineProperty(this, 'applyMedicalProduct', {
    //   get: () => {
    //     // console.log('[debug] ', this.rcmd)
    //     // console.log('[debug] call c{n}hasMedical')
    //     return this.customType === 'client_0_aml' ? this.rcmd.c1hasMedical : this.rcmd.c2hasMedical
    //   }
    // })
    // Object.defineProperty(this, 'transactValueBelowThreshold', {
    //   get: () => {
    //     const tv = this.customType === 'client_0_aml'
    //       ? this.rcmd.c1TransactValue
    //       : this.rcmd.c2TransactValue
    //     return tv <= this.transactThreshold ? 'yes' : 'no'
    //   }
    // })
    // console.log(this.rcmd.c1hasMedical, this.rcmd.c2hasMedical)
    return data
  }

  get _applyMedicalProduct () {
    return this.customType === 'client_0_aml' ? this.rcmd.c1hasMedical : this.rcmd.c2hasMedical
  }

  get _transactValueBelowThreshold () {
    const tv = this.customType === 'client_0_aml'
      ? this.rcmd?.c1TransactValue
      : this.rcmd?.c2TransactValue
    return tv <= this.transactThreshold ? 'yes' : 'no'
  }

  convertToRawData () {
    this.dateOfAssessment = Date.now()
    const results = super.convertToRawData()
    // console.log('aml - convert', results)
    return {
      [this.customType]: results
    }
  }

  get isHighRisk () {
    return Object.keys(this).some(key => {
      return ['adverseDBChecks', 'redFlag', 'sanctioned', 'financialFlowHighRisk', 'pepAssociated'].includes(key) && this[key] === 'yes'
    })
  }

  get isMediumRisk () {
    const keys = [
      'isSingaporean',
      'isHighRiskPR',
      'isHighRiskForeign',
      'applyMedicalProduct',
      'transactValueBelowThreshold',
      'pepAssociated',
      'financialFlowHighRisk',
      'sanctioned',
      'adverseDBChecks',
      'redFlag'
    ]
    return keys.every(key => this[key] === 'no')
  }

  get _riskRating () {
    const highRisk = this.isHighRisk
    if (highRisk) return 'HIGH'
    const medRisk = this.isHighRiskPR === 'yes' || this.isHighRiskForeign === 'yes' || this.isMediumRisk
    if (medRisk) return 'MEDIUM'
    const lowRisk = this._transactValueBelowThreshold === 'yes' || this._applyMedicalProduct === 'yes' || this.isSingaporean === 'yes'
    if (lowRisk) return 'LOW'
    return ''
  }
}
