/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateExamRecordDto = {
    oid: string;
    status?: UpdateExamRecordDto.status;
    accountOid?: any;
    date?: any;
}

export namespace UpdateExamRecordDto {

    export enum status {
        PENDING = 'PENDING',
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        EXEMPTED = 'EXEMPTED',
    }


}
