import { round, sum } from '@master/model/kyc-form/need-analysis/math'
import { ClientInfoBase } from './ClientInfoBase'

export const InsuranceClassOptions = [
  { value: '', label: '' },
  { value: 'allriskinsurance', label: 'All Risk Insurance' },
  { value: 'boilerinsurance', label: 'Boiler Insurance' },
  { value: 'burglary', label: 'Burglary' },
  { value: 'consequentiallossinsurance', label: 'Consequential Loss Insurance' },
  { value: 'contractorallrisksandthirdpartyliability', label: 'Contractor All Risks and Third Party Liability' },
  { value: 'criticalillness', label: 'Critical Illness' },
  { value: 'disabilityincome', label: 'Disability Income' },
  { value: 'electronicequipmentinsurance', label: 'Electronic Equipment Insurance' },
  { value: 'fidelityguaranteeinsurance', label: 'Fidelity Guarantee Insurance' },
  { value: 'fireextraneousperilsinsurance', label: 'Fire Extraneous Perils Insurance' },
  { value: 'goodsintransitinsurance', label: 'Goods In Transit Insurance' },
  { value: 'hospitalincome', label: 'Hospital Income' },
  { value: 'industrialallrisksinsurance', label: 'Industrial All Risks Insurance' },
  { value: 'inlandfloater', label: 'Inland Floater' },
  { value: 'marinecargoopencover', label: 'Marine Cargo Open Cover' },
  { value: 'marinehullinsurance', label: 'Marine Hull Insurance' },
  { value: 'medical', label: 'Medical' },
  { value: 'medicalinsurance', label: 'Medical Insurance' },
  { value: 'moneyinsurance', label: 'Money Insurance' },
  { value: 'motorinsurance', label: 'Motor Insurance' },
  { value: 'motorinsurance-commercialvehicle', label: 'Motor Insurance - Commercial Vehicle' },
  { value: 'motorinsurance-privatecar', label: 'Motor Insurance - Private Car' },
  { value: 'package', label: 'Package' },
  { value: 'performancebond', label: 'Performance Bond' },
  { value: 'personalaccident', label: 'Personal Accident' },
  { value: 'productliability', label: 'Product Liability' },
  { value: 'plateglassinsurance', label: 'Plate Glass Insurance' },
  { value: 'publicproductliabilityinsurance', label: 'Public Product Liability Insurance' },
  { value: 'publicliabilityinsurance', label: 'Public Liability Insurance' },
  { value: 'termlife-rop', label: 'Term Life - ROP' },
  { value: 'travelinsurance', label: 'Travel Insurance' },
  { value: 'warehousemanslegalliability', label: 'Warehouseman\'s Legal Liability' },
  { value: 'workmanscompensationinsurance', label: 'Workman\'s Compensation Insurance' }
]

export const ShoreOptions = [
  { label: 'Onshore', value: 'onshore' },
  { label: 'Offshore', value: 'offshore' }
]
export class ProductMetaData extends ClientInfoBase {
  sumAssured = undefined
  currency = undefined
  frequency = undefined
  prematureTermination = undefined

  grossPremium = undefined
  gst = undefined

  get netPremium () {
    const gstNumber = Number(this.gst)
    const gstAmt = round(this.grossPremium * (gstNumber / (100 + gstNumber)), 2)
    return round(sum(this.grossPremium, -gstAmt), 2)
  }
}
