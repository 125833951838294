/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComplianceResponseDto } from '../models/ComplianceResponseDto';
import type { NameScreeningResponseDto } from '../models/NameScreeningResponseDto';
import { request as __request } from '@openapi/request';

export class ComplianceService {

    /**
     * @param policyOid
     * @returns ComplianceResponseDto
     * @throws ApiError
     */
    public static async policyComplianceCheck(
        policyOid: string,
    ): Promise<ComplianceResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/compliance/policy/${policyOid}`,
        });
        return result.body;
    }

    /**
     * @param policyOid
     * @returns NameScreeningResponseDto
     * @throws ApiError
     */
    public static async nameScreeningCheck(
        policyOid: string,
    ): Promise<NameScreeningResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/compliance/policy/${policyOid}/name-screening`,
        });
        return result.body;
    }

}