import { ProductMetaData } from '../ProductMetaData'

export const ServiceTypeOptions = [
  { value: '', label: '' },
  { value: 'wills', label: 'Wills' },
  { value: 'financialplanning', label: 'Financial Planning' },
  { value: 'mortgageloan', label: 'Mortgage Loan' },
  { value: 'businessfinancing', label: 'Business Financing' },
  { value: 'businessconsulting', label: 'Business Consulting' },
  { value: 'trustplanning', label: 'Trust Planning' },
  { value: 'estateplanning', label: 'Estate Planning' },
  { value: 'others', label: 'Others' }
]
export class FBS extends ProductMetaData {
  nextReviewDate = undefined
  feesReceivable = undefined
  typeOfService = undefined
  remarks = undefined
}
