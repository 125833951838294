import { FormBase } from '../FormBase'

export class PrevEmploymentInquiry extends FormBase {
  // shared.name
  // shared.nric
  type = 'prev-employment-inquiry'

  data = {
    agree: false
  }
}
