import { ClientProductILPRP } from '../ClientProductILPRP'
import { ClientProductILPSP } from '../ClientProductILPSP'
import { ClientProductRider } from '../ClientProductRider'
import { ProductMetaData } from '../ProductMetaData'

export const OccupationalRatingOptions = [
  { label: 'NA', value: 'na' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' }
]
export const UnderwritingDecisionOptions = [
  { label: '', value: '' },
  { label: 'Approved as Standard', value: 'approvedasstandard' },
  { label: 'Others', value: 'others' }
]
export class LifeInsurance extends ProductMetaData {
  // life insurance
  sumAssured = undefined
  currency = undefined
  basicModalPremium = undefined
  frequency = undefined
  asp = undefined
  expiryAge = undefined
  issueAge = undefined
  height = undefined
  weight = undefined
  smoker = undefined
  occupationalRating = undefined
  medicalCondition = undefined
  medicalConditionReasons = undefined
  beneficiary = undefined
  underwritingDecision = undefined
  underinsuredUnderwritingDecisionRemarks = undefined
  prematureTermination = undefined
  riders: ClientProductRider[] = []
  ilpsp: ClientProductILPSP[] = []
  ilprp: ClientProductILPRP[] = []

  extractAndAssignData (data) {
    super.extractAndAssignData(data)
    this.riders = []
    let i = 1
    while (`rider_${i}_name` in data) {
      const rider = new ClientProductRider(i)
      Object.assign(rider, rider.extractData(data))
      this.riders.push(rider)
      i++
    }
    this.ilpsp = []
    i = 1
    while (`ilp_${i}_single_name` in data) {
      const ilp = new ClientProductILPSP(i)
      Object.assign(ilp, ilp.extractData(data))
      this.ilpsp.push(ilp)
      i++
    }
    this.ilprp = []
    i = 1
    while (`ilp_${i}_regular_name` in data) {
      const ilp = new ClientProductILPRP(i)
      Object.assign(ilp, ilp.extractData(data))
      this.ilprp.push(ilp)
      i++
    }
    // old data compatible
    if (!this.occupationalRating) this.occupationalRating = data.ccupational_rating
  }

  convertToRawData () {
    const raw = super.convertToRawData()
    raw.riders.forEach((rider) => {
      Object.assign(raw, rider.convertToRawData())
    })
    raw.ilpsp.forEach((ilp) => {
      console.log(ilp)
      Object.assign(raw, ilp.convertToRawData())
    })
    raw.ilprp.forEach((ilp) => {
      console.log(ilp)
      Object.assign(raw, ilp.convertToRawData())
    })
    delete raw.riders
    delete raw.ilpsp
    delete raw.ilprp
    return raw
  }

  get modalPremium () {
    return this.basicModalPremium + this.riders.map(rider => rider.premium).reduce((rider, sum) => {
      sum += rider
      return sum
    }, 0)
  }
}
