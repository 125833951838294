import { FormBase } from '../FormBase'

export class CPFAuthorization extends FormBase {
  // shared.name
  // shared.nric
  // shared.repCode
  type = 'cpf-authorization'
  data = {
    agree: false
  }
}
