/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyDocumentUploadDto } from '../models/PolicyDocumentUploadDto';
import { request as __request } from '@openapi/request';

export class PolicyDocumentService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getPolicyDocumentsList(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/policy_documents/list`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getTaggedKycDocuments(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/get_tagged_kyc_documents`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async uploadPolicyDocument(
        requestBody: PolicyDocumentUploadDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/policy_document/upload`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async getPolicyDocuments(
        policy_oid?: string
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/policy_document/list`,
            body: {policyOid: policy_oid , limit: 1000}
        });
        return result.body;
    }

}