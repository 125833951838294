import { ArrayBase } from '../ArrayBase'

export default class ILPPortfolio extends ArrayBase {
  type = 'ilp portfolio'
  startIndex = 1
  buySwitch = ''
  choice = ''
  for = ''
  frequency = ''
  fund1 = undefined
  fund2 = undefined
  fund3 = undefined
  fund4 = undefined
  fund5 = undefined
  fund6 = undefined
  fund7 = undefined
  fund8 = undefined
  inv1 = undefined
  inv2 = undefined
  inv3 = undefined
  inv4 = undefined
  inv5 = undefined
  inv6 = undefined
  inv7 = undefined
  inv8 = undefined
  name = ''
  platform = ''
  accountNumber = ''

  clearData () {
    const excludeKeys = ['type', 'startIndex', 'curIndex']
    Object.keys(this).forEach(key => {
      if (!excludeKeys.includes(key)) {
        // need assign value as empty string to overwrite the data that is stored in database,
        this[key] = ''
      }
    })
  }
}
