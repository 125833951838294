/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDependantDTO } from '../models/ClientDependantDTO';
import { request as __request } from '@openapi/request';

export class ClientDependantsService {

    /**
     * @param clientOid
     * @returns any
     * @throws ApiError
     */
    public static async getDependantRelationshpList(
        clientOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/client_dependants/${clientOid}`,
        });
        return result.body;
    }

    /**
     * @param childClientOid
     * @returns any
     * @throws ApiError
     */
    public static async getParentRelationshipList(
        childClientOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/client_dependants/parent/${childClientOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ClientDependantDTO
     * @throws ApiError
     */
    public static async saveNewDependantRelationship(
        requestBody: ClientDependantDTO,
    ): Promise<ClientDependantDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/client_dependants/save_new_dependant`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateDependantRelationship(
        requestBody: ClientDependantDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/client_dependants`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param childClientOid
     * @returns any
     * @throws ApiError
     */
    public static async removeDependant(
        childClientOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/client_dependants/${childClientOid}`,
        });
        return result.body;
    }

}