/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportLogDTO = {
    pky: number;
    ctm: number;
    utm: number;
    statementRunNumber: string;
    batch: string;
    runTimestamp: number;
    fromTimestamp: number;
    toTimestamp: number;
    status: ReportLogDTO.status;
    numberProcessed: number;
}

export namespace ReportLogDTO {

    export enum status {
        INCOMPLETE = 'INCOMPLETE',
        COMPLETE = 'COMPLETE',
        ERROR = 'ERROR',
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}