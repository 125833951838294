import { Module, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export default class KYCPdfSectionStore extends VuexModule {
  sections: { name: string; url?: string; completed?: boolean; allowed?: boolean; subs?: any[] }[] = [
    { name: 'CKA', url: 'cka', completed: false, allowed: true },
    { name: 'Insurer PDF', url: 'insurer', completed: false, allowed: true },
    { name: 'Pre-Transaction Alerts', url: 'ptc', completed: false, allowed: true },
    { name: 'E-Submission', url: 'esub', completed: false, allowed: true },
    { name: 'ECDD 2', url: 'ecdd/2', completed: false, allowed: true },
    { name: 'ECDD 1', url: 'ecdd/1', completed: false, allowed: true },
    { name: 'Beneficial Owner Client 2', url: 'bo/2', completed: false, allowed: true },
    { name: 'Beneficial Owner Client 1', url: 'bo/1', completed: false, allowed: true },
    { name: 'PEP', url: 'pep', completed: false, allowed: true },
    { name: 'Client\'s Pdf', url: 'client', completed: false, allowed: true },
    { name: 'Manager Endorsement', url: 'manager-endorsement', completed: false, allowed: true }
  ]
}
