import { sortBy } from 'lodash/fp'
import { MetaList } from './MetaList'
import { ProductMeta, ProductHeaders } from './ProductMeta'

export class ProductMetaList<T extends ProductMeta = ProductMeta> extends MetaList<T> {
  private riderList: T[]
  private insuranceProductList: T[]
  private insuranceIssuer: string[]
  private investmentProductList: T[]
  private investmentIssuer: string[]
  private ilpProductList: T[]

  // used to override the filter, keep it undefined will fallback to default list in T
  filter = {
    insurance: {
      type: undefined,
      category: undefined
    },
    investment: {
      type: undefined,
      category: undefined
    },
    rider: {
      type: undefined,
      category: undefined
    },
    ilp: {
      type: undefined,
      category: undefined
    }
  }

  constructor (data: (string | number)[][], T = null) {
    super(ProductHeaders, data, T || ProductMeta)
    // sort product based on name
    this.list = sortBy((product) => product.name, this.list)
  }

  protected getIssuer (productList: T[]) {
    const issuer = productList.map(product => product.issuer).filter(issuer => issuer)
    const uniqIssuer = [...new Set(issuer)]
    // sort issuer
    return sortBy((issuer) => issuer.toLowerCase(), uniqIssuer)
  }

  private getDisplayList (productList: T[], needsCovered?: string, issuer?: string) {
    if (needsCovered && issuer) {
      return productList.filter(product => product.needsCovered?.includes(needsCovered) && (product.issuer === issuer || product.issuer_oid === issuer))
    } else if (needsCovered) {
      return productList.filter(product => product.needsCovered?.includes(needsCovered))
    } else if (issuer) {
      return productList.filter(product => product.issuer === issuer || product.issuer_oid === issuer)
    }
    return productList
  }

  private getDisplayListByIssuerOid (productList: T[], needsCovered?: string, issuerOid?: string) {
    if (needsCovered && issuerOid) {
      return productList.filter(product => product.needsCovered?.includes(needsCovered) && product.issuer_oid === issuerOid)
    } else if (needsCovered) {
      return productList.filter(product => product.needsCovered?.includes(needsCovered))
    } else if (issuerOid) {
      return productList.filter(product => product.issuer_oid === issuerOid)
    }
    return productList
  }

  private getDisplayListByInvestmentAccountCategory (productList: T[], issuerOid?: string) {
    if (issuerOid) {
      return productList.filter(product => product.category?.includes('Investment Account') && product.issuer_oid === issuerOid)
    }
    return productList.filter(product => product.category?.includes('Investment Account'))
  }

  // rider
  getRiderList () {
    if (!this.riderList) {
      this.riderList = this.list
        .filter(product => product.isRider(this.filter.rider.type, this.filter.rider.category))
      Object.freeze(this.riderList)
    }
    return this.riderList
  }

  getRiderDisplayList (needsCovered?: string, issuer?: string) {
    return this.getDisplayList(this.getRiderList(), needsCovered, issuer)
  }

  // insurance
  getInsuranceProductList () {
    if (!this.insuranceProductList) {
      this.insuranceProductList = this.list
        .filter(product => product.isInsuranceProduct(this.filter.insurance.type, this.filter.insurance.category) &&
          this.isWithinLaunchAndWithdrawnDates(product.launchDateMillis, product.withdrawnDateMillis))
      Object.freeze(this.insuranceProductList)
    }
    return this.insuranceProductList
  }

  getInsuranceProductDisplayList (needsCovered?: string, issuer?: string) {
    return this.getDisplayList(this.getInsuranceProductList(), needsCovered, issuer)
  }

  getInsuranceProductDisplayListByIssuerOid (needsCovered?: string, issuerOid?: string) {
    return this.getDisplayListByIssuerOid(this.getInsuranceProductList(), needsCovered, issuerOid)
  }

  getInsuranceIssuer () {
    if (!this.insuranceIssuer) {
      this.insuranceIssuer = this.getIssuer(this.getInsuranceProductList())
    }
    return this.insuranceIssuer
  }

  getInsuranceIssuerWithProducts (needsCovered: string) {
    const allIssuer = this.getInsuranceIssuer()
    const allProds = this.getInsuranceProductList()
    return needsCovered === '' ? allIssuer : allIssuer.filter(issuer => {
      return allProds.some(product => product.issuer === issuer && product.needsCovered?.includes(needsCovered))
    })
  }

  // investment
  getInvestmentProductList () {
    if (!this.investmentProductList) {
      this.investmentProductList = this.list
        .filter(product => product.isInvestmentProduct(this.filter.investment.type, this.filter.investment.category) &&
          this.isWithinLaunchAndWithdrawnDates(product.launchDateMillis, product.withdrawnDateMillis))
      Object.freeze(this.investmentProductList)
    }
    return this.investmentProductList
  }

  getInvestmentProductDisplayList (needsCovered?: string, issuer?: string) {
    return this.getDisplayList(this.getInvestmentProductList(), needsCovered, issuer)
  }

  getInvestmentProductDisplayListByIssuerOid (needsCovered?: string, issuerOid?: string) {
    return this.getDisplayListByIssuerOid(this.getInvestmentProductList(), needsCovered, issuerOid)
  }

  getAccountNameProducts (issuerOid?: string) {
    return this.getDisplayListByInvestmentAccountCategory(this.getInvestmentProductList(), issuerOid)
  }

  getInvestmentIssuer () {
    if (!this.investmentIssuer) {
      this.investmentIssuer = this.getIssuer(this.getInvestmentProductList())
    }
    return this.investmentIssuer
  }

  getInvestmentIssuerWithProducts (needsCovered: string) {
    const allIssuer = this.getInvestmentIssuer()
    const allProds = this.getInvestmentProductList()
    return needsCovered === '' ? allIssuer : allIssuer.filter(issuer => {
      return allProds.some(product => product.issuer === issuer && product.needsCovered?.includes(needsCovered))
    })
  }

  isWithinLaunchAndWithdrawnDates (launchDateMillis: number, withdrawnDateMillis: number) {
    // displays product with empty values for both launch and withdrawn dates as by default most are empty
    if (launchDateMillis == null && withdrawnDateMillis == null) {
      return true
    } else {
      const currentTime = Date.now()
      return (launchDateMillis ? currentTime >= launchDateMillis : true) && (withdrawnDateMillis ? currentTime <= withdrawnDateMillis : true)
    }
  }

  // ILP
  getILPProductList () {
    if (!this.ilpProductList) {
      this.ilpProductList = this.list
        .filter(product => product.isILPProduct(this.filter.ilp.type, this.filter.ilp.category))
        .sort((product1, product2) => {
          const product1Name = product1.name.toUpperCase()
          const product2Name = product2.name.toUpperCase()
          if (product1Name > product2Name) {
            return 1
          } else if (product1Name < product2Name) {
            return -1
          } else {
            return 0
          }
        })
      Object.freeze(this.ilpProductList)
    }
    return this.ilpProductList
  }
}
