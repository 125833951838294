/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { CommissionsExceptionDTO } from './CommissionsExceptionDTO'

export class CommissionsExceptionEdit implements CommissionsExceptionDTO {
  policyNumber=''
  paymentDateMillis=''
  statementDateMillis=''
  calculationStatus=''
  principal=''
  remunerationType=''
  oid=''
  advCode=''
  planCode=''
  premiumAmt=null
  issuedDateMillis=null
  payMode=null
  productType=''
  commissionType=''
  descriptions=''
  premiumType=''
  premiumYear=''
  productName=''
  advName=''
  clientName=''
  commissionAmt=null
  orCommissionAmt=null
  transactionType=''
  paidToDate=''
  paid=''
  remark=''
  level1ComOid=''
  level2ComOid=''
  level3ComOid=''
  level1ComFullname=''
  level2ComFullname=''
  level3ComFullname=''
  level1ComPct=''
  level2ComPct=''
  level3ComPct=''
  level1ComAmt=''
  level2ComAmt=''
  level3ComAmt=''
  introducerFullname=''
  introducerComPct=''
  introducerComAmt=null
  vestingFullname=''
  vestingComPct=''
  vestingComAmt=null
  servicingFullname=''
  servicingComPct=''
  servicingComAmt=null
  bscClawbackPct=''
  level1BscClawbackAmt=null
  level2BscClawbackAmt=null
  level3BscClawbackAmt=null
  level4BscClawbackAmt=null
  totalBscClawbackAmt=null
  clientRebatePct=''
  clientRebateAmt=null
  level1ComPayout=''
  level2ComPayout=''
  level3ComPayout=''
  level4ComPayout=''
  serviceChargePayout=''
  groupId=''
  performanceBonusPayout=''
  profitSharingRpPayout=''
  discrepancy=''

  assignAttributes (data: CommissionsExceptionDTO) {
    const {policyNumber, paymentDateMillis, statementDateMillis, calculationStatus, principal, remunerationType,oid,
    advCode, planCode, premiumAmt, issuedDateMillis, payMode, productType, commissionType, descriptions, productName, advName,
    clientName, commissionAmt, orCommissionAmt, transactionType, paidToDate, paid, remark, level1ComOid,  level2ComOid,
    level3ComOid, level1ComFullname, level2ComFullname, level3ComFullname, level1ComPct, level2ComPct, level3ComPct,introducerFullname,
    introducerComPct, introducerComAmt, vestingFullname, vestingComPct, vestingComAmt, servicingFullname, servicingComPct, servicingComAmt,
    bscClawbackPct, level1BscClawbackAmt, level2BscClawbackAmt, level3BscClawbackAmt, level4BscClawbackAmt, totalBscClawbackAmt,
    clientRebatePct, clientRebateAmt, level1ComPayout, level2ComPayout, level3ComPayout, level4ComPayout, serviceChargePayout,
    groupId, performanceBonusPayout, profitSharingRpPayout, discrepancy, premiumType, premiumYear,level1ComAmt,
    level2ComAmt,level3ComAmt} = data

    this.productName=productName
    this.advName=advName
    this.clientName=clientName
    this.commissionAmt=commissionAmt
    this.orCommissionAmt=orCommissionAmt
    this.transactionType=transactionType
    this.paidToDate=paidToDate
    this.paid=paid
    this.remark=remark
    this.level1ComOid=level1ComOid
    this.level2ComOid=level2ComOid
    this.level3ComOid=level3ComOid
    this.level1ComFullname=level1ComFullname
    this.level2ComFullname=level2ComFullname
    this.level3ComFullname=level3ComFullname
    this.level1ComPct=level1ComPct
    this.level2ComPct=level2ComPct
    this.level3ComPct=level3ComPct
    this.level1ComAmt=level1ComAmt
    this.level2ComAmt=level2ComAmt
    this.level3ComAmt=level3ComAmt
    this.premiumType=premiumType
    this.premiumYear=premiumYear
    this.introducerFullname=introducerFullname
    this.introducerComPct=introducerComPct
    this.introducerComAmt=introducerComAmt
    this.vestingFullname=vestingFullname
    this.vestingComPct=vestingComPct
    this.vestingComAmt=vestingComAmt
    this.servicingFullname=servicingFullname
    this.servicingComPct=servicingComPct
    this.servicingComAmt=servicingComAmt
    this.bscClawbackPct=bscClawbackPct
    this.level1BscClawbackAmt=level1BscClawbackAmt
    this.level2BscClawbackAmt=level2BscClawbackAmt
    this.level3BscClawbackAmt=level3BscClawbackAmt
    this.level4BscClawbackAmt=level4BscClawbackAmt
    this.totalBscClawbackAmt=totalBscClawbackAmt
    this.clientRebatePct=clientRebatePct
    this.clientRebateAmt=clientRebateAmt
    this.level1ComPayout=level1ComPayout
    this.level2ComPayout=level2ComPayout
    this.level3ComPayout=level3ComPayout
    this.level4ComPayout=level4ComPayout
    this.serviceChargePayout=serviceChargePayout
    this.groupId=groupId
    this.performanceBonusPayout=performanceBonusPayout
    this.profitSharingRpPayout=profitSharingRpPayout
    this.discrepancy=discrepancy
    this.policyNumber= policyNumber
    this.paymentDateMillis = paymentDateMillis
    this.statementDateMillis = statementDateMillis
    this.calculationStatus = calculationStatus
    this.principal = principal
    this.remunerationType = remunerationType
    this.oid = oid
    this.advCode = advCode
    this.planCode = planCode
    this.premiumAmt = premiumAmt
    this.issuedDateMillis = issuedDateMillis
    this.payMode = payMode
    this.productType = productType
    this.commissionType = commissionType
    this.descriptions = descriptions
  }
}
