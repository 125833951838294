import { Base } from '@master/model/Base'
import { isUndefined } from 'lodash/fp'

export default class ESignSigner extends Base {
  contactCountryCode = '+65'
  contactNumber = ''
  emailAddress = ''
  expiresAt?: number = undefined
  lastRequest?: number = undefined
  mobileNumber?: number = undefined
  name = ''
  nric = ''
  order?: number = undefined
  role = ''
  signatureId = ''
  signingUrl = ''
  status = ''
  token = ''

  extractData (rawKycPdf: any, index?: number) {
    if (!rawKycPdf.esign_signers) return {}
    const rawData = rawKycPdf.esign_signers[index]
    const data = {}
    if (rawData) {
      Object.keys(this).forEach((key) => { data[key] = rawKycPdf.esign_signers[index][this.snakeCaseKey(key)] })
    }
    return data
  }

  extractLatest (updatedSigner: [any]) {
    if (!isUndefined(updatedSigner)) {
      const rawData = updatedSigner.find(signer => signer.role === this.role)
      if (rawData) {
        Object.keys(this).forEach((key) => {
          let newValue = this[key]
          // to check if the latest data contains the target data, if not, retain current value
          const latest = rawData[this.snakeCaseKey(key)]
          if (latest !== undefined) newValue = latest
          this[key] = newValue
        })
      }
    }
  }

  mapFormattedToRaw () {
    const data = {}
    if (data) {
      Object.keys(this).forEach((key) => { data[this.snakeCaseKey(key)] = this[key] })
    }
    return data
  }
}
