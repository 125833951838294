import { FNAStructure } from '@master/model/structure/FNAStructure'
import DisabilityProtectionClient from '@master/model/kyc-form/need-analysis/protection/DisabilityProtectionClient'

export function DisabilityProtectionStructure (defaultValue: { available1: number; available2: number; annualIncome: number }) {
  const structure: FNAStructure<DisabilityProtectionClient> = {
    title: 'P2. Total Permanent Disability Protection',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Desired annual income to sustain lifestyle ($)',
        key: 'income',
        type: 'currency',
        default: defaultValue?.annualIncome,
        validationMsg: 'Total Permanent Disability Protection requires to indicate desired annual income.'
      }, {
        label: 'Number of years required',
        key: 'yearsRequired',
        validationMsg: 'Total Permanent Disability Protection requires to indicate years required.'
      }, {
        label: 'Inflation adjusted rate return (%)',
        key: 'infAdjReturn',
        defaultTo: 'defaultRate',
        validationMsg: 'Total Permanent Disability Protection requires to indicate inflation adjusted rate return.'
      }, {
        label: 'Lump sum required to maintain lifestyle (PV) ($)',
        key: 'fundsRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '+ Lump sum required upon occurrence ($)',
        key: 'required1',
        type: 'currency',
        validationMsg: 'Total Permanent Disability Protection requires to indicate lump sum required for treatment of illness.'
      }, {
        label: 'Total disability needs($)',
        key: 'totalRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '- Existing disability benefit($)',
        key: 'available1',
        type: 'currency',
        default: defaultValue?.available1,
        validationMsg: 'Total Permanent Disability Protection requires to indicate existing disability benefit.'
      }, {
        label: 'Other funds available (e.g rental income etc.) ($)',
        key: 'available2',
        type: 'currency',
        default: defaultValue?.available2,
        validationMsg: 'Total Permanent Disability Protection requires to indicate other resources.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
