/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

// import type { definitions_SearchFieldDto } from './definitions_SearchFieldDto';

export type ListApprovalRequestDto = {
    limit?: number;
    page?: number;
    search?: any;
    retrieveAll?: boolean;
    sortBy?: ListApprovalRequestDto.sortBy;
    orderBy?: string;
    filters?: any;
}

export namespace ListApprovalRequestDto {

    export enum sortBy {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}