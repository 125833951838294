/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DownloadCsvDto = {
    company: string;
    user: string;
    dateType: string;
    from: number;
    to: number;
    status?: DownloadCsvDto.status;
}

export namespace DownloadCsvDto {

    export enum status {
        PAID = 'paid',
        UNPAID = 'unpaid',
    }


}
