import '@/DIContainer'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IndividualMyinfoDTO, UserMyinfoService } from './api'
import { IndividualMyinfo } from './model/IndividualMyinfo'

@Module({
  namespaced: true, name:'MyinfoStore'
})
export default class MyinfoStore extends VuexModule {
  loading = false
  myinfoDetails: IndividualMyinfoDTO = new IndividualMyinfo()

  @Mutation
  setMyinfoDto (myinfoDetails: IndividualMyinfoDTO) {
    this.myinfoDetails = myinfoDetails
  }

  @Action
  async retrieveMyinfoDetails (clientOid: string) {
    try {
      this.context.commit('setLoading', true)
      const myinfoDto = await UserMyinfoService.retrieveIndividualMyinfo(clientOid)
      this.context.commit('setMyinfoDto', myinfoDto)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
