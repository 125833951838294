/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export class SearchFieldDto {
    searchField?: string;
    searchFieldValue?: string;
}
export type SubPolicyFilterRequestDTO = {
    limit?: number;
    page?: number;
    search?: SearchFieldDto;
    retrieveAll?: boolean;
    sortBy?: SubPolicyFilterRequestDTO.sortBy;
    orderBy?: string;
}

export namespace SubPolicyFilterRequestDTO {

    export enum sortBy {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}
