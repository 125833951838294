/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommissionDTO } from '../models/CommissionDTO';
import type { CommissionRatesDTO } from '../models/CommissionRatesDTO';
import { request as __request } from '@openapi/request';

export class AccountStructureCommissionService {

    /**
     * @param memberId
     * @returns CommissionDTO Return commission
     * @throws ApiError
     */
    public static async getCommission(
        memberId: number,
    ): Promise<CommissionDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account-structure/commission/${memberId}`,
        });
        return result.body;
    }

    /**
     * @param accountOid
     * @returns CommissionRatesDTO
     * @throws ApiError
     */
    public static async getCommissionRates(
        accountOid: string,
    ): Promise<Array<CommissionRatesDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account-structure/commission/rates/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Create or update commission success
     * @throws ApiError
     */
    public static async editCommission(
        requestBody: CommissionDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account-structure/commission`,
            body: requestBody,
            errors: {
                400: `commission value not in correct range`,
                404: `invalid member id`,
                409: `commission value conflict with existing commission in hierarchy`,
            },
        });
        return result.body;
    }

}