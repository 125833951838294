/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductLicenceDto } from '../models/ProductLicenceDto';
import { request as __request } from '@openapi/request';

export class ProductLicenceService {

    /**
     * @returns ProductLicenceDto Autopoulates the Product Type table with default values
     * @throws ApiError
     */
    public static async defaultPopulateProductType(): Promise<Array<ProductLicenceDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/licence-product/default-licence-product`,
        });
        return result.body;
    }

    /**
     * @returns ProductLicenceDto Retrieve all the current system product licence list
     * @throws ApiError
     */
    public static async listSystemAdviserLicences(): Promise<Array<ProductLicenceDto>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/licence-product`,
        });
        return result.body;
    }

}