/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '@openapi/request';

export class CpdManagementRecordService {

    /**
     * @param userOid
     * @param courseOid
     * @returns any Checks if the user has passed a course
     * @throws ApiError
     */
    public static async isUserPassCourse(
        userOid: string,
        courseOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd/pass/${userOid}/${courseOid}`,
        });
        return result.body;
    }

    /**
     * @param courseTypes
     * @param courseOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns any Retrieve admin view of all cpd courses type of training and cumulated hours
     * @throws ApiError
     */
    public static async adminGetCpdCourses(
        courseTypes: Array<string>,
        courseOids: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd/courses`,
            query: {
                'courseTypes': courseTypes,
                'courseOids': courseOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @param classOids
     * @param classIds
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns any Retrieve admin view of all cpd classes
     * @throws ApiError
     */
    public static async adminGetCpdClasses(
        courseOid: string,
        classOids: Array<string>,
        classIds: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd/classes`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'classIds': classIds,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @param classOids
     * @param attendanceOids
     * @param userOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns any Retrieve admin view of all advisers cumulated hours, branch, reporting manager and reporting director
     * @throws ApiError
     */
    public static async adminGetAdvisers(
        courseOid?: Array<string>,
        classOids?: Array<string>,
        attendanceOids?: Array<string>,
        userOids?: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd/advisers`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'attendanceOids': attendanceOids,
                'userOids': userOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @param classOids
     * @param attendanceOids
     * @param userOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns any Retrieve admin view of CPD attendance records
     * @throws ApiError
     */
    public static async adminGetAttendances(
        courseOid?: Array<string>,
        classOids?: Array<string>,
        attendanceOids?: Array<string>,
        userOids?: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd/attendances/list`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'attendanceOids': attendanceOids,
                'userOids': userOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

}