export default class ESubProduct {
  lifeAssured = ''
  planName = ''
  type = ''
  productType = ''
  riders: [] = []

  constructor (rawData) {
    this.extractData(rawData)
  }

  get isInsurance () {
    return this.type === 'insurance'
  }

  extractData (rawData: any) {
    if (rawData) {
      Object.keys(this).forEach((key) => {
        this[key] = rawData[key]
      })
    }
  }

  updateProperties (latest: object) {
    Object.keys(latest).forEach((key) => {
      this[key] = latest[key]
    })
  }
}
