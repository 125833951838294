import { FormBase } from '../FormBase'

export class SecuritiesDealing extends FormBase {
  // shared.name
  // shared.nric
  type = 'securities-dealing'

  data = {
    agree: false
  }

  get error () {
    const errs = []
    if (!this.data.agree) {
      errs.push({
        key: 'agree',
        msg: 'Please read and confirm agreement on terms'
      })
    }
    return errs
  }
}
