import 'reflect-metadata'

const defaultMetadataKey = Symbol('Default')

/**
 * Add default value for target property
 * @param defaultValue any value with the same type of target property
 */
export function Default (defaultValue: any) {
  return Reflect.metadata(defaultMetadataKey, defaultValue)
}

/**
 * Get default value, if no default value set on the property key return undefined
 * @param target Target object
 * @param propertyKey property key of target object
 */
export function getDefault (target: any, propertyKey: string) {
  return Reflect.getMetadata(defaultMetadataKey, target, propertyKey)
}
