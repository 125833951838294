/* eslint-disable @typescript-eslint/camelcase */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { UserMeta, header } from '@core/model/common/UserMeta'
import { cid, container } from 'inversify-props'
import API from '@core/services/API'
import '@/DIContainer'
import { UserAdviserDetailsMeta } from '../sections/user-adviser-details-components/model/UserAdviserDetailsMeta'
import { PicodedAccount } from '@core/pages/account/model/PicodedAccountDTO'
import { IAdviserService } from '@master/services/IAdviserService'
import { UserMetaList } from '@core/model/common/UserMetaList'
import { AccountListDTO } from '@core/openapi/services/user-account/dto/AccountListDTO'
import { FilterAdviserSalesCodesDto, AdviserSalesCodesDto, AccountService, AdviserSalesCodesService, UpdateAdviserSalesCodesDto } from '@core/pages/account/api'
import { AccountService as AccountServiceV2 } from '@core/openapi/services/user-account/api'
import { SharedVariable } from '@core/model/SharedVariable'
import { UIService } from '@core/services/UIService'

const $api = container.get<API>('$api')
const adviserService = container.get<IAdviserService>(cid.IAdviserService)
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()
@Module({ namespaced: true, name : 'UserStore' })
export default class UserStore extends VuexModule {
  loading = false
  user: UserMeta = new UserMeta()
  userSystem: PicodedAccount = new PicodedAccount()
  originalUserName = ''
  productPrincipals: AdviserSalesCodesDto[] = []
  userAdviserDetails: UserAdviserDetailsMeta = new UserAdviserDetailsMeta(adviserService)
  userList: UserMeta[] = []
  filteredUserList: UserMeta[] = []

  @Action({ rawError: true })
  async fetchUser (oid: string) {
    // TODO - backup old api call. Deprecate later.
    // this.context.commit('setLoading', true)
    // const user = await $api.account.loginMetaGet({
    //   _oid: oid
    // })
    // const { groups, accountNames } = await $api.account.loginInfoGet({
    //   accountID: oid
    // })
    // this.context.commit('setUser', { ...user.meta, groups })
    // this.context.commit('setUserSystem', { ...user.meta, username: accountNames[0] })
    // this.context.dispatch('fetchPrincipalList')
    // this.context.commit('setLoading', false)

    this.context.commit('setLoading', true)
    const user = await AccountServiceV2.retrieveUserAccountInformation(oid)
    // const { groups, accountNames } = await $api.account.loginInfoGet({
    //   accountID: oid
    // })
    // this.context.commit('setUser', { ...user, groups })
    // this.context.commit('setUserSystem', { ...user, username: accountNames[0] })
    this.context.dispatch('fetchPrincipalList')
    this.context.commit('setLoading', false)
  }

  @Action({ rawError: true })
  async saveUser () {
    console.log('saveUser')
    this.context.commit('setLoading', true)
    this.user.account_status = this.userSystem.account_status
    this.user.systemrole = this.userSystem.systemrole
    await $api.account.meta({
      _oid: this.user.oid,
      meta: JSON.stringify(this.user.convertToRawData())
    })
    this.context.commit('setLoading', false)
  }

  @Action({ rawError: true })
  async changeUserName () {
    const result = await AccountService.changeUserName({
      oid: this.user.oid,
      username: this.userSystem.username
    })

    if (result) {
      this.context.commit('setUserSystem', this.userSystem)
    }
  }

  @Action({ rawError: true })
  async savePassword () {
    this.context.commit('setLoading', true)
    const result = await $api.account.password({
      accountID: this.user.oid,
      newPassword: this.userSystem.newPassword,
      oldPassword: this.userSystem.oldPassword,
      confirmPassword: this.userSystem.confirmPassword
    })
    this.context.commit('clearPassword')
    this.context.commit('setLoading', false)
    return result
  }

  @Action({ rawError: true })
  async fetchPrincipalList () {
    this.context.commit('setLoading', true)

    const filter: FilterAdviserSalesCodesDto = { } // TODO search and pagination
    const data = await AdviserSalesCodesService.retrieveAdviserSalesCodesList(filter)
    this.context.commit('setProductPrincipals', data)
    this.context.commit('setLoading', false)
  }

  @Action({ rawError: true })
  async fetchAdviserDetails (adviser_oid: string) {
    this.context.commit('setLoading', true)
    const res = await $api.adviserDetails.getDetails({
      adviser_oid
    })

    const data = { ...res.data }

    const educationList = await $api.adviserDetails.getEducationList({
      adviser_oid
    })
    data.education_list = JSON.stringify(educationList?.data) || {}

    const qualificationList = await $api.adviserDetails.getQualificationList({
      adviser_oid
    })
    data.qualification_list = JSON.stringify(qualificationList?.data) || {}

    const employmentList = await $api.adviserDetails.getEmploymentList({
      adviser_oid
    })
    data.employment_list = JSON.stringify(employmentList?.data) || {}

    this.context.commit('setAdviserDetails', data)
    this.context.commit('setLoading', false)
  }

  @Action({ rawError: true })
  async saveProductPrincipal (principal: UpdateAdviserSalesCodesDto) {
    this.context.commit('setLoading', true)
    await AdviserSalesCodesService.saveAdviserSalesCodes(principal)
    this.context.dispatch('saveAdviserSalesCodes')
    this.context.commit('setLoading', false)
  }

  @Action({ rawError: true })
  async fetchUserList (requestDTO: AccountListDTO) {
    if (this.userList.length > 0) return
    const { headers, representativeCodes, companyCodes, rnfNumbers, oids, page, limit, retrieveAll } = requestDTO
    // const res = await $api.account.list({
    //   headers: JSON.stringify(header),
    //   groupStatus: 'user'
    // })
    // TODOAMIT
    const response = await AccountServiceV2.listUserAccounts(headers.join(','), representativeCodes.join(','), companyCodes.join(','), rnfNumbers.join(','), oids.join(','), page, limit, retrieveAll)
    // const userList = response.data.map(_user => {
    //   const user = new UserMeta()
    //   // const userdetail = { ..._user, ..._user.userPersonalInfo, ..._user.userCompanyInfo }
    //   Object.assign(user, _user)
    //   user.fullname = _user.userPersonalInfo.fullname
    //   return user
    // })
    // console.log('userList in store', userList)
    this.context.commit('setUserList', response.data)
  }

  @Action
  async fetchUserListFilter (usersOid: string[]) {
    const query = []
    const queryArgs = []

    usersOid.forEach(userOid => {
      query.push('_oid = ?')
      queryArgs.push(userOid)
    })

    const res = await $api.account.list({
      headers: JSON.stringify(header),
      groupStatus: 'user',
      query: query.join(' OR '),
      queryArgs: JSON.stringify(queryArgs)
    })
    const userMetaList: UserMetaList = new UserMetaList(res.data, header)

    this.context.commit('setFilteredUserList', userMetaList.list)
  }

  @Mutation
  setProductPrincipals (data: AdviserSalesCodesDto[]) {
    this.productPrincipals = data
  }

  @Mutation
  setAdviserDetails (adviserDetails: object) {
    this.userAdviserDetails.assignData(Object.keys(adviserDetails), Object.values(adviserDetails))
  }

  @Mutation
  clearPassword () {
    this.user.oldPassword = ''
    this.user.newPassword = ''
    this.user.confirmPassword = ''
    this.userSystem.oldPassword = ''
    this.userSystem.newPassword = ''
    this.userSystem.confirmPassword = ''
  }

  @Mutation
  setUser (user: object) {
    this.user.assignData(Object.keys(user), Object.values(user))
  }

  @Mutation
  setUserSystem (user: object) {
    this.userSystem.assignData(user)
    this.originalUserName = this.userSystem.username
  }

  @Mutation
  setLoading (state: boolean) {
    this.loading = state
  }

  @Mutation
  setUserList (data: UserMeta[]) {
    this.userList = data
  }

  @Mutation
  setFilteredUserList (data: UserMeta[]) {
    this.filteredUserList = data
  }

  @Action
  async fetchCurrentLoggedInUser(){
    try {
      const response = await AccountService.getCurrentUserAccountInformation()
      this.context.commit("setUser", response)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
   
  }
}
