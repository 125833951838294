<template>
  <div>
    <header v-if="withLogo" class="hamburger" :class="{ mini: true }">
      <div class="logo-wrapper">
        <div class="logo" @click="goToHome"></div>
      </div>
      <nav>
        <ul id="nav-list">
            <a href="javascript:void(0)" class="icon" @click="toggleMenu">
              <img v-svg-inline :src="hamburgerIcon" alt="svg image" />
               <div class="menu-overlay" :class="{'open': isMenuOpen}">
                <template v-for="({ disable, event, href, target, icon, label, dropdown, customStyle, viewport, iconDown, iconUp }, index) in header">
            <li v-if="!disable" :key="index" :id="`nav-item-${index}`" @mouseout="hovering=-1" @mouseover="hovering=index" :class="[{ 'has-drop': dropdown && dropdown.length > 0, focus: hovering === index, 'dd-flip': isOutOfView(`nav-item-${index}`) }, label]" :style="customStyle" :title="label">
              <template v-if="enableVirtualMeeting">
            <a @click="startVirtualMeeting">
              <img v-svg-inline :src="videocamIcon" alt="svg image" />
              <p>Start Virtual Meeting</p>
            </a>
              </template>
              <template v-else-if="viewport">
                <a class="icon">
                  <img v-svg-inline :src="icon" alt="svg image" />
                </a>
              </template>
              <template v-else-if="event">
                <a @click="$emit(event)" :target="target">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }}</span>
                </a>
              </template>
              <template v-else-if="dropdown && dropdown.length > 0">
                <a @click="dropdownChange($event, index)">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span>{{ label }}</span>
                  <!-- <template v-if="!dropdownIcon"> -->
                  <img v-svg-inline :src="iconDown" alt="svg image" />
                </a>
                <ul class='dropdown-inline' v-if="clickedIndex == index && dropdownIcon">
                  <li v-for="(item, i) in dropdown" :key="i">
                <a v-if="!item.disable" :href="item.href" @mouseover="hovering=index">
                    {{item.icon}}
                    {{item.label}}
                    </a>
                  </li>
                </ul>
              </template>
              <template v-else>
                <a :href="href" :target="target" :title="label">
                  <img v-svg-inline :src="icon" alt="svg image" />
                  <span v-if="label == 'cancel'" class="cancel" @click="toggleMenu"></span>
                  <span v-else> {{label}}</span>
                </a>
              </template>
            </li>
          </template>
        </div>
              </a>
        </ul>
        <div v-if="showSideLogo" class="side-logo-wrapper">
          <img id="mochaLogo" v-svg-inline class="mocha-logo side-logo" :src="mochaIcon" alt="svg image" @click="goToHome"/>
        </div>
      </nav>
      <AppDialog v-if="enableVirtualMeeting" class="virtual-meeting-url" @closed="isCopied = false" :hideFooter="true">
        <template #header>Virtual Meeting URL</template>
        <div slot="body">
          <p>Click on the URL to copy: <strong>{{ isCopied ? 'Copied!' : '' }}</strong></p>
          <div @click="copyUrl" class="jitsi-url">
            <input id="url" :value="jitsiUrl" readonly>
          </div>
        </div>
      </AppDialog>
    </header>
    <header v-else class="hamburger">
      <aside>
        <nav>
          <ul id="nav-list">
              <a href="javascript:void(0)" class="icon" @click="toggleMenu">
                <img v-svg-inline class="icon-hamburger" :src="hamburgerIcon" alt="svg image" />
                <div id="overlay" :class="{'overlay': isMenuOpen}"></div>
                 <div class="menu-overlay" :class="{'open': isMenuOpen, 'inKYC': !withLogo}">
                  <template v-for="({ disable, event, href, target, icon, label, dropdown, customStyle, viewport, iconDown, iconUp }, index) in header">
              <li v-if="!disable" :key="index" :id="`nav-item-${index}`" @mouseout="hovering=-1" @mouseover="hovering=index" :class="[{ 'has-drop': dropdown && dropdown.length > 0, focus: hovering === index, 'dd-flip': isOutOfView(`nav-item-${index}`) }, label]" :style="customStyle" :title="label">
                <template v-if="enableVirtualMeeting">
              <a @click="startVirtualMeeting">
                <img v-svg-inline :src="videocamIcon" alt="svg image" />
                <p>Start Virtual Meeting</p>
              </a>
                </template>
                <template v-else-if="viewport">
                  <a class="icon">
                    <img v-svg-inline :src="icon" alt="svg image" />
                  </a>
                </template>
                <template v-else-if="event">
                  <a @click="$emit(event)" :target="target">
                    <img v-svg-inline :src="icon" alt="svg image" />
                    <span>{{ label }}</span>
                  </a>
                </template>
                <template v-else-if="dropdown && dropdown.length > 0">
                  <a @click="dropdownChange($event, index)">
                    <img v-svg-inline :src="icon" alt="svg image" />
                    <span>{{ label }}</span>
                    <!-- <template v-if="!dropdownIcon"> -->
                    <embed :class="{'icon-space': dropdownIcon == true}" :src="dropdownIcon ? iconUp: iconDown" />
                  </a>
                  <ul class='dropdown-inline' v-if="clickedIndex == index && dropdownIcon">
                    <li v-for="(item, i) in dropdown" :key="i">
                    <a v-if="!item.disable" :href="item.href" @mouseover="hovering=index">
                      {{item.icon}}
                      {{item.label}}
                    </a>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <a :href="href" :target="target" :title="label">
                    <img v-svg-inline :src="icon" alt="svg image" />
                    <span v-if="label == 'cancel'" class="cancel" @click.prevent="toggleMenu"></span>
                    <span v-else> {{label}}</span>
                  </a>
                </template>
              </li>
            </template>
          </div>
          </a>
          </ul>
          <div v-if="showSideLogo" class="side-logo-wrapper">
            <img id="mochaLogo" v-svg-inline class="mocha-logo side-logo" :src="mochaIcon" alt="svg image" @click="goToHome"/>
          </div>
        </nav>
      </aside>
      <AppDialog v-if="enableVirtualMeeting" class="virtual-meeting-url" @closed="isCopied = false" :hideFooter="true">
        <template #header>Virtual Meeting URL</template>
        <div slot="body">
          <p>Click on the URL to copy: <strong>{{ isCopied ? 'Copied!' : '' }}</strong></p>
          <div @click="copyUrl" class="jitsi-url">
            <input id="url" :value="jitsiUrl" readonly>
          </div>
        </div>
      </AppDialog>
    </header>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { HeaderItem } from '@core/model/structure/HeaderStructure'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import VideocamIcon from '@core/components/header/assets/videocam.svg'
import hamburgerIcon from '@core/assets/svg/hamburger.svg'
import MochaLogo from '@core/assets/mocha-logo.svg'
import AppDialog from '@core/components/AppDialog.vue'

@Component({
  components: {
    AppDialog
  }
})
export default class AppDynamicHeader extends Vue {
  @Prop({ required: true }) private readonly header!: HeaderItem[]

  @Prop({ required: true }) private readonly withLogo!: boolean

  @Prop()
  private mini

  private hamburgerIcon = hamburgerIcon
  private isMenuOpen = false
  private dropdownIcon = false
  private clickedIndex = null

  private jitsiUrl = ''
  private isJitsiUrlShow = false
  private isCopied = false
  private hovering = -1
  private dropDownWidth = 200

  startVirtualMeeting () {
    this.jitsiUrl = 'https://meet.jit.si/' + this.randomStr(32)
    this.isJitsiUrlShow = true
  }

  randomStr (len: number) {
    const arr = '1234567890abcdefghijklmnopqrstuvwxyz'
    let ans = ''
    for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)]
    }
    return ans
  }

  copyUrl () {
    const url: HTMLInputElement = document.getElementById('url') as HTMLInputElement
    url.select()
    url.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.isCopied = true
  }

  toggleMenu () {
    this.isMenuOpen = !this.isMenuOpen
  }

  // for flipping the dropdown list so that it stays within viewport
  isOutOfView (eleId) {
    const ele = document.getElementById(eleId)
    if (!ele) return false
    const pose = ele.getBoundingClientRect()
    if (document.documentElement.clientWidth - this.dropDownWidth < pose.left) {
      return true
    } else return false
  }

  get showSideLogo () {
    const { useSideLogo } = container.get<IProjectSettings>(cid.IProjectSettings)
    return useSideLogo
  }

  get enableVirtualMeeting () {
    const { virtualMeeting } = container.get<IProjectSettings>(cid.IProjectSettings)
    return virtualMeeting ? virtualMeeting.enable : false
  }

  get videocamIcon () {
    return VideocamIcon
  }

  get mochaIcon () {
    return MochaLogo
  }

  // Methods
  goToHome () {
    window.location.href = '/web/home'
  }

  dropdownChange (e, index) {
    this.clickedIndex = index
    e.preventDefault()
    e.stopPropagation()

    this.dropdownIcon = !this.dropdownIcon
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';
$font-size-base: 16px;
$dark-grey: #191919;
$menu-button-bar-height: 4px;
$menu-button-bar-width: $menu-button-bar-height * 8;
$menu-overlay-width: calc(30% - 4rem);
$menu-overlay-height: calc(30% - 4rem);

$transition-04s: all 0.4s ease;

$layer1: 1;
$layer2: 22;
$layer3: 33;
$toggleSize: 40px;
$nav-icon-width: 4.4rem;
$nav-icon-height: 3rem;
$nav-dropdown-width: 200px;
$toggleMargin: 10px;
$toggleLine: 4px;
$toggleColor: red;

.icon-space {
  margin-left: 0.5rem;
  width: 14px;
}

#overlay {
  position: fixed;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  right: 100%;
  top: 0;
}
.overlay {
  right: 0!important;
}

@media(min-width: 481px) and (max-width: 990px) {
  .menu-overlay {
  background-color: white;
  height: 100vh;
  width: 24vw;
  // right: 0;
  position: fixed;
  padding: 25px 2rem;
  visibility: hidden;
  opacity: 0;
  // transition: $transition-04s;
  transform: translate(10%, 40%);
  // transform: translateY(40%);
  z-index: $layer2;
  overflow-y: scroll;

  &.open {
    opacity: 1;
    visibility: visible;
    // height: $menu-overlay-width;
    transform: translate(5%, -6%) !important;
  }
}

.cancel {
  margin-left: 8rem !important;
}
}
.blur-class {
  filter: blur(8px)
}

.el-dropdown-menu {
  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    background-color: unset !important;
    color: unset !important;
    text-decoration: none;
    cursor:pointer;
  }
  }

.icon {
  margin-bottom: 2rem;
  width: inherit;
}

.dropdown-inline {
  list-style-type: none;

  & li {
    margin: 1rem;
    color: initial;
  }
}

.icon-hamburger {
  border: 1px solid #D8D8D8;
  border-radius: 100px;
  padding: 8px;
  height: 44px;
  width: 100%;
  margin-top: -7px;
  text-align: center;

  &:hover {
    background-color: #F4F6F9;
  }
  &:active {
    background-color: #EAEDF2;
  }
}

.menu-overlay {
  background-color: white;
  height: 100vh;
  width: 24vw;
  // right: 0;
  position: fixed;
  padding: 25px 2rem;
  // visibility: hidden;
  // display: none;
  // opacity: 0;
  // transition: $transition-04s;
  // transform: translate(10%, 40%);
  // transform: translateY(40%);
  z-index: $layer2;

  &.open {
    opacity: 1;
    visibility: visible;
    display: block;
    // height: $menu-overlay-width;
    transform: translate(-13%, -8%);
  }
}

.menu-overlay.inKYC {
  background-color: white;
  height: 100vh;
  padding: 25px 2rem;
  // visibility: hidden;
  // opacity: 0;
  bottom: 0px;
  right: -3rem;
  position: fixed;
  top: 0;
  max-width: 275px;
  transform: translate(100%, 0%);

  &.open.inKYC {
    opacity: 1;
    visibility: visible;
    // transform: translate(-2%, -1rem);
    transform: translate(0%, -2%);
    position: absolute;
    display: block;
  }
}
.background-overlay {
  background-color: rgba($color: $dark-grey, $alpha: 0.5);
  z-index: $layer1;
  opacity: 0;
  position: fixed;

  &.bg-overlay {
    opacity: 1;
  }
}

ul#nav-list {
  position: relative;
  margin-left: 0;
  li {
    margin-bottom: 2rem;
    list-style-type: none;
    a {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: $dark-grey;

    svg {
      margin-right: 0.5rem;
    }
    span {
      align-self:center;
    }
    }
  }
}

.cancel {
  margin-left:10rem;
  width: fit-content;
}

.Profile {
  border-top: 1px solid #D8D8D8;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  height: 10px;
}

header {
  // border-bottom: 1px solid lightgrey;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  transition: all 0.5s ease;

  &.mini {
    // height: calc( #{$header-height} - 20px );
    padding: 0rem 2rem;
    .logo-wrapper {
    height: $header-height;
    padding: 0.25rem;
    transition: all 0.5s ease;
    .logo {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center left;
      height: 100%;
      width: 170px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  }
  }
</style>
