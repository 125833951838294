/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyDto } from '../models/CompanyDto';
import { request as __request } from '@openapi/request';

export class CompanyService {

    /**
     * @returns CompanyDto Retrieve the list of Company records
     * @throws ApiError
     */
    public static async retrieveCompanyList(): Promise<Array<any>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/company/dropdown`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CompanyDto
     * @throws ApiError
     */
    public static async saveCompany(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/company`,
            body: requestBody,
        });
        return result.body;
    }

     /**
     * @param requestBody
     * @returns CompanyDto
     * @throws ApiError
     */
     public static async retrieveCompnayListWithFilter(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/company/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns CompanyDto
     * @throws ApiError
     */
    public static async deleteCompnay(
        oid: string,
    ): Promise<any> {
        console.log('inside delete')
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/company/${oid}`,
        });
        return result.body;
    }


     /**
     * @param requestBody 
     * @returns UserAccountImportResponse 
     * @throws ApiError
     */
     public static async companyCsvImport(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/company/import`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns UserAccountImportResponse 
     * @throws ApiError
     */
    public static async exportCompanyCSV(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/company/export/list`,
            body: {"retrieveAll": true},
        });
        return result.body;
    }

}