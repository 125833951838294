import { IRiskRatingService } from './IRiskRatingService'

const RiskRatingMap = {
  1: 'Conservative',
  2: 'Moderately Conservative',
  3: 'Balanced',
  4: 'Moderately Aggressive',
  5: 'Aggressive'
} as const

const rawToValue = {
  conservative: 1,
  moderately_conservative: 2, // eslint-disable-line @typescript-eslint/camelcase
  balanced: 3,
  moderately_aggressive: 4, // eslint-disable-line @typescript-eslint/camelcase
  aggressive: 5
} as const

export class RiskRatingService implements IRiskRatingService {
  riskRatingMap () {
    return RiskRatingMap
  }

  rawToValueMap () {
    return rawToValue
  }
}
