/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateActivityDto = {
    name: string;
    type: CreateActivityDto.type;
    accountOid: string;
    status: CreateActivityDto.status;
}

export namespace CreateActivityDto {

    export enum type {
        UPLOAD = 'UPLOAD',
        EXAM = 'EXAM',
        FORM = 'FORM',
    }

    export enum status {
        PENDING = 'PENDING',
        INPROGRESS = 'INPROGRESS',
        READY_FOR_SUBMISSION = 'READY_FOR_SUBMISSION',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
    }


}
