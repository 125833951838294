import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["theme"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_portal_target = _resolveComponent("portal-target")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    theme: _ctx.theme
  }, [
    (_ctx.currentUser && _ctx.currentUser.isLogin)
      ? (_openBlock(), _createBlock(_component_TheHeader, {
          key: 0,
          customHeaders: _ctx.customHeaders
        }, null, 8, ["customHeaders"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_portal_target, { name: "main-page" }),
    (this.uiService.loading.hasLoadingTasks())
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 1 }))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}