import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ScheduleService, ScheduleTimeResponseDto } from '../api'
import { ScheduleTime } from '../model/ScheduleTime'
import { ScheduleTimeRequestDto } from '../model/ScheduleTimeRequestDto'

@Module({
  namespaced: true
})
export default class ScheduleStore extends VuexModule {
  scheduleInView: ScheduleTimeResponseDto = null
  scheduleList: ScheduleTime[] = []
  private loading = false

  @Action
  selectedScheduleInView (schedule: ScheduleTimeResponseDto) {
    this.context.commit('setScheduleInView', schedule)
  }

  @Mutation
  setScheduleInView (schedule: ScheduleTimeResponseDto) {
    this.scheduleInView = schedule
  }

  @Action
  async saveSchedule (schedule: ScheduleTimeRequestDto) {
    this.context.commit('setLoading', true)

    const hour = parseInt(schedule.time.split(':')[0])
    const minute = parseInt(schedule.time.split(':')[1])
    schedule.hour = hour
    schedule.minute = minute
    try {
      const savedSchedule = await ScheduleService.updateScheduleTaskTime(schedule)
      this.context.commit('selectedScheduleInView', savedSchedule)
      return savedSchedule
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action
  async retrieveSchedules () {
    this.context.commit('setLoading', true)
    const schedules = await ScheduleService.listSchedules()
    try {
      this.context.commit('setScheduleList', schedules)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setScheduleList (schedules: ScheduleTime[]) {
    this.scheduleList = schedules.map(schedule => {
      schedule.time = `${`0${schedule.hour}`.slice(-2)}:${`0${schedule.minute}`.slice(-2)}`
      return schedule
    })
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
