import { Meta } from '@master/model/common/Meta'

export class NextOfKinDetailsMeta extends Meta {
  name = ''
  nric = ''
  dob: number = undefined
  citizenship = ''
  nationality = ''
  passportNo = ''
  occupation = ''
  employer = ''
  contactNo = ''
  race = ''
  idDocType = ''
  idNumber = ''
  contactCountryCode = ''
  relationship = ''
}