<template>
  <footer class="footer">
    <div @click="goToChangelog" class="footer-credit">
      <!-- {{ ifamVersion.credit }} {{ ifamVersion.name }} ({{ ifamVersion.version }}) - {{ projectVersion.name }} (Frontend Version {{ latestVersion }}: Last updated on: {{ latestDate }}) -->
      <!-- 2023 Singlife Propel Pte. Ltd. | UEN & GST Reg No: 199900597Z -->
    </div>
  </footer>
</template>

<script lang="ts">
import { inject } from 'inversify-props'
import { Component, Vue } from 'vue-facing-decorator'
import IVersionInfo from '@master/config/IVersionInfo'
import { IDateService } from '@master/services/IDateService'

@Component
export default class TheFooter extends Vue {
  @inject()
  private ifamVersion: IVersionInfo

  @inject()
  private projectVersion: IVersionInfo

  @inject()
  private dateService: IDateService

  private changelog = []

  loadJSON () {
    try {
      // this.changelog = require('@/config/changelog.json').changeLog
    } catch (error) {
      console.error(error)
    }
  }

  beforeMount () {
    this.loadJSON()
  }

  get latestVersion () {
    return this.changelog?.length ? this.changelog[0].version : '-'
  }

  get latestDate () {
    return this.changelog?.length ? this.dateService.format(this.changelog[0].time, 'DD/MM/YYYY') : '-'
  }

  goToChangelog () {
    this.$router.push({ name: 'change-log' })
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  // height: $footer-height;
  padding: 8px 10px;
  background-color: #fff;
  text-align: center;

  .footer-credit {
    text-decoration: none;
    // line-height: $footer-height;
    font-size: 12px;
    color: #8A8988;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.18px;
  }
}
</style>
