import CoreCashFlow from '@master/model/kyc-form/finances/CashFlow'
import { Default } from '@master/annotation/Default'

export default class CashFlow extends CoreCashFlow {
  @Default(0)
  mortgageLoan = ''

  @Default(0)
  carLoan = ''

  @Default(0)
  lifestyleExpenses = ''

  get totalExpenses () { // was totalAnnualExpenses
    let res = 0
    res += parseInt(this.mortgageLoan) || 0
    res += parseInt(this.carLoan) || 0
    res += parseInt(this.insurancePremiums) || 0
    res += parseInt(this.regularSavings) || 0
    res += parseInt(this.parentalSupport) || 0
    res += parseInt(this.personalExpenses) || 0
    res += parseInt(this.lifestyleExpenses) || 0
    res += parseInt(this.others12) || 0
    return res
  }
}
