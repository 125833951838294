import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "loader" }, [
        _createElementVNode("div", { class: "wrapper" }, [
          _createElementVNode("div", { class: "ring" }, [
            _createElementVNode("div"),
            _createElementVNode("div"),
            _createElementVNode("div"),
            _createElementVNode("div")
          ])
        ])
      ], -1)
    ])),
    _: 1
  }))
}