/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientInterestDto } from '../models/ClientInterestDto';
import { request as __request } from '@openapi/request';

export class ClientInterestService {

    /**
     * @param oid
     * @returns ClientInterestDto Retrieve a Client Interest record by Client Oid
     * @throws ApiError
     */
    public static async retrieveClientInterestByClientOid(
        oid: string,
    ): Promise<ClientInterestDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/client-interest/client/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ClientInterestDto Save the Client Interest record
     * @throws ApiError
     */
    public static async saveClientInterest(
        requestBody: ClientInterestDto,
    ): Promise<ClientInterestDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/client-interest`,
            body: requestBody,
        });
        return result.body;
    }

}