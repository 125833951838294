/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { iFamFileDTO } from '../models/iFamFileDTO';
import { request as __request } from '@openapi/request';

export class IfamFileSystemService {

    /**
     * @param moduleType
     * @param targetOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async uploadDoc(
        moduleType: string,
        targetOid: string,
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/ifam-file-system/${moduleType}/${targetOid}`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param attachedOid
     * @param moduleType
     * @returns any
     * @throws ApiError
     */
    public static async getAllDocumentBy(
        attachedOid: string,
        moduleType: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/ifam-file-system`,
            query: {
                'attachedOid': attachedOid,
                'moduleType': moduleType,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async deleteFile(): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/ifam-file-system`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async downloadFile(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/ifam-file-system/${oid}`,
        });
        return result.body;
    }

}