import { ExpandableArray } from '@core/annotation/ExpandableArray'
import { MaxChar } from '@master/annotation/MaxChar'
import { Selection } from '@core/annotation/Selection'
import { EmploymentTypeOptions } from '@master/model/options/EmploymentTypeOptions'
import { ExamTypeOptions } from '@master/model/options/ExamTypeOptions'
import { isUndefined } from 'lodash/fp'
import { FormBase } from '../FormBase'

export const NsStatus = [
  { label: '', value: '' },
  { label: 'Completed', value: 'completed' },
  { label: 'Deferred', value: 'deferred' },
  { label: 'Exempted', value: 'exempted' }
]
export class NationalServiceDetails {
  notApplicable = false

  @Selection(NsStatus, true)
  status: typeof NsStatus[number]['value'] = ''

  enlistmentDate: number = undefined
  ordDate: number = undefined
}
export class AcademicQualification {
  from = ''
  to = ''

  @MaxChar(20)
  title = ''

  institute = ''

  get error () {
    const err = []
    const anyFilled = Object.values(this).some(val => val !== '' && !isUndefined(val))
    if (!anyFilled) return []
    if (this.from === '' || isUndefined(this.from)) err.push({ key: 'from', msg: 'Please complete "From" detail.' })
    if (this.to === '' || isUndefined(this.to)) err.push({ key: 'to', msg: 'Please complete "To" detail.' })
    if (this.title === '' || isUndefined(this.title)) err.push({ key: 'title', msg: 'Please complete the title of your academic qualification.' })
    if (this.institute === '' || isUndefined(this.institute)) err.push({ key: 'institute', msg: 'Please complete the name of your academic institution.' })
    return err
  }
}
export class OtherProfessionalQualification {
  attain: number = undefined
  title = ''
  body = ''
}
export class Employment {
  from: number = undefined
  to: number = undefined
  employer = ''
  position = ''

  @Selection(EmploymentTypeOptions, true)
  nature = ''

  reason = ''
}
export class QualiEmployFields {
  nationalService = new NationalServiceDetails()
  academic = Array(4).fill(null).map(() => new AcademicQualification())

  @Selection(ExamTypeOptions)
  professional = []

  other = Array(4).fill(null).map(() => new OtherProfessionalQualification())
  // to be stamped in reverse chronological order ( latest first )
  @ExpandableArray({ maxSize: 8, clone: () => new Employment() })
  employment: Employment[] = Array(4).fill(null).map(() => new Employment())
}
export class QualificationEmployment extends FormBase {
  type = 'qualification-employment'

  data: QualiEmployFields = new QualiEmployFields()

  get error () {
    const err = []
    const academicError = this.data.academic.map((acad) => acad.error)
    const hasAcademicError = academicError.length && academicError.some(err => err.length > 0)
    if (hasAcademicError) err.push({ key: 'academic', msgs: academicError })
    return err
  }
}
