import CoreBOP, { IBOP } from '@master/model/kyc-form/external/BeneficialOwnerParticulars'
import { isEmpty, isNull } from 'lodash/fp'

export default class CustomBOPParticulars extends CoreBOP implements IBOP {
  isNotComplete () {
    return isEmpty(this.contactNumber) ||
    isEmpty(this.correspondenceAddress) ||
    isNaN(this.dateOfBirth) ||
    isNull(this.dateOfBirth) ||
    isEmpty(this.fullName) ||
    isEmpty(this.nationality) ||
    isEmpty(this.nric) ||
    // isEmpty(this.politicallyExposedPerson) ||
    isEmpty(this.reasonsForAppointment) ||
    isEmpty(this.relationshipToCustomer) ||
    isEmpty(this.residentialAddress)
  }
}
