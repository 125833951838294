import { Base } from '../Base'
import ILPPortfolioV2 from './ILPPortfolioV2'

export default class ILPPortfolioArray extends Base {
  ilpPortfolios: ILPPortfolioV2[] = []

  afterExtractData (data: { ilpPortfolios: any[] }) {
    const ilpPortfolios = data.ilpPortfolios || []
    data.ilpPortfolios = ilpPortfolios.map(element => {
      const ilpPortfolio = new ILPPortfolioV2()
      Object.assign(ilpPortfolio, ilpPortfolio.extractData(element))
      return ilpPortfolio
    })
    return data
  }

  convertToRawData () {
    const rawArray = this.ilpPortfolios.map(ilpPortfolio => ilpPortfolio.convertToRawData())

    return {
      /* eslint-disable @typescript-eslint/camelcase */
      ilp_portfolios: rawArray
    }
  }
}
