import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ApprovalService, ListApprovalResponseDto, UpdateRequestApprovalDto, CsvExportDto, FilterOptionsResponseDto, ListApprovalRequestDto } from '@core/openapi/services/approval/api'
import { ApprovalsFilter } from '../api/models/ApprovalFilter'
import { nullEvent } from 'xstate/lib/actionTypes'

@Module({
    namespaced: true, name: 'ApprovalStore'
})
export default class ApprovalStore extends VuexModule {
    approvals = []
    pendingApprovals = []
    approvedApprovals = []
    rejectedApprovals = []
    filterApprovals = []
    filterOptions: {
        modules: string[];
        items: { [key: string]: string[] };
        users: { oid: string, username: string }[];
    } = null;
    downloadFileData = null;
    exportCsvData = null;
    successResponse = '';
    approvedApprovalsCount = 0
    rejectedApprovalsCount = 0
    approvalsCount = 0
    pendingApprovalsCount = 0

    @Action
    async getFilterOptions() {
        try {
            const result = await ApprovalService.getFilterOptions();
            this.context.commit('setFilterOptions', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setFilterOptions(data) {
        this.filterOptions = data ?? {};
   }
    @Action
    async retrieveAllApprovals(params) {
        const {limit, page, filters, search} = params

        try {
            const result = await ApprovalService.getApprovalList({
                limit,
                page,
                filters,
                search
            });
            this.context.commit('setAllApprovals', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setAllApprovals(result) {
        this.approvals = result.data
        this.approvalsCount = result.recordsTotal
    }

    @Action
    async retrieveFilterAllApproval(
        params: {
            limit: number,
            page: number,
            searchText: string,
            filter: ApprovalsFilter,
        }
    ) {
        try {
            const result = await ApprovalService.getApprovalList({
                limit: params.limit,
                page: params.page,
                filters: {
                    fromDate: !!params.filter.fromdate ? params.filter.fromdate : undefined,
                    toDate: !!params.filter.todate? params.filter.todate: undefined,
                    module: !!params.filter.module ? params.filter.module : undefined,
                    item: !!params.filter.item && !!params.filter.item.length ? params.filter.item : undefined,
                    userIds: !!params.filter.user && !!params.filter.user.length ? params.filter.user : undefined,
                    status: !!params.filter.status ? params.filter.status : undefined,
                },
            });
            this.context.commit('setFilterAllApprovals', result.data)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setFilterAllApprovals(data) {
        this.approvals = data;
        this.filterApprovals = data;
    }
    @Action
    async retrievePendingApprovals(params) {
        const {limit, page, filters, search} = params
        try {
            const result = await ApprovalService.getApprovalList({
                limit,
                page,
                filters,
                search
                });
            this.context.commit('setPendingApprovals', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setPendingApprovals (result) {
        this.pendingApprovals = result.data;
        this.pendingApprovalsCount = result.recordsTotal;
    }

    @Action
    async retrieveFilterPendingApproval(
        params: {
            limit: number,
            page: number,
            searchText: string,
            filter: ApprovalsFilter,
        }
    ) {
        try {
            const result = await ApprovalService.getApprovalList({
                limit: params.limit,
                page: params.page,
                filters: {
                    fromDate: !!params.filter.fromdate ? params.filter.fromdate : undefined,
                    toDate: !!params.filter.todate? params.filter.todate: undefined,
                    module: !!params.filter.module ? params.filter.module : undefined,
                    item: !!params.filter.item && !!params.filter.item.length ? params.filter.item : undefined,
                    userIds: !!params.filter.user && !!params.filter.user.length ? params.filter.user : undefined,
                    status: !!params.filter.status ? params.filter.status : ['Pending'],
                },
            });
            this.context.commit('setFilterPendingApprovals', result.data)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setFilterPendingApprovals(data) {
        this.pendingApprovals = data;
        this.filterApprovals = data;
    }
    @Action
    async retrieveApprovedApprovals(params) {
        const {limit, page, filters, search } = params
        try {
            const result = await ApprovalService.getApprovalList({
                limit,
                page,
                filters,
                search
            });
            this.context.commit('setApprovedApprovals', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setApprovedApprovals (result) {
        this.approvedApprovals = result.data
        this.approvedApprovalsCount = result.recordsTotal;
    }

    @Action
    async retrieveFilterApprovedApproval(
        params: {
            limit: number,
            page: number,
            searchText: string,
            filter: ApprovalsFilter,
        }
    ) {
        try {
            const result = await ApprovalService.getApprovalList({
                limit: params.limit,
                page: params.page,
                filters: {
                    fromDate: !!params.filter.fromdate ? params.filter.fromdate : undefined,
                    toDate: !!params.filter.todate? params.filter.todate: undefined,
                    module: !!params.filter.module ? params.filter.module : undefined,
                    item: !!params.filter.item && !!params.filter.item.length ? params.filter.item : undefined,
                    userIds: !!params.filter.user && !!params.filter.user.length ? params.filter.user : undefined,
                    status: !!params.filter.status ? params.filter.status : ['Approved'],
                },
            });
            this.context.commit('setFilterApprovedApprovals', result.data)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setFilterApprovedApprovals(data) {
        this.approvedApprovals = data;
        this.filterApprovals = data;
        this.approvedApprovalsCount = this.approvedApprovals.length;
    }
    @Action
    async retrieveRejectedApprovals(params) {
        const {limit, page, filters, search} = params
        try {
            const result = await ApprovalService.getApprovalList({
                limit,
                page,
                filters,
                search
            });
            this.context.commit('setRejectedApprovals', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setRejectedApprovals (result) {
        this.rejectedApprovals = result.data;
        this.rejectedApprovalsCount = result.recordsTotal;
    }

    @Action
    async retrieveFilterRejectedApproval(
        params: {
            limit: number,
            page: number,
            searchText: string,
            filter: ApprovalsFilter,
        }
    ) {
        try {
            const result = await ApprovalService.getApprovalList({
                limit: params.limit,
                page: params.page,
                filters: {
                    fromDate: !!params.filter.fromdate ? params.filter.fromdate : undefined,
                    toDate: !!params.filter.todate? params.filter.todate: undefined,
                    module: !!params.filter.module ? params.filter.module : undefined,
                    item: !!params.filter.item && !!params.filter.item.length ? params.filter.item : undefined,
                    userIds: !!params.filter.user && !!params.filter.user.length ? params.filter.user : undefined,
                    status: !!params.filter.status ? params.filter.status : ['Rejected'],
                },
            });
            this.context.commit('setFilterRejectedApprovals', result.data)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setFilterRejectedApprovals(data) {
        this.rejectedApprovals = data;
        this.filterApprovals = data;
        this.rejectedApprovalsCount = this.rejectedApprovals.length;
    }


    @Action
    async updateApprovalStatus(payload : UpdateRequestApprovalDto) {
        try {
        const response = await ApprovalService.updateApproval(payload)
        this.context.commit('updateApprovals', response)
        this.context.commit('updateApprovalsCount', payload);
        return {}
        } catch (error) {
            alert(error.message)
            return { error: error.message }
        }
    }

    @Mutation
    updateApprovals (response: string) {
      this.successResponse =response;
    }

    @Mutation
    updateApprovalsCount(data : UpdateRequestApprovalDto) {
        if (data.status === 'Approved') {
        this.approvedApprovalsCount = this.approvedApprovalsCount + data.oids.length;
      } else if (data.status === 'Rejected') {
        this.rejectedApprovalsCount = this.rejectedApprovalsCount + data.oids.length;
      }
    }

    @Action
    async getFile(oid: string) {
        try {
            const result = await ApprovalService.getFile(oid);
            this.context.commit('setDownloadFileData', result);
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setDownloadFileData(response: string) {
        this.downloadFileData = response;
    }

    @Action
    async productCsvExport(requestBody: CsvExportDto) {
        try {
            const result = await ApprovalService.productCsvExport(requestBody);
            this.context.commit('setProductCsv', result);
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setProductCsv(response) {
        this.exportCsvData = response;
    }
}
