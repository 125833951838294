
import { IProjectSettings } from '@master/config/IProjectSettings'
import AdviserDetails from '@master/model/kyc-form/AdviserDetails'
import CoreKYCForm from '@master/model/kyc-form/KYCForm'
import { IDateService } from '@master/services/IDateService'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import AdviserDeclaration from './AdviserDeclaration'
import KYCClient from './KYCClient'
import { CustomDependant } from './CustomDependant'
import CustomTrustedIndividual from './CustomTrustedIndividual'
import { IExistingInvestmentWrapper } from '@master/model/kyc-form/existing-plans/IExistingInvestmentWrapper'
import { IExistingPortfolioWrapper } from '@master/model/kyc-form/existing-plans/IExistingPortfolioWrapper'
import CustomExistingPortfolioDTO from './existing-plans/v2/CustomExistingPortfolioDTO'
import ExistingInvestmentDTO from '@master/model/kyc-form/existing-plans/v2/ExistingInvestmentDTO'
import CustomRecommendation from './CustomRecommendation'
import ILPPortfolioArray from '@master/model/kyc-form/ILPPortfolioArray'

export default class KYCForm extends CoreKYCForm {
  declare client1: KYCClient
  declare client2: KYCClient
  declare trustedIndividual: CustomTrustedIndividual
  declare dependants: CustomDependant[]
  declare recommendations: CustomRecommendation
  newIlpPortfolios: ILPPortfolioArray

  existingPortfolio: IExistingPortfolioWrapper = new CustomExistingPortfolioDTO()
  existingInvestment: IExistingInvestmentWrapper = new ExistingInvestmentDTO()

  adviser = {
    details: new AdviserDetails(),
    declaration: new AdviserDeclaration()
  }

  constructor (dateService: IDateService, projectSettings: IProjectSettings, riskRatingService: IRiskRatingService) {
    super(dateService, projectSettings, riskRatingService)

    this.client1 = new KYCClient('client_0', dateService, projectSettings)
    this.client2 = new KYCClient('client_1', dateService, projectSettings)
    this.dependants = Array(5).fill(null).map(() => new CustomDependant(dateService))
    this.trustedIndividual = new CustomTrustedIndividual()
    this.newIlpPortfolios = new ILPPortfolioArray()
    this.recommendations = new CustomRecommendation(riskRatingService)
  }
}
