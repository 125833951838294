import { get, isUndefined } from 'lodash/fp'

export interface ModelProxyConfig {
  watch: string;
  watchKeys?: string[];
  readKey?: string;
  react: string;
  reactKey?: string;
  mutualKeys?: string[];
  syncOnInit?: boolean;
}
/**
 * This is a service for creating js' proxy between model's attribute
 */
export default class ModelProxyService {
  modelSrc: any
  debug: boolean

  /**
   * @param inModelSrc: the source of models
   * @param inDebug: to see console logs
   */
  constructor (inModelSrc, inDebug = false) {
    this.modelSrc = inModelSrc
    if (this.debug) console.log(this.modelSrc)
    this.debug = inDebug
  }

  /**
   * if watchKeys and reactKey are defined, reactKey will get initial value of watchKeys
   * if mutualKeys are defined, no intial value will be set
   * if watchKeys length is more than 1, set the proxy to watch multiple keys and readKey must be defined
   * @param proxies {
   *  watch:
   *  watchKeys: an array of strings to check which has been triggered
   *  readKey: to be used when watchKeys are defined. This specific the key to read from object
   *  react: the object that is suppose to have its value changed
   *  reactKey: the attribute name to put the value in
   *  mutualKeys: an array of strings to define the attributes for both the watch and react target to read
   *  syncOnInit: a boolean value that defines if the value should be loaded on init
   * }
   */
  setProxy (proxies: ModelProxyConfig[]) {
    proxies.forEach((prox) => {
      const { parent: watchParent, target: watchTarget } = this.getTarget(this.modelSrc, prox.watch)
      const { parent: reactParent, target: reactTarget } = this.getTarget(this.modelSrc, prox.react)
      // if (this.debug) {
      //   console.log({
      //     watchkey: prox.watch,
      //     reactkey: prox.react,
      //     watchParent,
      //     watchTarget,
      //     reactParent,
      //     reactTarget
      //   })
      // }
      // if either one doesnt exist, exit
      if (isUndefined(watchTarget) || isUndefined(reactTarget)) {
        console.error(`Proxy targets in ${prox} not found in ${this}!`)
        return
      }
      const react = reactParent[reactTarget]
      // set initial value (only when not mutualkeys)
      if (prox.mutualKeys === undefined && prox.syncOnInit) {
        // Defines where to read the value from
        const keyToReadFrom = prox.readKey || prox.watchKeys[0]
        if (react instanceof Array) {
          react.forEach((each) => {
            if (prox.reactKey in each) each[prox.reactKey] = watchParent[watchTarget][keyToReadFrom]
          })
        } else {
          if (this.debug) console.log(reactParent, reactTarget, react)
          if (prox.reactKey in react) react[prox.reactKey] = watchParent[watchTarget][keyToReadFrom]
        }
      }
      // create proxy to keep synced
      if (prox.mutualKeys?.length > 0) {
        prox.mutualKeys.forEach((mkey) => {
          this.constructProxy(watchParent, watchTarget, react, [mkey], mkey)
        })
      } else {
        this.constructProxy(watchParent, watchTarget, react, prox.watchKeys, prox.reactKey, prox.readKey)
      }
    })
  }

  /**
   * diving into $obj until target is reached
   * @param obj
   * @param chain
   */
  private getTarget (obj, chain) {
    if (isUndefined(chain)) {
      console.error(`Failed to get ${chain} in ${obj}!`)
      return
    }
    const chainArr = chain.split('.')
    let parent, target
    if (chainArr.length === 1) {
      parent = obj
      target = chain
    } else {
      parent = get(chainArr.slice(0, chainArr.length - 1), obj)
      target = chainArr.slice(-1)
    }
    return { parent, target }
  }

  /**
   * creating proxy where when $watch is changed and $react will get the value too
   * @param watchParent: target's parent to listen for changes
   * @param watchTarget: target to listen for changes
   * @param react: target to change if triggered
   * @param watchKeys: array of string attribute that trigger proxy
   * @param reactKey: attribute to update when proxy is triggered
   * @param readKey: attribute to read from in the object
   */
  private constructProxy (watchParent, watchTarget, react, watchKeys: string[], reactKey, readKey?: string) {
    watchParent[watchTarget] = new Proxy(watchParent[watchTarget], {
      set: (obj, prop, value) => {
        obj[prop] = value

        const propString = prop.toString()
        if (watchKeys.includes(propString)) {
          if (watchKeys.length > 1) {
            if (!readKey) console.error('readKey is not configured when multiple watchKeys is set.')
            const readValue = obj[readKey]
            if (reactKey in react) {
              react[reactKey] = readValue
            } else console.error(`${reactKey} doesnt exist in ${react}!`)
          } else if (react instanceof Array) {
            react.forEach((each) => {
              if (reactKey in each) {
                each[reactKey] = value
              } else console.error(`${reactKey} doesnt exist in ${each}!`)
            })
          } else {
            if (reactKey in react) {
              react[reactKey] = value
            } else console.error(`${reactKey} doesnt exist in ${react}!`)
          }
        }
        return true
      }
    })
  }
}
