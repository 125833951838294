export const ClientManagementRoutes = [
  {
    path: 'profile',
    name: 'Account Details',
    component: () => import('@core/pages/clientManagement-v2/views/ClientProfile.vue')
  },
  {
    path: 'personal',
    name: 'Client Details',
    component: () => import('@core/pages/clientManagement-v2/views/ClientPersonal.vue')
  },
  {
    path: 'dependants',
    name: 'Relationships',
    component: () => import('@core/pages/clientManagement-v2/views/ClientDependants.vue')
  },
  {
    path: 'products',
    name: 'Policies',
    component: () => import('@core/pages/client-management/views/policy-management/PolicyList.vue')
  },
  {
    path: 'risks',
    name: 'AML / CKA / AI / OI Monitoring',
    component: () => import('@core/pages/clientManagement-v2/views/ClientRisks.vue')
  },
  {
    path: 'kycpdf',
    name: 'Submitted eFNA PDF',
    component: () => import('@core/pages/clientManagement-v2/views/ClientKycPdf.vue')
  },
  // {
  //   path: 'client-history',
  //   name: 'Account History',
  //   component: () => import('@core/pages/client-management/views/ClientAccountHistory.vue')
  // }
]