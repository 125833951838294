<template>
  <div id="switch">
    <template v-for="(option, index) in options" :key="option">
      <span v-if="index != 0" :key="index"></span>
      <a class="option"
        :class="{ selected: selected == option }"
        @click="optionSelected(option)">
        {{ option }}
      </a>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'

@Component
export default class AppSwitch extends Vue {
    @Prop() readonly options!: string[]
    @Prop() readonly selected!: string

    @Emit()
    optionSelected (option: string) {
      return option
    }
}

</script>

<style lang="scss" scoped>
@import '@/design';

#switch {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  width: 25%;
  justify-content: space-evenly;
  max-width: 450px;

  .option {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.56px;
    padding: 0 10px;
    border: 1px solid $form-disabled;
    padding: 8px 24px;
    border-radius: 100px;
    background: $color-neutral-min;
    min-width: fit-content;
    margin-right: 10px;

    &.selected {
      font-weight: 600;
      color: $switch-highlight;
      border-color: $form-primary;
      // background: $switch-bg;
    }
  }
  span {
    // height: 15px;
    // width: 1px;
    // background-color: black;
    // display: inline-block;
  }
}
</style>
