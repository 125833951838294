<template>
  <transition name="fade">
    <div class="loader">
      <div class="wrapper">
        <div class="ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class AppLoader extends Vue {
}
</script>
<style lang="scss" scoped>
@import '@/design';

.loader {
  @include overlay;

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      margin: 0px auto;
    }

    .ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 5px;
      border: 5px solid #d3d3d3;
      border-radius: 50%;
      animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #d3d3d3 transparent transparent transparent;
    }

    .ring div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .ring div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
