import { ProductFullMeta } from './ProductFullMeta'
import { ProductMetaList } from './ProductMetaList'

export class ProductFullMetaList extends ProductMetaList<ProductFullMeta> {
  constructor (data: (string | number)[][]) {
    super(data, ProductFullMeta)
  }

  get allIssuer () {
    return this.getIssuer(this.list)
  }

  get allProduct () {
    return this.list
  }
}
