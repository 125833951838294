import { SharedVariable } from '@core/model/SharedVariable'
import { UserMeta } from '@core/model/common/UserMeta'
import { AdviserLicenceDto, AdviserLicenceService } from '@core/openapi/services/adviser-licence/api'
import { LicenceProductMappingDto, LicenceProductMappingService, ProductLicenceDto, ProductLicenceService } from '@core/openapi/services/product-licence/api'
import {AccountService, CountryDetailDTO } from '@core/openapi/services/user-account/api'
import { UIService } from '@core/services/UIService'
import { cid, container } from 'inversify-props'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {Getter} from "vuex-facing-decorator";
import moment from 'moment'
import { BranchManagerService } from '../api'
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

@Module({ namespaced: true, name: 'BranchManagerStore' })
export default class UserStoreV2 extends VuexModule {
  loading = false
  countryList: CountryDetailDTO[] = [];
  defaultCountry: CountryDetailDTO = null;
  branchList: any = null;
  managerGroupList: any = null;
  managerGroupRecordsTotal: any = 0;
  branchRecordsTotal: any = 0;
  branchData: any = null;
  managerData: any = null;
  branchAllList: any = [];
  managerGroupAllList: any = [];

  @Action({ rawError: true })
  async fetchCountryList() {
    try {
      const response = await AccountService.getCountryList();
      this.context.commit('setCountryList', response);
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      });
    }
  }

  @Mutation
  setCountryList(data: CountryDetailDTO[]) {
    this.countryList = data;
    // Set default country based on priorit 
    if (data && data.length) {
      this.defaultCountry = data.find((item) => item.priority === 1);
    }
  }

  @Action({ rawError: true })
  async fetchBranchList({ pagination, limit, withAssignedLeader, searchFields }) {
    const branchData = await BranchManagerService.retrieveBranchList({ pagination, limit, withAssignedLeader, searchFields })
    this.context.commit('setBranchList', branchData)
  }

  @Action({ rawError: true })
  async fetchAllBranchList() {
    const branchData = await BranchManagerService.retrieveBranchDropdown()
    this.context.commit('setAllBranchList', branchData)
  }

  @Action({ rawError: true })
  async fetchAllManagerGroupList() {
    const managerData = await BranchManagerService.retrieveManagerList()
    this.context.commit('setManagerGroupAllList', managerData)
  }

  @Action({ rawError: true })
  async createBranch(branch) {
    try {
      const response = await BranchManagerService.createBranch(branch)
      this.context.commit('setBranchData', response)
      if (response) {
        await uiService.alert.show ({
          type: 'success',
          message: 'Data saved successfully'
        })
      }
    } catch (error) {
        await uiService.alert.show({
          type: 'error',
          message: error
        })
    }
    }
  
  @Action({ rawError: true })
  async createManager(manager) {
    try {
      const response = await BranchManagerService.createManagerGroup(manager)
      this.context.commit('setManagerData', response)
      if (response) {
        await uiService.alert.show ({
          type: 'success',
          message: 'Data saved successfully'
        })
      }
    } catch (error) {
        await uiService.alert.show({
          type: 'error',
          message: error
        })
    }
  }

  @Action({ rawError: true })
  async updateManager(manager) {
    try {
      const response = await BranchManagerService.updateManagerGroup(manager)
      this.context.commit('setManagerData', response)
      if (response) {
        await uiService.alert.show ({
          type: 'success',
          message: 'Data saved successfully'
        })
      }
    } catch (error) {
        await uiService.alert.show({
          type: 'error',
          message: error
        })
    }
  }

  @Action({ rawError: true })
  async updateBranch(branch) {
    try {
      const response = await BranchManagerService.updateBranch(branch)
      this.context.commit('setBranchData', response)
      if (response) {
        await uiService.alert.show ({
          type: 'success',
          message: 'Data saved successfully'
        })
      }
    } catch (error) {
        await uiService.alert.show({
          type: 'error',
          message: error
        })
    }
    }

  @Action({ rawError: true })
  async fetchManagerGroupList({ pagination, limit, withAssignedLeader, searchFields }) {
    const managerGroupList = await BranchManagerService.retrieveManagerGroupList({ pagination, limit, withAssignedLeader, searchFields })
    this.context.commit('setManagerGroupList', managerGroupList)
  }

  @Mutation
  setBranchList ({data, recordsTotal}) {
    this.branchList = data;
    this.branchRecordsTotal = recordsTotal;
  }

  @Mutation
  setAllBranchList ({data}) {
    this.branchAllList = data;
  }

  @Mutation
  setManagerGroupAllList(data) {
    this.managerGroupAllList = data;
  }

  @Mutation
  setBranchData (data) {
    this.branchData = data;
  }

  @Mutation
  setManagerData (data) {
    this.managerData = data;
  }

  @Mutation
  setManagerGroupList ({data, recordsTotal}) {
    this.managerGroupList = data;
    this.managerGroupRecordsTotal = recordsTotal;
  }
}
