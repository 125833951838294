import { RouteRecordRaw } from 'vue-router'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
export const commissionModuleRouter = ((): RouteRecordRaw[] => {
  const useNewCommissionModule = container.get<IProjectSettings>(cid.IProjectSettings).useNewCommissionModule

  if (useNewCommissionModule) {
    return [
      {
        path: '/commission-module/',
        component: () => import('@core/pages/CommissionModule/NewCommissionModule.vue'),
        redirect: '/commission-module/transactions',
        children: [
          {
            path: 'transactions',
            name: 'Transactions',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/Transactions.vue')
          },
          {
            path: 'vestings',
            name: 'Vestings',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/Vestings.vue')
          },
          {
            path: 'introducers',
            name: 'Introducers',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/Introducers.vue')
          },
          {
            path: 'servicing-agents',
            name: 'Servicing Agents',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/ServicingAgents.vue')
          },
          {
            path: 'reports',
            name: 'Reports',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/Reports.vue')
          },
          {
            path: 'commission-statements',
            name: 'Commission Statement',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/CommissionStatements.vue')
          },
          {
            path: 'other-income-deduction',
            name: 'Other Income and Deduction',
            component: () => import('@core/pages/CommissionModule/new-commission-module-components/OtherIncomeAndDeduction.vue')
          }
        ]
      },
      {
        path: '/commission-structure',
        name: 'Commission Structure',
        component: () => import('@core/pages/sales-team-administration/Landing.vue')
      },
      {
        path: '/commission-details',
        name: 'Commission Details',
        component: () => import('@core/pages/CommissionModule/CommissionDetails.vue')
      }
    ]
  } else {
    return [
      {
        path: '/commission-module/',
        component: () => import('@core/pages/CommissionModule/CommissionModule.vue')
      },
      {
        path: '/commission-structure',
        name: 'Commission Structure',
        component: () => import('@core/pages/sales-team-administration/Landing.vue')
      },
      {
        path: '/commission-details',
        name: 'Commission Details',
        component: () => import('@core/pages/CommissionModule/CommissionDetails.vue')
      }
    ]
  }
})()
