import { Base } from '@master/model/Base'

export default class ESignDoc extends Base {
  id = ''
  status = ''
  retrieved?: boolean = false

  extractData (rawKycPdf: any) {
    const rawData = rawKycPdf.esign_doc
    const data = {}
    if (rawData) {
      Object.keys(this).forEach((key) => {
        data[key] = rawData[key]
      })
    } else { // if no raw data found, assume all empty
      Object.keys(this).forEach((key) => {
        if (key === 'retrieved') {
          data[key] = false
        } else data[key] = ''
      })
    }
    return data
  }

  extractLatest (updatedDoc: any) {
    const rawData = updatedDoc
    if (rawData) {
      Object.keys(this).forEach((key) => {
        this[key] = rawData[this.snakeCaseKey(key)]
      })
    }
  }

  convertToRawData () {
    const result = {}
    Object.keys(this).forEach((key) => {
      result[key] = this[key]
    })
    return result
  }
}
