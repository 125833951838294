import { sum, fv, round, valueIsUndefined } from '../math'
import { snakeCase, isNull, isUndefined } from 'lodash/fp'
import { ArrayBase } from '../../../ArrayBase'
import { Ignore, isIgnore } from '@master/annotation/Ignore'

export default class EducationNeedsDetail extends ArrayBase {
  type = 's4_c'
  inflation: number = null
  providedFor = ''
  age: number = null
  costOfEducation: number = null
  countryOfStudy = ''
  courseDuration: number = null
  yearsToSave: number = null
  maturityValueOfExistingPolicies: number = null
  existingResources: number = null
  startIndex = 1 // s4_c1, s4_c2,...

  @Ignore()
  defaultRate?: number

  @Ignore()
  disabledInput = false

  constructor (id = 's4') {
    super()
    this.type = `${id}_c`
  }

  /**
   * this is to override default behavior where s4 is transformed to s_4
   */
  snakeCaseKey (key: string) {
    return this.type + snakeCase(`${this.curIndex + this.startIndex} ${key}`)
  }

  /**
   * this will auto set the corresponding rate to default if undefined
   * this is to allow implementation side to set default rate to the model
   * @param inDefaultRate
   */
  setupDefaultRate (inDefaultRate: number) {
    this.defaultRate = inDefaultRate
    if (isUndefined(this.inflation)) this.inflation = this.defaultRate
  }

  get totalEducationCost () {
    if (valueIsUndefined(this.costOfEducation)) return null
    const totalFV = fv(this.costOfEducation, this.yearsToSave, (this.inflation || 0) / 100)
    const total = this.courseDuration * totalFV
    return round(total, 0)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.maturityValueOfExistingPolicies) && valueIsUndefined(this.existingResources) && valueIsUndefined(this.totalEducationCost)) return null
    const total = sum(this.maturityValueOfExistingPolicies, this.existingResources, -this.totalEducationCost)
    return total
  }

  get isDefaultRate () {
    return this.defaultRate === this.inflation
  }

  isValid () {
    return !isUndefined(this.providedFor) && !isNull(this.providedFor) && this.providedFor !== ''
  }

  get shouldDisabledInput () {
    return this.disabledInput || !this.isValid()
  }

  clear () {
    Object.keys(this).forEach(key => {
      if (key === 'type' || isIgnore(this, key) || key === 'startIndex') {
        return
      }
      this[key] = null
    })
  }
}
