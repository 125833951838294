import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from 'axios'
import ESub from './model/ESub'
import { Assumption } from '@master/model/common/Assumption'
import NtucNric from '@master/model/kyc-pdf/NtucNric'
import FormKeyConverter from '@/views/kyc-pdf/FormKeyConverter'
import { FormOptions } from '@master/model/kyc-form/FormOptions'
import KYCPdf from '@company-common/model/kyc-pdf/KYCPdf'
import { cid, container } from 'inversify-props'
import { IDateService } from '@master/services/IDateService'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import PreTransactionalCheck from './model/ptc/PreTransactionalCheck'
// import { KycPdfV2Service } from '@core/openapi/services/kyc-pdf-v2/api/services/KycPdfV2Service'
import { KycPdfV2Service } from '@core/openapi/services/default/api/services/KycPdfV2Service'

const dateService = container.get<IDateService>(cid.IDateService)
const projectSettings = container.get<IProjectSettings>(cid.IProjectSettings)
const riskRatingService = container.get<IRiskRatingService>(cid.IRiskRatingService)

let kycPdf: KYCPdf
const ptcCheck = new PreTransactionalCheck(kycPdf)

@Module({
  namespaced: true, name: 'KYCPdfStore'
})
export default class KYCPdfStore extends VuexModule {
  kycPdf = new KYCPdf(dateService, projectSettings, riskRatingService, ptcCheck)
  formOptions: FormOptions = null
  assumption: Assumption = new Assumption()
  loadingState: 'loading' | 'loaded' | 'saving' = 'loading'
  allowedTabs = []
  showSignModule = false
  computedValidationResult = {}
  kycPdfErrors = undefined
  eSubData = new ESub()
  ntucNrics = []

  // State changes
  showCompletionModalState = false

  get atLeastOneProductRecommended () { // el-mandate
    return this.kycPdf.recommendations.rcmd instanceof Array && this.kycPdf.recommendations.rcmd.length > 0
  }

  @Mutation
  setKYCPdf (kycPdf: any) {
    this.kycPdf.setData(kycPdf)
    this.formOptions = new FormOptions(this.kycPdf)
  }

  @Mutation
  setESubData (esub: object) {
    this.eSubData.extractData(esub)
  }

  @Mutation
  updateESub (latest: any) {
    const client = this.eSubData.client
    if (client && client.length) {
      const ids = latest.identifier
      client.forEach((eclient) => {
        eclient.external.forEach((exsub) => {
          const matched = !Object.keys(ids).map((id) => {
            return exsub[id] === ids[id]
          }).includes(false)
          if (matched) {
            exsub.updateProperties(latest.data)
          }
        })
        eclient.internal.forEach((insub) => {
          const matched = !Object.keys(ids).map((id) => {
            return insub[id] === ids[id]
          }).includes(false)
          if (matched) {
            insub.updateProperties(latest.data)
          }
        })
      })
    }
  }

  @Mutation
  setNtucNrics (ntuc: []) {
    this.ntucNrics = Object.assign([], this.ntucNrics, ntuc)
    this.ntucNrics.forEach((ntucNric) => {
      const ntucCl = ntucNric.client_list.map((clist) => {
        return new NtucNric(clist)
      })
      delete ntucNric.client_list
      ntucNric.clientList = ntucCl
    })
  }

  @Mutation
  setupAllowableTab (tab: string) {
    this.allowedTabs.push(tab)
  }

  @Mutation
  setKYCPdfErrors (errors: {}) {
    this.kycPdfErrors = errors
  }

  @Mutation
  setShowSignModule (state?: boolean) {
    this.showSignModule = state
  }

  @Mutation
  setLoadingState (state: 'loading' | 'loaded' | 'saving') {
    this.loadingState = state
  }

  @Mutation
  setShowCompletionModalState (state: boolean) {
    this.showCompletionModalState = state
  }

  @Action
  updateShowCompletionModalState (state: boolean) {
    this.context.commit('setShowCompletionModalState', state)
  }

  // add rawError to get the raw error during this action to get better knowing where the issue come from
  @Action({ rawError: true })
  async loadKYCPdf (kycPdfOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const data = await KycPdfV2Service.getKycPdf(kycPdfOid)
    // const converter = new FormKeyConverter()
    // const formattedKYCPdf = converter.mapRawToFormatted(kycPdf)
    //  convert data based on kycform
    // const kycFormConverter = new KycFormKeyConverter()
    // const formattedKYCForm = kycFormConverter.mapRawToFormatted(kycPdf)

    // if (error) {
    //   this.context.commit('setKYCPdfErrors', error)
    //   this.context.commit('setLoadingState', 'loaded')
    //   return formattedKYCPdf
    // }
    //
    // pre processor that will change all the ma to the standardize format
    //
    // console.log('Formatted', formattedKYCPdf)
    // this.context.commit('setKYCPdf', formattedKYCPdf)
    this.context.commit('setLoadingState', 'loaded')
  }

  // add rawError to get the raw error during this action to get better knowing where the issue come from
  @Action({ rawError: true })
  async loadKYCPdfV2 (kycPdfOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const result = await KycPdfV2Service.getKycPdf(kycPdfOid)
    const converter = new FormKeyConverter()
    if (!result) return this.context.commit('setLoadingState', 'loaded')
    const formattedKYCPdf = converter.mapRawToFormatted(result?.data)
    if (result?.error) {
      this.context.commit('setKYCPdfErrors', result?.error)
      this.context.commit('setLoadingState', 'loaded')
      return formattedKYCPdf
    }

    this.context.commit('setKYCPdf', formattedKYCPdf)
    this.context.commit('setLoadingState', 'loaded')
  }

  @Action({ rawError: true })
  loadESub (res: any) {
    if (res != null && typeof res === 'object' && res.eSubmissionData) {
      this.context.commit('setESubData', JSON.parse(res.eSubmissionData))
    }
  }

  @Action
  upESub (delta: any) {
    this.context.commit('updateESub', delta)
  }

  @Action({ rawError: true })
  /* eslint-disable @typescript-eslint/camelcase */
  loadNtucNricList (res: any) {
    if (res != null && typeof res === 'object' && res.nric_list) {
      this.context.commit('setNtucNrics', res.nric_list)
      // the following is for testing only
      // const dummy = [
      //   {
      //     ntucESubmissionMasterOid: 'EpwAiBYJFKCUXBjTruHEV9',
      //     client_list: [
      //       {
      //         client_name: 'Testing client name',
      //         ntucClientNricOid: 'some Oid',
      //         nric_list: [
      //           {
      //             fileName: 'Testing 001',
      //             fileType: 'PNG'
      //           }, {
      //             fileName: 'Testing 002',
      //             fileType: 'PNG'
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // ]
      // this.context.commit('setNtucNrics', dummy)
    }
  }
}
