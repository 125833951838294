import { cid, container } from 'inversify-props'
import { Store } from 'vuex'
import { SharedVariable } from './../model/SharedVariable'
import { UIService } from '@core/services/UIService'
import { AccountService } from '@core/openapi/services/user-account/api'
import { UserAccessControlService } from '@core/openapi/services/user-access-control/api'
import UserStore from '@core/pages/user-profile/store/UserStore'
import store from "src/store/index";
import { getModule } from 'vuex-module-decorators'
const userStore = getModule(UserStore, store);
export const permissionChecking = (store, to) => {
  // For now, just a strict hardcoded check, but when permission is created
  // using casl ability, we will use that to check instead, for now,
  // Check if user has rights to the route
  const permission = store.getters.currentAllPermission
  const currentUser = store.getters.currentUser
  const subject = to.meta.subject
  if (!subject) {
    return
  }
  const currentPermission = permission.filter((value) => subject.includes(value.subject))
  // console.log('*****currentPermission', currentPermission)
  // console.log('*currentUser', currentUser)
  // console.log('*to', to)
  // permission target: 'All' | 'Self' | 'Others'
  const isCurrentPermissionValid = () => {
    let valid = true
    currentPermission.forEach(permission => {
      if (
        permission.target === 'all' ||
        (
          permission.target === 'others' &&
          permission.conditions &&
          to.params[permission.conditions]
        )
      ) return
      if (
        permission.target === 'self' &&
        permission.conditions &&
        to.params[permission.conditions] &&
        to.params[permission.conditions] !== currentUser.oid
      ) {
        valid = false
      }
    })
    return valid
  }
  // console.log('*isCurrentPermissionValid', isCurrentPermissionValid())
  if (!currentPermission.length || !isCurrentPermissionValid()) {
    throw new Error('Unauthorized access to page')
  }
  store.dispatch('getPermission', currentPermission)
}

export function routerRedirect (store: Store<any>, publicPage: string[]) {
  const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
  const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

  return async (to, from, next) => {
    // if is public page, no need to login, continue
    if (publicPage.includes(to.name)) {
      return next()
    }

    // If the user is logged in, continue
    if (store.getters.currentUser.isLogin) {
      // Checking for permission
      const permission = store.getters.currentAllPermission.length ? store.getters.currentAllPermission : await getPermission()
      await store.dispatch('getAllPermission', permission)
      try {
        await permissionChecking(store, to)
      } catch (error) {
        await uiService.alert.show({
          type: 'error',
          message: error
        })
        return next({ path: '/', replace: true })
      }
      return next()
    }

    // If the user is not logged in, redirect to login page and
    // let the login page handle the redirection accordingly
    const { params, hash, query } = to
    /* eslint-disable @typescript-eslint/camelcase */
    query.redirect_uri = to.path // this should be in sync with LoginMixin's redirectHome
    /* eslint-enable */
    try {
      const user =await userStore.fetchCurrentLoggedInUser()
      if (user) {
        const response = await store.dispatch('login', user)
        const permission = store.getters.currentAllPermission.length ? store.getters.currentAllPermission : await getPermission()
        await store.dispatch('getAllPermission', permission)
        try {
          await permissionChecking(store, to)
        } catch (error) {
          await uiService.alert.show({
            type: 'error',
            message: error
          })
          return next({ path: '/', replace: true })
        }
        return next()
      } else {
        uiService.alert.show({
          type: 'error',
          message: 'Please login into your account.'
        })
        return next({ path: '/', params, hash, query, replace: true })
      }
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: 'Please login into your account.'
      })
      return next({ path: '/', params, hash, query, replace: true })
    }
  }
}

async function getPermission () {
  const rolesPermission = await UserAccessControlService.retirieveUserAccessControl()
  return rolesPermission?.permissions
}
