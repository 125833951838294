/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdhocReportRequest } from '../models/AdhocReportRequest';
import type { PresalesCallbackReportRequest } from '../models/PresalesCallbackReportRequest';
import { request as __request } from '@openapi/request';

export class AdhocReportService {

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async runPresalesCallbackReport(
        requestBody: PresalesCallbackReportRequest,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adhoc/presales`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async runAdHocReport(
        requestBody: AdhocReportRequest,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adhoc/report-service`,
            body: requestBody,
        });
        return result.body;
    }

}