import IVersionInfo from '@core/config/IVersionInfo'
export default class ProjectVersion implements IVersionInfo {
  name = 'Mocha'
  version = 'Version 2.1.0 : Last updated on 18/08/2020'
  changeLog = [
    {
      version: 'Version 2.0.9',
      date: '31/12/2019',
      changes: [
        'Completion check fixes',
        'RPA section not visible fixes'
      ]
    },
    {
      version: 'Version 2.0.8',
      date: '23/12/2019',
      changes: [
        'CKA main field text change and updated PDF template',
        'Completion check fix for RPA'
      ]
    },
    {
      version: 'Version 2.0.7',
      date: '23/12/2019',
      changes: [
        'Completion check fix for Risk Profile Assessment section',
        'Clear RPA subsection if custom checkbox is checked',
        'Display confirmation dialogue box when unchecking the custom checkbox'
      ]
    },
    {
      version: 'Version 2.0.6',
      date: '22/12/2019',
      changes: [
        'Age calculation logic changes - uses date of review instead of current date and time',
        'Removed RPA main checkbox field - section is always compulsory',
        'Fixed indicate priority type pdf field for Others section in Needs Analysis'
      ]
    },
    {
      version: 'Version 2.0.5',
      date: '19/12/2019',
      changes: [
        'CKA and RPA section fixes',
        'Needs Analysis Calculation \'Indicate Priority Type\' field fixes'
      ]
    },
    {
      version: 'Version 2.0.4',
      date: '18/12/2019',
      changes: [
        'Risk profile assessment, main field checkbox is now independent from CKA main field checkbox',
        'Risk profile assessment, clear section fields if main checkbox is checked',
        'Risk profile assessment, chosen rp is independent of - (last option) ; rp result of (last option) does not affect anything',
        'Risk profile assessment, completion check - chosen risk profile cannot be empty',
        'CKA, completion check - if any of the yes/no fields is selected \'yes\', at least one of the sub-checkboxes has to be checked',
        'Needs analysis calculation, when main field (indicate priority type) is selected, at least one of the fields in the respective columns have to be selected',
        'Product recommendation, move the 3 textareas into one section, leave product deviation as it is, header text change, PDF pg under each textarea'
      ]
    },
    {
      version: 'Version 2.0.3',
      date: '21/11/2019',
      changes: [
        'Post feedback document changes'
      ]
    },
    {
      version: 'Version 2.0.2',
      date: '14/11/2019',
      changes: [
        'Your Finances section calculation fixes'
      ]
    },
    {
      version: 'Version 2.0.1',
      date: '04/08/2017',
      changes: [
        'Merged phase-3 changes'
      ]
    },
    {
      version: 'Version 0.0.1',
      date: '20/01/2015',
      changes: [
        'Initial custom version 0.0.1 tracking'
      ]
    }
  ]
}
