import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = { id: "nav-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["id", "onMouseover", "title"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "icon"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick", "target"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 0,
  class: "dropdown-inline"
}
const _hoisted_14 = ["href", "onMouseover"]
const _hoisted_15 = ["href", "target", "title"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 0,
  class: "side-logo-wrapper"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = { slot: "body" }
const _hoisted_21 = ["value"]
const _hoisted_22 = {
  key: 1,
  class: "hamburger"
}
const _hoisted_23 = { id: "nav-list" }
const _hoisted_24 = ["src"]
const _hoisted_25 = ["id", "onMouseover", "title"]
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  key: 1,
  class: "icon"
}
const _hoisted_28 = ["src"]
const _hoisted_29 = ["onClick", "target"]
const _hoisted_30 = ["src"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["src"]
const _hoisted_33 = ["src"]
const _hoisted_34 = {
  key: 0,
  class: "dropdown-inline"
}
const _hoisted_35 = ["href", "onMouseover"]
const _hoisted_36 = ["href", "target", "title"]
const _hoisted_37 = ["src"]
const _hoisted_38 = { key: 1 }
const _hoisted_39 = {
  key: 0,
  class: "side-logo-wrapper"
}
const _hoisted_40 = ["src"]
const _hoisted_41 = { slot: "body" }
const _hoisted_42 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDialog = _resolveComponent("AppDialog")!
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.withLogo)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          class: _normalizeClass(["hamburger", { mini: true }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "logo",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
            })
          ]),
          _createElementVNode("nav", null, [
            _createElementVNode("ul", _hoisted_2, [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                class: "icon",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
              }, [
                _withDirectives(_createElementVNode("img", {
                  src: _ctx.hamburgerIcon,
                  alt: "svg image"
                }, null, 8, _hoisted_3), [
                  [_directive_svg_inline]
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["menu-overlay", {'open': _ctx.isMenuOpen}])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, ({ disable, event, href, target, icon, label, dropdown, customStyle, viewport, iconDown, iconUp }, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (!disable)
                        ? (_openBlock(), _createElementBlock("li", {
                            key: index,
                            id: `nav-item-${index}`,
                            onMouseout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hovering=-1)),
                            onMouseover: ($event: any) => (_ctx.hovering=index),
                            class: _normalizeClass([{ 'has-drop': dropdown && dropdown.length > 0, focus: _ctx.hovering === index, 'dd-flip': _ctx.isOutOfView(`nav-item-${index}`) }, label]),
                            style: _normalizeStyle(customStyle),
                            title: label
                          }, [
                            (_ctx.enableVirtualMeeting)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startVirtualMeeting && _ctx.startVirtualMeeting(...args)))
                                }, [
                                  _withDirectives(_createElementVNode("img", {
                                    src: _ctx.videocamIcon,
                                    alt: "svg image"
                                  }, null, 8, _hoisted_5), [
                                    [_directive_svg_inline]
                                  ]),
                                  _cache[15] || (_cache[15] = _createElementVNode("p", null, "Start Virtual Meeting", -1))
                                ]))
                              : viewport
                                ? (_openBlock(), _createElementBlock("a", _hoisted_6, [
                                    _withDirectives(_createElementVNode("img", {
                                      src: icon,
                                      alt: "svg image"
                                    }, null, 8, _hoisted_7), [
                                      [_directive_svg_inline]
                                    ])
                                  ]))
                                : event
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 2,
                                      onClick: ($event: any) => (_ctx.$emit(event)),
                                      target: target
                                    }, [
                                      _withDirectives(_createElementVNode("img", {
                                        src: icon,
                                        alt: "svg image"
                                      }, null, 8, _hoisted_9), [
                                        [_directive_svg_inline]
                                      ]),
                                      _createElementVNode("span", null, _toDisplayString(label), 1)
                                    ], 8, _hoisted_8))
                                  : (dropdown && dropdown.length > 0)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.dropdownChange($event, index))
                                        }, [
                                          _withDirectives(_createElementVNode("img", {
                                            src: icon,
                                            alt: "svg image"
                                          }, null, 8, _hoisted_11), [
                                            [_directive_svg_inline]
                                          ]),
                                          _createElementVNode("span", null, _toDisplayString(label), 1),
                                          _withDirectives(_createElementVNode("img", {
                                            src: iconDown,
                                            alt: "svg image"
                                          }, null, 8, _hoisted_12), [
                                            [_directive_svg_inline]
                                          ])
                                        ], 8, _hoisted_10),
                                        (_ctx.clickedIndex == index && _ctx.dropdownIcon)
                                          ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdown, (item, i) => {
                                                return (_openBlock(), _createElementBlock("li", { key: i }, [
                                                  (!item.disable)
                                                    ? (_openBlock(), _createElementBlock("a", {
                                                        key: 0,
                                                        href: item.href,
                                                        onMouseover: ($event: any) => (_ctx.hovering=index)
                                                      }, _toDisplayString(item.icon) + " " + _toDisplayString(item.label), 41, _hoisted_14))
                                                    : _createCommentVNode("", true)
                                                ]))
                                              }), 128))
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 4,
                                        href: href,
                                        target: target,
                                        title: label
                                      }, [
                                        _withDirectives(_createElementVNode("img", {
                                          src: icon,
                                          alt: "svg image"
                                        }, null, 8, _hoisted_16), [
                                          [_directive_svg_inline]
                                        ]),
                                        (label == 'cancel')
                                          ? (_openBlock(), _createElementBlock("span", {
                                              key: 0,
                                              class: "cancel",
                                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
                                            }))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(label), 1))
                                      ], 8, _hoisted_15))
                          ], 46, _hoisted_4))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ], 2)
              ])
            ]),
            (_ctx.showSideLogo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _withDirectives(_createElementVNode("img", {
                    id: "mochaLogo",
                    class: "mocha-logo side-logo",
                    src: _ctx.mochaIcon,
                    alt: "svg image",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
                  }, null, 8, _hoisted_19), [
                    [_directive_svg_inline]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.enableVirtualMeeting)
            ? (_openBlock(), _createBlock(_component_AppDialog, {
                key: 0,
                class: "virtual-meeting-url",
                onClosed: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isCopied = false)),
                hideFooter: true
              }, {
                header: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Virtual Meeting URL")
                ])),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("p", null, [
                      _cache[17] || (_cache[17] = _createTextVNode("Click on the URL to copy: ")),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.isCopied ? 'Copied!' : ''), 1)
                    ]),
                    _createElementVNode("div", {
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.copyUrl && _ctx.copyUrl(...args))),
                      class: "jitsi-url"
                    }, [
                      _createElementVNode("input", {
                        id: "url",
                        value: _ctx.jitsiUrl,
                        readonly: ""
                      }, null, 8, _hoisted_21)
                    ])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("header", _hoisted_22, [
          _createElementVNode("aside", null, [
            _createElementVNode("nav", null, [
              _createElementVNode("ul", _hoisted_23, [
                _createElementVNode("a", {
                  href: "javascript:void(0)",
                  class: "icon",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
                }, [
                  _withDirectives(_createElementVNode("img", {
                    class: "icon-hamburger",
                    src: _ctx.hamburgerIcon,
                    alt: "svg image"
                  }, null, 8, _hoisted_24), [
                    [_directive_svg_inline]
                  ]),
                  _createElementVNode("div", {
                    id: "overlay",
                    class: _normalizeClass({'overlay': _ctx.isMenuOpen})
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["menu-overlay", {'open': _ctx.isMenuOpen, 'inKYC': !_ctx.withLogo}])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, ({ disable, event, href, target, icon, label, dropdown, customStyle, viewport, iconDown, iconUp }, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (!disable)
                          ? (_openBlock(), _createElementBlock("li", {
                              key: index,
                              id: `nav-item-${index}`,
                              onMouseout: _cache[10] || (_cache[10] = ($event: any) => (_ctx.hovering=-1)),
                              onMouseover: ($event: any) => (_ctx.hovering=index),
                              class: _normalizeClass([{ 'has-drop': dropdown && dropdown.length > 0, focus: _ctx.hovering === index, 'dd-flip': _ctx.isOutOfView(`nav-item-${index}`) }, label]),
                              style: _normalizeStyle(customStyle),
                              title: label
                            }, [
                              (_ctx.enableVirtualMeeting)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.startVirtualMeeting && _ctx.startVirtualMeeting(...args)))
                                  }, [
                                    _withDirectives(_createElementVNode("img", {
                                      src: _ctx.videocamIcon,
                                      alt: "svg image"
                                    }, null, 8, _hoisted_26), [
                                      [_directive_svg_inline]
                                    ]),
                                    _cache[18] || (_cache[18] = _createElementVNode("p", null, "Start Virtual Meeting", -1))
                                  ]))
                                : viewport
                                  ? (_openBlock(), _createElementBlock("a", _hoisted_27, [
                                      _withDirectives(_createElementVNode("img", {
                                        src: icon,
                                        alt: "svg image"
                                      }, null, 8, _hoisted_28), [
                                        [_directive_svg_inline]
                                      ])
                                    ]))
                                  : event
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        onClick: ($event: any) => (_ctx.$emit(event)),
                                        target: target
                                      }, [
                                        _withDirectives(_createElementVNode("img", {
                                          src: icon,
                                          alt: "svg image"
                                        }, null, 8, _hoisted_30), [
                                          [_directive_svg_inline]
                                        ]),
                                        _createElementVNode("span", null, _toDisplayString(label), 1)
                                      ], 8, _hoisted_29))
                                    : (dropdown && dropdown.length > 0)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                          _createElementVNode("a", {
                                            onClick: ($event: any) => (_ctx.dropdownChange($event, index))
                                          }, [
                                            _withDirectives(_createElementVNode("img", {
                                              src: icon,
                                              alt: "svg image"
                                            }, null, 8, _hoisted_32), [
                                              [_directive_svg_inline]
                                            ]),
                                            _createElementVNode("span", null, _toDisplayString(label), 1),
                                            _createElementVNode("embed", {
                                              class: _normalizeClass({'icon-space': _ctx.dropdownIcon == true}),
                                              src: _ctx.dropdownIcon ? iconUp: iconDown
                                            }, null, 10, _hoisted_33)
                                          ], 8, _hoisted_31),
                                          (_ctx.clickedIndex == index && _ctx.dropdownIcon)
                                            ? (_openBlock(), _createElementBlock("ul", _hoisted_34, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdown, (item, i) => {
                                                  return (_openBlock(), _createElementBlock("li", { key: i }, [
                                                    (!item.disable)
                                                      ? (_openBlock(), _createElementBlock("a", {
                                                          key: 0,
                                                          href: item.href,
                                                          onMouseover: ($event: any) => (_ctx.hovering=index)
                                                        }, _toDisplayString(item.icon) + " " + _toDisplayString(item.label), 41, _hoisted_35))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      : (_openBlock(), _createElementBlock("a", {
                                          key: 4,
                                          href: href,
                                          target: target,
                                          title: label
                                        }, [
                                          _withDirectives(_createElementVNode("img", {
                                            src: icon,
                                            alt: "svg image"
                                          }, null, 8, _hoisted_37), [
                                            [_directive_svg_inline]
                                          ]),
                                          (label == 'cancel')
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: "cancel",
                                                onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["prevent"]))
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(label), 1))
                                        ], 8, _hoisted_36))
                            ], 46, _hoisted_25))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ], 2)
                ])
              ]),
              (_ctx.showSideLogo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _withDirectives(_createElementVNode("img", {
                      id: "mochaLogo",
                      class: "mocha-logo side-logo",
                      src: _ctx.mochaIcon,
                      alt: "svg image",
                      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
                    }, null, 8, _hoisted_40), [
                      [_directive_svg_inline]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.enableVirtualMeeting)
            ? (_openBlock(), _createBlock(_component_AppDialog, {
                key: 0,
                class: "virtual-meeting-url",
                onClosed: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isCopied = false)),
                hideFooter: true
              }, {
                header: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode("Virtual Meeting URL")
                ])),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("p", null, [
                      _cache[20] || (_cache[20] = _createTextVNode("Click on the URL to copy: ")),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.isCopied ? 'Copied!' : ''), 1)
                    ]),
                    _createElementVNode("div", {
                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.copyUrl && _ctx.copyUrl(...args))),
                      class: "jitsi-url"
                    }, [
                      _createElementVNode("input", {
                        id: "url",
                        value: _ctx.jitsiUrl,
                        readonly: ""
                      }, null, 8, _hoisted_42)
                    ])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
  ]))
}