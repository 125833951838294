import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export const SpokenLangOptions = [
  { label: '', value: '' },
  { label: 'English', value: 'english' },
  { label: 'Mandarin', value: 'mandarin' },
  { label: 'Malay', value: 'malay' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Others', value: 'others' }
]
export const WrittenLangOptions = [
  { label: '', value: '' },
  { label: 'English', value: 'english' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'Malay', value: 'malay' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Others', value: 'others' }
]
export const EduLevelOptions = [
  { label: '', value: '' },
  { label: 'Primary & below', value: 'primaryandbelow' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'GCE \'O/N\' Level', value: 'gceonlevel' },
  { label: 'Pre-U/JC', value: 'preujc' },
  { label: 'Diploma', value: 'diploma' },
  { label: 'Degree', value: 'degree' },
  { label: 'Post Graduate', value: 'postgrad' }
]
export const IncomeTierOptions = [
  { label: '', value: '' },
  { label: '< 30,000', value: 'tier1' },
  { label: '30,000 - 50,000', value: 'tier2' },
  { label: '50,001 - 100,000', value: 'tier3' },
  { label: '100,001 - 150,000', value: 'tier4' },
  { label: '150,001 - 300,000', value: 'tier5' },
  { label: '> 300,000', value: 'tier6' }
]
export const EmploymentStatOptions = [
  { label: '', value: '' },
  { label: 'Full Time', value: 'fulltime' },
  { label: 'Self Employed', value: 'selfemployed' },
  { label: 'Part Time', value: 'parttime' },
  { label: 'Not Employed', value: 'unemployed' }
]
export const ActivitiesOptions = [
  { label: '', value: '' },
  { label: 'Money changer', value: 'Money changer' },
  { label: 'Remittance agent', value: 'Remittance agent' },
  { label: 'Casino', value: 'Casino' },
  { label: 'Pawnbroker', value: 'Pawnbroker' },
  { label: 'Moneylender', value: 'Moneylender' },
  { label: 'Senior management or equivalent of non-profit organization', value: 'Senior management or equivalent of non-profit organization' },
  { label: 'Precious stones & metal dealer', value: 'Precious stones & metal dealer' },
  { label: 'Virtual currency (eg.bitcoins, precious metal backed digital currency etc)', value: 'Virtual currency ' },
  { label: 'Antique trader', value: 'Antique trader' },
  { label: 'Owner of cash-intensive (eg. hawker, convenience store, second-hand dealer, coffeeshop operator etc)', value: 'Owner of cash-intensive' },
  { label: 'Activities related to production / distribution of arms related products, trading of oil and natural gas (eg. crude oil, processed oil)', value: 'Activities related to production / distribution of arms related products, trading of oil and natural gas' },
  { label: 'Agriculture (eg. wheat, barley, livestock, fruit, cotton)', value: 'Agriculture ' },
  { label: 'Mineral resource trader (eg. phosphate mines, salt mines, cement plants, sulphuric manufacturer) ', value: 'Mineral resource trader' }
]
export class ClientPersonal extends Meta {
  nationality = undefined
  nationalityOthers = undefined
  nationalityOthers2 = undefined
  gender = undefined
  maritalStatus = undefined
  dob = undefined

  @RawKey('residential_address1')
  residentialAddress1 = undefined

  @RawKey('country_of_residence1')
  countryOfResidence1 = undefined

  @RawKey('postal_code1')
  postalCode1 = undefined

  @RawKey('residential_address2')
  residentialAddress2 = undefined

  @RawKey('country_of_residence2')
  countryOfResidence2 = undefined

  @RawKey('postal_code2')
  postalCode2 = undefined

  @RawKey('residential_address3')
  residentialAddress3 = undefined

  @RawKey('country_of_residence3')
  countryOfResidence3 = undefined

  @RawKey('postal_code3')
  postalCode3 = undefined

  @RawKey('mobile_no1')
  mobileNo1 = undefined

  @RawKey('office_no1')
  officeNo1 = undefined

  @RawKey('home_no1')
  homeNo1 = undefined

  @RawKey('email1')
  email1 = undefined

  @RawKey('mobile_no2')
  mobileNo2 = undefined

  @RawKey('office_no2')
  officeNo2 = undefined

  @RawKey('home_no2')
  homeNo2 = undefined

  @RawKey('email2')
  email2 = undefined

  @RawKey('mobile_no3')
  mobileNo3 = undefined

  @RawKey('office_no3')
  officeNo3 = undefined

  @RawKey('home_no3')
  homeNo3 = undefined

  @RawKey('email3')
  email3 = undefined

  employmentStatus = undefined
  eduLvl = undefined
  exactDuties = undefined
  occupation = undefined
  companyName = undefined
  annualIncomeRange = undefined
  spokenLang = []
  spokenLangOthers = undefined
  writtenLang = []
  writtenLangOthers = undefined
  smokerStatus: 'yes' | 'no' | '' = undefined
  medicalConditionOption: 'yes' | 'no' | '' = undefined
  medicalCondition = undefined
  involvedInActivities: 'yes' | 'no' | '' = undefined
  activitiesSpecified = undefined
  mobileNoOne?: any
  mobileCountryCodeNoOne?:any
  officeCountryCodeNoOne?:any
  officeNoOne?:any


}
