import { FormBase } from '../FormBase'
import { EmploymentTypeOptions } from '@master/model/options/EmploymentTypeOptions'
import { EmployeeStatusOptions } from '@master/model/options/EmployeeStatusOptions'

export class EmployerConsentFields {
  category: typeof EmploymentTypeOptions[number]['value'] = ''
  weeklyHours: number = undefined
  contractStart: number = undefined
  contractEnd: number = undefined
  position = ''
  status: typeof EmployeeStatusOptions[number]['value'] = ''
  // authorizedSignature: signature
  // authorizedName: string
  // authorizedDesignation: string
  // authorizedContactNo: string
}
export class EmployerConsent extends FormBase {
  // shared.name
  // shared.nric
  type = 'employer-consent'

  data: EmployerConsentFields = new EmployerConsentFields()
}
