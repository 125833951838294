import { Base } from '../Base'

export interface Fund {
  name: string;
  invAmt: number;
  frequency: string;
  paymentMode: string;
  for: string;
  choice: string;
}
export default class ILPPortfolioV2 extends Base {
  buySwitch = ''
  choice = ''
  for = ''
  frequency = ''
  // Future way of using funds, previous fund and inv are for backward compatibility.
  funds: Fund[] = []

  name = ''
  platform = ''
  accountNumber = ''

  oid = ''
  issuerOid = ''
  paymentMode = ''
  rcmdReferenceId = '' // this used for mapping on the NBR, it will show relevant ilp subList on nbr detail page

  clearData () {
    const excludeKeys = ['type', 'startIndex', 'curIndex']
    Object.keys(this).forEach(key => {
      if (!excludeKeys.includes(key)) {
        // need assign value as empty string to overwrite the data that is stored in database,
        if (this[key] instanceof Array) {
          this[key] = []
        } else {
          this[key] = ''
        }
      }
    })
  }

  afterExtractDataBase (data: { funds: any[] }) {
    return super.afterExtractData(data)
  }

  afterExtractData (data: { funds: any[] }) {
    const defaultMap = super.afterExtractData(data)

    data.funds = data.funds.map(element => {
      /* eslint-disable @typescript-eslint/camelcase */
      const fund = {
        name: element.name,
        invAmt: element.inv_amt,
        frequency: element.frequency,
        paymentMode: element.payment_mode,
        for: element.for,
        choice: element.choice
      }
      return fund
    })

    Object.assign(data, defaultMap)
    return data
  }

  convertToRawData () {
    const data: any = super.convertToRawData()

    // Convert funds
    /* eslint-disable @typescript-eslint/camelcase */
    const rawFunds = this.funds.map(fund => {
      return {
        name: fund.name,
        inv_amt: fund.invAmt,
        frequency: fund.frequency,
        payment_mode: fund.paymentMode,
        for: fund.for,
        choice: fund.choice
      }
    })
    data.funds = rawFunds
    return data
  }
}
