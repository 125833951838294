import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { IDateService } from '@master/services/IDateService'
import { RelationshipOptions } from '@master/model/options/RelationshipOptions'
import { isNull, isUndefined } from 'lodash/fp'
import { ArrayBase } from '../ArrayBase'
import { MaxChar } from '@master/annotation/MaxChar'
import { GenderOptions } from '../options/GenderOptions'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class Dependant extends ArrayBase {
  type = 'dependant'

  @MaxChar(InputMaxChar.XSMALL)
  name = ''

  @MaxChar(InputMaxChar.XSMALL)
  nric = ''

  dob?: number = undefined
  gender: string | keyof typeof GenderOptions = ''
  yearsSupport = ''

  @MaxChar(InputMaxChar.XSMALL)
  relationship: string | keyof typeof RelationshipOptions = ''

  @Ignore()
  dateService: IDateService

  constructor (dateService: IDateService) {
    super()
    this.dateService = dateService
  }

  @Ignore()
  @Pointer('ROOT.clientDeclaration', 'reviewDate')
  reviewDate?: number = 0

  @Ignore()
  relationshipOptions = RelationshipOptions

  @Ignore()
  genderOptions = GenderOptions

  get age () {
    if (!this.dob) return null
    return this.dateService.calculateAge(this.reviewDate ? new Date(this.reviewDate) : new Date(), new Date(this.dob))
  }

  isValid () {
    return !isUndefined(this.name) && !isNull(this.name) && this.name !== ''
  }
}
