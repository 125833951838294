import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { CpdAttendanceRecordDto, CpdAttendanceRecordService, CpdClassesRecordDto, CpdClassesRecordService, CpdManagementRecordService, CpdMasterRecordDTO, CpdMasterRecordService } from '../api'
import { CpdCourse } from '../model/CpdCourse'

@Module({
  namespaced: true,
  name: 'CpdCourseStore'
})
export default class CpdCourseStore extends VuexModule {
  loading = false
  cpdCourse: CpdMasterRecordDTO = new CpdCourse()
  cpdClasses: CpdClassesRecordDto[] = []
  cpdAttendances: CpdAttendanceRecordDto[] = []
  cpdClassInView: CpdClassesRecordDto = null

  @Action
  async retrieveCourse (courseOid: string) {
    this.context.commit('setLoading', true)

    try {
      const course = await CpdMasterRecordService.retrieveCpdMasterRecord(courseOid)
      this.context.commit('setCourse', course)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setCourse (course: CpdMasterRecordDTO) {
    this.cpdCourse = course
  }

  @Action
  async retrieveClasses (payload) {
    this.context.commit('setLoading', true)
    try {
      const classes = await CpdManagementRecordService.adminGetCpdClasses(payload.courseOid, [], [], payload?.page, payload?.limit)
      this.context.commit('setClasses', classes.data)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setClasses (classes: CpdClassesRecordDto[]) {
    this.cpdClasses = classes
  }

  @Mutation
  updateClassInView (cpdClass: CpdClassesRecordDto) {
    this.cpdClassInView = cpdClass
  }

  @Action
  selectedClassInView (cpdClass: CpdAttendanceRecordDto) {
    this.context.commit('updateClassInView', cpdClass)
  }

  @Action
  async retrieveAttendances (payload) {
    this.context.commit('setLoading', true)
    try {
      const attendances = await CpdManagementRecordService.adminGetAttendances([], [payload.classOid, ''], [], [], payload?.page, payload?.limit)
      this.context.commit('setAttendances', attendances)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setAttendances (attendances: CpdAttendanceRecordDto[]) {
    this.cpdAttendances = attendances
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
