import { Base } from '../Base'
import PoliticalExposedPerson from '@master/model/kyc-form/external/PoliticalExposedPerson'
import { Default } from '@master/annotation/Default'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'
import { IDateService } from '@master/services/IDateService'
import { Ignore } from '@master/annotation/Ignore'
import { cid, container } from 'inversify-props'

export default class ClientDeclaration extends Base {
  identifyingPrioritiesAdditionalNotes = '' // was ma_identifying_priorities_additional_notes // no completion check needed
  clientDeclFcProvideCopy?: boolean = false // was caDeclFcProvideCopy
  clientDeclStatements?: boolean = false // was caDeclStatements
  clientDeclAdvisoryProcess = '' // was declarationAdvisoryProcess
  clientDeclIntroduced = '' // was declarationIntroduced

  @MaxChar(70)
  clientDeclIntroducedId = '' // was declarationIntroducedId

  @MaxChar(70)
  clientDeclIntroducedName = '' // was declarationIntroducedName

  clientDeclPoliticallyExposed = '' // was declarationPoliticallyExposed
  clientDecisionAgree?: boolean = false // used in recommendation page's client decision // no completion check needed
  clientDeclAcknowledgement = '' // was declarationAcknowledgementByClient
  contactingYou?: boolean = false
  crcPartI?: boolean = false
  crcPartIi?: boolean = false
  crcPartIii?: boolean = false
  crcPartIv?: boolean = false
  crcPartV?: boolean = false
  crcPartVi?: boolean = false
  crcPartVii?: boolean = false
  crcPartViii?: boolean = false
  disclosure?: boolean = false
  disclosureAps?: boolean = false
  explainedFeesCharges = ''
  pdpaConsent = '' // was ppdaConsent
  marketingConsent = ''
  reviewDate?: number = undefined // not sure if need completion check
  suggestedNextFinancialUpdate?: number = undefined
  cashRebate = ''
  client0ContactMode: string[] = []
  client1ContactMode: string[] = []

  @MaxChar(InputMaxChar.LARGE)
  discloseDependant?: boolean = false

  relationshipAdviser = ''

  @Default('annually')
  frequency = '' // to determine the calculation of the financial needs analysis calculation, either "monthly" or "annually"

  client0DeclarationPoliticallyExposed = '' // Client 1 declaration political exposed
  client1DeclarationPoliticallyExposed = '' // Client 2 declaration political exposed

  client0AccreditedInvestor = '' // Client 1 accredited investor
  client1AccreditedInvestor = '' // Client 2 accredited investor

  @Default([])
  pepList?: PoliticalExposedPerson[] =[]

  afterExtractData (data: { pepList: any[] }) {
    // Formatting pep list
    const pepList = (!data.pepList.length || Object.keys(data.pepList)) ? [] : data.pepList
    data.pepList = pepList.map((rawPep) => {
      const pep = new PoliticalExposedPerson()
      Object.assign(pep, pep.extractData(rawPep))
      return pep
    })

    this.autosetSuggestedReviewDate()
    return data
  }

  // to get back the raw data for saving to backend
  convertToRawData () {
    this.autosetSuggestedReviewDate()
    const result: any = super.convertToRawData()
    const rawPepList: {}[] = []
    this.pepList.forEach((pep) => {
      rawPepList.push(pep.convertToRawData())
    })
    result.pep_list = rawPepList // eslint-disable-line @typescript-eslint/camelcase
    return result
  }

  autosetSuggestedReviewDate () {
    if (!this.suggestedNextFinancialUpdate && this.reviewDate) {
      const dateService = container.get<IDateService>(cid.IDateService)
      const d = dateService.add(this.reviewDate, 'year', 1)
      this.suggestedNextFinancialUpdate = d.valueOf()
    }
  }
}
