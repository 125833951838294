import { pv, sum, valueIsUndefined } from './../math'
import NeedsBase from '../NeedsBase'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { isUndefined } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class DeathProtectionClient extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  name = 'Death Protection'
  needs = 'Death'
  isProtection = true
  income?: number = undefined
  yearsRequired?: number = undefined
  infAdjReturn?: number = undefined

  // required
  required1?: number = undefined
  required2?: number = undefined
  required3?: number = undefined
  required4?: number = undefined
  required5?: number = undefined

  // available
  available1?: number = undefined
  available2?: number = undefined
  available3?: number = undefined
  available4?: number = undefined
  available5?: number = undefined

  additionalNotes = ''

  /**
   * this will auto set the corresponding rate to default if undefined
   * @param rate
   */
  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.infAdjReturn)) this.infAdjReturn = this.defaultRate
  }

  get annualIncome () {
    if (valueIsUndefined(this.income)) return null
    return (this.projectSettings.needs.period * this.income) || 0
  }

  get fundsRequired () {
    if (valueIsUndefined(this.income) && valueIsUndefined(this.yearsRequired)) return null
    const total = pv(this.annualIncome, this.yearsRequired, this.infAdjReturn)
    return Math.round(total)
  }

  get sumRequired () {
    return sum(this.required1, this.required2, this.required3, this.required4, this.required5)
  }

  get totalRequired () {
    return sum(this.fundsRequired, this.sumRequired)
  }

  get sumAvailable () {
    return sum(this.available1, this.available2, this.available3, this.available4, this.available5)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.sumAvailable) && valueIsUndefined(this.totalRequired)) return null
    return sum(this.sumAvailable, -this.totalRequired)
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings: IProjectSettings, id = 'p1') {
    super(`${type}_${id}`)
    this.projectSettings = projectSettings
  }

  get isDefaultRate () {
    return this.defaultRate === this.infAdjReturn
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true
  }
}
