/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPolicyIndividualDTO } from '../models/AddPolicyIndividualDTO';
import type { AssignIntermediaryDTO } from '../models/AssignIntermediaryDTO';
import type { CancellationLetterDTO } from '../models/CancellationLetterDTO';
import type { ClientPolicyDto } from '../models/ClientPolicyDto';
import type { ClientPolicyReportRequestDTO } from '../models/ClientPolicyReportRequestDTO';
import type { ClientPolicyRequestDto } from '../models/ClientPolicyRequestDto';
import type { ClientPolicySearchDTO } from '../models/ClientPolicySearchDTO';
import type { ExistingPolicyDto } from '../models/ExistingPolicyDto';
import type { FilterPoliciesDTO } from '../models/FilterPoliciesDTO';
import type { IntermediaryPoliciesDTO } from '../models/IntermediaryPoliciesDTO';
import type { IntermediaryPoliciesResponseDTO } from '../models/IntermediaryPoliciesResponseDTO';
import type { ManualNbrDTO } from '../models/ManualNbrDTO';
import type { ManualNbrDTOV2 } from '../models/ManualNbrDTOV2';
import type { NbrImportDto } from '../models/NbrImportDto';
import type { NBRImportDTOV2 } from '../models/NBRImportDTOV2';
import type { NbrRecordsbyKycPdfOidRequestDto } from '../models/NbrRecordsbyKycPdfOidRequestDto';
import type { PolicyIndividualDTO } from '../models/PolicyIndividualDTO';
import type { PolicySearchResultDto } from '../models/PolicySearchResultDto';
import type { ReconReportDTO } from '../models/ReconReportDTO';
import type { SyncPolicyIndividualRequestDTO } from '../models/SyncPolicyIndividualRequestDTO';
import type { SyncPolicyIndividualResponseDTO } from '../models/SyncPolicyIndividualResponseDTO';
import { request as __request } from '@openapi/request';
import { UpdateClientPolicyInformationRequestDTO } from '../models/UpdateClientPolicyInformationRequestDTO';
import type { PolicyComponentRequestDto } from '../models/PolicyComponentRequestDto';
import type { UnAssignedIntermediaryPoliciesDTO } from '../models/UnAssignedIntermediaryPoliciesDTO';
export class ClientPolicyService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async autoExtractProducts(
requestBody: NbrRecordsbyKycPdfOidRequestDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/extract_products`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns PolicySearchResultDto 
     * @throws ApiError
     */
    public static async listPoliciesV2(
requestBody: FilterPoliciesDTO,
): Promise<PolicySearchResultDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns PolicySearchResultDto 
     * @throws ApiError
     */
    public static async existingListPoliciesV2(
requestBody: FilterPoliciesDTO,
): Promise<PolicySearchResultDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/existing/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns ClientPolicyDto Return commission
     * @throws ApiError
     */
    public static async getPolicyV2(
oid: string,
): Promise<ClientPolicyDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/clientPolicy/getPolicy/${oid}`,
        });
        return result.body;
    }

    /**
     * @param policyOid 
     * @returns ClientPolicyDto Return list of sub policies
     * @throws ApiError
     */
    public static async getSubPolicy(
policyOid: string,
): Promise<Array<ClientPolicyDto>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/clientPolicy/getSubPolicy/${policyOid}`,
        });
        return result.body;
    }

    /**
     * @param policyOid 
     * @returns ClientPolicyDto Return commission
     * @throws ApiError
     */
    public static async deleteSubPolicy(
policyOid: string,
requestBody
): Promise<ClientPolicyDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/clientPolicy/subPolicy/${policyOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns ClientPolicyDto 
     * @throws ApiError
     */
    public static async saveNewBusinessV2(
requestBody: ManualNbrDTOV2,
): Promise<ClientPolicyDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/save_new_business`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updatePolicy(
requestBody: ClientPolicyRequestDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/update_policy`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns PolicyIndividualDTO Return list of policy individuals
     * @throws ApiError
     */
    public static async retrievePolicyIndividuals(
oid: string,
): Promise<Array<PolicyIndividualDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/clientPolicy/policyIndividuals/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async addNewPolicyIndividuals(
requestBody: AddPolicyIndividualDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/policyIndividual`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async savePolicyIndividual(
requestBody: PolicyIndividualDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/policyIndividual/save`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param individualOid 
     * @returns any 
     * @throws ApiError
     */
    public static async deletePolicyIndividual(
individualOid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/clientPolicy/policyIndividual/${individualOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateReconReportV2(
requestBody: ClientPolicySearchDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/generate-recon-report`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async validateNbrImportV2(
requestBody: Array<NBRImportDTOV2>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/import/validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns NBRImportDTOV2 
     * @throws ApiError
     */
    public static async importNbrCsvV2(
requestBody: Array<NBRImportDTOV2>,
): Promise<Array<NBRImportDTOV2>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/import/csv`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns SyncPolicyIndividualResponseDTO 
     * @throws ApiError
     */
    public static async syncClientInformation(
requestBody: SyncPolicyIndividualRequestDTO,
): Promise<SyncPolicyIndividualResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/clientPolicy/syncClient`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns ClientPolicyDto Return commission
     * @throws ApiError
     */
    public static async getPolicy(
oid: string,
): Promise<ClientPolicyDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/getPolicy/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateReconReport(
requestBody: ReconReportDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/generate-recon-report`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateAmlReport(
requestBody: ReconReportDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/generate-aml-report`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async amlSaveCheckResults(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/aml/save_check_results`,
        });
        return result.body;
    }

    /**
     * @param type 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async saveIntermediaries(
type: string,
requestBody: Array<AssignIntermediaryDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/save_intermediaries/${type}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns ClientPolicyDto 
     * @throws ApiError
     */
    public static async saveNewBusiness(
requestBody: ManualNbrDTO,
): Promise<ClientPolicyDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/save_new_business`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async extractProducts(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v1/clientPolicy/extract_products`,
        });
        return result.body;
    }

    /**
     * @param list 
     * @param type 
     * @returns any 
     * @throws ApiError
     */
    public static async getIntermediaryCsv(
type: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/intermediary-csv`,
            query: {
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * @param type 
     * @returns IntermediaryPoliciesDTO 
     * @throws ApiError
     */
    public static async getIntermediaryPolicies(
type: string,
): Promise<IntermediaryPoliciesDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/intermediary-policies`,
            query: {
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * @param searchField 
     * @param searchFieldValue 
     * @param type 
     * @param page 
     * @param limit 
     * @param retrieveAll 
     * @returns IntermediaryPoliciesResponseDTO 
     * @throws ApiError
     */
    public static async getAssignedIntermediaryPolicies(
searchField?: string,
searchFieldValue?: string,
type?: string,
page?: number,
limit?: number,
retrieveAll?: boolean,
): Promise<IntermediaryPoliciesResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/assigned-intermediary-policies`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'type': type,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async validateExistingPolicies(
requestBody: Array<ExistingPolicyDto>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/validate-existing-policies`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns ClientPolicyDto 
     * @throws ApiError
     */
    public static async importExistingPolicies(
requestBody: Array<ExistingPolicyDto>,
): Promise<Array<ClientPolicyDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/import-existing-policies`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param kycPdfOid 
     * @param isApprove 
     * @returns any 
     * @throws ApiError
     */
    public static async updateNbrApplicationStatus(
kycPdfOid: string,
isApprove: boolean,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/update-nbr-status/${kycPdfOid}/${isApprove}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async validateNbrImport(
requestBody: Array<NbrImportDto>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/validate-nbr-import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns NbrImportDto 
     * @throws ApiError
     */
    public static async importNbrCsv(
requestBody: Array<NbrImportDto>,
): Promise<Array<NbrImportDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/import-nbr-csv`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async deletePolicies(
requestBody: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/delete-policies`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async uploadCancellationLetter(
requestBody: CancellationLetterDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/clientPolicy/cancellationLetter`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param policyOid 
     * @param toDeleteFileName 
     * @returns any 
     * @throws ApiError
     */
    public static async deleteCancellationLetter(
policyOid: string,
toDeleteFileName: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/clientPolicy/cancellationLetter`,
            query: {
                'policyOid': policyOid,
                'toDeleteFileName': toDeleteFileName,
            },
        });
        return result.body;
    }

    /**
     * @param policyOid 
     * @returns any 
     * @throws ApiError
     */
    public static async downloadCancellationLetter(
policyOid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/clientPolicy/cancellationLetter/${policyOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateAdhocReports(
requestBody: ClientPolicyReportRequestDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/client-policy-report/generate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
 * @param requestBody
 * @returns any
 * @throws ApiError
 */
public static async updateClientPolicyInformation(
    requestBody: UpdateClientPolicyInformationRequestDTO,
): Promise<any> {
    const result = await __request({
        method: 'PUT',
        path: `/web/api/v2/clientPolicy/update-new-business-information`,
        body: requestBody,
    });
    return result.body;
}
 /**
     * @param mainNbrOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
 public static async updateNbrComponent(
    mainNbrOid: string,
    requestBody: PolicyComponentRequestDto,
): Promise<any> {
    const result = await __request({
        method: 'POST',
        path: `/web/api/v2/clientPolicy/component/${mainNbrOid}`,
        body: requestBody,
    });
    return result.body;
}

/**
 * @param oid
 * @returns any
 * @throws ApiError
 */
public static async getAssignedIntermediariesPolicy(
    oid: string,
): Promise<any> {
    const result = await __request({
        method: 'GET',
        path: `/web/api/clientPolicy/assigned-intermediary-policy`,
        query: {
            'oid': oid,
        },
    });
    return result.body;
}

/**
 * @param type
 * @param search
 * @param limit
 * @param selectedOid
 * @returns UnAssignedIntermediaryPoliciesDTO
 * @throws ApiError
    */
public static async getUnAssignedIntermediaryPolicies(
    type: string,
    search?: string,
    limit?: number,
    selectedOid?: string,
): Promise<UnAssignedIntermediaryPoliciesDTO> {
    const result = await __request({
        method: 'GET',
        path: `/web/api/clientPolicy/unassigned-intermediary-policies`,
        query: {
            'type': type,
            'search': search,
            'limit': limit,
            'selectedOid': selectedOid,
        },
    });
    return result.body;
}

}

