import { Ignore } from '@master/annotation/Ignore'
import { MaxChar } from '@master/annotation/MaxChar'
import { RawKey } from '@master/annotation/RawKey'
import { ClientChoiceOptions } from '@master/model/options/ClientChoiceOptions'
import { FrequencyToValueMappingCalculation, FrequencyType } from '@master/model/options/FrequencyOptions'
import { TransactionTypeList } from '@master/model/options/TransactionTypeOptions'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { Base } from '../../Base'
import { ProductMeta } from './../../common/ProductMeta'

export type PriorityId = ''
  | 'P1' | 'P2' | 'P3' | 'P4' | 'P5' | 'P6'
  | 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6'
  | 'S1' | 'S2' | 'S3' | 'S4' | 'S5' | 'S6'
  | 'M1' | 'M2' | 'M3' | 'M4' | 'M5' | 'M6'
export default class ProductBase extends Base {
  @RawKey('typeOfProduct')
  type: '' | 'insurance' | 'investment' | 'investment-subFunds' | 'insurance-rider' = ''

  @RawKey('priority_type')
  priority: PriorityId = ''

  @RawKey('needs_covered')

  need = ''

  @RawKey('issurer')
  issuer = ''

  issuerOid = ''
  frequency: '' | FrequencyType = ''
  clientChoice: '' | keyof typeof ClientChoiceOptions = ''
  dateOfApplication?: number = undefined

  // insurance
  lifeAssured = ''
  lifeAssuredTwo = ''
  sumAssured: number = undefined
  premiumAmount: number = undefined

  // investment
  investmentAmount: number = undefined

  @MaxChar(32)
  accountNumber = undefined

  // Use for NBR only
  policyNumber = ''

  transactionType: '' | typeof TransactionTypeList[number] = ''
  principal = ''
  provider :any = undefined
  introducerOid : string = null 
  introducerIfam :boolean = null 
  lineOfBusinessInfo: any = null 
  categoryInfo :any = null 
  productTypeInfo : any =null 
  // get from product
  @RawKey('_oid')
  oid = '' // product id

  @RawKey('plan_name')
  productName = ''

  productType = ''
  productCategory = ''
  recommendedRiskProfile: number = undefined
  productNeedsCovered: string[] = []
  productReasons = ''
  productLimitations = ''
  productFamilyCode = ''
  productFamilyName = ''

  referenceId = '' // base64 randomly generated alphanumeric

  isCustom = false
  @Ignore()
  riskRatingService: IRiskRatingService

  @Ignore()
  ownerName?: string

  @Ignore()
  priorityName?: string

  constructor (riskRatingService: IRiskRatingService) {
    super()
    this.riskRatingService = riskRatingService
  }

  randomString (length) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = ''
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
    return result
  }

  beforeExtractData (product: any) {
    /* eslint-disable @typescript-eslint/camelcase */
    product.premium_amount = typeof product.premium_amount === 'string' ? parseFloat(product.premium_amount) : product.premium_amount
    product.client_index = typeof product.client_index === 'string' ? parseFloat(product.client_index) : product.client_index
    product.sum_assured = typeof product.sum_assured === 'string' ? parseFloat(product.sum_assured) : product.sum_assured
    /* eslint-enable */
    return product
  }

  setProduct (product: ProductMeta) {
    this.oid = product?.oid
    this.issuer = product?.issuer
    this.issuerOid = product?.issuer_oid
    this.productName = product?.name
    this.productType = product?.type
    this.productCategory = product?.category
    this.productFamilyCode = product?.productFamilyCode
    this.productFamilyName = product?.productFamilyName
    this.recommendedRiskProfile = product?.recommendedRiskProfile
    this.productNeedsCovered = product?.needsCovered
    this.productReasons = product?.reasons
    this.productLimitations = product?.limitations
  }

  resetProduct () {
    this.oid = null
    this.productName = null
    this.productType = null
    this.productCategory = null
    this.productFamilyCode = null
    this.productFamilyName = null
    this.recommendedRiskProfile = null
    this.productReasons = null
    this.productLimitations = null
  }

  isNtuc () {
    const companyNameMin = this.issuer.replace(/ /g, '').toLowerCase()
    if (companyNameMin === 'ntuc' || companyNameMin === 'income' || companyNameMin === 'ntucincome') {
      return true
    }
    return false
  }

  get isInvestmentProduct () {
    return this.type === 'investment' || this.type === 'investment-subFunds' // add investment-subFunds to handle BasicProductV2
  }

  get annualisedPremium () {
    let annualPremium = 0
    let premiumAmt = 0
    if (this.frequency.toLocaleLowerCase() === 'single') {
      return 0
    }

    if (this.isInvestmentProduct) {
      // sum_assured might not be the correct name, this value corresponds to the Investment Amount
      // also not sure if it is accurate to consider investment amount a premium
      // may need to move this elsewhere or rename the functions
      premiumAmt = this.sumAssured
    } else {
      premiumAmt = this.premiumAmount
    }

    if (this.frequency) {
      annualPremium = premiumAmt * FrequencyToValueMappingCalculation(this.frequency)
    }
    return parseFloat(annualPremium.toFixed(2))
  }

  get singlePremium () {
    if (this.frequency.toLocaleLowerCase() !== 'single') {
      return 0
    }

    if (this.frequency && this.frequency.toLowerCase() === 'single') {
      if (this.isInvestmentProduct) {
        // sum_assured might not be the correct name, this value corresponds to the Investment Amount
        // also not sure if it is accurate to consider investment amount a premium
        // may need to move this elsewhere or rename the functions
        return this.sumAssured
      } else {
        return this.premiumAmount
      }
    }
    return 0
  }

  @Ignore()
  get recommendedRiskProfileString () {
    return this.riskRatingService.riskRatingMap()[this.recommendedRiskProfile] || ''
  }

  getReasons () {
    return `${this.issuer} - ${this.productName}
${this.productReasons}`
  }

  getLimitations () {
    return `${this.issuer} - ${this.productName}
${this.productLimitations}`
  }

  @Ignore()
  get isAcceptedProduct () {
    return this.clientChoice === 'accept' || this.clientChoice === 'ownchoice'
  }

  @Ignore()
  get isRider () {
    return this.type === 'insurance-rider'
  }

  @Ignore()
  get isFund () {
    return this.type === 'investment-subFunds'
  }

  get isIlpPlan () {
    if (!this.productType) return false
    return this.productType.toLowerCase().includes('ilp') || this.productCategory.toLowerCase().includes('investment-linked plan')
  }
}
